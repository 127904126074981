import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ReactComponent as EditIcon } from "assets/svg/icon/edit_message.svg";
import { ReactComponent as CopyIcon } from "assets/svg/icon/message_copy.svg";
import { ReactComponent as ForwardIcon } from "assets/svg/icon/message_forward.svg";
import { ReactComponent as InformationIcon } from "assets/svg/icon/message_information.svg";
import { ReactComponent as PinIcon } from "assets/svg/icon/message_pin.svg";
import { ReactComponent as ReplyIcon } from "assets/svg/icon/message_reply.svg";
import { ReactComponent as SaveIcon } from "assets/svg/icon/message_save.svg";
import { ReactComponent as TrashIcon } from "assets/svg/icon/message_trash.svg";
import { ReactComponent as TrashBackIcon } from "assets/svg/icon/message_trash_black.svg";
import { ReactComponent as TipIcon } from "assets/svg/icon/tip_icon.svg";
import PropTypes from "prop-types";
import { memo } from "react";

import { IconButton, Popover } from "@mui/material";
import styles from "assets/styles/pages/Chat/component/MessageActionSheet";
import axios from "axios";
import {
  CHAT_ACTIVE_TYPE,
  MESSAGE_TYPE,
  TYPE_ACTION_MESSAGE,
  VALUE_ACTION_MESSAGE,
} from "constant/chat";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function MessageActionSheet({
  indexImg = 0,
  openSetting,
  handleClose,
  handleDownMediaMessage,
  handleOpenViewerDialog,
  handleOpenEditMessage,
  handleOpenReplyMessage,
  mouseX,
  mouseY,
  userInformation,
  handleOpenForwardDialog,
  handleOpenPinMessage,
  handleFeeMessage,
  handleTipMessage,
  isOwner,
  detailMessage,
  handleDeleteMessage,
}) {
  const { t } = useTranslation();

  const handleCopy = async () => {
    if (detailMessage.kind === MESSAGE_TYPE.TEXT) navigator.clipboard.writeText(detailMessage.text);

    if (detailMessage.kind === MESSAGE_TYPE.IMAGE) {
      try {
        axios
          .get(detailMessage.image[indexImg].url, { responseType: "arraybuffer" })
          .then(async (response) => {
            const blob = new Blob([response.data], { type: response.headers["content-type"] });
            const blobPng = new Blob([blob], { type: "image/png" });
            const clipboardItem = new ClipboardItem({ "image/png": blobPng });
            await navigator.clipboard.write([clipboardItem]);
          });
      } catch (error) {
        console.error(error);
      }
    }

    handleClose();
  };

  const checkMeReact = () => {
    const react = detailMessage?.likeUsers.filter((item) => item.id === userInformation.id);
    return TYPE_ACTION_MESSAGE[react?.[0]?.value];
  };

  return (
    <Popover
      open={openSetting}
      disableEnforceFocus
      anchorReference="anchorPosition"
      anchorPosition={{ top: mouseY, left: mouseX }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      sx={styles.popper}
      onContextMenu={(e) => e.preventDefault()}
    >
      <Box onClick={(e) => e.stopPropagation()}>
        <Paper sx={styles.emotionPaper}>
          {["LIKE", "DISLIKE", "SAD", "FUN", "LOVE"].map((emotion) => (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleFeeMessage(VALUE_ACTION_MESSAGE[emotion]);
              }}
              sx={() =>
                styles.emotionButton({ isBackground: checkMeReact() === emotion?.toLowerCase() })
              }
            >
              <img
                src={`/emotion/${emotion?.toLowerCase()}.webp`}
                alt={emotion}
                width="30"
                height="30"
                style={{
                  "&:hover": {
                    width: "40px",
                    height: "40px",
                  },
                }}
              />
            </IconButton>
          ))}

          {!isOwner && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleTipMessage(VALUE_ACTION_MESSAGE.TIP);
              }}
              // sx={() => styles.emotionButton({ isBackground: emotion === "tip" })}
            >
              <TipIcon style={styles.emotionIcon} />
            </IconButton>
          )}
        </Paper>
        <Paper sx={styles.paper}>
          <ClickAwayListener disableReactTree onClickAway={handleClose}>
            <Box>
              <Box
                onClick={() => handleOpenViewerDialog(detailMessage)}
                sx={styles.topItemContainer}
              >
                <InformationIcon style={styles.icon} />
                <Typography variant="body1" sx={styles.item}>
                  {t("chat.content_chat.message_action_sheet.information")}
                </Typography>
              </Box>
              <Divider sx={styles.divider} />
              <Box onClick={() => handleOpenReplyMessage(detailMessage)} sx={styles.itemContainer}>
                <ReplyIcon style={styles.icon} />
                <Typography variant="body1" sx={styles.item}>
                  {t("chat.content_chat.message_action_sheet.reply")}
                </Typography>
              </Box>
              {/* Edit */}
              {isOwner && detailMessage?.kind === MESSAGE_TYPE.TEXT && (
                <>
                  <Divider sx={styles.divider} />
                  <Box onClick={handleOpenEditMessage} sx={styles.itemContainer}>
                    <EditIcon style={styles.icon} />
                    <Typography variant="body1" sx={styles.item}>
                      {t("chat.content_chat.message_action_sheet.edit")}
                    </Typography>
                  </Box>
                </>
              )}

              <Divider sx={styles.divider} />
              {/* Pin */}
              <Box onClick={handleOpenPinMessage} sx={styles.itemContainer}>
                <PinIcon style={styles.icon} />
                <Typography variant="body1" sx={styles.item}>
                  {t("chat.content_chat.message_action_sheet.pin")}
                </Typography>
              </Box>
              <Divider sx={styles.divider} />
              {/* Copy */}

              {[MESSAGE_TYPE.IMAGE, MESSAGE_TYPE.TEXT].includes(detailMessage.kind) && (
                <Box onClick={() => handleCopy()} sx={styles.itemContainer}>
                  <CopyIcon style={styles.icon} />
                  <Typography variant="body1" sx={styles.item}>
                    {t("chat.content_chat.message_action_sheet.copy_text")}
                  </Typography>
                </Box>
              )}

              <Divider sx={styles.divider} />
              {/* Forward */}
              {/* ---DISABLE--- */}

              <Box onClick={() => handleOpenForwardDialog(detailMessage)} sx={styles.itemContainer}>
                <ForwardIcon style={styles.icon} />
                <Typography variant="body1" sx={styles.item}>
                  {t("chat.content_chat.message_action_sheet.forward")}
                </Typography>
              </Box>
              <Divider sx={styles.divider} />
              {/* Save */}
              {[MESSAGE_TYPE.IMAGE, MESSAGE_TYPE.AUDIO].includes(detailMessage.kind) && (
                <Box onClick={handleDownMediaMessage} sx={styles.itemContainer}>
                  <SaveIcon style={styles.icon} />
                  <Typography variant="body1" sx={styles.item}>
                    {t("chat.content_chat.message_action_sheet.save")}
                  </Typography>
                </Box>
              )}

              {[MESSAGE_TYPE.VIDEO].includes(detailMessage.kind) && (
                <Box component={Link} to={detailMessage.video.url} sx={styles.itemContainer}>
                  <SaveIcon style={styles.icon} />
                  <Typography variant="body1" sx={styles.item}>
                    {t("chat.content_chat.message_action_sheet.save")}
                  </Typography>
                </Box>
              )}

              {isOwner && (
                <>
                  <Divider sx={styles.divider} />
                  {/* Delete */}
                  <Box
                    onClick={() => handleDeleteMessage(CHAT_ACTIVE_TYPE.removeForAll)}
                    sx={styles.itemContainer}
                  >
                    <TrashIcon style={styles.icon} />
                    <Typography variant="body1" sx={styles.itemErrorColor}>
                      {t("chat.content_chat.message_action_sheet.delete_everyone")}
                    </Typography>
                  </Box>
                </>
              )}

              <Divider sx={styles.divider} />
              {/* Delete */}
              <Box
                onClick={() => handleDeleteMessage(CHAT_ACTIVE_TYPE.removeForMe)}
                sx={styles.bottomItemContainer}
              >
                {isOwner ? (
                  <TrashBackIcon style={styles.icon} />
                ) : (
                  <TrashIcon style={styles.icon} />
                )}
                <Typography variant="body1" sx={(e) => styles.itemColor(e, { isOwner })}>
                  {t("chat.content_chat.message_action_sheet.delete")}
                </Typography>
              </Box>
            </Box>
          </ClickAwayListener>
        </Paper>
      </Box>
    </Popover>
  );
}

MessageActionSheet.defaultProps = {
  openSetting: false,
};

MessageActionSheet.propTypes = {
  openSetting: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

export default memo(MessageActionSheet);
