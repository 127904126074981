export default {
  container: ({ breakpoints }) => ({
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.15)",
    [breakpoints.down("lg")]: {
      padding: "0 8px",
    },
  }),
  flexBoxRight: ({ breakpoints }) => ({
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [breakpoints.down("lg")]: {
      flexDirection: "column-reverse",
      mr: 1.5,
    },
  }),
  listIcon: ({ breakpoints }) => ({
    height: "26px",
    [breakpoints.down("lg")]: {
      width: "100%",
      marginBottom: "5px",
    },
  }),
  positionCenter: ({ breakpoints }) => ({
    width: "190px",
    display: "flex",
    [breakpoints.down("lg")]: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    [breakpoints.between("lg", "xxl")]: {
      width: "180px",
    },
  }),
  flexGrowResponsive: () => ({
    borderLeft: "2px solid #cdcccc",
  }),
  boxRight: ({ breakpoints }) => ({
    width: "190px",
    padding: "20px 10px",
    [breakpoints.down("lg")]: {
      width: "64px",
    },
    [breakpoints.between("lg", "xl")]: {
      width: "100px",
    },
  }),
  flexEnd: ({ breakpoints }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    [breakpoints.down("md")]: {
      flexGrow: 1,
    },
  }),
  actionIcon: {
    width: "26px",
    marginRight: "16px",
    height: "26px",
  },
  input: {
    marginLeft: "20px",
    fontSize: "20px",
    width: "120px",
  },
  sendButton: {
    width: "40px",
    height: "40px",
  },
};
