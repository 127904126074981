import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import styles from "assets/styles/pages/Chat/component/ForwardDialog";
import { InputAdornment, TextField, Stack, Avatar } from "@mui/material";
import { ReactComponent as SearchIcon } from "assets/svg/icon/search.svg";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import defaultAvatar from "assets/svg/icon/default_avatar_group.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import TabPanel from "components/TabPanel";
import { useTranslation } from "react-i18next";
import Checkbox from "./CheckBox";

export default function ForwardDialog({
  onClose,
  isOpen,
  selectForwardIndex,
  setSelectForwardIndex,
  handleDoneForwardDialog,
  listGroupChat,
}) {
  // state
  const { t } = useTranslation();
  const [value] = useState(0);
  const [isEmpty, setIsEmpty] = useState(true);
  const [searchResults, setSearchResults] = useState(null);
  // hook
  // hook
  useEffect(() => {
    if (selectForwardIndex.length > 0) setIsEmpty(false);
    else setIsEmpty(true);
  }, [selectForwardIndex]);
  // function
  const handleCheckBox = (id) => {
    const cpList = [...selectForwardIndex];
    if (cpList.includes(id)) {
      const newList = cpList.filter((item) => item !== id);
      setSelectForwardIndex(newList);
    } else {
      cpList.push(id);
      setSelectForwardIndex(cpList);
    }
  };
  const handleClose = (id) => {
    const cpList = [...selectForwardIndex];
    if (cpList.includes(id)) {
      const newList = cpList.filter((item) => item !== id);
      setSelectForwardIndex(newList);
    } else {
      cpList.push(id);
      setSelectForwardIndex(cpList);
    }
  };

  function searchByName(name, list) {
    const searchTerm = name?.toLowerCase().trim();
    if (searchTerm === "") {
      return list; // Trả về toàn bộ danh sách nếu không có từ khóa tìm kiếm
    }
    return list.filter((item) => {
      const itemName = item.name?.toLowerCase();
      return itemName.includes(searchTerm);
    });
  }

  const handleSearch = (event) => {
    const name = event.target.value;
    const results = searchByName(name, listGroupChat);
    setSearchResults(results);
  };

  return (
    <Dialog sx={styles.dialog} onClose={onClose} open={isOpen}>
      <DialogTitle sx={styles.dFlexColumn}>
        <Box />
        <Box sx={styles.dialogTitle}>
          <IconButton onClick={onClose} sx={styles.paddingReset}>
            <Typography variant="body2" sx={styles.doneActive}>
              {t("forward_message.close")}
            </Typography>
          </IconButton>
          <Box sx={styles.textCenter}>
            <Typography variant="body1" color="initial" sx={styles.title}>
              {t("forward_message.forward_to")}
            </Typography>
            <Typography
              variant="caption"
              sx={styles.textDefault}
            >{`${selectForwardIndex.length}`}</Typography>
          </Box>
          <IconButton disabled={isEmpty} onClick={handleDoneForwardDialog} sx={styles.paddingReset}>
            <Typography variant="body2" sx={isEmpty ? styles.doneDefault : styles.doneActive}>
              {t("forward_message.done")}
            </Typography>
          </IconButton>
        </Box>
        <TextField
          placeholder={t("chat.user_list.search")}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={styles.searchIcon} position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            autoComplete: "off",
          }}
          sx={styles.search}
        />
      </DialogTitle>
      <Divider sx={styles.divider} />
      <Box sx={styles.contentContainer}>
        {selectForwardIndex.length !== 0 && (
          <Box sx={styles.boxShowSelected}>
            <Box sx={styles.overflowX}>
              {listGroupChat.map((item) => (
                <Box key={item.id}>
                  {selectForwardIndex.includes(item.id) && (
                    <Box key={item.id} sx={styles.selectedImage}>
                      <Box sx={{ ...styles.avatarImageSelected, mr: 1 }}>
                        <Avatar src={defaultAvatar} srcSet={item.image} sx={styles.avatarImage} />
                      </Box>
                      <IconButton onClick={() => handleClose(item.id)} sx={styles.closeImageIcon}>
                        <CloseIcon sx={styles.iconW18} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <Box sx={styles.countSelectedBox}>
          <Typography variant="caption" color="initial" sx={styles.subTitle}>
            Gợi ý
          </Typography>
        </Box>
        <TabPanel value={value} sx={styles.tabPanel} index={0}>
          <Stack sx={styles.overflowY}>
            {(searchResults || listGroupChat).map((item) => (
              <Box key={item.id} sx={styles.boxCheckBox} onChange={() => handleCheckBox(item.id)}>
                <Checkbox
                  label={
                    <Box sx={styles.checkBox}>
                      <Avatar src={defaultAvatar} srcSet={item.image} sx={styles.avatarImage} />
                      <Typography variant="caption" color="initial" sx={styles.label}>
                        {item.name}
                      </Typography>
                    </Box>
                  }
                  checked={Boolean(selectForwardIndex.includes(item.id))}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              </Box>
            ))}
          </Stack>
        </TabPanel>
      </Box>
    </Dialog>
  );
}
