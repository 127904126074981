import { isEmpty } from "../../../../../utils/validate";

export default {
  container: ({ statusMessage }) => ({
    marginTop: statusMessage ? "4px" : "10px",
    // p: 1,
  }),
  section: ({ isOwner }) => ({
    cursor: "pointer",
    display: "flex",
    alignSelf: isOwner ? "flex-end" : "flex-start",
    flexDirection: isOwner ? "row-reverse" : "row",
  }),
  avatar: {
    mr: 1.5,
    width: "30px",
    height: "30px",
    alignSelf: "flex-start",
  },
  messageSection: ({ borders }, { isOwner }) => ({
    minWidth: "120px",
    minHeight: "60px",
    overflow: "hidden",
    borderTopRightRadius: !isOwner && borders.borderRadius.xl,
    borderTopLeftRadius: isOwner ? borders.borderRadius.xl : 0,
    borderBottomRightRadius: borders.borderRadius.xl,
    borderBottomLeftRadius: borders.borderRadius.xl,
  }),
  // local
  emojiContainer: ({ palette }, { statusMessage }) => ({
    cursor: "pointer",
    background: palette.white.main,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EDF1F8",
    marginLeft: !statusMessage ? "0px" : "43px",
  }),
  nameContainer: {
    width: "100%",
    flexShrink: 0,
    px: 1,
    my: 0.5,
  },
  username: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    color: "#406EE2",
    display: "inline",
    "&:hover": {
      textDecoration: "underline", // hoặc các kiểu khác như 'underline', 'solid', 'dashed', etc.
      color: "#406EE2",
      textDecorationThickness: "1.5px",
    },
  }),

  userID: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    // background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    background: "#406EE2",

    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    pl: 0.7,
    display: "inline",
  }),
  emojiSection: ({ isOwner }) => ({
    display: "flex",
    justifyContent: isOwner ? "flex-end" : "flex-start",
    minWidth: "120px",
    minHeight: "60px",
    backgroundColor: "#EDF1F8 !important",
    lineHeight: 0,
    height: "170px",
  }),
  imageEmoji: {
    objectFit: "cover",
    maxHeight: "45vh",
  },
  emojiTimeContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    transform: "translate(-15px,0px)",
    display: "flex",
  },
  emojiTime: ({ palette }) => ({
    color: palette.white.inherit,
    fontSize: "13px",
  }),
  messageSendedIcon: {
    flexShrink: 0,
    height: "14px",
    marginLeft: "8px",
  },
  messagePendingIcon: {
    flexShrink: 0,
    height: "14px",
    width: "14px",
    marginLeft: "8px",
  },
  sendedIcon: {
    flexShrink: 0,

    width: "22px",
    marginLeft: "8px",
  },
  emotionContainer: ({ palette, borders }, { isOwner }) => ({
    flexShrink: 0,
    display: "flex",
    // border: `1.5px solid ${palette.grey[250]}`,
    // background: palette.white.main,
    // borderRadius: borders.borderRadius.round,

    background: isOwner ? palette.white.main : "",
    border: `1.5px solid ${palette.grey[250]}`,
    // background: palette.white.main,
    borderRadius: borders.borderRadius.round,
    p: 0.4,
    mx: 0.5,
    alignItems: "center",
    cursor: "pointer",
  }),
  emotionIcon: {
    height: "16px",
    width: "100%",
    maxWidth: "20px",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  emotionCount: () => ({
    paddingLeft: "3px",
    paddingRight: "4px",
    color: "#898989",
    fontSize: "12px",
  }),
  tipIcon: {
    height: "16px",
    width: "100%",
    maxWidth: "30px",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  tipCount: () => ({
    paddingLeft: "3px",
    paddingRight: "4px",
    minWidth: "30px",
    color: "#898989",
    fontSize: "12px",
  }),
  replyContainer: ({ borders }, { isOwner, isDisplayUsername, statusMessage }) => ({
    background: isOwner ? "#406EE2" : "rgba(0, 0, 0, 0.1)",
    px: 1,
    py: 0.5,
    // mb: 0.5,
    borderTopRightRadius:
      !isOwner && isDisplayUsername && statusMessage ? borders.borderRadius.xl : 0,
    borderTopLeftRadius: isOwner || !isDisplayUsername ? borders.borderRadius.xl : 0,
  }),
  replyIconContainer: ({ palette }, { isOwner }) => ({
    path: {
      fill: isOwner ? palette.white.main : "#454545",
    },
    height: "10px !important",
    width: "12px !important",
  }),
  replyIcon: {
    marginTop: "4px",
    height: "10px !important",
    width: "12px !important",
  },
  replyName: ({ typography, palette }, { isOwner }) => ({
    color: isOwner ? palette.white.main : palette.black.main,
    fontWeight: typography.fontWeightBold,
    fontSize: typography.size.xs,
    marginLeft: "5px",
  }),
  messageImage: {
    display: "flex",
    alignItems: "center",
    marginBottom: "2px",
    // flexGrow: 1,
  },
  boxImageEmoji: {
    width: "45px",
    height: "45px",
    position: "relative",
  },
  boxImage: {
    width: "40px",
    height: "40px",
    mr: "5px",
    position: "relative",
  },
  boxFile: {
    width: "40px",
    height: "40px",
    position: "relative",
  },
  downloadIcon: {
    width: "30px",
  },
  imageReply: ({ borders }) => ({
    flexShrink: 0,
    border: "1px solid #D5D5D5",
    width: "40px",
    objectFit: "cover",
    height: "40px",
    borderRadius: borders.borderRadius.md,
  }),
  imageVideoBg: ({ borders }) => ({
    background: "black",
    opacity: 0.5,
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    height: "40px",
    borderRadius: borders.borderRadius.md,
  }),
  imagePlayBg: ({ borders }) => ({
    width: "30px",
    opacity: 1,
    position: "absolute",
    top: 5,
    left: 5,
    height: "30px",
    borderRadius: borders.borderRadius.md,
  }),
  imageBg: {
    // background: "black",
    // opacity: 0.5,
    width: "100%",
    // position: "absolute",
    height: "40px",
    // borderRadius: borders.borderRadius.md,
  },
  numberImagePin: ({ typography }) => ({
    position: "absolute",
    top: "30%",
    left: "30%",
    color: "#ffff",
    fontSize: "14px",
    fontFamily: typography.fontFamily3,
  }),
  replyMessage: ({ typography, palette }, { isOwner }) => ({
    color: isOwner ? palette.white.main : palette.black.main,
    fontSize: typography.size.xs,
    // marginLeft: "6px",
  }),
  message: ({ palette, typography }, { replyName, replyMessage, isOwner }) => ({
    color: isOwner ? palette.white.main : palette.black.main,
    px: 2,
    mt: !isEmpty(replyName) && !isEmpty(replyMessage) ? 1 : 0,
    fontSize: typography.size.sm,
    a: {
      color: isOwner ? palette.white.main : palette.black.main,
      textDecoration: "underline",
    },
  }),
  textMessageBottom: ({ typography }, { isOwner }) => ({
    fontFamily: typography.fontFamily3,
    color: isOwner ? "#FFFFFF" : "#406EE2",
    fontSize: "14px",
  }),
  linkContainer: ({ isOwner }) => ({
    width: "100%",
    overflow: "hidden",
    mt: isOwner ? -1.5 : 0,
  }),
  noImage: ({ borders }, { isOwner }) => ({
    width: "100%",
    borderTopRightRadius: !isOwner ? borders.borderRadius.xl : 0,
    borderTopLeftRadius: isOwner ? borders.borderRadius.xl : 0,
    maxHeight: "25vh",
    background: "#ffff",
  }),
  linkContentContainer: {
    px: 2,
    maxWidth: "100%",
  },
  linkTitle: ({ typography, palette }, { isOwner }) => ({
    pt: 0.5,
    fontWeight: typography.fontWeightBold,
    color: isOwner ? palette.white.main : palette.black.main,
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  }),
  link: ({ palette, typography }, { isOwner }) => ({
    pt: 0.5,
    color: isOwner ? palette.white.main : palette.black.main,
    a: {
      color: isOwner ? palette.white.main : "#406EE2",
      textDecoration: "underline",
    },
    fontSize: typography.size.xs,
  }),
};
