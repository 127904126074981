export default {
  popper: () => ({
    minWidth: "220px",
    width: "11%",
    zIndex: 2000,
  }),
  paper: ({ borders }) => ({
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.3)",
    width: "auto",
    minWidth: "265px",
    borderRadius: borders.borderRadius.xl,
  }),
  topItemContainer: ({ palette, borders }) => ({
    borderTopLeftRadius: borders.borderRadius.xl,
    borderTopRightRadius: borders.borderRadius.xl,
    display: "flex",
    height: "45px",
    width: "100%",
    justifyContent: "flex-start",
    py: 1.5,
    px: 1.5,
    cursor: "pointer",
    alignItems: "center",
    "&:hover": {
      backgroundColor: palette.grey[100],
    },
  }),
  itemContainer: ({ palette }) => ({
    display: "flex",
    height: "45px",
    width: "100%",
    justifyContent: "flex-start",
    py: 1.5,
    px: 1.5,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: palette.grey[100],
    },
  }),
  bottomItemContainer: ({ palette, borders }) => ({
    borderBottomLeftRadius: borders.borderRadius.xl,
    borderBottomRightRadius: borders.borderRadius.xl,
    display: "flex",
    height: "45px",
    width: "100%",
    justifyContent: "flex-start",
    py: 1.5,
    px: 1.5,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: palette.grey[100],
    },
  }),
  item: ({ palette }) => ({
    color: palette.text.main,
    ml: 1,
  }),
  itemColor: {
    ml: 1,
  },
  container: ({ breakpoints, borders }) => ({
    backdropFilter: "blur(2px)",
    py: 2,
    minWidth: "180px",
    width: "100%",
    height: "190px",
    borderRadius: borders.borderRadius.lg,
    [breakpoints.down("sm")]: {
      width: "calc(100vw - 32px)",
    },
  }),
  settingItem: {
    ml: 2,
    fontWeight: 500,
    userSelect: "none",
  },
  settingItemWithMarginContainer: {
    width: "100%",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    my: 1,
  },
  settingItemButton1: {
    // borderRadius: borders.borderRadius.md,
    width: "98%",
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "4px",
    transition: "background-color 0.3s", // Thêm hiệu ứng chuyển động cho background-color
    "&:hover": {
      backgroundColor: "#F6F6F6", // Màu nền khi hover
    },
    color: "#FFF",
  },
  settingItemButton2: {
    // borderRadius: borders.borderRadius.md,
    // marginTop: "10px",
    width: "98%",
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "4px",
    transition: "background-color 0.3s", // Thêm hiệu ứng chuyển động cho background-color
    "&:hover": {
      backgroundColor: "#F6F6F6", // Màu nền khi hover
    },
    "&:active": {
      backgroundColor: "#d7d3d3", // Màu khi nút được click
    },
    color: "#FFF",
  },
  icon: {
    height: "22px",
    width: "22px",
  },
};
