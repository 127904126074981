import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import styles from "assets/styles/pages/Chat/component/TipPopup";
import { Button, Typography, Grid } from "@mui/material";
import { ReactComponent as CloseVoteIcon } from "assets/svg/icon/close_vote.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as TipIcon } from "assets/svg/icon/tip_icon.svg";
import { ReactComponent as TipIconWhite } from "assets/svg/icon/point_icon_white.svg";
import { formatNumber } from "utils/convert";
import { VALUE_TIP } from "constant/chat";
import { useState } from "react";

function PollPopper({ openVote, handleClose, handleTip, pointUser }) {
  const { t } = useTranslation();
  const [isShowButtonTip, setShowButtonTip] = useState(true);
  const [value, setValue] = useState(0);
  return (
    <Popper
      sx={styles.popper}
      placement="right-end"
      open={openVote}
      anchorEl={undefined}
      role={undefined}
      transition
      disablePortal
      style={{ position: "fixed", bottom: 0, right: 0, top: "unset", left: "unset" }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={styles.paper}>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              disableReactTree
              onClickAway={() => {
                setShowButtonTip(true);
                handleClose();
                setValue(0);
              }}
            >
              <Box sx={styles.popperPoll}>
                <Box sx={styles.boxHeader}>
                  <Box sx={styles.boxTitle}>
                    <Box sx={styles.pointContainer}>
                      <TipIcon />
                      <Box sx={styles.infoPoint}>
                        <Typography sx={styles.surplus} variant="body1">
                          {t("chat.footer.tip.surplus")}:
                        </Typography>
                        <Typography sx={styles.point} variant="body1">
                          {formatNumber(pointUser?.point)}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant="body1" color="initial" sx={styles.textTitle}>
                      {t("chat.footer.tip.title")}
                    </Typography>
                    <Box sx={{ mr: "17px" }}>
                      <IconButton
                        onClick={() => {
                          setShowButtonTip(true);
                          handleClose();
                          setValue(0);
                        }}
                      >
                        <CloseVoteIcon sx={styles.icon} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box sx={styles.noteContainer}>
                  <Typography sx={styles.titleNoteOne} variant="body1">
                    {t("chat.footer.tip.note1")}
                  </Typography>
                  <Typography sx={styles.titleNoteTwo} variant="body1">
                    {t("chat.footer.tip.note2")}
                  </Typography>
                </Box>
                <Box>
                  <Box id="items" sx={styles.boxAnswer}>
                    <Grid
                      container
                      spacing={{ xs: 3, sm: 3, md: 3 }}
                      columns={{ xs: 12, sm: 12, md: 12 }}
                    >
                      {VALUE_TIP.map((item, index) => (
                        <Grid item xs={4} sm={4} md={4} key={index}>
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowButtonTip(false);
                              setValue(item.value);
                            }}
                            id="create_poll"
                            variant="outlined"
                            sx={(e) => styles.buttonPoint(e, { value: item.value === value })}
                          >
                            <Box sx={{ pr: 1, mt: 0.5 }}>
                              <TipIcon />
                            </Box>
                            {formatNumber(item.value)}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Box sx={styles.dFlexCenter}>
                    <Button
                      disabled={isShowButtonTip || value > pointUser?.point}
                      sx={styles.buttonTipPoint}
                      id="create_poll"
                      variant="outlined"
                      onClick={() => {
                        handleTip(value);
                        handleClose();
                        setShowButtonTip(true);
                        setValue(0);
                      }}
                    >
                      <TipIconWhite />
                      <Typography sx={styles.textTip} variant="body1">
                        {t("chat.footer.tip.give")}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default PollPopper;
