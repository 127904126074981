import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import en from "translations/en.json";
import vi from "translations/vi.json";
import moment from "moment";
import "moment/min/locales";
import { getLanguage } from "utils/storage";

i18next.use(initReactI18next).init({
  lng: getLanguage() || "vi",
  fallbackLng: "en",
  compatibilityJSON: "v3",
  resources: {
    en: {
      translation: en,
    },
    vi: {
      translation: vi,
    },
  },
  interpolation: {
    format(value, format, lng) {
      moment.locale(lng);
      if (format === "ago") {
        return moment(value).fromNow();
      }
      return value;
    },
  },
});

export default i18next;
