import { useRenameGroup } from "service";
import { v4 } from "uuid";

import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import styles from "assets/styles/pages/Chat/section/ChatInformation";
import { ReactComponent as Ring } from "assets/svg/icon/ring.svg";
import { ReactComponent as AddMember } from "assets/svg/icon/add_member.svg";
import { ReactComponent as ApprovalIcon } from "assets/svg/icon/approval.svg";
import { ReactComponent as TrashIcon } from "assets/svg/icon/Icon_trash.svg";
import { ReactComponent as AddGroup } from "assets/svg/icon/add_group.svg";
import { ReactComponent as AddMembersGroup } from "assets/svg/icon/add_group3.svg";
import { ReactComponent as SettingIcon } from "assets/svg/icon/setting2.svg";
// import { ReactComponent as AddPrivateGroup } from "assets/svg/icon/add_private_group.svg";
import { ReactComponent as Participants } from "assets/svg/icon/participants.svg";
import { ReactComponent as EditNameGroupIcon } from "assets/svg/icon/edit_name_group.svg";
import { ReactComponent as CameraGroupIcon } from "assets/svg/icon/camera_group.svg";
import { ReactComponent as CloseIcon } from "assets/svg/icon/close_icon.svg";
import defaultGroupAvatar from "assets/svg/icon/default_info_avatar_group.svg";
import defaultUserAvatar from "assets/svg/icon/default_info_avatar_user.svg";
import { ReactComponent as DoneEditNameIcon } from "assets/svg/icon/done.svg";
import { ReactComponent as OutGroup } from "assets/svg/icon/out_group.svg";
import { useEffect, useRef, useState } from "react";

import { AdminNames, TYPE_MUTE } from "constant/chat";
import { useTranslation } from "react-i18next";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";
import AddMemberChat from "../component/AddMemberChat";
import ConfirmLeaveAndDeleteChatDialog from "../component/ConfirmLeaveAndRemoveDialog";
import ConfirmDeleteChatDialog from "../component/ConfirmRemoveDialog";
import ListMember from "../component/ListMember";
import NewGroupDialog from "../component/NewGroupDialog";
// eslint-disable-next-line import/order
import { useMutation } from "react-query";
import GroupIDB from "../../../IndexedDB/GroupIDB";
import { groupChatDetail, uploadResource } from "../../../api/chat";
import { encrypt } from "../../../utils/cipher";
// eslint-disable-next-line import/order
import { isEmpty } from "jssip/lib/Utils";
import CropGroupAvatarUpdateGroup from "../component/CropGroupAvatarUpdateGroup";
// eslint-disable-next-line import/order
import TextField from "@mui/material/TextField";
import { UPLOAD_URL } from "../../../config/keys";
import NotificationAdminDialog from "../component/NotificationAdminDialog";
// eslint-disable-next-line import/order
// import { decodeID } from "../../../utils/hex";
// eslint-disable-next-line import/order
// import { useParams } from "react-router-dom";
// eslint-disable-next-line import/order
import ListMemberPending from "../component/ListMemberPending";
import SettingGroup from "../component/SettingGroup";
import Media from "./Media";

function ChatInformation({
  handleToggleInformation,
  isOpenInformation,
  isMediumSize,
  handleToggleAddMemberChat,
  handleRemoveGroupChat,
  handleLeaveAndRemoveGroupChat,
  isOpenAddMemberChat,
  selectIndex,
  setSelectIndex,
  handleAddMemberChat,
  isOpenListMemberDialog,
  handleCloseListMember,
  handleOpenListMemberPendingDialog,
  isOpenListMemberPendingDialog,
  handleCloseListMemberPending,
  users,
  detailGroupFetching,
  detailGroup,
  idDetailGroup,
  handleSetSearch,
  setSearchValue,
  searchValue,
  listSearchVID,
  handleOpenAddMemberChat,
  isAdmin,
  handleOpenListMember,
  handleChangePermissionGroup,
  handleRemoveUser,
  handleMuteGroupChat,
  addGroupChat,
  listGroupChat,
  userInformation,
  listGroupAdd,
  handleOpenNotificationAdminDialog,
  handleCloseNotificationAdminDialog,
  openNotificationAdminDialog,
  listPendingJoinGroup,
  handleRefuseMemberGroup,
  handleApprovalMemberGroup,
  updateSettingGroup,
  handleOpenSettingGroup,
  handleCloseSettingGroup,
  isOpenSetting,
  infoChanel,
  addReporterChanelTv,
  removeReporterChanelTv,
}) {
  const { t } = useTranslation();
  const [openDeleteChatDialog, setOpenDeleteChatDialog] = useState(false);
  const [openLeaveAndDeleteChatDialog, setOpenLeaveAndDeleteChatDialog] = useState(false);
  const [openNewGroup, setOpenNewGroup] = useState(false);
  const [members, setMember] = useState([]);
  const isUserInMembers = members.some((member) => member.id === userInformation.id);
  const [isOpenCrop, setOpenCrop] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const textFieldRef = useRef();
  const [editedName, setEditedName] = useState("");

  const [openAll, setOpenAll] = useState(false);
  const [typeMedia, setTypeMedia] = useState(1);
  const [indexTab, setIndexTab] = useState(0);
  const [nameTab, setNameTab] = useState("image");

  const listTypeMedia = [
    { name: "image", type: 1 },
    { name: "video", type: 2 },
    { name: "file", type: 3 },
    { name: "link", type: 6 },
    { name: "audio", type: 4 },
  ];

  const { mutateAsync } = useRenameGroup();
  useEffect(() => {
    setEditedName(detailGroup?.name);
    setAvatar(detailGroup?.image);
  }, [detailGroup?.name, detailGroup?.image]);

  let updatedMembers;
  if (isUserInMembers) {
    // Nếu userInformation tồn tại trong members, loại bỏ nó
    updatedMembers = members.filter((member) => member.id !== userInformation.id);
  } else {
    // Nếu userInformation không tồn tại trong members, giữ nguyên danh sách
    updatedMembers = members;
  }

  const handleDeleteChat = () => {
    // Xóa chat
    handleRemoveGroupChat(detailGroup?.id);
    setOpenDeleteChatDialog(false);
  };
  const handleCloseDeleteChatDialog = () => {
    setOpenDeleteChatDialog(false);
  };
  const handleLeaveAndDeleteChat = () => {
    // Xóa chat
    handleLeaveAndRemoveGroupChat(detailGroup?.id);
    setOpenLeaveAndDeleteChatDialog(false);
  };
  const handleCloseLeaveAndDeleteChatDialog = () => {
    setOpenLeaveAndDeleteChatDialog(false);
  };
  const groupDetailMutate = useMutation("detail", (id) => groupChatDetail(id), {
    onSuccess: async (res) => {
      if (res.error !== 0) return;
      const idbGroup = await GroupIDB.get(res?.group?.id, "_id");
      await GroupIDB.put({
        ...(idbGroup || {}),
        _id: res?.group?.id,
        user_id: userInformation?.id,
        detail: encrypt(res?.group),
      });
    },
  });

  const handleModifyMember = async (user, state) => {
    let member = {};
    if (state) {
      if (isEmpty(user.id)) {
        const group = (await groupDetailMutate.mutateAsync(user.group_id))?.group?.users?.filter(
          (v) => v.id !== userInformation.id
        )[0];
        addGroupChat.refetchRecent();
        if (updatedMembers.map((v) => v.id).includes(group.id)) member = null;
        else
          member = {
            id: group.id,
            avatar: group.avatar,
            username: group.username,
            group_id: user.group_id,
          };
      } else {
        listGroupChat.forEach(async (v) => {
          if (v.group.name === user.username && isEmpty(v.detail)) {
            await groupDetailMutate.mutateAsync(v._id);
            addGroupChat.refetchRecent();
          }
        });
        member = {
          id: user.id,
          avatar: user.avatar,
          username: user.username,
        };
      }
      if (!isEmpty(member)) setMember((prev) => [...prev, member]);
    } else {
      const removeMember = updatedMembers.filter((v) => v.id !== user.id);
      setMember(removeMember);
    }
  };

  const uploadImageMutate = useMutation((data) => uploadResource(data));
  const updateImageGroup = async (avatarGroup) => {
    const res = await fetch(avatarGroup);
    const img = await res.blob();
    const formData = new FormData();

    formData.append("image", img, `${v4()}.png`);
    const imageURL = await uploadImageMutate.mutateAsync(formData);

    await mutateAsync({
      id: idDetailGroup,
      image: `${UPLOAD_URL}/${imageURL.path}`,
    });
  };
  const handleOpenNewGroupDialog = () => {
    setOpenNewGroup(true);
  };
  const handleCloseNewGroupDialog = () => {
    setOpenNewGroup(false);
  };

  const handleOpenCropImage = () => setOpenCrop(true);
  const handleCloseCropImage = () => setOpenCrop(false);
  const nameGroup = editedName === "" ? detailGroup?.name : editedName;
  const handleEditNameClick = () => {
    setIsEditing(true);
    setEditedName(nameGroup);
    setTimeout(() => {
      textFieldRef.current?.select();
    }, 0);
  };

  const handleSaveEdit = async () => {
    if (detailGroup?.name === editedName) {
      setIsEditing(false);
    } else {
      await mutateAsync({
        id: detailGroup.id,
        name: editedName,
      });
      setIsEditing(false);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setAvatar(detailGroup?.image);
    setEditedName(detailGroup?.name);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSaveEdit();
    }
  };

  if (detailGroupFetching) {
    return (
      <Drawer
        sx={(e) => styles.drawer(e, { isOpenInformation })}
        onClose={handleToggleInformation}
        open={isOpenInformation}
        anchor="right"
        variant={!isMediumSize ? "permanent" : "temporary"}
      >
        <Box
          sx={{
            height: "100%",
            ...styles.dFlexCenter,
          }}
        >
          <CircularProgress />
        </Box>
      </Drawer>
    );
  }
  return (
    <>
      <CropGroupAvatarUpdateGroup
        setting={{
          isOpen: isOpenCrop,
          onClose: handleCloseCropImage,
          updateImage: updateImageGroup,
          setAvatar,
          avatar,
          setOpenCrop,
        }}
      />
      <Drawer
        sx={(e) => styles.drawer(e, { isOpenInformation })}
        onClose={handleToggleInformation}
        open={isOpenInformation}
        anchor="right"
        variant={!isMediumSize ? "permanent" : "temporary"}
      >
        {!openAll && detailGroup?.type === "group" && (
          <Box sx={styles.imageContainer}>
            <Box
              component="img"
              src={detailGroup?.image || defaultGroupAvatar}
              srcSet={avatar}
              sx={styles.imgAvatar}
            />
            <IconButton
              sx={styles.groupCloseButton}
              onClick={() => {
                handleToggleInformation();
                handleCancelEdit();
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  ...styles.groupNameContainer,
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                {isEditing ? (
                  <>
                    <TextField
                      type="text"
                      value={editedName}
                      sx={styles.editNameGroup}
                      onChange={(e) => {
                        if (e.target.value.length <= 40) {
                          setEditedName(e.target.value);
                        }
                      }}
                      maxLength={40}
                      inputRef={textFieldRef}
                      onKeyDown={handleKeyDown}
                    />
                    <Box
                      onClick={handleSaveEdit}
                      style={{
                        marginRight: "10px",
                        marginTop: "5px",
                        marginLeft: "90%",
                        marginBottom: "5px",
                      }}
                    >
                      <DoneEditNameIcon />
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography variant="body1" color="white.main" sx={styles.groupName}>
                      {nameGroup}
                    </Typography>
                    {detailGroup?.type === "group" && detailGroup?.is_club !== 1 && (
                      <Box
                        sx={{
                          ml: "auto",
                          marginRight: "20px",
                          display: "flex",
                          marginTop: "10px",
                          marginBottom: "6px",
                        }}
                      >
                        <Box
                          sx={{ marginRight: "15px", cursor: "pointer" }}
                          onClick={handleOpenCropImage}
                        >
                          <CameraGroupIcon />
                        </Box>
                        <Box sx={{ cursor: "pointer" }} onClick={handleEditNameClick}>
                          <EditNameGroupIcon />
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        )}
        {!openAll && detailGroup?.type !== "group" && (
          <Box sx={styles.imageContainer}>
            <IconButton onClick={handleToggleInformation} sx={styles.groupCloseButton}>
              <CloseIcon />
            </IconButton>
            <Box
              component="img"
              src={detailGroup?.image || defaultUserAvatar}
              sx={styles.imgAvatar}
            />
            <Box sx={styles.personalContainer}>
              <Box sx={styles.personalSection}>
                <Box>
                  <Typography variant="body1" color="white.main" sx={styles.personalName}>
                    {detailGroup?.name} - {`vID: ${users?.[0]?.id} `}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Box>
          <Box sx={styles.itemContainerNotification}>
            <Box sx={styles.itemNotificationSection}>
              <Box sx={styles.iconContainer}>
                <Ring />
              </Box>
              <Typography variant="body1" color="inherit.main" sx={styles.textOnlyContainer}>
                {t("chat.chat_information.notification")}
              </Typography>
            </Box>
            {/* ---DISABLE--- */}
            <Switch
              checked={detailGroup?.is_mute === TYPE_MUTE.TRUE}
              onChange={() => handleMuteGroupChat(detailGroup?.id)}
              defaultChecked
              color="default"
            />
          </Box>
          {detailGroup?.type !== "group" && (
            <>
              <Divider sx={styles.divider} />
              {!AdminNames.includes(detailGroup?.name) && (
                <Box sx={styles.itemContainer}>
                  <Box sx={styles.iconContainer}>
                    <AddGroup />
                  </Box>
                  <Box
                    sx={styles.newGroupContainer}
                    onClick={() => {
                      handleOpenNewGroupDialog();
                      // eslint-disable-next-line no-unsafe-optional-chaining
                      setMember((prev) => [...prev, ...detailGroup?.users]);
                    }}
                  >
                    <Typography variant="body1" color="inherit.main" sx={styles.newGroup}>
                      {t("chat.chat_information.public_group")}
                    </Typography>
                    <Typography variant="body1" color="inherit.main" sx={styles.newGroupName}>
                      {detailGroup?.name}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Divider sx={styles.divider} />
              {/* {!AdminNames.includes(detailGroup?.name) && ( */}
              {/*  <Box sx={styles.itemContainer}> */}
              {/*    <Box sx={styles.iconContainer}> */}
              {/*      <AddPrivateGroup /> */}
              {/*    </Box> */}
              {/*    <Box sx={styles.newGroupContainer}> */}
              {/*      <Typography variant="body1" color="inherit.main" sx={styles.newGroup}> */}
              {/*        {t("chat.chat_information.private_group")} */}
              {/*      </Typography> */}
              {/*      <Typography variant="body1" color="inherit.main" sx={styles.newGroupName}> */}
              {/*        {detailGroup?.name} */}
              {/*      </Typography> */}
              {/*    </Box> */}
              {/*  </Box> */}
              {/* )} */}
              {/* <Divider sx={styles.divider} /> */}
            </>
          )}

          {detailGroup?.type === "group" && (
            <>
              <Divider sx={styles.divider} />
              <Box sx={styles.itemDeleteContainer} onClick={handleOpenListMember}>
                <Box
                  sx={{
                    ...styles.iconContainer,
                    transform: "scale(1.5)",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Participants />
                </Box>
                <Typography
                  variant="body1"
                  sx={{ ...styles.textOnlyContainer, marginLeft: "14px" }}
                >
                  {t("chat.chat_information.participants")}
                </Typography>
              </Box>
              {(isAdmin || detailGroup?.group_permission === 1) && (
                <>
                  <Divider sx={styles.divider} />
                  <Box sx={styles.itemAddGroupContainer} onClick={handleToggleAddMemberChat}>
                    <Box sx={{ ...styles.iconContainer, marginLeft: "5px" }}>
                      <AddMember />
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{ ...styles.textOnlyContainer, marginLeft: "11px" }}
                    >
                      {t("chat.chat_information.add_people_group")}
                    </Typography>
                  </Box>
                </>
              )}
              {isAdmin && (
                <>
                  <Divider sx={styles.divider} />
                  <Box
                    sx={styles.itemAddGroupContainer}
                    onClick={() => {
                      handleOpenNewGroupDialog();
                      setMember((prev) => {
                        // Lọc ra các thành viên không trùng lặp theo userInformation.id
                        const newMembers = detailGroup?.users.filter(
                          (user) => user.id !== userInformation.id
                        );
                        // Thêm các thành viên mới vào mảng cũ
                        return [...prev, ...newMembers];
                      });
                    }}
                  >
                    <Box sx={{ ...styles.iconContainer, marginLeft: "4px" }}>
                      <AddMembersGroup />
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{ ...styles.textOnlyContainer, marginLeft: "13px" }}
                    >
                      {t("chat.chat_information.create_new_group_others")}
                    </Typography>
                  </Box>
                  {listPendingJoinGroup?.data?.list &&
                    listPendingJoinGroup.data.list.length > 0 && (
                      <>
                        <Divider sx={styles.divider} />
                        <Box
                          sx={styles.itemAddGroupContainer}
                          onClick={handleOpenListMemberPendingDialog}
                        >
                          <Box sx={{ ...styles.iconContainer, marginLeft: "3px" }}>
                            <ApprovalIcon />
                          </Box>
                          <Typography
                            variant="body1"
                            sx={{ ...styles.textOnlyContainer, marginLeft: "14px" }}
                          >
                            {t("chat.chat_information.approve_members_to_the_group")}
                          </Typography>
                        </Box>
                      </>
                    )}
                  <Divider sx={styles.divider} />
                  <Box sx={styles.itemAddGroupContainer} onClick={handleOpenSettingGroup}>
                    <Box sx={{ ...styles.iconContainer, marginLeft: "5px" }}>
                      <SettingIcon />
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{ ...styles.textOnlyContainer, marginLeft: "11px" }}
                    >
                      {t("chat.chat_information.setting")}
                    </Typography>
                  </Box>
                </>
              )}
              <Divider sx={styles.divider} />
              <Box
                onClick={() => {
                  if (isAdmin && JSON.parse(detailGroup?.admin_ids).length === 1) {
                    handleOpenNotificationAdminDialog();
                  } else {
                    setOpenLeaveAndDeleteChatDialog(true);
                  }
                }}
                sx={styles.itemDeleteContainer}
              >
                <Box sx={styles.iconContainer}>
                  <OutGroup />
                </Box>
                <Typography variant="body1" color="error.main" sx={styles.textOnlyContainer}>
                  {t("chat.chat_information.leave_and_delete_chat")}
                </Typography>
              </Box>
              <ConfirmLeaveAndDeleteChatDialog
                open={openLeaveAndDeleteChatDialog}
                handleDeleteChat={handleLeaveAndDeleteChat}
                handleClose={handleCloseLeaveAndDeleteChatDialog}
                style={{ borderRadius: "20px !important" }}
              />
              <Divider sx={styles.divider} />
            </>
          )}

          {/* onClick={() => setOpenDeleteChatDialog(true)}  */}
          <Box sx={styles.itemDeleteContainer} onClick={() => setOpenDeleteChatDialog(true)}>
            <Box sx={styles.iconContainer}>
              <TrashIcon />
            </Box>
            <Typography variant="body1" color="error.main" sx={styles.textOnlyContainer}>
              {t("chat.chat_information.delete_chat")}
            </Typography>
          </Box>
        </Box>
        {!openAll && <Divider style={{ height: "5px" }} sx={styles.divider} />}
        <Box>
          <Box sx={styles.mediaTab}>
            {!openAll && (
              <Tabs value={indexTab} sx={styles.tabList}>
                {listTypeMedia.map(({ name, type }, index) => (
                  <Tab
                    onClick={() => {
                      setNameTab(name);
                      setIndexTab(index);
                      setTypeMedia(type);
                    }}
                    key={type}
                    label={
                      <Typography
                        variant="body1"
                        sx={(e) =>
                          styles.textMedia(e, {
                            isBlack: indexTab !== index,
                            isBold: indexTab === index,
                          })
                        }
                      >
                        {t(`chat.chat_information.${name}`)}
                      </Typography>
                    }
                    sx={styles.tab}
                  />
                ))}
              </Tabs>
            )}
          </Box>

          <Box>
            {detailGroup && (
              <Media
                nameTab={t(`chat.chat_information.${nameTab}`)}
                type={typeMedia}
                openAll={openAll}
                setOpenAll={setOpenAll}
                groupId={detailGroup.id}
              />
            )}
          </Box>
        </Box>
      </Drawer>
      <Box>
        <ConfirmDeleteChatDialog
          open={openDeleteChatDialog}
          handleDeleteChat={handleDeleteChat}
          handleClose={handleCloseDeleteChatDialog}
        />
        <AddMemberChat
          isMediumSize={isMediumSize}
          handleToggleAddMemberChat={handleToggleAddMemberChat}
          isOpenAddMemberChat={isOpenAddMemberChat}
          selectIndex={selectIndex}
          setSelectIndex={setSelectIndex}
          handleAddMemberChat={handleAddMemberChat}
          group={detailGroup}
          handleSetSearch={handleSetSearch}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          listSearchVID={listSearchVID}
        />
      </Box>

      <Box>
        <ListMember
          handleToggleAddMemberChat={handleToggleAddMemberChat}
          isMediumSize={isMediumSize}
          handleCloseListMember={handleCloseListMember}
          isOpenListMemberDialog={isOpenListMemberDialog}
          listMember={detailGroup?.users}
          handleOpenAddMemberChat={handleOpenAddMemberChat}
          group={detailGroup}
          isAdmin={isAdmin}
          handleChangePermissionGroup={handleChangePermissionGroup}
          handleRemoveUser={handleRemoveUser}
          addGroupChat={addGroupChat}
          handleOpenNewGroupDialog={handleOpenNewGroupDialog}
          setMember={setMember}
          handleOpenNotificationAdminDialog={handleOpenNotificationAdminDialog}
          openNotificationAdminDialog={openNotificationAdminDialog}
          infoChanel={infoChanel}
          addReporterChanelTv={addReporterChanelTv}
          removeReporterChanelTv={removeReporterChanelTv}
        />
        <ListMemberPending
          isMediumSize={isMediumSize}
          handleCloseListMemberPending={handleCloseListMemberPending}
          isOpenListMemberPendingDialog={isOpenListMemberPendingDialog}
          listPendingJoinGroup={listPendingJoinGroup?.data?.list}
          handleRefuseMemberGroup={handleRefuseMemberGroup}
          handleApprovalMemberGroup={handleApprovalMemberGroup}
        />
        <SettingGroup
          isMediumSize={isMediumSize}
          handleCloseSetting={handleCloseSettingGroup}
          isOpenSetting={isOpenSetting}
          detailGroup={detailGroup}
          updateSettingGroup={updateSettingGroup}
        />
        <NewGroupDialog
          onClose={handleCloseNewGroupDialog}
          isOpen={openNewGroup}
          addGroupChat={addGroupChat}
          listGroupAdd={listGroupAdd}
          handleModifyMember={handleModifyMember}
          members={updatedMembers}
          setMember={setMember}
        />
        <NotificationAdminDialog
          listMember={detailGroup?.users}
          group={detailGroup}
          openNotificationAdminDialog={openNotificationAdminDialog}
          handleChangePermissionGroup={handleChangePermissionGroup}
          handleCloseNotificationAdminDialog={handleCloseNotificationAdminDialog}
          handleRemoveUser={handleRemoveUser}
        />
      </Box>
    </>
  );
}
export default ChatInformation;
