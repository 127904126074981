import axios from "axios";
import { BASE_URL } from "config/keys";
import { isEmpty } from "utils/validate";
import { getUserToken } from "utils/storage";

const axiosInstance = axios.create({ baseURL: BASE_URL, timeout: 10000 });

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = getUserToken()?.token;
    if (isEmpty(config.headers.Authorization) && token) config.headers.Authorization = `${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  async (response) => response.data,
  (error) => Promise.reject(error)
);

export default axiosInstance;
