import { useNavigate } from "react-router-dom";
import { checkQrCodeLogin } from "api/loginQR";
import { BASE_URL_API_V2 } from "config/keys";
import { useEffect, useState } from "react";
import { v5 as uuid, v4 } from "uuid";
import { getVIDMapChat, removeVIDMapChat, setUserToken } from "utils/storage";
import { useDispatch } from "react-redux";
import { updateRoute } from "redux/actions/routeAction";
import routes from "routes/routes";
import { addGroupChat, getListGroupChat } from "api/chat";
import { encodeID } from "utils/hex";
import { isEmpty } from "jssip/lib/Utils";
import QR from "./section/QR";

export default function LoginQR() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clientId, setClientID] = useState("");
  const vIDMapChat = getVIDMapChat();
  const handleNavigateLogin = () => {
    navigate("/login");
  };
  useEffect(() => {
    if (isEmpty(clientId)) {
      setClientID(uuid(`${Math.floor(Math.random() * 1000)}`, v4()));
    }
    const idSetInterval = setInterval(async () => {
      const res = await checkQrCodeLogin({ client_id: clientId });
      if (res.error === 0) {
        clearInterval(idSetInterval);
        const userInformation = { ...res.uinfo, token: res.token, sip: res.sip };
        setUserToken(userInformation);
        dispatch(updateRoute(routes));
        if (!isEmpty(vIDMapChat)) {
          const storeData = {
            type: "single",
            member_users: [userInformation?.id, vIDMapChat],
            is_private: 0,
          };
          addGroupChat(storeData).then((resGroup) => {
            if (+resGroup.error !== 0) return;
            removeVIDMapChat();
            navigate(`/t/${encodeID(resGroup?.group?.id)}`);
          });
        } else {
          const groupChat = await getListGroupChat();
          const [first] = groupChat.groups;
          const vID = encodeID(first.id.toString());
          navigate(`/t/${vID}`);
        }
      }
    }, 2000);
    return () => {
      clearInterval(idSetInterval);
    };
  }, [clientId]);

  return (
    <QR
      handleNavigateLogin={handleNavigateLogin}
      qrCode={clientId && `${BASE_URL_API_V2}/api/utils/gen_qrcode?client_id=${clientId}`}
    />
  );
}
