import styles from "assets/styles/pages/Chat/component/PinMessage";
import { Box, Typography, Fade, IconButton, Button } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/icon/close_#00000.svg";
import { ReactComponent as MessagePin } from "assets/svg/icon/pin_message.svg";
import { ReactComponent as AudioIcon } from "assets/svg/icon/audio_icon.svg";
import { ReactComponent as KeyboardArrowDownIcon } from "assets/svg/icon/arrow_down_icon.svg";
import { ReactComponent as BarChartActiveIcon } from "assets/svg/icon/poll_icon_active.svg";

import { formatDate } from "utils/date";
import VideoPoster from "assets/images/video_poster.png";
import { ReactComponent as AttachFileActiveIcon } from "assets/svg/icon/attach_icon_active.svg";
import { useTranslation } from "react-i18next";
import { Stickers } from "assets/stickers";
import { tagManyNameRegex } from "constant/regex";

export default function PinMessage({
  // messagePinContent,
  handleOpenDeletePinMessage,
  openDialogPinMessage,
  handleDialogPinMessage,
  openPinMessage,
  listPin,
  handleScrollPin,
}) {
  const [pin] = listPin;

  const { t } = useTranslation();
  const viewText = (
    <Box sx={styles.content}>
      <IconButton sx={styles.fileIcon}>
        <MessagePin />
      </IconButton>
      <Box sx={styles.messagePin}>
        <Typography sx={styles.message}>
          {tagManyNameRegex.exec(pin?.text) ? pin?.text.replace(tagManyNameRegex, `$1`) : pin?.text}
        </Typography>
        <Typography variant="caption" sx={styles.dayPin}>
          {t("chat.header.pin.pinned")} {formatDate(pin?.createdAt)}
        </Typography>
      </Box>
    </Box>
  );
  const viewImage = (
    <Box sx={styles.contentImageOrFile}>
      <Box sx={styles.boxImage}>
        <Box component="img" src={pin?.image[0]?.url} sx={styles.image} />
        {pin?.image?.length > 1 && (
          <>
            <Box sx={styles.imageBg} />
            <Typography sx={styles.numberImagePin}>+{pin.image.length - 1}</Typography>
          </>
        )}
      </Box>
      <Box sx={styles.boxContentImage}>
        <Typography sx={styles.textImage}>{t("chat.header.pin.photo_messages")}</Typography>
        <Typography variant="caption" sx={styles.dayPin}>
          {t("chat.header.pin.pinned")} {formatDate(pin?.createdAt)}
        </Typography>
      </Box>
    </Box>
  );
  const viewVideo = (
    <Box sx={styles.contentImageOrFile}>
      <Box sx={styles.boxImage}>
        <Box component="img" src={pin?.video?.url_thumb || VideoPoster} sx={styles.image} />
      </Box>
      <Box sx={styles.boxContentImage}>
        <Typography sx={styles.textImage}>{t("chat.header.pin.video_messages")}</Typography>
        <Typography variant="caption" sx={styles.dayPin}>
          {t("chat.header.pin.pinned")} {formatDate(pin?.createdAt)}
        </Typography>
      </Box>
    </Box>
  );
  const viewFile = (
    <Box sx={styles.content}>
      <IconButton sx={styles.fileIcon}>
        <AttachFileActiveIcon />
      </IconButton>
      <Box sx={styles.messagePin}>
        <Typography sx={styles.textImage}>{t("chat.header.pin.file_messages")}</Typography>
        <Typography variant="caption" sx={styles.dayPin}>
          {t("chat.header.pin.pinned")} {formatDate(pin?.createdAt)}
        </Typography>
      </Box>
    </Box>
  );
  const viewAudio = (
    <Box sx={styles.content}>
      <IconButton sx={styles.fileIcon}>
        <AudioIcon />
      </IconButton>
      <Box sx={styles.messagePin}>
        <Typography sx={styles.message}>{t("chat.header.pin.voice_messages")}</Typography>
        <Typography variant="caption" sx={styles.dayPin}>
          {t("chat.header.pin.pinned")} {formatDate(pin?.createdAt)}
        </Typography>
      </Box>
    </Box>
  );
  const viewEmoji = (
    <Box sx={styles.content}>
      <Box
        sx={styles.boxImageEmoji}
        className="imageUrl"
        component="img"
        src={Stickers[pin?.emoji?.stickerIndex]?.images[pin?.emoji?.index]}
      />
      <Box sx={styles.messagePin}>
        <Typography sx={styles.message}>{t("chat.header.pin.sticker_messages")}</Typography>
        <Typography variant="caption" sx={styles.dayPin}>
          {t("chat.header.pin.pinned")} {formatDate(pin?.createdAt)}
        </Typography>
      </Box>
    </Box>
  );
  const viewVote = (
    <Box sx={styles.content}>
      <IconButton sx={styles.fileIcon}>
        <BarChartActiveIcon />
      </IconButton>
      <Box sx={styles.messagePin}>
        <Typography sx={styles.message}>{pin?.vote?.content}</Typography>
        <Typography variant="caption" sx={styles.dayPin}>
          {t("chat.header.pin.pinned")} {formatDate(pin?.createdAt)}
        </Typography>
      </Box>
    </Box>
  );
  if (!openPinMessage) return null;
  return (
    <Fade in={openPinMessage}>
      <Box sx={styles.PinMessage}>
        <Box onClick={() => handleScrollPin(pin)} sx={styles.boxMessagePin}>
          {pin?.kind === "text" && viewText}
          {pin?.kind === "image" && viewImage}
          {pin?.kind === "file" && viewFile}
          {pin?.kind === "video" && viewVideo}
          {pin?.kind === "audio" && viewAudio}
          {pin?.kind === "emoji" && viewEmoji}
          {pin?.kind === "vote" && viewVote}
        </Box>
        <Box sx={styles.icon}>
          {listPin.length > 1 ? (
            <Box sx={styles.buttonDialog}>
              <Button onClick={handleDialogPinMessage} sx={styles.button}>
                <Typography sx={styles.numberPin}>+{listPin.length - 1}</Typography>
                <Box sx={styles.iconDown({ openDialogPinMessage })}>
                  <KeyboardArrowDownIcon />
                </Box>
              </Button>
            </Box>
          ) : (
            <IconButton onClick={() => handleOpenDeletePinMessage(pin)} sx={styles.IconClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Fade>
  );
}
