import {
  informationWidth,
  lgInformationWidth,
  xlInformationWidth,
  xxlInformationWidth,
} from "constant/chat";

export default {
  drawer: ({ transitions, breakpoints }, { isOpenAddMemberChat }) => ({
    ...(!isOpenAddMemberChat && {
      width: 0,
      "& .MuiDrawer-paper": {
        width: 0,
      },
      transition: transitions.create("width", {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
    }),

    ...(isOpenAddMemberChat && {
      width: informationWidth,
      "& .MuiDrawer-paper": {
        width: informationWidth,
      },
      transition: transitions.create(["margin", "width"], {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
      [breakpoints.down("xxl")]: {
        width: xxlInformationWidth,
        "& .MuiDrawer-paper": {
          width: xxlInformationWidth,
        },
      },
      [breakpoints.down("xl")]: {
        width: xlInformationWidth,
        "& .MuiDrawer-paper": {
          width: xlInformationWidth,
        },
      },
      [breakpoints.down("lg")]: {
        width: lgInformationWidth,
        "& .MuiDrawer-paper": {
          width: lgInformationWidth,
        },
      },
      [breakpoints.down("md")]: {
        width: "100%",
        "& .MuiDrawer-paper": {
          width: "100%",
        },
      },
    }),
  }),
  dialog: ({ breakpoints, borders }) => ({
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.lg,
        width: "100%",
        height: "100%",
        // maxHeight: "600px",
        maxWidth: "450px",
        [breakpoints.down("lg")]: {
          maxWidth: "90vw",
        },
      },
    },
  }),
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    m: 2,
  },
  title: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
  }),
  subTitle: ({ typography }) => ({
    color: "#B4B4B4",
    py: 1,
    ml: 2,
    fontWeight: typography.fontWeightBold,
  }),
  textCenter: { textAlign: "center" },
  textDefault: ({ palette }) => ({
    color: palette.grey[400],
  }),
  doneDefault: ({ palette }) => ({
    cursor: "default",
    color: palette.inputBorderColor.main,
  }),
  doneActive: ({ typography }) => ({
    // background: "-webkit-linear-gradient(180deg, #26BBFE 3.27%, #1BC88B 97.45%);",
    background: "#406EE2",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: `${typography.fontFamily2} !important`,
  }),
  label: {
    flexGrow: 1,
    px: 1.25,
    fontWeight: "600",
  },
  divider: {
    my: 0,
    backgroundColor: "#D1D1D1",
  },
  contentContainer: {
    height: "500px",
    maxHeight: "80%",
    minHeight: "20%",
    flexGrow: 1,
    display: "flex",
    borderRadius: 0,
    // mb: 2,
    // ml: 2,
    mt: 1,
    flexDirection: "column",
    // pl: 2.5,
  },
  search: ({ isClicked }) => ({
    width: "100%",
    height: "44px",
    borderRadius: "100px",
    fieldset: {
      borderRadius: "100px",
      border: "none",
      height: "50px",
    },
    input: {
      py: 1.5,
      "&::placeholder": {
        fontWeight: 550,
      },
    },
    border: isClicked ? "1px solid var(--button_new, #406EE2)" : "none",
    backgroundColor: isClicked ? "none" : "#F6F6F6",
  }),
  dFlexCenter: {
    display: "flex",
    px: 4,
    py: 3,
  },

  buttonDefault: {
    width: "100%",
    height: "44px",
    borderRadius: "10px",
    border: "transparent solid 2px !important",
    background:
      "linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, #26BBFE, #1BC88B) border-box",
    "&:hover": {
      background:
        "linear-gradient(#f8ffff 0 0) padding-box, linear-gradient(to right, #26BBFE, #1BC88B) border-box !important",
    },
  },
  iconW18: {
    width: "14px",
    fill: "#fff",
  },
  fLexCol: { display: "flex", flexDirection: "column" },
  arrowIcon: { fill: "#090710", height: "14px" },
  avatarImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
    "&>img": {
      height: "44px",
    },
  },
  labelLeft: ({ breakpoints }) => ({
    flexGrow: 1,
    fontWeight: "600",
    pl: 1.25,
    // pr: 0.5,
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flex: "1",
    [breakpoints.between("md", "lg")]: {
      fontSize: "12px",
      pl: 1,
    },
    [breakpoints.down("md")]: {
      fontSize: "12px",
      pl: 1,
    },
  }),
  labelRight: ({ breakpoints }) => ({
    flexGrow: "0",
    flexShrink: "0",
    fontWeight: "600",
    [breakpoints.between("md", "lg")]: {
      fontSize: "12px",
    },
    [breakpoints.down("md")]: {
      fontSize: "12px",
    },
  }),
  flexGrow: {
    py: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
  },
  boxCheckBox: ({ breakpoints }, isMember) => ({
    display: "flex ",
    alignItems: "center",
    borderRadius: "10px",
    width: "100%",
    pl: 2.5,
    opacity: isMember ? "0.5" : "1",
    cursor: isMember ? "no-drop" : "pointer",
    "&:hover": {
      background: "#F3F3F3",
    },
    [breakpoints.between("md", "lg")]: {
      pl: 0,
    },
    [breakpoints.down("sm")]: {
      pl: 0,
    },
  }),
  labelActive: ({ breakpoints }) => ({
    flexGrow: 1,
    px: 0.5,
    // background: "-webkit-linear-gradient(#26BBFE 3.27%, #1BC88B 97.45%)",
    background: " #406EE2",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "550",
    [breakpoints.between("md", "lg")]: {
      fontSize: "12px",
    },
  }),
  overflowY: {
    overflowY: "auto",
    pl: 0.5,
    ml: 2,
  },
  boxShowSelected: { height: "70px", mb: 1, pr: 2, pl: 2.5 },
  overflowX: { display: "flex", overflowX: "auto" },
  countSelectedBox: { display: "flex", justifyContent: "space-between", pr: 4, pl: 2.5 },
  selectedImage: {
    height: "70px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarImageSelected: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
    "&>img": {
      height: "54px",
    },
  },
  closeImageIcon: ({ palette }) => ({
    position: "absolute",
    bottom: "40px",
    right: "4px",
    background: "#8D929F",
    color: palette.white.main,
    width: "18px",
    height: "18px",
    boxShadow: "0px 0px 3.55848px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      background: "#8D929F",
    },
  }),
};
