export default {
  dialog: ({ breakpoints, borders }) => ({
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.lg,
        width: "100%",
        height: "60%",
        // maxHeight: "600px",
        maxWidth: "400px",
        [breakpoints.down("sm")]: {
          maxWidth: "90vw",
        },
      },
    },
  }),
  dFlexColumn: { display: "flex", flexDirection: "column" },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textCenter: { textAlign: "center" },
  title: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    marginTop: "15px",
    marginBottom: "15px",
    flex: 1,
    textAlign: "center",
  }),
  paddingReset: {
    p: 0,
    marginRight: "10px",
    marginLeft: "-20px",
  },
  information: {
    fontWeight: 500,
    marginTop: "20px",
    marginBottom: "10px",
  },
  name: {
    fontSize: "14px",
    marginTop: "20px",
    marginBottom: "5px",
  },
  date: {
    fontSize: "14px",
    marginTop: "15px",
    marginBottom: "5px",
  },
  nickName: {
    fontSize: "14px",
    marginTop: "20px",
    marginBottom: "5px",
  },
  doneActive: ({ typography }) => ({
    fontFamily: `${typography.fontFamily2} !important`,
  }),
  label: ({ typography }) => ({
    flexGrow: 1,
    px: 1.25,
    fontWeight: typography.fontWeightBold,
  }),
  container: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  tabList: {
    mx: 2,
    my: 1.5,
    background: "#fff",
    justifyContent: "space-between",
  },

  divider: {
    my: 0,
    backgroundColor: "#D1D1D1",
  },
  buttonCancel: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    background: "#fff",
    border: "2px solid #406EE2",
    color: "#406EE2",
    fontWeight: 700,
    borderRadius: "7px",
    marginRight: "15px",
    height: "40px",
    width: "120px",
    "&:hover": {
      background: "#fff",
    },
  }),
  buttonCreateGroup: {
    borderRadius: "10px",
    background: "#406EE2",
    minWidth: "50px",
    maxWidth: "120px",
    height: "50px",
    margin: "30px auto",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: "0px 8px 16px 0px rgba(51, 102, 255, 0.25)",
    "&:disabled": {
      backgroundColor: "#c8d3f3",
      color: "#FFFF",
    },
    "&:hover": {
      background: "#406EE2",
      boxShadow: "0px 8px 16px 0px rgba(51, 102, 255, 0.25)",
    },
  },
};
