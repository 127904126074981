import { FormControlLabel } from "@mui/material";
import MuiCheckbox from "@mui/material/Checkbox";

export default function Checkbox({ label, icon, checkedIcon, checked }) {
  return (
    <FormControlLabel
      sx={{
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        mr: 0,
        "& label": {
          width: "100%",
        },
        "& span": {
          "& svg": {
            width: "22px !important",
            height: "22px !important",
          },
        },
      }}
      label={label}
      checked={checked}
      control={<MuiCheckbox icon={icon} checkedIcon={checkedIcon} />}
    />
  );
}
