export default {
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  progress: {
    width: "30px",
    height: "30px",
  },
};
