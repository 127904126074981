export default {
  dialog: ({ breakpoints, borders }) => ({
    // m: 2,
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.lg,
        width: "100%",
        // height: "90%",
        position: "relative",
        maxWidth: "450px",
        [breakpoints.down("sm")]: {
          maxWidth: "90vw",
        },
      },
    },
  }),
  dFlexColumn: { display: "flex", flexDirection: "column" },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    mx: 2,
    mt: 2,
    mb: 0,
  },
  textCenter: { textAlign: "center" },
  title: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    color: "#000",
    fontSize: "26px",
    fontStyle: "normal",
    lineHeight: "normal",
    marginLeft: "auto",
    marginRight: "auto",
  }),
  content: {
    color: "#000",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "normal",
    textAlign: "center",
    mx: 2,
    marginTop: "20px",
    marginBottom: "20px",
  },
  doneActive: ({ typography }) => ({
    fontFamily: `${typography.fontFamily2} !important`,
  }),
  divider: {
    my: 2,
    backgroundColor: "#D1D1D1",
    // margin: "15px 0",
  },
  boxShowSelected: { height: "70px", mb: 1, pr: 2, pl: 2.5 },
  overflowX: { display: "flex", overflowX: "auto" },
  selectedImage: {
    height: "70px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarImageSelected: { width: "54px", height: "54px", borderRadius: "50%" },
  contentContainer: {
    height: "500px",
    maxHeight: "80%",
    minHeight: "20%",
    flexGrow: 1,
    display: "flex",
    borderRadius: 0,
    mt: 1,
    mb: 2.5,
    flexDirection: "column",
  },
  search: ({ isClicked }) => ({
    borderRadius: "100px",
    mx: 2,
    mb: 2,
    background: "var(--White, #FFF)",
    fieldset: {
      borderRadius: "100px",
      border: "none",
      height: "50px",
    },
    input: {
      py: 1.5,
      "&::placeholder": {
        fontWeight: 550,
      },
    },
    border: isClicked ? "1px solid var(--button_new, #406EE2)" : "none",
    backgroundColor: isClicked ? "none" : "#F6F6F6",
  }),
  iconW18: {
    width: "14px",
    fill: "#fff",
  },
  paddingReset: {
    p: 1,
  },
  groupContainer: {
    display: "flex",
    alignItems: "center",
    p: 2,
  },
  groupCropImage: () => ({
    width: "60px",
    height: "63px",
    // border: `1px dashed ${palette.primary.main}`,
    backgroundColor: "#e0e0e0",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  }),
  cameraContainer: {
    mt: 0.5,
  },
  overlay: ({ palette, functions, borders }) => ({
    position: "absolute",
    display: "none",
    width: "100%",
    height: "100%",
    left: 0,
    bottom: 0,
    backgroundColor: functions.rgba(palette.black.main, 0.3),
    zIndex: 1,
    flexGrow: 1,
    borderRadius: borders.borderRadius.round,
    justifyContent: "center",
    alignItems: "center",
  }),
  updateAvatarIcon: {
    fill: "white",
  },
  cropAvatar: {
    width: "60px",
    height: "60px",
  },
  groupNameContainer: {
    width: "100%",
    ml: 2,
  },
  groupName: ({ typography }) => ({
    width: "100%",
    input: {
      fontFamily: `${typography.fontFamily2} !important`,
      fontSize: "16px",
      color: "#000",
    },
    "& .MuiInput-root": {
      "&:before, :after, :hover:not(.Mui-disabled):before": {
        borderBottom: "1px solid #00000",
      },
    },
  }),

  addGroupIconContainer: {
    width: "100%",
    height: "100%",
  },
  addGroupChatIcon: {
    width: "100%",
    height: "100%",
  },
  loadingContainer: {
    display: "flex",
    mb: 2,
    justifyContent: "space-between",
  },
  loadSearch: {
    borderRadius: "100px",
  },
  loadInformation: {
    display: "flex",
    alignItems: "center",
  },
  loadAvatar: {
    width: "60px",
    height: "60px",
    mr: 1.5,
  },
  loadName: {
    mb: 1,
  },
  loadTime: {
    mt: 1,
  },
  searchType: {
    p: 2,
  },
  memberContainer: {
    width: "100%",
    maxWidth: 400,
    marginTop: 2,
  },
  text: ({ typography }) => ({
    color: "var(--icon, #000)",
    fontFamily: typography.fontFamily,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    letterSpacing: "-0.064px",
    marginLeft: 2,
    marginBottom: 2,
  }),
  memberSection: {
    width: 237,
    height: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 1.5,
    borderRadius: "50px",
    background: "var(--tab, #E4EFFF)",
    marginLeft: "auto",
    marginRight: "auto",
  },
  memberAvatarContainer: {
    display: "flex",
    alignItems: "center",
  },
  memberAvatar: {
    width: 30,
    height: 30,
    borderRadius: 50,
    marginLeft: 1,
  },
  listUserName: ({ typography }) => ({
    color: "#090710",
    width: "150px",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: typography.fontFamily,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18px",
    marginLeft: "10px",
    overflow: "hidden",
    height: "18px",
    whiteSpace: "nowrap", // Ensure that the text doesn't wrap to the next line
    textOverflow: "ellipsis",
  }),
  vidUser: ({ typography }) => ({
    color: "var(--text1, #999)",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: typography.fontFamily,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    marginLeft: "10px",
  }),
  userGroupContainerLeft: {
    overflow: "scroll",
    maxWidth: "100%",
    height: "50vh",
    mx: 2,
    scrollbarWidth: "thin", // Cho Firefox
    scrollbarColor: "#EDF1F8 #EDF1F8", // Cho Firefox
    "&::-webkit-scrollbar": {
      width: "8px", // Kích thước chiều rộng của thanh cuộn
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#C3C3C3", // Màu sắc của "nút trượt"
    },
  },
  userGroupContainerRight: {
    overflow: "auto", // Sử dụng "auto" để hiển thị thanh cuộn chỉ khi cần thiết
    width: "270px",
    height: "50vh",
    float: "right",
    border: "1px solid #C3C3C3",
    "&::-webkit-scrollbar": {
      width: "0.5em", // Đặt chiều rộng của thanh cuộn thành 0.5em
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#C3C3C3", // Màu sắc của "nút trượt"
    },
  },
  buttonCreateGroup: {
    borderRadius: "10px",
    textAlign: "center",
    background: "#406EE2",
    minWidth: "100px",
    maxWidth: "170px",
    // height: "50px",
    margin: "30px auto",
    boxShadow: "0px 8px 16px 0px rgba(51, 102, 255, 0.25)",
    "&:hover": {
      background: "#406EE2",
      boxShadow: "0px 8px 16px 0px rgba(51, 102, 255, 0.25)",
    },
  },
  userGroupItem: {
    px: 2,
    // marginLeft: "20px",
    // marginRight: "20px",
    display: "flex",
    // borderRadius: borders.borderRadius.md,
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    // textAlign: "center",
    cursor: "pointer",
    // p: 1,
    transition: "background-color 0.3s ease", // Thêm hiệu ứng chuyển động
    "&:hover": {
      backgroundColor: "#E5F0FF", // Màu nền khi hover
      borderRadius: "10px",
    },
  },
  checkContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    mr: 0,
    label: {
      width: "100%",
    },
    svg: {
      width: "18px !important",
      height: "18px !important",
    },
  },
  labelContainer: {
    py: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
  },
  groupAvatar: {
    width: "44px",
    height: "44px",
  },
  groupUsernameContainer: {
    display: "inline-block",
    userSelect: "none",
    ml: 1,
  },
};
