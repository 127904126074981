import { BASE_URL_API_V2, UPLOAD_FILE } from "config/keys";
import axiosInstance from "middleware/axios";
import { getUserToken } from "utils/storage";

const getListGroupChat = (params) => axiosInstance.get("/api/group/list", { params });
const getListPhoneBookUser = async (params) => {
  const response = await axiosInstance.get("/api/v1/users/list_phone_book_user", { params });
  return response.data; // Assuming you want to return the data from the API call
};
const groupChatDetail = (id) => axiosInstance.get(`/api/group/detail/${id}/?is_full=1`);
const messageOffline = (params) =>
  axiosInstance.get(`${BASE_URL_API_V2}/api/group_messenger/list_messenge_logs`, {
    params,
    headers: { token: getUserToken()?.token },
  });

const detailInformationUser = (vId) =>
  axiosInstance.get(
    `https://wghn.vcallid.com/api/v1/other_services/get_user_info_for_web?uid=${vId}`,
    {
      headers: { authen: "A4KcOxIPiLr9oPqDNX0AWJlN1SIhBIt7cfHE6SHDYWPj5dTWUIQCxD961e" },
    }
  );

const listMemberPendingJoinGroup = (vId, group_id) =>
  axiosInstance.get(
    `https://api.vcallid.com/api/group/list_review?uid=${vId}&group_id=${group_id}`
  );
const addReporter = (channel_id, journaler_id, uid) =>
  axiosInstance.get(
    `https://api-v2.vcallid.com/api/channel/add_journaler?channel_id=${channel_id}&journaler_id=${journaler_id}&uid=${uid}`
  );
const removeReporter = (channel_id, journaler_id, uid) =>
  axiosInstance.get(
    `https://api-v2.vcallid.com/api/channel/remove_journaler?channel_id=${channel_id}&journaler_id=${journaler_id}&uid=${uid}`
  );

const addMemberPendingJoinGroup = (vId, group_id, data) =>
  axiosInstance.post(
    `https://api.vcallid.com/api/group/review?uid=${vId}&group_id=${group_id}`,
    data
  );
const AddMember = ({ user_ids, permission, group_id }) =>
  axiosInstance.post(`/api/group/add_user`, { user_ids, permission, group_id });

const ChangePermission = ({ user_id, permission, group_id }) =>
  axiosInstance.post(`/api/group/change_permission`, { user_id, permission, group_id });

const RemoveMember = ({ user_ids, group_id }) =>
  axiosInstance.post(`/api/group/remove_user`, { user_ids, group_id });

const uploadResource = async (data) => {
  const { name, path, video_thumb } = await axiosInstance.post(
    `${UPLOAD_FILE}/api/public/post_file_for_chat`,
    data
  );

  return {
    name,
    path,
    url_thumb: video_thumb,
  };
};

const addGroupChat = (data) => axiosInstance.post(`/api/group/create`, data);

const removeMessageChat = (id) => axiosInstance.get(`/api/group_messenger/delete/${id}`);

const updateMuteGroupChat = (data) => axiosInstance.post(`/api/group/update`, data);

const getListAttachment = (params) =>
  axiosInstance.get(`${BASE_URL_API_V2}/api/channel/list_attachment`, { params });

const detailChanel = (params, id) =>
  axiosInstance.get(`${BASE_URL_API_V2}/api/channel/detail/${id}`, { params });

const sendEventScore = (data) =>
  axiosInstance.post(`${BASE_URL_API_V2}/api/channel/send_event_score`, data);

const updateInformationGroup = (data) =>
  axiosInstance.post(`${BASE_URL_API_V2}/api/group/update`, data);

const updateScore = (data) =>
  axiosInstance.post(`${BASE_URL_API_V2}/api/channel/update_score`, data);

const getDetailMess = async (groupId, uid) => {
  const res = await axiosInstance.get(
    `${BASE_URL_API_V2}/api/group_messenger/item_detail/${groupId}?uid=${uid}`
  );

  return res;
};

export {
  getListGroupChat,
  groupChatDetail,
  messageOffline,
  AddMember,
  uploadResource,
  ChangePermission,
  RemoveMember,
  addGroupChat,
  removeMessageChat,
  updateMuteGroupChat,
  getListAttachment,
  detailChanel,
  updateScore,
  getListPhoneBookUser,
  sendEventScore,
  updateInformationGroup,
  detailInformationUser,
  getDetailMess,
  listMemberPendingJoinGroup,
  addMemberPendingJoinGroup,
  addReporter,
  removeReporter,
};
