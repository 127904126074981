import { Box, Typography, Fade, IconButton } from "@mui/material";
import { ReactComponent as CloseEditIcon } from "assets/svg/icon/close_edit_message.svg";
import styles from "assets/styles/pages/Chat/component/EditMessage";
import { useTranslation } from "react-i18next";

export default function EditMessage({ openEditMessage, handleCloseEditMessage }) {
  const { t } = useTranslation();
  if (!openEditMessage) return null;
  return (
    <Fade in={openEditMessage}>
      <Box sx={styles.container}>
        <IconButton onClick={handleCloseEditMessage}>
          <CloseEditIcon />
        </IconButton>
        <Typography sx={styles.title}>{t("chat.content_chat.message.edit")}</Typography>
      </Box>
    </Fade>
  );
}
