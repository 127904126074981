import { useEffect, useState } from "react";

function useDelaySearch(value, delay) {
  const [result, setResult] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setResult(value), delay);

    return () => clearTimeout(handler);
  }, [value]);

  return result;
}
export default useDelaySearch;
