import PropTypes from "prop-types";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { ReactComponent as MessageNewIcon } from "assets/svg/icon/mesage_new.svg";
import { ReactComponent as MessageSimultaneouslyIcon } from "assets/svg/icon/simultaneously.svg";
import { ReactComponent as NewGroupIcon } from "assets/svg/icon/new_group.svg";
import { ReactComponent as ChannelTvIcon } from "assets/svg/icon/channel_tv.svg";
import styles from "assets/styles/pages/Chat/component/PopupMessageNew";
import { useTranslation } from "react-i18next";

function PopupMessageNew({
  isOpenPopper,
  personalAnchor,
  handleClose,
  handleOpenNewGroupDialog,
  handleOpenNewMessagseDialog,
  handleOpenSendMessage,
  handleOpenNewChannelTvDialog,
}) {
  const { t } = useTranslation();
  return (
    <Popper
      placement="bottom-start"
      sx={styles.popper}
      open={isOpenPopper}
      anchorEl={personalAnchor}
      role={undefined}
      transition
      disablePortal
      popperOptions={{ modifiers: [{ name: "offset", options: { offset: [-70, 10] } }] }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={styles.paper}>
            <ClickAwayListener disableReactTree onClickAway={handleClose}>
              <Box style={{ width: "100%" }}>
                <Box
                  onClick={() => {
                    handleOpenNewMessagseDialog();
                    handleClose();
                  }}
                  sx={styles.topItemContainer}
                >
                  <MessageNewIcon style={styles.icon} />
                  <Typography variant="body1" sx={styles.item}>
                    {t("chat.message_new")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    handleOpenSendMessage();
                    handleClose();
                  }}
                  sx={styles.itemContainer}
                >
                  <MessageSimultaneouslyIcon style={styles.icon} />
                  <Typography variant="body1" sx={styles.item}>
                    {t("chat.send_messages_simultaneously")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    handleOpenNewGroupDialog();
                    handleClose();
                  }}
                  sx={styles.itemContainer}
                >
                  <NewGroupIcon style={styles.icon} />
                  <Typography variant="body1" sx={styles.item}>
                    {t("chat.create_new_group")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    handleOpenNewChannelTvDialog();
                    handleClose();
                  }}
                  sx={styles.bottomItemContainer}
                >
                  <ChannelTvIcon style={styles.icon} />
                  <Typography variant="body1" sx={styles.itemColor}>
                    {t("chat.create_new_channel_tv")}
                  </Typography>
                </Box>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

PopupMessageNew.defaultProps = {
  isOpenPopper: false,
  anchor: "",
};

PopupMessageNew.propTypes = {
  isOpenPopper: PropTypes.bool,
  anchor: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
};

export default memo(PopupMessageNew);
