import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

const { info, dark, primary } = colors;
const { fontWeightRegular } = typography;

export default {
  html: {
    scrollBehavior: "smooth",
    cursor: "default",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
    fontWeight: fontWeightRegular,
  },
  "a, a:link, a:visited": {
    textDecoration: "none",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main}`,
    transition: "color 150ms ease-in",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main}`,
  },
  "*::-webkit-scrollbar": {
    width: "12px",
  },
  "*::-webkit-scrollbar-track": {
    background: "rgba(0,0,0,0.00)",
  },
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: "#cdcccc",
    border: "3px solid #ffff",
    borderRadius: "10px",
    "&:hover": { backgroundColor: "#a7a7a7" },
  },
  "*::-webkit-scrollbar:horizontal": {
    height: "12px",
    background: "rgba(0,0,0,0.00)",
  },
  "*::-webkit-scrollbar-thumb:horizontal": {
    backgroundColor: "#cdcccc",
    borderRadius: "10px",
  },

  "*::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "*::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "input[type='number']": {
    MozAppearance: "textfield",
  },

  // ****************************************************
  // *                  CK EDITOR                       *
  // ****************************************************
  ".ck-blurred": {
    border: "1px solid #b3b3b3 !important",
    borderRadius: " 4px",
  },
  ".ck-content": {
    minHeight: "80px",
  },
  ".cropper-point.point-n, .cropper-point.point-e, .cropper-point.point-w, .cropper-point.point-s":
    {
      backgroundColor: "transparent",
    },
  // "": {
  //   backgroundColor: "transparent",
  // },
  // "": {
  //   backgroundColor: "transparent",
  // },
  // "": {
  //   backgroundColor: "transparent",
  // },
  ".cropper-modal": {
    opacity: 0.6,
  },
  ".cropper-view-box": {
    outline: `2px solid ${primary.main}`,
  },
  ".cropper-point, .crop-point:before, .cropper-point:after": {
    borderRadius: "50%",
    width: "8px !important",
    height: "8px !important",
    opacity: "1 !important",
    backgroundColor: primary.main,
  },
  ".cropper-point.point-ne": {
    right: "-4px",
    top: "-4px",
  },
  ".cropper-point.point-nw": {
    left: "-4px",
    top: "-4px",
  },
  ".cropper-point.point-sw": {
    left: "-4px",
    bottom: "-4px",
  },
  ".cropper-point.point-se": {
    right: "-4px",
    bottom: "-4px",
  },
};
