import Grow from "@mui/material/Grow";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import styles from "assets/styles/pages/Chat/component/MessageTagName";
import { List, ListItem } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemButton from "@mui/material/ListItemButton";
import { useRef, useEffect } from "react";

import defaultAvatar from "../../../assets/svg/icon/default_avatar_group.svg";

function MessageTagName({
  anchor,
  isOpenTagName,
  handleClose,
  filteredUsers,
  handleUserClick,
  selectedIndex,
}) {
  const listRef = useRef(null);
  useEffect(() => {
    if (listRef?.current && selectedIndex >= 0) {
      const selectedListItem = listRef?.current?.children?.[selectedIndex];
      selectedListItem?.scrollIntoView({ block: "nearest" });
    }
  }, [selectedIndex]);
  return (
    <Popper
      placement="bottom-end"
      sx={styles.popper}
      open={isOpenTagName}
      anchorEl={anchor}
      role={undefined}
      transition
      disablePortal
      popperOptions={{ modifiers: [{ name: "offset", options: { offset: [20, 10] } }] }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={styles.paper}>
            <ClickAwayListener mouseEvent="onMouseDown" disableReactTree onClickAway={handleClose}>
              <List ref={listRef} dense sx={styles.list} tabIndex={isOpenTagName ? 0 : -1}>
                {filteredUsers?.map((value, index) => (
                  <ListItem key={value.id} sx={styles.listItem}>
                    <ListItemButton
                      onClick={() => handleUserClick(value)}
                      sx={() => styles.hover({ index, selectedIndex })}
                    >
                      <Box sx={styles.avatar}>
                        <Avatar src={defaultAvatar} srcSet={value.avatar} sx={styles.image} />
                      </Box>
                      <Box>
                        <Typography
                          className="username"
                          color="initial"
                          variant="body1"
                          sx={styles.name}
                        >
                          {value?.username}
                        </Typography>
                        <Typography className="vID" color="initial" variant="body1" sx={styles.vid}>
                          {value?.id}
                        </Typography>
                      </Box>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default MessageTagName;
