import React from "react";
import { Dialog, DialogContent, Typography, Grid, Box, Avatar, Button } from "@mui/material";
import styles from "assets/styles/pages/Chat/component/ScoreBox";
import TeamRed from "assets/images/borderRed.png";
import BGBlue from "assets/images/bgBlue.png";
import BGRed from "assets/images/bgRed.png";
import BlueTeam from "assets/images/borderBlue.png";
import { ReactComponent as Place } from "assets/svg/icon/place.svg";
import { ReactComponent as DoubleDot } from "assets/svg/icon/doubleDot.svg";
import Score from "assets/images/score_circle.png";
import defaultAvatar from "assets/svg/icon/default_avatar_group.svg";
import { formatDate } from "utils/convert";
import { STATUS_SCORE } from "constant/chat";
import { useTranslation } from "react-i18next";

function ScoreDialog({ open, handleClose, detailChanel }) {
  if (!detailChanel) return null;
  const getScore = () => {
    if (detailChanel) {
      const countBlue = detailChanel?.flight.score.filter(
        (item) => item.status && item.status === STATUS_SCORE.BLUE
      ).length;
      const countRed = detailChanel?.flight.score.filter(
        (item) => item.status && item.status === STATUS_SCORE.RED
      ).length;
      return { countBlue, countRed };
    }
    return null;
  };
  const getHole = () => {
    if (detailChanel) {
      const listHole = detailChanel?.flight.score.filter((item) => item.status);
      return listHole;
    }
    return null;
  };
  const getUserByTeam = (team) => {
    let idsTeam = [];
    if (detailChanel) {
      idsTeam = team
        .filter((item) => item != null)
        .map((u) => {
          const data = detailChanel?.list_users
            ?.filter((item) => item !== null)
            .find((user) => user.id === u.id);
          return { ...u, avatar: data.avatar, avatar_large: data.avatar_large };
        });
    }

    return idsTeam;
  };
  const { t } = useTranslation();
  return (
    <Dialog
      PaperProps={{
        sx: {
          minWidth: "700px",
          width: "100%",
          maxHeight: "100%",
        },
      }}
      open={open}
      onClose={handleClose}
      sx={styles.ScoreBox}
    >
      <DialogContent sx={styles.ScoreDialog}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box />
          <Typography sx={styles.title} variant="h6">
            {detailChanel?.flight?.play_type.name}
          </Typography>
          {detailChanel.is_endding_channel === 1 && (
            <Button sx={styles.endChannel}>Kết thúc</Button>
          )}
        </Box>
        <Grid container alignItems="center" sx={styles.placeAndTime}>
          <Grid sx={styles.facilityContainer} item>
            <Place />
            <Box>
              <Typography sx={styles.facility} variant="caption">
                {detailChanel.flight.facility_name}
              </Typography>
              <Box sx={{ display: "flex", ml: 0.5 }}>
                <Typography variant="body1" sx={styles.hole_index_text}>
                  {t("update_point.starting_hole")}
                </Typography>
                <Typography variant="body1" sx={styles.hole_index}>
                  {
                    detailChanel.flight.score.find(
                      (item) => +item.hole === +detailChanel.flight.hole_index
                    )?.holeName
                  }
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Box sx={styles.TeeContainer}>
              <Box sx={() => styles.boxTeeTime({ colorTee: detailChanel.tee_id })} />
              <Typography>{formatDate(detailChanel.tee_time, "HH:mm DD/MM/YYYY")}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container alignItems="center" sx={styles.TeamPlay}>
          <Grid item>
            <Box style={{ position: "relative" }}>
              {getScore()?.countBlue <= getScore()?.countRed ? (
                <Box component="img" sx={styles.imageBorderRed} src={BlueTeam} />
              ) : (
                <Box component="img" sx={styles.imageBorderRed} src={BGBlue} />
              )}

              {getUserByTeam(detailChanel.flight.user_plays.team1).map((user, keyUser) => (
                <Box key={keyUser}>
                  <Box
                    sx={() =>
                      styles.playerTeamRed({
                        indexUser: detailChanel.flight.user_plays.team1.filter((u) => u !== null)
                          .length,
                        keyUser,
                      })
                    }
                  >
                    <Box sx={() => styles.avatarContainer({ direction: "left" })}>
                      <Avatar sx={styles.avatar} src={defaultAvatar} srcSet={user?.avatar} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        sx={() =>
                          styles.fullname({
                            index: getScore()?.countBlue <= getScore()?.countRed,
                          })
                        }
                        variant="caption"
                      >
                        {user.username || user.name}
                      </Typography>
                      <Typography
                        sx={() =>
                          styles.vID({
                            index: getScore()?.countBlue <= getScore()?.countRed,
                          })
                        }
                        variant="caption"
                      >
                        {user.id}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={() =>
                      styles.ScoreRed({
                        indexUser: detailChanel.flight.user_plays.team1.filter((u) => u !== null)
                          .length,
                        keyUser,
                      })
                    }
                  >
                    <Box sx={styles.scoreContainer}>
                      <Box sx={styles.imageCircle} component="img" src={Score} />
                      <Typography sx={styles.cap} variant="caption">
                        {user.cap}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
          {detailChanel.is_endding_channel === 1 && (
            <Grid item sx={styles.Score}>
              {getScore()?.countBlue === getScore()?.countRed && (
                <>
                  <Typography sx={styles.TextABlue} variant="caption">
                    A
                  </Typography>
                  <Typography sx={styles.TextSRed} variant="caption">
                    S
                  </Typography>
                </>
              )}
              {getScore()?.countBlue > getScore()?.countRed && (
                <>
                  <Typography sx={styles.TextScoreBlue} variant="caption">
                    {getScore().countBlue - getScore().countRed}
                  </Typography>
                  <Typography sx={styles.TextBlue} variant="caption">
                    &
                  </Typography>
                  <Typography sx={styles.TextBlue} variant="caption">
                    {
                      detailChanel?.flight.score.filter((item) => item.status === STATUS_SCORE.NONE)
                        .length
                    }
                  </Typography>
                </>
              )}
              {getScore()?.countBlue < getScore()?.countRed && (
                <>
                  <Typography sx={styles.TextScoreRed} variant="caption">
                    {getScore().countRed - getScore().countBlue}
                  </Typography>
                  <Typography sx={styles.TextSRed} variant="caption">
                    &
                  </Typography>
                  <Typography sx={styles.TextSRed} variant="caption">
                    {
                      detailChanel?.flight.score.filter((item) => item.status === STATUS_SCORE.NONE)
                        .length
                    }
                  </Typography>
                </>
              )}
            </Grid>
          )}
          {detailChanel.is_endding_channel !== 1 && (
            <Grid item sx={styles.Score}>
              {getScore() && (
                <Typography sx={styles.ScoreTeamBlue} variant="caption">
                  {getScore()?.countBlue}
                </Typography>
              )}

              <Typography style={{ width: "20px" }} variant="caption">
                <DoubleDot />
              </Typography>
              {getScore() && (
                <Typography sx={styles.ScoreTeamRed} variant="caption">
                  {getScore()?.countRed}
                </Typography>
              )}
            </Grid>
          )}

          <Box style={{ position: "relative" }}>
            <Grid item>
              {getScore()?.countBlue >= getScore()?.countRed ? (
                <Box component="img" sx={styles.imageBorderRed} src={TeamRed} />
              ) : (
                <Box component="img" sx={styles.imageBorderRed} src={BGRed} />
              )}

              {getUserByTeam(detailChanel.flight.user_plays.team2)
                .filter((u) => u !== null)
                .map((user, keyUser) => (
                  <Box key={keyUser}>
                    <Box
                      sx={() =>
                        styles.playerTeamBlue({
                          indexUser: detailChanel.flight.user_plays.team2.filter((u) => u !== null)
                            .length,
                          keyUser,
                        })
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={() =>
                            styles.fullname({
                              index: getScore()?.countBlue >= getScore()?.countRed,
                            })
                          }
                          variant="caption"
                        >
                          {user.username || user.name}{" "}
                        </Typography>
                        <Typography
                          sx={() =>
                            styles.vID({
                              index: getScore()?.countBlue >= getScore()?.countRed,
                            })
                          }
                          variant="caption"
                        >
                          {user.id}
                        </Typography>
                      </Box>
                      <Box sx={() => styles.avatarContainer({ direction: "right" })}>
                        <Avatar sx={styles.avatar} src={defaultAvatar} srcSet={user?.avatar} />
                      </Box>
                    </Box>
                    <Box
                      sx={() =>
                        styles.scoreBlue({
                          indexUser: detailChanel.flight.user_plays.team1.filter((u) => u !== null)
                            .length,
                          keyUser,
                        })
                      }
                    >
                      <Box sx={styles.scoreContainer}>
                        <Box sx={styles.imageCircle} component="img" src={Score} />
                        <Typography sx={styles.cap} variant="caption">
                          {user.cap}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Grid>
          </Box>
        </Grid>
        <Box sx={styles.pagination}>
          {getHole() &&
            getHole().map((hole, indexHole) => (
              <Box sx={() => styles.holeContainer({ status: hole.status })} key={indexHole}>
                {hole.status === STATUS_SCORE.NONE && <Box sx={styles.holeNone} />}
                <Typography sx={() => styles.hole({ status: hole.status })} variant="caption">
                  {hole.hole}
                </Typography>
              </Box>
            ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ScoreDialog;
