import { Box, ClickAwayListener, Grow, List, Paper, Popper } from "@mui/material";
import styles from "assets/styles/pages/Chat/component/EmojiPopper";
import EmojiPicker, { EmojiStyle, Categories } from "emoji-picker-react";
import { useTranslation } from "react-i18next";

function EmojiPopper({ openSetting, anchor, handleClose, onClickEmoji }) {
  const { t } = useTranslation();

  return (
    <Popper
      placement="bottom-end"
      sx={styles.popper}
      open={openSetting}
      anchorEl={anchor}
      role={undefined}
      transition
      disablePortal
      popperOptions={{ modifiers: [{ name: "offset", options: { offset: [20, 10] } }] }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={styles.paper}>
            <ClickAwayListener disableReactTree mouseEvent="onMouseDown" onClickAway={handleClose}>
              <Box sx={styles.heightFull}>
                <List sx={styles.heightFull}>
                  <Box sx={styles.dFlex}>
                    <EmojiPicker
                      searchPlaceholder={t("chat.footer.emoji.titleSearch")}
                      onEmojiClick={onClickEmoji}
                      autoFocusSearch={false}
                      emojiStyle={EmojiStyle.NATIVE}
                      categories={[
                        {
                          name: t("chat.footer.emoji.suggested"),
                          category: Categories.SUGGESTED,
                        },
                        {
                          name: t("chat.footer.emoji.smileys_people"),
                          category: Categories.SMILEYS_PEOPLE,
                        },

                        {
                          name: t("chat.footer.emoji.yum"),
                          category: Categories.FOOD_DRINK,
                        },
                        {
                          name: t("chat.footer.emoji.travel_places"),
                          category: Categories.TRAVEL_PLACES,
                        },
                        {
                          name: t("chat.footer.emoji.activities"),
                          category: Categories.ACTIVITIES,
                        },
                        {
                          name: t("chat.footer.emoji.animals_nature"),
                          category: Categories.ANIMALS_NATURE,
                        },
                        {
                          name: t("chat.footer.emoji.symbols"),
                          category: Categories.SYMBOLS,
                        },
                        {
                          name: t("chat.footer.emoji.objects"),
                          category: Categories.OBJECTS,
                        },
                      ]}
                    />
                  </Box>
                </List>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default EmojiPopper;
