import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { ReactComponent as CameraIcon } from "assets/svg/icon/camera.svg";
import { ReactComponent as CloseAddGroupIcon } from "assets/svg/icon/close_add_group.svg";
import { ReactComponent as PlayerIcon } from "assets/svg/icon/player.svg";
import { ReactComponent as ReporterIcon } from "assets/svg/icon/reporter.svg";
import { ReactComponent as DateTimeIcon } from "assets/svg/icon/date_time.svg";
import { ReactComponent as LocationIcon } from "assets/svg/icon/location.svg";
import { ReactComponent as GolfIcon } from "assets/svg/icon/golf.svg";
import { ReactComponent as CreateReporterIcon } from "assets/svg/icon/create_reporter.svg";
import { ReactComponent as VsIcon } from "assets/svg/icon/vs.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "jssip/lib/Utils";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TeamRed from "../../../assets/images/borderRed.png";
import TeamBlue from "../../../assets/images/borderBlue.png";
import CropGroupAvatar from "./CropGroupAvatar";
import styles from "../../../assets/styles/pages/Chat/component/NewChannelTvDialog";

export default function NewChannelTvDialog({ onClose, isOpen }) {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState("");
  const [isOpenCrop, setOpenCrop] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [groupPermission, setGroupPermission] = useState(0);
  const [numberOfPlayers, setNumberOfPlayers] = useState(3);
  const [isPrivate, setIsPrivate] = useState(0);
  const [numberPlayer, setNumberPlayer] = useState(null);
  const [listNumberPlayer, setListNumberPlayer] = useState([]);
  const [selectedValueHole, setSelectedValueHole] = useState(1);
  const [competitionFormat, setCompetitionFormat] = useState(1);
  // const [listReporter, setListReporter] = useState([]);
  // const [listPlayerBlue, setListPlayerTeamBlue] = useState([]);
  // const [listPlayerRed, setListPlayerTeamRed] = useState([]);

  const handleChangeCompetitionFormat = (event) => {
    const value = parseInt(event.target.value, 10);
    setCompetitionFormat(parseInt(value, 10));
    if (value === 1 || value === 2) {
      setListNumberPlayer([
        {
          value: 4,
          title: "4",
        },
        {
          value: 6,
          title: "6",
        },
      ]);
    }
    if (value === 3) {
      setListNumberPlayer([
        {
          value: 2,
          title: "2",
        },
      ]);
    }
  };
  const handleResetGroup = () => {
    setAvatar(null);
    setGroupName("");
  };
  const handleChangeNumberPlayer = (event) => {
    const { value } = event.target;
    setNumberPlayer(value);
    if (value === 2) {
      setNumberOfPlayers(1);
    }
    if (value === 4) {
      setNumberOfPlayers(2);
    }
    if (value === 6) {
      setNumberOfPlayers(3);
    }
  };
  const handleChangeHole = (event) => {
    setSelectedValueHole(event.target.value);
  };

  // const handlePlayerChange = (count) => {
  //   setNumberOfPlayers(count);
  // };

  // useEffect(() => {
  //   const slider = document.querySelector(".container");
  //   let isDown = false;
  //   let startX;
  //   let scrollLeft;
  //
  //   if (slider) {
  //     slider.addEventListener("mousedown", (e) => {
  //       isDown = true;
  //       slider.classList.add("active");
  //       startX = e.pageX - slider.offsetLeft;
  //       scrollLeft = slider.scrollLeft;
  //     });
  //     slider.addEventListener("mouseleave", () => {
  //       isDown = false;
  //       slider.classList.remove("active");
  //     });
  //     slider.addEventListener("mouseup", () => {
  //       isDown = false;
  //       slider.classList.remove("active");
  //     });
  //     slider.addEventListener("mousemove", (e) => {
  //       if (!isDown) return;
  //       e.preventDefault();
  //       const x = e.pageX - slider.offsetLeft;
  //       const walk = (x - startX) * 2;
  //       slider.scrollLeft = scrollLeft - walk;
  //     });
  //   }
  // }, [members]);

  const handleOpenCropImage = () => setOpenCrop(true);
  const handleCloseCropImage = () => setOpenCrop(false);

  const handleGroupPermissionChange = (option) => {
    setGroupPermission(option);
  };
  const handleIsPrivateChange = (option) => {
    setIsPrivate(option);
  };
  const menuItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
  return (
    <Dialog
      PaperProps={{
        sx: {
          minWidth: "800px",
          width: "90%",
          maxHeight: "90%",
        },
      }}
      onClose={() => {
        handleResetGroup();
        onClose();
      }}
      open={isOpen}
      sx={styles.dialog}
    >
      <CropGroupAvatar
        setting={{
          isOpen: isOpenCrop,
          onClose: handleCloseCropImage,
          setAvatar,
          avatar,
          setOpenCrop,
        }}
      />

      <DialogTitle sx={styles.dFlexColumn}>
        <Box sx={styles.dialogTitle}>
          <Typography variant="body1" color="initial" sx={styles.title}>
            {t("channel_tv.create_channel_tv")}
          </Typography>
          <IconButton
            sx={styles.paddingReset}
            onClick={() => {
              handleResetGroup();
              onClose();
            }}
          >
            <CloseAddGroupIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={styles.divider} />
      <Box sx={styles.groupContainer}>
        {!avatar ? (
          <Button sx={styles.groupCropImage} onClick={handleOpenCropImage}>
            <Box sx={styles.cameraContainer}>
              <CameraIcon />
            </Box>
          </Button>
        ) : (
          <Box onClick={handleOpenCropImage} sx={styles.cropAvatarContainer}>
            <Box className="avatar-overlay" sx={styles.overlay}>
              <CameraIcon style={styles.updateAvatarIcon} />
            </Box>
            <Avatar sx={styles.cropAvatar} src={avatar} />
          </Box>
        )}
        <Box sx={styles.groupNameContainer}>
          <TextField
            sx={styles.groupName}
            variant="standard"
            placeholder={t("chat.user_list.group_name")}
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            InputProps={{ autoComplete: "off" }}
          />
        </Box>
      </Box>
      <Box style={{ margin: "15px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={styles.boxCompetitionFormat}>
              <Box sx={styles.boxTitle}>
                <GolfIcon />
                <Typography variant="body1" color="initial" sx={styles.boxTextCompetitionFormat}>
                  {t("channel_tv.competition_format")}
                </Typography>
              </Box>
              <FormControl style={{ marginLeft: "25px", marginTop: "15px" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={1}
                  value={competitionFormat}
                  onChange={handleChangeCompetitionFormat}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio sx={styles.radio} />}
                    label="Four-some match play"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio sx={styles.radio} />}
                    label="Four-ball match play"
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio sx={styles.radio} />}
                    label="Single match play"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={styles.boxDateTime}>
              <Box sx={styles.boxTitle}>
                <Box style={{ marginTop: "5px" }}>
                  <DateTimeIcon />
                </Box>
                <Box style={{ display: "inline-block" }}>
                  <Typography variant="body1" color="initial" sx={styles.boxText}>
                    {t("channel_tv.time")}
                  </Typography>
                  <input
                    type="datetime-local"
                    style={{
                      border: "none",
                      outline: "none",
                      marginLeft: "10px",
                      marginTop: "5px",
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={styles.boxReporter}>
              <Box sx={styles.boxTitle}>
                <ReporterIcon />
                <Typography variant="body1" color="initial" sx={styles.boxTextCompetitionFormat}>
                  {t("channel_tv.reporter")}
                </Typography>
              </Box>
              <Box sx={styles.createReporter}>
                <CreateReporterIcon />
                <Typography variant="body1" color="initial" sx={styles.boxTextReporter}>
                  {t("channel_tv.add_reporter")}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={styles.boxStadium}>
              <Box sx={styles.boxTitle}>
                <LocationIcon />
                <Box style={{ display: "inline-block" }}>
                  <Typography variant="body1" color="initial" sx={styles.boxText}>
                    {t("channel_tv.arena")}
                  </Typography>
                  <Typography variant="body1" color="initial" sx={styles.choose}>
                    {t("channel_tv.select_golf_course")}
                  </Typography>
                </Box>
              </Box>
              <Box style={{ padding: "10px" }}>
                <Typography variant="body1" color="initial" sx={styles.choose}>
                  {t("channel_tv.path")}
                </Typography>
                <Box sx={styles.selectOptionsContainer}>
                  <Box
                    sx={{
                      ...styles.selectOptionBox,
                      backgroundColor: groupPermission === 0 ? "#E4EFFF" : "#F4F4F4",
                    }}
                    onClick={() => handleGroupPermissionChange(0)}
                  >
                    <Typography
                      sx={{
                        ...styles.textSelectOptions,
                        color: groupPermission === 0 ? "#406EE2" : "#808080",
                      }}
                    >
                      A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      ...styles.selectOptionBox,
                      backgroundColor: groupPermission === 1 ? "#E4EFFF" : "#F4F4F4",
                    }}
                    onClick={() => handleGroupPermissionChange(1)}
                  >
                    <Typography
                      sx={{
                        ...styles.textSelectOptions,
                        color: groupPermission === 1 ? "#406EE2" : "#808080",
                      }}
                    >
                      B
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body1" color="initial" sx={styles.choose}>
                  {t("channel_tv.way_back")}
                </Typography>
                <Box sx={styles.selectOptionsContainer}>
                  <Box
                    sx={{
                      ...styles.selectOptionBox,
                      backgroundColor: isPrivate === 0 ? "#E4EFFF" : "#F4F4F4",
                    }}
                    onClick={() => handleIsPrivateChange(0)}
                  >
                    <Typography
                      sx={{
                        ...styles.textSelectOptions,
                        color: isPrivate === 0 ? "#406EE2" : "#808080",
                      }}
                    >
                      A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      ...styles.selectOptionBox,
                      backgroundColor: isPrivate === 1 ? "#E4EFFF" : "#F4F4F4",
                    }}
                    onClick={() => handleIsPrivateChange(1)}
                  >
                    <Typography
                      sx={{
                        ...styles.textSelectOptions,
                        color: isPrivate === 1 ? "#406EE2" : "#808080",
                      }}
                    >
                      B
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body1" color="initial" sx={styles.choose}>
                  {t("channel_tv.starting_pit")}
                </Typography>
                <FormControl sx={styles.hole}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedValueHole}
                    onChange={handleChangeHole}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 10, // Adjust the maximum height as needed
                          overflowY: "auto", // Enable vertical scrolling
                        },
                      },
                    }}
                    endAdornment={<ArrowDropDownIcon />}
                    sx={styles.selectPlayer}
                    inputProps={{
                      MenuProps: {
                        MenuListProps: {
                          sx: {
                            "& .MuiMenuItem-root": {
                              backgroundColor: "#fff", // Set the default background color
                              "&:hover": {
                                backgroundColor: "#d9e8f6", // Change the background color on hover
                              },
                              "&.Mui-selected": {
                                backgroundColor: "#d9e8f6", // Change the background color when selected
                              },
                              marginTop: "5px",
                              color: "#000",
                            },
                          },
                        },
                      },
                    }}
                  >
                    {menuItems.map((value) => (
                      <MenuItem
                        key={value}
                        sx={{
                          "&:hover": { backgroundColor: "#F0F0F0" },
                          backgroundColor: "#FFF",
                        }}
                        value={value}
                      >
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box sx={styles.boxPlayer}>
              <Box sx={styles.boxTitlePlayer}>
                <PlayerIcon />
                <Box style={{ display: "inline-block" }}>
                  <Typography variant="body1" color="initial" sx={styles.boxText}>
                    {t("channel_tv.player")}
                  </Typography>
                  <Select
                    value={numberPlayer}
                    defaultValue={listNumberPlayer[0]?.value}
                    onChange={handleChangeNumberPlayer}
                    endAdornment={<ArrowDropDownIcon />}
                    sx={styles.selectPlayer}
                    inputProps={{
                      MenuProps: {
                        MenuListProps: {
                          dense: true,
                          sx: {
                            "& .MuiMenuItem-root": {
                              backgroundColor: "#fff", // Set the default background color
                              "&:hover": {
                                backgroundColor: "#d9e8f6", // Change the background color on hover
                              },
                              "&.Mui-selected": {
                                backgroundColor: "#d9e8f6", // Change the background color when selected
                              },
                              "&.Mui-focused": {
                                backgroundColor: "#fff", // Change the background color when focused
                              },
                              marginTop: "5px",
                              color: "#000",
                            },
                          },
                        },
                      },
                    }}
                  >
                    {listNumberPlayer.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.title} {t("channel_tv.person")}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
              <Box container style={{ display: "flex", padding: "15px", marginTop: "-30px" }}>
                <Box style={{ position: "relative", height: "100%" }}>
                  <Box component="img" sx={styles.imageBorderRed} src={TeamBlue} />
                  {[...Array(numberOfPlayers)].map((_, index) => (
                    <Box
                      key={index}
                      sx={{
                        ...styles.boxAddPlayerBlue,
                        top: `calc(50% + ${index * 40}px)`, // Điều chỉnh khoảng cách giữa các box thêm người chơi
                      }}
                    >
                      <CreateReporterIcon />
                      <Typography variant="body1" color="initial" sx={styles.textAddPlayerBlue}>
                        {t("channel_tv.add_person")}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box
                  style={{
                    lineHeight: "90px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <VsIcon />
                </Box>
                <Box style={{ position: "relative" }}>
                  <Box component="img" sx={styles.imageBorderRed} src={TeamRed} />
                  {[...Array(numberOfPlayers)].map((_, index) => (
                    <Box
                      key={index}
                      sx={{
                        ...styles.boxAddPlayerRed,
                        top: `calc(50% + ${index * 40}px)`, // Điều chỉnh khoảng cách giữa các box thêm người chơi
                      }}
                    >
                      <Typography variant="body1" color="initial" sx={styles.textAddPlayerRed}>
                        {t("channel_tv.add_person")}
                      </Typography>
                      <CreateReporterIcon />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Button
        variant="contained"
        sx={styles.buttonCreateGroup}
        disabled={isEmpty(groupName)}
        onClick={() => {
          // Determine the review value based on isPrivate
          // const reviewValue = isPrivate === 1 ? 1 : 0;
          //
          // // Call handleAddGroupChat with the correct review value
          // addGroupChat.handleAddGroupChat(
          //   {
          //     name: groupName,
          //     members: members.map((v) => v.id),
          //     image: avatar,
          //     is_private: isPrivate,
          //     group_permission: groupPermission,
          //     review: reviewValue,
          //   },
          //   handleResetGroup
          // );

          // Close the dialog or perform other actions as needed
          onClose();
        }}
      >
        {t("channel_tv.create_channel")}
      </Button>
    </Dialog>
  );
}
