export default {
  dialog: ({ breakpoints, borders }) => ({
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.lg,
        width: "100%",
        height: "50%",
        // maxHeight: "600px",
        maxWidth: "450px",
        [breakpoints.down("sm")]: {
          maxWidth: "90vw",
        },
      },
    },
  }),
  dFlexColumn: { display: "flex", flexDirection: "column" },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    mx: 2,
    mt: 3,
    // mb: 0,
  },
  textCenter: { textAlign: "center" },
  textDefault: ({ palette }) => ({
    color: palette.grey[400],
  }),
  textActive: ({ palette }) => ({
    color: palette.white.main,
  }),
  title: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
  }),

  overflowX: { display: "flex", overflowX: "auto" },

  countSelectedBox: { display: "flex", justifyContent: "space-between", pr: 4, pl: 2.5 },
  overflowY: { overflowY: "auto", pl: 0.5, ml: 2, alignItems: "center", paddingTop: "10%" },

  contentContainer: {
    flexGrow: 1,
    display: "flex",
    borderRadius: 0,
    mb: 2.5,
    flexDirection: "column",
  },
  sharelink: {
    textAlign: "center",
    mt: 6.5,
    mb: 2.5,
  },
  copylink: {
    alignItems: "center",
    textAlign: "center",
    mb: 2.5,
  },
};
