import { AddMember } from "api/chat";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { encodeID } from "utils/hex";
import { getUserToken } from "utils/storage";

export default function JoinFromLink() {
  const { groupId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (groupId) {
      const userInformation = getUserToken();

      AddMember({
        user_ids: [userInformation?.id],
        permission: 0,
        group_id: groupId,
      }).then((res) => {
        if (res.error === 0) {
          navigate(`/t/${encodeID(groupId)}`);
        } else {
          navigate(`/`);
        }
      });
    }
  }, [groupId]);

  return <h1>JoinFromLink</h1>;
}
