export default {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    my: "auto",
    pt: { lg: 12, xs: 8, sm: 8, md: 8 },
  },
  pRelative: {
    position: "relative",
  },
  LogoBack: {
    width: "259px",
    height: "259px",
  },
  LogoPhone: {
    position: "absolute",
    width: "160.62px",
    height: "160.62px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  LogoWGHN: ({ breakpoints }) => ({
    width: "303.15px",
    height: "96.12px",
    [breakpoints.down("sm")]: {
      width: "254.05px",
      height: "82.28px",
    },
  }),
  dFlexC: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
  },
  loginForm: ({ isShowOtp }) => ({
    mt: 1,
    display: isShowOtp ? "none" : "flex",
    flexDirection: "column",
    width: "397px",
    maxWidth: "100%",
  }),
  otpForm: ({ isShowOtp }) => ({
    display: isShowOtp ? "flex" : "none",
    flexDirection: "column",
    width: "397px",
    maxWidth: "100%",
  }),
  popperOption: {
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover": {
      backgroundColor: "#F2F2F2 !important",
    },
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']:hover": {
      backgroundColor: "#F2F2F2 !important",
    },
  },
  borderRadius10: ({ borders }) => ({
    borderRadius: `${borders.borderRadius.lg} !important`,
  }),
  popperListBox: {
    fontSize: "14px",
    px: 2,
    py: 1,
  },
  title: ({ typography, breakpoints }) => ({
    mt: { lg: 4, xs: 2, sm: 1.0, md: 2.0 },
    fontFamily: `${typography.fontFamily2} !important`,
    fontWeight: typography.fontWeightBold,
    fontSize: "38px",
    lineHeight: "46px",
    textAlign: "Center",
    height: "62px",
    [breakpoints.down("sm")]: {
      fontSize: "28px !important",
      height: "58px",
    },
  }),
  subtitleContainer: ({ breakpoints }) => ({
    mt: { lg: 2, xs: 0.5, sm: 0.5, md: 0.5 },
    [breakpoints.down("sm")]: {
      fontSize: "14px !important",
    },
    textAlign: "center",
    maxWidth: "100%",
    width: "331px",
    height: "51px",
  }),
  messageError: ({ palette }) => ({
    fontSize: "12px",
    color: palette.error.main,
    width: "100%",
    textAlign: "center",
    height: "24px",
  }),
  inlineContainer: {
    // py: 2,
  },
  inline: {
    display: "inline",
  },
  autocomplete: ({ borders, typography }) => ({
    mt: 3.75,
    borderRadius: borders.borderRadius.round,
    width: "100%",
    fontSize: "16px",
    label: {
      paddingLeft: "30px",
    },
    fieldset: {
      paddingLeft: "30px",
      borderRadius: borders.borderRadius.round,
    },
    "& .MuiInputBase-input": {
      fontFamily: `${typography.fontFamily2} !important`,
      height: "18px",
      marginLeft: "20px",
      fontWeight: "450",
    },
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover": {
      backgroundColor: "#FF8787",
    },
  }),
  textfield: ({ borders, typography }) => ({
    mt: 1.75,
    fontSize: "16px",
    borderRadius: borders.borderRadius.round,
    width: "100%",
    marginBottom: "5px",
    label: {
      paddingLeft: "30px",
    },
    fieldset: {
      paddingLeft: "30px",
      borderRadius: borders.borderRadius.round,
    },
    "& .MuiInputBase-input": {
      fontFamily: `${typography.fontFamily2} !important`,
      height: "35px",
      fontWeight: "450",
      px: 0,
    },
  }),
  startText: {
    fontWeight: "450",
    paddingLeft: "16px",
  },
  loginButton: ({ borders }) => ({
    mb: { lg: 4, xs: 4, sm: 4, md: 4 },
    width: "100%",
    borderRadius: borders.borderRadius.round,
    mt: 2,
    height: "50px",
    // background: "linear-gradient(90deg, #26BBFE 0%, #1BC88B 100%) border-box",
    background: "#17479E",
    "&:hover": {
      boxShadow: "none",
      opacity: 0.9,
      background: "#17479E",
    },
    boxShadow: "none",
  }),
  loginButtonQR: ({ borders }) => ({
    mb: { lg: 8, xs: 4, sm: 4, md: 4 },
    width: "100%",
    borderRadius: borders.borderRadius.round,
    textTransform: "uppercase",
    height: "50px",
    border: "2px solid #17479E",
    "&:hover": {
      border: "2px solid #17479E",
    },
    // background:
    //   "linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, #26BBFE, #1BC88B) border-box",
    // "&:hover": {
    //   background:
    //     "linear-gradient(#f8ffff 0 0) padding-box, linear-gradient(to right, #26BBFE, #1BC88B) border-box",
    // },
  }),
  login: ({ typography, breakpoints, palette }) => ({
    textTransform: "uppercase",
    color: palette.white.main,
    fontWeight: typography.fontWeightBold,
    fontFamily: `${typography.fontFamily2} !important`,
    [breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  }),
  loginQR: ({ typography, breakpoints }) => ({
    // background: "-webkit-linear-gradient(#26BBFE 3.27%, #1BC88B 97.45%)",
    background: "#17479E",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textTransform: "uppercase",
    fontWeight: typography.fontWeightBold,
    fontFamily: `${typography.fontFamily2} !important`,
    [breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  }),
  formOtp: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  textOtp: ({ breakpoints, typography }) => ({
    MozAppearance: "textfield",
    WebkitAppearance: "none",
    margin: 0,
    width: "50px",
    input: {
      fontFamily: `${typography.fontFamily2} !important`,
      fontWeight: "500",
      textAlign: "center",
      fontSize: "30px",
    },
    [breakpoints.down("sm")]: {
      width: "40px",
      input: {
        fontSize: "25px",
      },
    },
  }),
};
