import { Box, Drawer, FormControlLabel, IconButton, Typography } from "@mui/material";
import styles from "assets/styles/pages/Chat/component/SettingGroup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { t } from "i18next";
import React, { useState } from "react";
import { styled } from "@mui/system";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";

function SettingGroup({
  isMediumSize,
  handleCloseSetting,
  isOpenSetting,
  detailGroup,
  updateSettingGroup,
}) {
  const [isPrivate, setIsPrivate] = useState(detailGroup?.is_private);
  const [groupPermission, setGroupPermission] = useState(detailGroup?.group_permission);
  const [switchValue, setSwitchValue] = useState(detailGroup?.review);
  const Android12Switch = styled(Switch)(() => ({
    padding: 10,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      // margin: 2,
      // marginBottom: "30px",
    },
  }));

  const handleIsPrivateChange = (option) => {
    updateSettingGroup({
      id: detailGroup?.id,
      groupPermission,
      isPrivate: option,
      review: switchValue,
    });
    setIsPrivate(option);
  };

  const handleGroupPermissionChange = (option) => {
    updateSettingGroup({
      id: detailGroup?.id,
      groupPermission: option,
      isPrivate,
      review: switchValue,
    });
    setGroupPermission(option);
  };

  const handleSwitchChange = () => {
    // Toggle between 0 and 1
    const updatedSwitchValue = switchValue === 0 ? 1 : 0;
    // Update the state
    setSwitchValue(updatedSwitchValue);
    // Use the updated value in the updateSettingGroup function
    updateSettingGroup({
      id: detailGroup?.id,
      groupPermission,
      isPrivate,
      review: updatedSwitchValue,
    });
  };
  return (
    <Drawer
      sx={(e) => styles.drawer(e, { isOpenSetting })}
      onClose={handleCloseSetting}
      anchor="right"
      open={isOpenSetting}
      variant={!isMediumSize ? "permanent" : "temporary"}
    >
      <Box sx={styles.fLexCol}>
        <Box sx={styles.dialogTitle}>
          <IconButton
            onClick={() => {
              handleCloseSetting();
            }}
          >
            <ArrowBackIosIcon sx={styles.arrowIcon} />
          </IconButton>
          <Typography variant="body1" color="initial" sx={styles.title}>
            Cài đặt
          </Typography>
        </Box>
      </Box>
      <Box style={{ margin: "20px" }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "40px",
          }}
        >
          <Typography variant="body1" color="initial" sx={styles.title1}>
            Phê duyệt thành viên nhóm
          </Typography>
          <FormControlLabel
            label=""
            control={<Android12Switch checked={switchValue === 1} onChange={handleSwitchChange} />}
            style={{ lineHeight: "40px", marginRight: "-10px" }}
          />
        </Box>
        <Divider />
        <Box style={{ height: "65px" }}>
          <Typography variant="body1" color="initial" sx={styles.title2}>
            Quyền thêm thành viên vào nhóm
          </Typography>
          <Box style={{ display: "flex" }}>
            <Box
              sx={{
                ...styles.selectOptionBox,
                backgroundColor: groupPermission === 0 ? "#E4EFFF" : "#F4F4F4",
              }}
              onClick={() => handleGroupPermissionChange(0)}
            >
              <Typography
                sx={{
                  ...styles.textSelectOptions,
                  color: groupPermission === 0 ? "#406EE2" : "#808080",
                }}
              >
                {t("new_group.administrator")}
              </Typography>
            </Box>
            <Box
              sx={{
                ...styles.selectOptionBox,
                backgroundColor: groupPermission === 1 ? "#E4EFFF" : "#F4F4F4",
              }}
              onClick={() => handleGroupPermissionChange(1)}
            >
              <Typography
                sx={{
                  ...styles.textSelectOptions,
                  color: groupPermission === 1 ? "#406EE2" : "#808080",
                }}
              >
                {t("new_group.member")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box style={{ height: "65px" }}>
          <Typography variant="body1" color="initial" sx={styles.title2}>
            Chế độ nhóm
          </Typography>
          <Box style={{ display: "flex" }}>
            <Box
              sx={{
                ...styles.selectOptionBox,
                backgroundColor: isPrivate === 0 ? "#E4EFFF" : "#F4F4F4",
              }}
              onClick={() => handleIsPrivateChange(0)}
            >
              <Typography
                sx={{
                  ...styles.textSelectOptions,
                  color: isPrivate === 0 ? "#406EE2" : "#808080",
                }}
              >
                {t("new_group.public")}
              </Typography>
            </Box>
            <Box
              sx={{
                ...styles.selectOptionBox,
                backgroundColor: isPrivate === 1 ? "#E4EFFF" : "#F4F4F4",
              }}
              onClick={() => handleIsPrivateChange(1)}
            >
              <Typography
                sx={{
                  ...styles.textSelectOptions,
                  color: isPrivate === 1 ? "#406EE2" : "#808080",
                }}
              >
                {t("new_group.private")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
      </Box>
    </Drawer>
  );
}
export default SettingGroup;
