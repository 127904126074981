import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "assets/styles/pages/Chat/component/NotificationLoading";
import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

function NotifyStatusPin({ openDialogNotifyLoadingStatusPin, handleOpenLoadingNotifyPin }) {
  return (
    <Box>
      <Dialog
        open={openDialogNotifyLoadingStatusPin}
        onClose={() => handleOpenLoadingNotifyPin(false)}
        sx={styles.dialog}
      >
        <Box sx={styles.container}>
          <Box textAlign="center">
            <DialogTitle>
              <Box>
                <Typography sx={styles.titleText}>Vui lòng đợi...</Typography>
                <Box sx={styles.boxMessage}>
                  <Typography sx={styles.message}>
                    <CircularProgress />
                  </Typography>
                </Box>
              </Box>
            </DialogTitle>
          </Box>
          {/* <Box onClick={() => handleOpenLoadingNotifyPin(false)} sx={styles.action}>
            <Typography sx={styles.cancel}>Ok</Typography>
          </Box> */}
        </Box>
      </Dialog>
    </Box>
  );
}

export default React.memo(NotifyStatusPin);
