import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Typography,
  Fade,
} from "@mui/material";
import TabPanel from "components/TabPanel";
import { useState } from "react";
import { ReactComponent as CloseIcon } from "assets/svg/icon/close.svg";
import { TransitionGroup } from "react-transition-group";
import styles from "assets/styles/pages/Chat/section/voterInfomation";
import { useTranslation } from "react-i18next";
import ViewerItem from "../component/ViewerItem";

export default function VotingDialogs({ onClose, open, detailGroup, voteInfo }) {
  const handleCloseVoting = () => {
    onClose();
  };
  if (!detailGroup || !voteInfo) return null;
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getUserVote = () => {
    const voteUserId = voteInfo.voteUser.map((v) => v.id) || [];
    const data = detailGroup.users.filter((item) => voteUserId?.includes(item.id));
    return data || null;
  };
  // const getNonVoters = () => {
  //   const voteUserId = voteInfo.voteUser.map((v) => v.id) || [];
  //   const data = detailGroup.users.filter((item) => !voteUserId?.includes(item.id));
  //   return data || null;
  // };
  // console.log("getUserVote_____________________", getUserVote());
  return (
    <Dialog sx={styles.container} open={open} onClose={handleCloseVoting}>
      <Box sx={{ borderRadius: 0 }}>
        <DialogTitle sx={styles.DialogTittle}>
          <Box />
          <Typography
            color="initial"
            sx={({ typography }) => ({
              fontWeight: typography.fontWeightBold,
              fontSize: typography.size.md,
            })}
          >
            {t("chat.information_viewer.title")}
          </Typography>

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={styles.DividerT} />
        <Box>
          <Tabs variant="scrollable" value={value} onChange={handleChange} sx={styles.Tabs}>
            <Tab
              sx={
                value === 0
                  ? { ...styles.Tab, ml: { xs: 2, sm: 3 } }
                  : { ...styles.TabBg, ml: { xs: 2, sm: 3 } }
              }
              label={
                <Typography
                  sx={value === 0 ? styles.TabViewed : styles.TabNoBorder}
                  variant="body2"
                >
                  <Typography color="initial">
                    {t("chat.information_viewer.voted_by")}(
                    {`${voteInfo.voteUser.length}/${detailGroup.users.length}`})
                  </Typography>
                </Typography>
              }
            />
          </Tabs>
        </Box>
      </Box>
      <Divider sx={styles.DividerB} />
      <TabPanel sx={styles.TabPanel} value={value} index={0}>
        <TransitionGroup>
          {getUserVote() &&
            getUserVote()?.map((element) => (
              <Fade key={element.id}>
                <Box sx={styles.users}>
                  <ViewerItem
                    information={{
                      avatar: element.avatar,
                      name: element.username,
                      vID: element.id,
                      message: element.message,
                      time: element.time,
                      emotion: null,
                      tip: null,
                    }}
                  />
                </Box>
              </Fade>
            ))}
        </TransitionGroup>
      </TabPanel>
    </Dialog>
  );
}
