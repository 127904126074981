export default {
  dialog: ({ breakpoints, borders }) => ({
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.lg,
        width: "100%",
        height: "100%",
        maxHeight: "527px",
        maxWidth: "879px",
        [breakpoints.down("lg")]: {
          maxWidth: "90vw",
        },
      },
    },
  }),
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    m: 2,
  },
  title: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    fontSize: "26px",
  }),
  divider: {
    my: 0,
    backgroundColor: "#D1D1D1",
  },
  contentContainer: {
    flexGrow: 1,
    display: "flex",
    borderRadius: 0,
  },
  tabsContainer: ({ palette }) => ({
    backgroundColor: palette.background.secondary,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  }),
  tabs: ({ palette, breakpoints }) => ({
    backgroundColor: palette.background.secondary,
    borderRadius: 0,
    padding: 0,
    minWidth: "250px",
    [breakpoints.down("md")]: {
      minWidth: "210px",
    },
    [breakpoints.down("sm")]: {
      minWidth: "110px",
    },
    "& .MuiTabs-indicator": {
      borderRadius: 0,
      // background: "linear-gradient(90deg, #26BBFE 0%, #1BC88B 100%);",
      background: "#406EE2",
      boxShadow: "none",
    },
  }),
  tab: ({ palette, breakpoints }) => ({
    borderBottom: `2px solid ${palette.white.main}`,
    borderRadius: 0,
    height: "60px",
    width: "250px",
    [breakpoints.down("md")]: {
      width: "210px",
    },
    [breakpoints.down("sm")]: {
      width: "128px",
    },
  }),
  informationButton: {
    ml: "15%",
    mb: "20%",
    mt: 1,
  },
  informationIcon: {
    height: "26px",
  },
  tabContainer: ({ value }) => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
    px: { xs: 1, sm: 3 },
    path: {
      fill: value === 0 ? "#fff" : "#545454",
    },
  }),
  tabContainer1: ({ value }) => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
    px: { xs: 1, sm: 3 },
    path: {
      fill: value === 1 ? "#fff" : "#545454",
    },
  }),
  tabContainer2: ({ value }) => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
    px: { xs: 1, sm: 3 },
    path: {
      fill: value === 2 ? "#fff" : "#545454",
    },
  }),
  tabIcon: {
    flexShrink: "0",
    height: "22px",
  },
  tabName: {
    ml: { xs: 0.5, sm: 2 },
  },
  tabPanel: {
    width: "100%",
  },
  personalContainer: ({ palette }) => ({
    pr: 2,
    pl: 3,
    minHeight: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${palette.grey[100]}`,
    py: 1,
  }),
  personalTitle: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
  }),
  personalContent: ({ palette }) => ({
    color: palette.grey[650],
    fontSize: "12px",
  }),
  languageContainer: ({ palette, breakpoints }) => ({
    pr: 2,
    pl: 3,
    minHeight: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${palette.grey[100]}`,
    py: 1,
    [breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  }),
  formSelect: {
    py: 1,
    width: "140px",
  },
  select: {
    borderRadius: "4px",
  },
  selectLang: {
    PaperProps: {
      sx: {
        mt: 1,
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
        borderRadius: "4px",
        "& .MuiMenuItem-root.Mui-selected": {
          background: "#DDF7F9",
        },
        "& .MuiMenuItem-root:hover": {
          background: "#F2F2F2",
        },
        "& .MuiMenuItem-root.Mui-selected:hover": {
          background: "#DDF7F9",
        },
      },
    },
  },
  menuItemLang: {
    mt: 0.5,
    p: 1,
  },
  boxLang: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  lang: {
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    p: 0,
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  nameLang: () => ({
    ml: 1,
    color: "#3D3D3D",
    fontSize: "14px",
    fontWeight: 600,
  }),
};
