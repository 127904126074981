import {
  informationWidth,
  lgInformationWidth,
  xlInformationWidth,
  xxlInformationWidth,
} from "constant/chat";

export default {
  drawer: ({ transitions, breakpoints }, { isOpenListMemberDialog }) => ({
    ...(!isOpenListMemberDialog && {
      width: 0,
      "& .MuiDrawer-paper": {
        width: 0,
      },
      transition: transitions.create("width", {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
    }),

    ...(isOpenListMemberDialog && {
      width: informationWidth,
      "& .MuiDrawer-paper": {
        width: informationWidth,
      },
      transition: transitions.create(["margin", "width"], {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
      [breakpoints.down("xxl")]: {
        width: xxlInformationWidth,
        "& .MuiDrawer-paper": {
          width: xxlInformationWidth,
        },
      },
      [breakpoints.down("xl")]: {
        width: xlInformationWidth,
        "& .MuiDrawer-paper": {
          width: xlInformationWidth,
        },
      },
      [breakpoints.down("lg")]: {
        width: lgInformationWidth,
        "& .MuiDrawer-paper": {
          width: lgInformationWidth,
        },
      },
      [breakpoints.down("md")]: {
        width: "100%",
        "& .MuiDrawer-paper": {
          width: "100%",
        },
      },
    }),
  }),
  dialog: ({ breakpoints, borders }) => ({
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.lg,
        width: "100%",
        height: "100%",
        // maxHeight: "600px",
        maxWidth: "450px",
        [breakpoints.down("lg")]: {
          maxWidth: "90vw",
        },
      },
    },
  }),
  dFlexNotJust: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
  },
  dFlexNotJust1: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    height: "45px",
    // marginTop: "5px",
  },
  boxUser: {
    // justifyContent: "space-between",
    "&:hover": {
      background: "#E5F0FF",
      borderRadius: "10px",
    },
  },
  boxUserSelect: {
    // justifyContent: "space-between",
    background: "#E5F0FF",
    borderRadius: "10px",
  },
  fLexCol: { display: "flex", flexDirection: "column" },
  arrowIcon: { fill: "#090710", height: "14px", marginRight: "-20px" },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    m: 2,
  },
  title: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    marginLeft: "auto",
    marginRight: "auto",
  }),
  overflowY: ({ breakpoints }) => ({
    pl: 0.5,
    ml: 2,
    marginRight: "15px",
    [breakpoints.between("md", "lg")]: {
      ml: 0,
    },
    [breakpoints.down("sm")]: {
      ml: 0,
    },
  }),
  avatarImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
    "&>img": {
      height: "44px",
    },
    border: "0.1px solid lightgray",
  },
  labelLeft: ({ breakpoints }) => ({
    flexGrow: 1,
    fontWeight: "600",
    pl: 1.25,
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flex: "1",
    [breakpoints.between("md", "lg")]: {
      fontSize: "12px",
      pl: 1,
    },
    [breakpoints.down("md")]: {
      fontSize: "12px",
      pl: 1,
    },
  }),
  labelActive: ({ breakpoints }) => ({
    flexGrow: 1,
    px: 0.5,
    pl: 1.25,
    background: "#8A8A8D",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "400",
    [breakpoints.between("md", "lg")]: {
      fontSize: "12px",
    },
  }),
  divider: {
    my: 0,
    backgroundColor: "#707070",
  },
  contentContainer: {
    height: "500px",
    maxHeight: "80%",
    minHeight: "20%",
    flexGrow: 1,
    display: "flex",
    borderRadius: 0,
    overflowY: "auto",
    // mb: 2,
    // ml: 2,
    mt: 1,
    flexDirection: "column",
  },
  tag: ({ borders }) => ({
    flexGrow: "0",
    flexShrink: "0",
    borderRadius: borders.borderRadius.round,
    padding: "4px 0px",
    display: "none",
  }),
  tagLabel: ({ palette }) => ({
    fontSize: "12px",
    color: palette.grey[600],
    //  minWidth: "90px"
  }),
  tagAll: {
    fontSize: "14px",
    color: "#000",
    pl: 1.5,
    fontWeight: "700",
    marginBottom: "10px",
    //  minWidth: "90px"
  },
  searchIcon: {
    mx: 0.5,
    cursor: "default",
  },
  searchUser: ({ isClicked }) => ({
    width: "100%",
    height: "44px",
    borderRadius: "100px",
    fieldset: {
      borderRadius: "100px",
      border: "none",
      height: "50px",
    },
    input: {
      py: 1.5,
      "&::placeholder": {
        fontWeight: 550,
      },
    },
    border: isClicked ? "1px solid var(--button_new, #406EE2)" : "none",
    backgroundColor: isClicked ? "none" : "#F6F6F6",
  }),
  listMessagse: ({ palette }) => ({
    borderRadius: "50%",
    background: palette.grey[150],
    marginRight: "10px",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s ease", // Thêm hiệu ứng chuyển đổi
    "&:hover": {
      background: "#406EE2", // Đổi màu khi hover
    },
  }),

  phonebook: ({ palette }) => ({
    borderRadius: "50%",
    background: palette.grey[150],
    cursor: "pointer",
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s ease", // Thêm hiệu ứng chuyển đổi
    "&:hover": {
      background: "#406EE2", // Đổi màu khi hover
    },
  }),
};
