/* eslint-disable no-unused-vars */
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { ReactComponent as AddOptionIcon } from "assets/svg/icon/add_option.svg";
import { ReactComponent as CloseVoteIcon } from "assets/svg/icon/close_vote.svg";
import { ReactComponent as IconCLose } from "assets/svg/icon/icon_close.svg";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import styled from "@emotion/styled";
import styles from "../../../assets/styles/pages/Chat/component/PollPopperVote";

const BoxCus = styled(Box)`
  #question {
    color: #000;
    font-weight: 500;
    font-size: 16px;
  }
`;

function PollPopperVote({
  openVote,
  handleCloseVote,
  textQuestion,
  handleTextQuestion,
  inputQuestionRef,
  boxRef,
  listVote,
  setListVote,
  handleChange,
  elRefs,
  handleAddVote,
  isMaxOption,
  isActive,
  handleCreatePoll,
}) {
  const { t } = useTranslation();
  const [clickBorderText, setClickBorderText] = useState(null); // Sửa: Khởi tạo là null
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }, [listVote.length]);

  const handleClickBorderText = (boxIndex) => {
    setClickBorderText((prevSelectedBox) => (prevSelectedBox === boxIndex ? null : boxIndex)); // Sửa: Sử dụng null thay vì false
  };

  const removeOption = (i) => {
    const newList = listVote.filter((item, index) => index !== i);
    setListVote([...newList]);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "90%",
          maxWidth: "700px",
          maxHeight: "80%",
          margin: "auto", // Center the dialog
          "@media (max-width: 600px)": {
            width: "100%",
            maxWidth: "none", // Remove max-width for small screens
          },
        },
      }}
      onClose={handleCloseVote}
      open={openVote}
      sx={styles.ScoreBox}
    >
      <Paper sx={styles.paper}>
        <ClickAwayListener mouseEvent="onMouseDown" disableReactTree onClickAway={handleCloseVote}>
          <Box sx={styles.popperPoll}>
            <Box sx={styles.boxHeader}>
              <Box sx={styles.boxTitle}>
                <Typography variant="body1" color="initial" sx={styles.textTitle}>
                  {t("chat.footer.poll.title")}
                </Typography>
                <Box sx={{ mr: "17px" }}>
                  <IconButton onClick={handleCloseVote}>
                    <CloseVoteIcon sx={styles.icon} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <BoxCus>
              <TextField
                id="question"
                placeholder=""
                multiline
                rows={2}
                sx={styles.textQuestion}
                value={textQuestion}
                onChange={handleTextQuestion}
                inputRef={inputQuestionRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={styles.InputAdornment}>
                      <Typography sx={styles.textColorBlack}>
                        {t("chat.footer.poll.question")}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <Box id="items" ref={boxRef} sx={styles.boxAnswer}>
                <ReactSortable
                  filter=".add_option .create_poll"
                  list={listVote}
                  setList={setListVote}
                  dragClass="sortableDrag"
                  animation="200"
                  easing="ease-out"
                  handle="#dragIcon"
                >
                  {listVote.map((vote, i) => (
                    <Box
                      key={i}
                      sx={styles.flexGrow}
                      ref={(el) => {
                        if (i === listVote.length - 1) {
                          scrollRef.current = el;
                        }
                      }}
                    >
                      <TextField
                        value={vote.value}
                        multiline
                        rows={2}
                        sx={styles.inputAnswer({ clickBorderText, boxIndex: i })}
                        onChange={(e) => handleChange(e, i)}
                        onClick={() => handleClickBorderText(i)}
                        onBlur={() => handleClickBorderText(i)}
                        ref={elRefs[i]}
                        placeholder={`${t("chat.footer.poll.select")} ${i + 1}`}
                        variant="standard"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => removeOption(i)}
                                sx={{ color: "#406EE2", cursor: "pointer" }}
                              >
                                <IconCLose sx={{ color: "#333" }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  ))}
                </ReactSortable>
              </Box>
              <Box style={{ marginLeft: "7px", height: "30px" }}>
                {!isMaxOption && (
                  <IconButton onClick={handleAddVote}>
                    <AddOptionIcon sx={styles.fillColorIconLeft} />
                    <Typography sx={styles.textColorBlue}>
                      {t("chat.footer.poll.add_option")}
                    </Typography>
                  </IconButton>
                )}
              </Box>
              <Box sx={styles.dFlexCenter}>
                <Button
                  id="create_poll"
                  variant="outlined"
                  color="primary"
                  onClick={handleCreatePoll}
                  sx={isActive ? styles.buttonCreatePollActive : styles.buttonCreatePollDefault}
                >
                  <Typography
                    variant="body1"
                    sx={isActive ? styles.textButtonActive : styles.textButtonDefault}
                  >
                    {t("chat.footer.poll.create")}
                  </Typography>
                </Button>
              </Box>
            </BoxCus>
          </Box>
        </ClickAwayListener>
      </Paper>
    </Dialog>
  );
}

export default PollPopperVote;
