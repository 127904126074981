import { memo } from "react";
import { Outlet } from "react-router-dom";
import BaseLayout from "base/BaseLayout";

function LandingLayout() {
  return (
    <BaseLayout>
      <Outlet />
    </BaseLayout>
  );
}

export default memo(LandingLayout);
