import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import defaultAvatar from "assets/svg/icon/default_avatar_group.svg";
import styles from "assets/styles/pages/Chat/component/ChannelTV";
import { MESSAGE_TYPE, STATUS_SCORE } from "constant/chat";
import playVideo from "assets/images/play_channel.png";
import playAudio from "assets/images/play_audio.png";
import LogoChanel from "assets/images/chanelLogo.png";
import AddressIcon from "assets/images/address_icon.png";
import DateIcon from "assets/images/dateIcon.png";
// eslint-disable-next-line import/no-extraneous-dependencies
import Lottie from "lottie-react";
import audioAnimation from "assets/audio/audio_animation.json";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { Button, Typography } from "@mui/material";
import { formatDate } from "utils/convert";
import { isEmpty } from "utils/validate";
import ScoreDialog from "./ScoreBox";

function ChannelTV({ openChanelTv, listChanel, detailChanel }) {
  if (!listChanel || !detailChanel || !openChanelTv) return;
  const [current, setCurrent] = useState(null);
  const [selectIndex, setSelectIndex] = useState(0);
  const lottieRef = useRef();
  const [scoreDialogOpen, setScoreDialogOpen] = useState(false);
  const handleShowChanelMain = (chanel) => {
    let url = "";
    if (chanel.kind === MESSAGE_TYPE.VIDEO) {
      url = chanel.video.url;
    } else if (chanel.kind === MESSAGE_TYPE.AUDIO) {
      url = chanel.audio.url;
    } else if (chanel.kind === MESSAGE_TYPE.IMAGE) {
      url = chanel.image[0].url;
    }
    setSelectIndex(url);
    setCurrent(chanel);
  };
  const handleOpenScoreDialog = () => {
    setScoreDialogOpen(true);
  };
  const handleCloseScoreDialog = () => {
    setScoreDialogOpen(false);
  };
  const getHole = () => {
    if (detailChanel) {
      const listHole = detailChanel?.flight.score.filter((item) => item.status);
      const finish = detailChanel?.flight.score.find((item) => !item.status);
      if (listHole.length === 0) return "Start";
      if (isEmpty(finish)) return "Finish";
      const holeLess = detailChanel?.flight.score.filter(
        (item) => item.status && +item.hole < +detailChanel.hole_index
      );
      if (holeLess.length > 0) return `Hố ${listHole[holeLess.length - 1].holeName}`;
      if (listHole.length > 0) return `Hố ${listHole[listHole.length - 1].holeName}`;
    }
    return null;
  };
  const getScore = () => {
    if (detailChanel) {
      const countBlue = detailChanel?.flight.score.filter(
        (item) => item.status && item.status === STATUS_SCORE.BLUE
      ).length;
      const countRed = detailChanel?.flight.score.filter(
        (item) => item.status && item.status === STATUS_SCORE.RED
      ).length;
      return { countBlue, countRed };
    }
    return null;
  };
  const getUserByTeam = (team) => {
    let idsTeam = [];
    if (detailChanel) {
      idsTeam = team
        .filter((item) => item != null)
        .map((u) => {
          const data = detailChanel?.list_users
            ?.filter((item) => item !== null)
            .find((user) => user.id === u.id);
          return { ...u, avatar: data.avatar, avatar_large: data.avatar_large };
        });
    }

    return idsTeam;
  };
  const getList = useMemo(() => {
    const chanelImages = [];
    const data = listChanel.filter(
      (item) => item.kind === MESSAGE_TYPE.IMAGE && item.image.length > 1
    );
    if (data.length > 1) {
      for (let index = 0; index < data.length; index += 1) {
        const img = data[index];
        img.image.forEach((i) => {
          chanelImages.push({ ...img, image: [i] });
        });
      }
    }
    const list = listChanel.filter(
      (item) =>
        item.kind === MESSAGE_TYPE.VIDEO ||
        item.kind === MESSAGE_TYPE.AUDIO ||
        item.image.length === 1
    );
    return [...list, ...chanelImages].sort((a, b) => b.id - a.id);
  }, [listChanel]);
  useEffect(() => {
    if (!openChanelTv) {
      setSelectIndex(0);
      setCurrent(null);
    }
  }, [openChanelTv]);
  useEffect(() => {
    if (current && current.group_id !== listChanel[0]?.group_id) {
      setSelectIndex(0);
      setCurrent(null);
    }
  }, [detailChanel]);
  if (getList.length === 0) {
    return (
      <Box>
        <Box sx={styles.posterChannelTV}>
          <Box sx={styles.infoUserContainer}>
            <Box
              sx={() =>
                styles.timeBlueContainer({
                  check:
                    detailChanel.flight.user_plays.team1.filter((item) => item != null).length > 1,
                })
              }
            >
              {getUserByTeam(detailChanel.flight.user_plays.team1).map((user, keyUser) => (
                <Box key={keyUser} sx={() => styles.golferContainer({ flex: "flex-start" })}>
                  <Box sx={styles.avatarContainer}>
                    <Avatar sx={styles.avatarGolfer} src={defaultAvatar} srcSet={user?.avatar} />
                  </Box>
                  <Box sx={styles.infoGolferTeamBlue}>
                    <Typography sx={(e) => styles.userName(e, { text: "right" })} variant="caption">
                      {user.username || user.name}
                    </Typography>
                    <Typography sx={styles.vID} variant="caption">
                      {user.id}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box sx={styles.imageLogoContainer}>
              <Box component="img" sx={styles.imageLogo} src={LogoChanel} />
            </Box>
            <Box
              sx={() =>
                styles.timeRedContainer({
                  check:
                    detailChanel.flight.user_plays.team2.filter((item) => item != null).length > 1,
                })
              }
            >
              {getUserByTeam(detailChanel.flight.user_plays.team2).map((user, keyUser) => (
                <Box key={keyUser} sx={() => styles.golferContainer({ flex: "flex-end" })}>
                  <Box sx={styles.infoGolferTeamRed}>
                    <Typography sx={(e) => styles.userName(e, { text: "left" })} variant="caption">
                      {user.username || user.name}
                    </Typography>
                    <Typography sx={styles.vID} variant="caption">
                      {user.id}
                    </Typography>
                  </Box>
                  <Box sx={styles.avatarContainer}>
                    <Avatar sx={styles.avatarGolfer} src={defaultAvatar} srcSet={user?.avatar} />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box sx={styles.infoPlaceContainer}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                top: 0,
                position: "absolute",
                opacity: 0.2,
                background: "black",
              }}
            />
            <Box sx={styles.timeAndPlace}>
              <Box sx={styles.placeBox}>
                <Box component="img" src={AddressIcon} />
                <Box>
                  <Typography variant="body1" sx={styles.facility_name}>
                    {detailChanel.flight.facility_name}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Typography variant="body1" sx={styles.hole_index_text}>
                      Hố xuất phát:
                    </Typography>
                    <Typography variant="body1" sx={styles.hole_index}>
                      {
                        detailChanel.flight.score.find(
                          (item) => +item.hole === +detailChanel.flight.hole_index
                        )?.holeName
                      }
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ width: "150px" }} />
              <Box sx={styles.placeBox}>
                <Box component="img" src={DateIcon} />
                <Typography variant="body1" sx={styles.time}>
                  {formatDate(detailChanel.tee_time, "HH:mm DD/MM/YYYY")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <Box sx={styles.channelTV}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={8} sx={styles.leftColumItem}>
            <Grid container direction="column" spacing={0}>
              <Grid item xs={6}>
                {detailChanel.is_endding_channel === 1 && (
                  <Button sx={styles.endChannel} onClick={handleOpenScoreDialog}>
                    Kết thúc
                  </Button>
                )}
                {listChanel.length > 0 && detailChanel.is_endding_channel === 0 && (
                  <Box sx={styles.ScoreContainer} onClick={handleOpenScoreDialog}>
                    <Box sx={styles.scoreBox}>
                      <Box sx={styles.rightTopBox}>
                        {getScore() && (
                          <Typography variant="body1" sx={styles.score}>
                            {getScore().countBlue}
                          </Typography>
                        )}
                      </Box>
                      <Box sx={styles.rightBottomBox}>
                        {getScore() && (
                          <Typography variant="body1" sx={styles.score}>
                            {getScore().countRed}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    {detailChanel && (
                      <Typography variant="body1" sx={styles.hole}>
                        {getHole()}
                      </Typography>
                    )}
                  </Box>
                )}

                {current ? (
                  <>
                    {current?.kind === MESSAGE_TYPE.VIDEO && (
                      <Grid item>
                        <Box sx={styles.videoSection}>
                          <Box
                            autoPlay
                            component="video"
                            sx={styles.video}
                            controls
                            key={current.video.url}
                          >
                            <Box component="source" src={current.video.url} />
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {current?.kind === MESSAGE_TYPE.AUDIO && (
                      <Grid item>
                        <Box sx={styles.audioSection}>
                          <Box sx={styles.lottieContainer}>
                            <Lottie
                              autoplay={false}
                              lottieRef={lottieRef}
                              style={styles.lottie}
                              animationData={audioAnimation}
                            />
                          </Box>
                          <Box
                            key={current.audio.url}
                            autoPlay
                            component="video"
                            sx={styles.audioContainer}
                            controls
                            onPlay={() => {
                              lottieRef.current.play();
                            }}
                            onPause={() => {
                              lottieRef.current.pause();
                            }}
                          >
                            <Box component="source" src={current.audio.url} />
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {current?.kind === MESSAGE_TYPE.IMAGE && (
                      <Grid item>
                        <Box sx={styles.videoSection}>
                          <Box component="img" src={current?.image[0]?.url} sx={styles.video} />
                        </Box>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    {listChanel[0]?.kind === MESSAGE_TYPE.VIDEO && (
                      <Grid item>
                        <Box sx={styles.videoSection}>
                          <Box
                            key={listChanel[0]?.video?.url}
                            autoPlay
                            component="video"
                            sx={styles.video}
                            controls
                          >
                            <Box
                              className="videoUrl"
                              component="source"
                              src={listChanel[0]?.video?.url}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {listChanel[0]?.kind === MESSAGE_TYPE.IMAGE && (
                      <Grid item>
                        <Box sx={styles.videoSection}>
                          <Box
                            key={listChanel[0]?.image[0]?.url}
                            component="img"
                            src={listChanel[0]?.image[0]?.url}
                            sx={styles.video}
                          />
                        </Box>
                      </Grid>
                    )}
                    {listChanel[0]?.kind === MESSAGE_TYPE.AUDIO && (
                      <Grid item>
                        <Box sx={styles.audioSection}>
                          <Box sx={styles.lottieContainer}>
                            <Lottie
                              autoplay={false}
                              lottieRef={lottieRef}
                              style={styles.lottie}
                              animationData={audioAnimation}
                            />
                          </Box>
                          <Box
                            autoPlay
                            component="video"
                            key={listChanel[0]?.audio?.url}
                            sx={styles.audioContainer}
                            onPlay={() => {
                              lottieRef.current.play();
                            }}
                            onPause={() => {
                              lottieRef.current.pause();
                            }}
                            controls
                          >
                            <Box
                              className="videoUrl"
                              component="source"
                              src={listChanel[0]?.audio?.url}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={4} sx={styles.rightColumItem}>
            <Grid container spacing={0}>
              {getList &&
                getList?.map((chanel, keyChanel) => (
                  <>
                    {chanel?.kind === MESSAGE_TYPE.VIDEO && (
                      <Grid sx={styles.itemMediaContainer} item xs={4} sm={4} md={4} lg={4} xl={6}>
                        <Box
                          onClick={() => handleShowChanelMain(chanel, keyChanel)}
                          sx={styles.videoItemSection}
                        >
                          <Box sx={styles.imgMediaAudio}>
                            <Box
                              component="img"
                              src={chanel?.video?.url_thumb}
                              sx={styles.imgMediaVideo}
                            />
                            <Box component="img" src={playVideo} sx={styles.imgPlayVideo} />
                          </Box>
                          <Box
                            sx={() =>
                              styles.indexChanelContainer({
                                selectIndex:
                                  selectIndex === chanel.video.url || selectIndex === keyChanel,
                              })
                            }
                          >
                            <Typography
                              variant="body1"
                              color="inherit.main"
                              sx={() => styles.indexChanel()}
                            >
                              {getList.length - keyChanel}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {chanel?.kind === MESSAGE_TYPE.IMAGE && (
                      <Grid
                        item
                        key={keyChanel}
                        sx={styles.itemMediaContainer}
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={6}
                      >
                        <Box
                          onClick={() => handleShowChanelMain(chanel, keyChanel)}
                          sx={styles.videoItemSection}
                        >
                          <Box
                            component="img"
                            src={chanel.image[0].url}
                            sx={styles.imgMediaVideo}
                          />
                          <Box
                            sx={() =>
                              styles.indexChanelContainer({
                                selectIndex:
                                  selectIndex === chanel.image[0].url || selectIndex === keyChanel,
                              })
                            }
                          >
                            <Typography
                              variant="body1"
                              color="inherit.main"
                              sx={() => styles.indexChanel()}
                            >
                              {getList.length - keyChanel}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {chanel?.kind === MESSAGE_TYPE.AUDIO && (
                      <Grid key={keyChanel} item xs={4} sm={4} md={4} lg={4} xl={6}>
                        <Box
                          onClick={() => handleShowChanelMain(chanel, keyChanel)}
                          sx={styles.videoItemSection}
                        >
                          <Box sx={styles.imgMediaAudio}>
                            <Box component="img" sx={styles.playAudio} src={playAudio} />
                            <Box component="img" src={playVideo} sx={styles.imgPlayVideo} />
                          </Box>
                          <Box
                            sx={() =>
                              styles.indexChanelContainer({
                                selectIndex:
                                  selectIndex === chanel.audio.url || selectIndex === keyChanel,
                              })
                            }
                          >
                            <Typography
                              variant="body1"
                              color="inherit.main"
                              sx={() => styles.indexChanel()}
                            >
                              {getList.length - keyChanel}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </>
                ))}
            </Grid>
          </Grid>
        </Grid>
        <ScoreDialog
          detailChanel={detailChanel}
          open={scoreDialogOpen}
          handleClose={handleCloseScoreDialog}
        />
      </Box>
    </Box>
  );
}

export default memo(ChannelTV);
