import { Box, Typography, Fade, IconButton } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/icon/close_#00000.svg";
import styles from "assets/styles/pages/Chat/component/ReplyMessage";
import { isEmpty } from "utils/validate";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { FILE_TYPE, MESSAGE_TYPE } from "constant/chat";
import playVideo from "assets/images/playVideo.png";
import { ReactComponent as XLSXIcon } from "assets/svg/icon/xlsx_message.svg";
import { ReactComponent as PDFIcon } from "assets/svg/icon/pdf_message.svg";
import { ReactComponent as DOCIcon } from "assets/svg/icon/doc_message.svg";
import { ReactComponent as PPTIcon } from "assets/svg/icon/ppt_message.svg";
import { ReactComponent as ZIPIcon } from "assets/svg/icon/zip_message.svg";
import { ReactComponent as TXTIcon } from "assets/svg/icon/txt_message.svg";
import { ReactComponent as FILEIcon } from "assets/svg/icon/file_message.svg";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { Stickers } from "assets/stickers";
import { tagManyNameRegex } from "constant/regex";

function ReplyMessage({ openReplyMessage, handleCloseReplyMessage, reply }) {
  if (!reply) return;
  const { t } = useTranslation();
  const viewNormal = (
    <Box sx={styles.content}>
      <Box sx={styles.infoUserReply}>
        <Typography sx={styles.reply}>{t("chat.footer.reply")}</Typography>
        <Typography sx={styles.replyName}>
          {!isEmpty(reply.replyName) ? reply.replyName : ""}
        </Typography>
      </Box>
      <Typography sx={styles.text}>
        {tagManyNameRegex.exec(reply?.message)
          ? reply?.message.replace(tagManyNameRegex, `$1`)
          : reply?.message}{" "}
      </Typography>
    </Box>
  );
  const viewMedia = (
    <Box sx={styles.contentImageOrFile}>
      {reply?.kind === MESSAGE_TYPE.IMAGE && (
        <Box sx={styles.boxImage}>
          <Box component="img" src={reply?.image[0]?.url} sx={styles.image} />
          {reply?.image?.length > 1 && (
            <>
              <Box sx={styles.imageBg} />
              <Typography sx={styles.numberImagePin}>+{reply.image.length - 1}</Typography>
            </>
          )}
        </Box>
      )}
      {reply?.kind === MESSAGE_TYPE.VIDEO && (
        <Box sx={styles.boxImage}>
          <Box component="img" src={reply?.video?.url_thumb} sx={styles.image} />
          <Box sx={styles.imageVideoBg} />
          <Box component="img" src={playVideo} sx={styles.imagePlayBg} />
        </Box>
      )}
      {reply?.kind === MESSAGE_TYPE.FILE && (
        <Box sx={styles.boxImage}>
          <Box sx={styles.downloadIcon}>
            {FILE_TYPE.XLSX.includes(reply.file.typeFile) && <XLSXIcon style={styles.icon} />}
            {FILE_TYPE.PDF.includes(reply.file.typeFile) && <PDFIcon style={styles.icon} />}
            {FILE_TYPE.DOC.includes(reply.file.typeFile) && <DOCIcon style={styles.icon} />}
            {FILE_TYPE.PPT.includes(reply.file.typeFile) && <PPTIcon style={styles.icon} />}
            {FILE_TYPE.TXT.includes(reply.file.typeFile) && <TXTIcon style={styles.icon} />}
            {(FILE_TYPE.ZIP.includes(reply.file.typeFile) || reply.file.name.includes(".rar")) && (
              <ZIPIcon style={styles.icon} />
            )}
            {!FILE_TYPE.XLSX.includes(reply.file.typeFile) &&
              !FILE_TYPE.PDF.includes(reply.file.typeFile) &&
              !FILE_TYPE.DOC.includes(reply.file.typeFile) &&
              !FILE_TYPE.PPT.includes(reply.file.typeFile) &&
              !FILE_TYPE.TXT.includes(reply.file.typeFile) &&
              !(
                FILE_TYPE.ZIP.includes(reply.file.typeFile) || reply.file.name.includes(".rar")
              ) && <FILEIcon style={styles.icon} />}
          </Box>
        </Box>
      )}
      {reply?.kind === MESSAGE_TYPE.AUDIO && (
        <IconButton sx={styles.fillColorIconRight}>
          <KeyboardVoiceIcon sx={styles.fillColorWhite} />
        </IconButton>
      )}
      {reply?.kind === MESSAGE_TYPE.EMOJI && (
        <Box
          sx={styles.boxImageEmoji}
          className="imageUrl"
          component="img"
          src={Stickers[reply.emoji.stickerIndex]?.images[reply.emoji.index]}
        />
      )}
      <Box sx={styles.boxContentImage}>
        <Box sx={styles.infoUserReply}>
          <Typography sx={styles.reply}>{t("chat.footer.reply")}</Typography>
          <Typography sx={styles.replyName}>
            {!isEmpty(reply.replyName) ? reply.replyName : ""}
          </Typography>
        </Box>
        {reply?.kind === MESSAGE_TYPE.IMAGE && (
          <Typography sx={styles.textImage}>{t("chat.type_message.photo_messages")}</Typography>
        )}
        {reply?.kind === MESSAGE_TYPE.VIDEO && (
          <Typography sx={styles.textImage}>{t("chat.type_message.video_messages")}</Typography>
        )}
        {reply?.kind === MESSAGE_TYPE.FILE && (
          <Typography sx={styles.textImage}>{t("chat.type_message.file_messages")}</Typography>
        )}
        {reply?.kind === MESSAGE_TYPE.AUDIO && (
          <Typography sx={styles.textImage}>{t("chat.type_message.voice_messages")}</Typography>
        )}
        {reply?.kind === MESSAGE_TYPE.EMOJI && (
          <Typography sx={styles.textImage}>{t("chat.type_message.sticker_messages")}</Typography>
        )}
      </Box>
    </Box>
  );
  if (!openReplyMessage) return null;
  return (
    <Fade in={openReplyMessage}>
      <Box sx={styles.container}>
        {reply?.kind === MESSAGE_TYPE.TEXT ? viewNormal : viewMedia}
        <Box sx={styles.IconClose}>
          <IconButton onClick={handleCloseReplyMessage}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Fade>
  );
}
export default memo(ReplyMessage);
