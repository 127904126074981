import { useEffect, useState } from "react";
// routes
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import routes from "routes/routes";
// i18next
import { I18nextProvider, withTranslation } from "react-i18next";
import i18next from "middleware/i18next";
// material ui
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
// react query
import { QueryClientProvider } from "react-query";
import { queryClient } from "config/config";

// redux
import { Provider } from "react-redux";
import store from "redux/store";
// console'
import "middleware/console";
// layout
import DefaultLayout from "layouts/DefaultLayout";
// utils
import { isEmpty } from "utils/validate";
import { generateRoutes } from "utils/route";
import { getUserToken } from "utils/storage";
// api
// idb
import "middleware/idb";
import JoinFromLink from "pages/Chat/component/JoinFromLink";
import { isMobile } from "react-device-detect";

function App() {
  const [initialRoutes, setInitialRoutes] = useState(routes);

  useEffect(() => {
    const constantRoutes = routes.filter((element) => !element.require_token);
    const routeID = (store?.getState()?.route || []).map(({ route }) => route);
    const routeStore = routes.filter((route) => routeID.includes(route.route));
    if (!isEmpty(store?.getState()?.route)) setInitialRoutes(routeStore);
    else setInitialRoutes(constantRoutes);
    store.subscribe(() => {
      const currentRoute = routes.filter((route) =>
        (store?.getState()?.route || []).map((r) => r.route).includes(route.route)
      );
      if (!isEmpty(currentRoute)) setInitialRoutes(currentRoute);
    });
  }, []);

  useEffect(() => {
    if (isMobile) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      const link = document.createElement("a");
      link.href = "https://apps.apple.com/vn/app/wghn/id1590376779?l=vi";

      if (/android/i.test(userAgent)) {
        link.href = "https://play.google.com/store/apps/details?id=com.vhandicap.vphone&hl=en_US";
      }

      document.body.appendChild(link);
      link.click();
    }
  }, []);

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <Routes>
                <Route element={<DefaultLayout />}>
                  {generateRoutes(initialRoutes)}
                  <Route path="/invite/:groupId" element={<JoinFromLink />} />
                  <Route
                    path="*"
                    element={<Navigate to={isEmpty(getUserToken()) ? "/qr" : "/"} />}
                  />
                </Route>
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </Provider>
  );
}

export default withTranslation()(App);
