import { Box, Divider, Drawer, Grid, IconButton, Link, Typography } from "@mui/material";
import styles from "assets/styles/pages/Chat/component/Multimedia";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment/moment";
import { ReactComponent as XLSXIcon } from "assets/svg/icon/xlsx_message.svg";
import { ReactComponent as PDFIcon } from "assets/svg/icon/pdf_message.svg";
import { ReactComponent as DOCIcon } from "assets/svg/icon/doc_message.svg";
import { ReactComponent as PPTIcon } from "assets/svg/icon/ppt_message.svg";
import { ReactComponent as ZIPIcon } from "assets/svg/icon/zip_message.svg";
import { ReactComponent as TXTIcon } from "assets/svg/icon/txt_message.svg";
import { ReactComponent as FILEIcon } from "assets/svg/icon/file_message.svg";
import { useEffect, useRef, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Avatar from "@mui/material/Avatar";
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from "dayjs";
// eslint-disable-next-line import/no-extraneous-dependencies
import { groupBy } from "lodash";
import { FILE_TYPE } from "../../../constant/chat";
import playVideo from "../../../assets/images/play_channel.png";
// import { urlRegex } from "../../../constant/regex";
// import updateLocale from "dayjs/plugin/updateLocale";
// import isToday from "dayjs/plugin/isToday";

function Multimedia({
  handleCloseMultimedia,
  isMediumSize,
  isOpenMultimediaDialog,
  listMessageMedia,
  textMedia,
  indexMedia,
}) {
  const [isOpenPreview, setOpenPreview] = useState(false);
  const [position, setPosition] = useState(0);
  const [isOpenPreviewVideo, setOpenPreviewVideo] = useState(false);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

  const playAudio = (id) => {
    if (currentlyPlaying) {
      currentlyPlaying.pause();
    }
    const audio = document.getElementById(id);
    setCurrentlyPlaying(audio);
    audio.play();
  };
  const [groupData, setGroupData] = useState([]);
  const handleOpenPreview = (id) => {
    setPosition(id);
    setOpenPreview(true);
  };
  const handleOpenPreviewVideo = (id) => {
    setPosition(id);
    setOpenPreviewVideo(true);
  };
  const handleClosePreviewVideo = () => setOpenPreviewVideo(false);
  const handleClosePreview = () => setOpenPreview(false);
  const handleDownloadFile = (v) => {
    // eslint-disable-next-line no-undef
    if (v?.file?.url) saveAs(v.file.url, v.file.name);
  };

  useEffect(() => {
    if (listMessageMedia.length > 0) {
      const dataMutate = listMessageMedia.filter((item) => item.media_type === indexMedia);

      const messageFormatDate = groupBy(dataMutate, (item) => {
        const dateFormat = dayjs(item.createdAt).format(` D [tháng] M [năm] YYYY`);
        return dateFormat;
      });

      setGroupData(messageFormatDate);
    }
  }, [listMessageMedia]);

  const renderImages = () => {
    if (indexMedia === 1)
      return (
        <Box style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
          {Object?.keys(groupData)?.map((key) => (
            <Box style={{ width: "100%" }} key={key}>
              <Typography
                style={{
                  fontWeight: 700,
                  marginLeft: "15px",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                {key}
              </Typography>
              <Box style={{ display: "flex", flexWrap: "wrap" }}>
                {groupData?.[key]?.map((item) =>
                  item?.message?.image?.map((img, index) => (
                    <Avatar
                      key={index}
                      onClick={() => handleOpenPreview(index)}
                      style={{
                        width: "104px",
                        height: "104px",
                        display: "flex",
                        marginBottom: "10px",
                        marginLeft: "5px",
                        cursor: "pointer",
                        border: "0.01px solid #D4D4D4",
                      }}
                      src={img?.url}
                      variant="rounded"
                    />
                  ))
                )}
              </Box>
            </Box>
          ))}
        </Box>
      );

    if (indexMedia === 2) {
      return (
        <Box>
          {Object?.keys(groupData)?.map((key) => (
            <Box>
              <Typography
                style={{
                  fontWeight: 700,
                  marginLeft: "15px",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                {key}
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                }}
              >
                {groupData?.[key]?.map((data, index) => (
                  <Grid
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    item
                    key={index}
                    onClick={() => handleOpenPreviewVideo(index)}
                  >
                    <Avatar
                      key={index}
                      onClick={() => handleOpenPreview(index)}
                      style={{
                        width: "104px",
                        height: "104px",
                        display: "flex",
                        marginBottom: "10px",
                        marginLeft: "5px",
                        cursor: "pointer",
                        border: "0.01px solid #D4D4D4",
                      }}
                      src={
                        data?.content?.video?.url_thumb ||
                        data?.content?.video?.url_thumb ||
                        data?.content?.video?.url_thumb
                      }
                      variant="rounded"
                    />
                    <Box component="img" src={playVideo} sx={styles.imgPlayVideo} />
                  </Grid>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      );
    }

    if (indexMedia === 3) {
      return (
        <Box>
          {Object?.keys(groupData)?.map((key) => (
            <Box key={key}>
              <Typography
                style={{
                  fontWeight: 700,
                  marginLeft: "15px",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                {key}
              </Typography>
              {groupData?.[key]?.map((data, indexFile) => {
                return (
                  <Box key={indexFile}>
                    <Box
                      sx={styles.itemContainer}
                      onClick={() => handleDownloadFile(data?.content?.file)}
                    >
                      <Box sx={styles.iconContainer}>
                        {FILE_TYPE.XLSX.includes(data?.content?.file.typeFile) && (
                          <XLSXIcon style={styles.icon} />
                        )}
                        {FILE_TYPE.PDF.includes(data?.content?.file.typeFile) && (
                          <PDFIcon style={styles.icon} />
                        )}
                        {FILE_TYPE.DOC.includes(data?.content?.file.typeFile) && (
                          <DOCIcon style={styles.icon} />
                        )}
                        {FILE_TYPE.PPT.includes(data?.content?.file.typeFile) && (
                          <PPTIcon style={styles.icon} />
                        )}
                        {FILE_TYPE.TXT.includes(data?.content?.file.typeFile) && (
                          <TXTIcon style={styles.icon} />
                        )}
                        {(FILE_TYPE.ZIP.includes(data?.content?.file.typeFile) ||
                          data?.content?.file.name.includes(".rar")) && (
                          <ZIPIcon style={styles.icon} />
                        )}
                        {!FILE_TYPE.XLSX.includes(data?.content?.file.typeFile) &&
                          !FILE_TYPE.PDF.includes(data?.content?.file.typeFile) &&
                          !FILE_TYPE.DOC.includes(data?.content?.file.typeFile) &&
                          !FILE_TYPE.PPT.includes(data?.content?.file.typeFile) &&
                          !FILE_TYPE.TXT.includes(data?.content?.file.typeFile) &&
                          !(
                            FILE_TYPE.ZIP.includes(data?.content?.file.typeFile) ||
                            data?.content?.file.name.includes(".rar")
                          ) && <FILEIcon style={styles.icon} />}
                      </Box>
                      <Box sx={styles.informationContainer}>
                        <Typography variant="body1" color="inherit.main" sx={styles.nameFile}>
                          {data?.content?.file.name}
                        </Typography>
                        <Typography variant="body2" sx={styles.vga}>
                          {`${data?.content?.user?.username} `}{" "}
                          {moment(data?.content?.createdAt).format("HH:mm DD/MM/YYYY")}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider sx={styles.divider} />
                  </Box>
                );
              })}
            </Box>
          ))}
        </Box>
      );
    }

    if (indexMedia === 6) {
      return (
        <Box>
          {Object?.keys(groupData)?.map((key) => (
            <Box key={key}>
              <Typography
                style={{
                  fontWeight: 700,
                  marginLeft: "15px",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                {key}
              </Typography>
              {groupData?.[key]?.map((data, index) => (
                <Box key={index}>
                  <Box
                    sx={styles.itemContainer}
                    component={Link}
                    target="_blank"
                    // href={urlRegex.exec(link.text)[0]}
                  >
                    {data?.content?.preview?.description !== "NULL" ? (
                      <Box sx={styles.iconContainerLinkMessage}>
                        <Box
                          component="img"
                          src={data?.content?.preview?.images?.[0]}
                          sx={styles.messageLinkImage}
                        />
                      </Box>
                    ) : (
                      <Box sx={styles.linkNoImageMessage} />
                    )}
                    <Box sx={styles.informationContainer}>
                      <Typography variant="body1" color="inherit.main" sx={styles.nameLinkMessage}>
                        {data?.content?.preview?.title}
                      </Typography>
                      <Typography variant="body2" sx={styles.vga}>
                        {`${data?.content?.user.username} `}{" "}
                        {moment(data.createdAt).format("HH:mm DD/MM/YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={styles.divider} />
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      );
    }
    if (indexMedia === 4) {
      return (
        <Box>
          {Object?.keys(groupData)?.map((key) => (
            <Box key={key}>
              <Typography
                style={{
                  fontWeight: 700,
                  marginLeft: "15px",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                {key}
              </Typography>
              {groupData?.[key]?.map((data) => {
                return (
                  <Box
                    key={data?.id}
                    style={{ width: "100%", marginTop: "10px", textAlign: "center" }}
                  >
                    <audio
                      controls
                      id={`audio_${data?.id}`}
                      onPlay={() => playAudio(`audio_${data?.id}`)}
                    >
                      <source src={data?.content?.audio?.url} type="audio/mp3" />
                      <track kind="captions" srcLang="en" label="English" />
                    </audio>
                  </Box>
                );
              })}
            </Box>
          ))}
        </Box>
      );
    }

    // Add more conditions if needed for other indexMedia values

    return null;
  };
  const boxRef = useRef(null);

  const handleScroll = () => {
    if (
      boxRef.current &&
      boxRef.current.scrollTop + boxRef.current.clientHeight >= boxRef.current.scrollHeight
    ) {
      console.log("Đã cuộn xuống cuối cùng của trang");
    }
  };
  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (boxRef.current) {
        boxRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <>
      <Lightbox
        plugins={[Thumbnails]}
        open={isOpenPreview}
        close={handleClosePreview}
        carousel={{
          finite: true,
          preload: listMessageMedia
            ?.filter((item) => item.message.kind === "image")
            .map((item) => item.message.image)
            .flat().length,
        }}
        animation={{ navigation: 0 }}
        index={position}
        slides={listMessageMedia
          ?.filter((item) => item.message.kind === "image")
          .map((item) => item.message.image)
          .flat()
          .map((image) => ({ src: image.url }))}
        thumbnails={{ width: 120, height: 80, padding: 0 }}
      />
      <Lightbox
        plugins={[Video]}
        open={isOpenPreviewVideo}
        close={handleClosePreviewVideo}
        animation={{ navigation: 0 }}
        index={position}
        slides={listMessageMedia
          ?.filter((item) => item.message.kind === "video")
          .map((item) => item.message.video)
          .map((v) => ({
            type: "video",
            width: 1280,
            height: 720,
            autoPlay: true,
            sources: [
              {
                src: v.url,
                type: "video/mp4",
              },
            ],
          }))}
      />
      <Drawer
        sx={(e) => styles.drawer(e, { isOpenMultimediaDialog })}
        onClose={handleCloseMultimedia}
        anchor="right"
        open={isOpenMultimediaDialog}
        variant={!isMediumSize ? "permanent" : "temporary"}
      >
        <Box sx={styles.fLexCol}>
          <Box sx={styles.dialogTitle}>
            <IconButton onClick={handleCloseMultimedia}>
              <ArrowBackIosIcon sx={styles.arrowIcon} />
            </IconButton>
            <Typography variant="body1" color="initial" sx={styles.title}>
              {textMedia}
            </Typography>
          </Box>
        </Box>
        <Box ref={boxRef} sx={styles.fullWidth}>
          {renderImages()}
        </Box>
      </Drawer>
    </>
  );
}
export default Multimedia;
