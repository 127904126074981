import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Drawer from "@mui/material/Drawer";
import styles from "assets/styles/pages/Chat/section/UserList";
import Skeleton from "@mui/material/Skeleton";

export default function LoadingUserList() {
  return (
    <Box>
      <Drawer sx={styles.drawer} variant="permanent">
        <Box sx={styles.boxHeader}>
          <Box sx={styles.headerContainer}>
            {/* Header */}
            <Box sx={styles.personalContainer}>
              <Badge sx={styles.avatarContainer}>
                <Skeleton variant="circular" sx={styles.avatar} />
              </Badge>
              <Box>
                <Skeleton sx={styles.loadName} width={160} height={15} variant="rounded" />
                <Skeleton width={100} height={15} variant="rounded" />
              </Box>
            </Box>
            <Box>
              <Skeleton style={styles.settingSkeleton} variant="rectangular" />
            </Box>
          </Box>
          <Skeleton sx={styles.loadSearch} variant="rounded" height={40} />
        </Box>
        {/* Users */}
        <Box sx={styles.userList}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
            <Box key={item} sx={styles.loadingContainer}>
              <Box sx={styles.loadInformation}>
                <Skeleton variant="circular" sx={styles.loadAvatar} />
                <Box>
                  <Skeleton sx={styles.loadName} width={100} height={15} variant="rounded" />
                  <Skeleton width={160} height={15} variant="rounded" />
                </Box>
              </Box>
              <Skeleton sx={styles.loadTime} width={70} height={15} variant="rounded" />
            </Box>
          ))}
        </Box>
      </Drawer>
    </Box>
  );
}
