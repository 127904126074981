import TabPanel from "components/TabPanel";
import {
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  List,
  Paper,
  Popper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import styles from "assets/styles/pages/Chat/component/TickerPopper";
import { useEffect, useState } from "react";
import { Stickers } from "assets/stickers";

function TickerPopper({ openSetting, anchor, handleClose, handleSendTicker }) {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (openSetting) {
      const slider = document.querySelector("#items");
      let isDown = false;
      let startX;
      let scrollLeft;
      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = x - startX;
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }, [openSetting]);

  return (
    <Popper
      placement="bottom-end"
      open={openSetting}
      anchorEl={anchor}
      role={undefined}
      transition
      disablePortal
      sx={styles.popper}
      popperOptions={{ modifiers: [{ name: "offset", options: { offset: [20, 10] } }] }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={styles.paper}>
            <ClickAwayListener
              disableReactTree
              mouseEvent="onMouseDown"
              onClickAway={() => {
                handleClose();
                setValue(0);
              }}
            >
              <Box sx={styles.heightFull}>
                <List sx={styles.heightFull}>
                  <Box sx={styles.dFlex}>
                    <Box sx={styles.IconBox}>
                      {Stickers.map((tabPanel, indexTab) => (
                        <TabPanel
                          key={indexTab}
                          value={value}
                          sx={styles.tabPanel}
                          index={indexTab}
                        >
                          <Grid container spacing={1}>
                            {tabPanel.images.map((v, i) => (
                              <Grid
                                onClick={() => {
                                  handleSendTicker(indexTab, i);
                                  handleClose();
                                  setValue(0);
                                }}
                                item
                                xs={12 / 5}
                                sm={12 / 5}
                                md={12 / 5}
                                lg={12 / 5}
                                key={i}
                                sx={styles.hoverIcon}
                              >
                                <Box component="img" src={v} sx={styles.imgMedia} />
                              </Grid>
                            ))}
                          </Grid>
                        </TabPanel>
                      ))}
                    </Box>
                    <Box sx={styles.height50}>
                      <Tabs
                        id="items"
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{
                          style: styles.borderRadius0,
                        }}
                        sx={styles.tabList}
                      >
                        {Stickers?.map((tab, i) => (
                          <Tab
                            key={i}
                            sx={styles.tab}
                            label={
                              <Typography variant="body1" sx={styles.height49}>
                                <Box component="img" src={tab.images[0]} sx={styles.tab} />
                              </Typography>
                            }
                          />
                        ))}
                      </Tabs>
                    </Box>
                  </Box>
                </List>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default TickerPopper;
