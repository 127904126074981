import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Typography,
  Fade,
} from "@mui/material";
import TabPanel from "components/TabPanel";
import { useState } from "react";
import { ReactComponent as CloseIcon } from "assets/svg/icon/close.svg";
import { ReactComponent as LikeIcon } from "assets/svg/icon/emotion_like.svg";
import { ReactComponent as DislikeIcon } from "assets/svg/icon/emotion_dislike.svg";
import { ReactComponent as SadIcon } from "assets/svg/icon/emotion_sad.svg";
import { ReactComponent as FunIcon } from "assets/svg/icon/emotion_fun.svg";
import { ReactComponent as LoveIcon } from "assets/svg/icon/sub_emotion_love.svg";
import { ReactComponent as TipIcon } from "assets/svg/icon/tip_icon.svg";
import { TransitionGroup } from "react-transition-group";
import styles from "assets/styles/pages/Chat/section/ViewerDialog";
import { useGetSeen } from "service/UserService";
import { useTranslation } from "react-i18next";
import { VALUE_ACTION_MESSAGE } from "constant/chat";
import { isEmpty } from "utils/validate";
import { convertArrayUnique } from "utils/convert";
import ViewerItem from "../component/ViewerItem";

export default function SettingDialog({ onClose, isOpen, detailMessage, detailGroup }) {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const { data: userSeen } = useGetSeen(detailMessage.id);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    onClose();
    setValue(0);
  };
  return (
    <Dialog sx={styles.container} onClose={handleClose} open={isOpen}>
      <Box sx={{ borderRadius: 0 }}>
        <DialogTitle sx={styles.DialogTittle}>
          <Box />
          <Typography
            color="initial"
            sx={({ typography }) => ({
              fontWeight: typography.fontWeightBold,
              fontSize: typography.size.md,
            })}
          >
            {t("chat.information_viewer.title")}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={styles.DividerT} />
        <Box>
          <Tabs variant="scrollable" value={value} onChange={handleChange} sx={styles.Tabs}>
            <Tab
              sx={
                value === 0
                  ? { ...styles.Tab, ml: { xs: 2, sm: 3 } }
                  : { ...styles.TabBg, ml: { xs: 2, sm: 3 } }
              }
              label={
                <Typography
                  sx={value === 0 ? styles.TabViewed : styles.TabNoBorder}
                  variant="body2"
                >
                  {t("chat.information_viewer.watch")}
                </Typography>
              }
            />
            {!isEmpty(detailMessage) &&
              convertArrayUnique(detailMessage?.likeUsers.map((item) => item.value)).map(
                (item, index) => (
                  <Tab
                    sx={value === index + 1 ? styles.Tab : styles.TabBg}
                    label={
                      <Box sx={styles.TabInfo}>
                        {item === VALUE_ACTION_MESSAGE.LIKE && <LikeIcon style={styles.Icon} />}
                        {item === VALUE_ACTION_MESSAGE.DISLIKE && (
                          <DislikeIcon style={styles.Icon} />
                        )}
                        {item === VALUE_ACTION_MESSAGE.SAD && <SadIcon style={styles.Icon} />}
                        {item === VALUE_ACTION_MESSAGE.FUN && <FunIcon style={styles.Icon} />}
                        {item === VALUE_ACTION_MESSAGE.LOVE && <LoveIcon style={styles.Icon} />}
                        <Typography
                          sx={value === index + 1 ? styles.TabTitle : styles.fontFamily}
                          variant="caption"
                        >
                          {detailMessage.likeUsers.filter((fee) => fee.value === item).length}
                        </Typography>
                      </Box>
                    }
                  />
                )
              )}
            {!isEmpty(detailMessage) && detailMessage?.tipUsers?.length > 0 && (
              <Tab
                sx={
                  value ===
                  convertArrayUnique(detailMessage?.likeUsers.map((item) => item.value)).length + 1
                    ? styles.Tab
                    : styles.TabBg
                }
                label={
                  <Box sx={styles.TabInfo}>
                    <TipIcon style={styles.tipIcon} />
                    <Typography
                      sx={
                        value ===
                        convertArrayUnique(detailMessage?.likeUsers.map((item) => item.value))
                          .length +
                          1
                          ? styles.TabTitle
                          : styles.fontFamily
                      }
                      variant="caption"
                    >
                      {detailMessage?.tipUsers.length}
                    </Typography>
                  </Box>
                }
              />
            )}
          </Tabs>
        </Box>
      </Box>
      <Divider sx={styles.DividerB} />
      <TabPanel sx={styles.TabPanel} value={value} index={0}>
        <TransitionGroup>
          {detailGroup &&
            userSeen?.map((element) => (
              <Fade key={element.id}>
                <Box sx={styles.users}>
                  <ViewerItem
                    information={{
                      avatar: element.avatar,
                      name: element.username,
                      vID: element.id,
                      message: element.message,
                      time: element.time,
                      emotion:
                        detailMessage?.likeUsers?.find((fee) => +fee.id === element.id)?.value ||
                        null,
                      tip:
                        detailMessage?.tipUsers?.find((tip) => +tip.id === element.id)?.value ||
                        null,
                    }}
                  />
                </Box>
              </Fade>
            ))}
        </TransitionGroup>
      </TabPanel>
      {!isEmpty(detailMessage) &&
        convertArrayUnique(detailMessage?.likeUsers.map((item) => item.value)).map(
          (item, index) => (
            <TabPanel sx={styles.TabPanel} value={value} index={index + 1}>
              <TransitionGroup>
                {detailGroup?.users
                  .filter((u) =>
                    detailMessage?.likeUsers
                      ?.filter((fee) => fee.value === item)
                      ?.map((f) => f.id)
                      ?.includes(u.id)
                  )
                  .map((element) => (
                    <Fade key={element.id}>
                      <Box sx={styles.users}>
                        <ViewerItem
                          information={{
                            avatar: element.avatar,
                            name: element.username,
                            vID: element.id,
                            message: element.message,
                            time: element.time,
                            emotion: item,
                          }}
                        />
                      </Box>
                    </Fade>
                  ))}
              </TransitionGroup>
            </TabPanel>
          )
        )}
      {!isEmpty(detailMessage) && detailMessage?.tipUsers?.length > 0 && (
        <TabPanel
          sx={styles.TabPanel}
          value={value}
          index={convertArrayUnique(detailMessage?.likeUsers.map((item) => item.value)).length + 1}
        >
          <TransitionGroup>
            {detailGroup?.users
              .filter((u) =>
                detailMessage?.tipUsers
                  ?.filter((fee) => fee.id === u.id)
                  ?.map((f) => f.id)
                  ?.includes(u.id)
              )
              .map((element) => (
                <Fade key={element.id}>
                  <Box sx={styles.users}>
                    <ViewerItem
                      information={{
                        avatar: element.avatar,
                        name: element.username,
                        vID: element.id,
                        message: element.message,
                        time: element.time,
                        emotion: null,
                        tip:
                          detailMessage?.tipUsers?.find((tip) => +tip.id === element.id)?.value ||
                          null,
                      }}
                    />
                  </Box>
                </Fade>
              ))}
          </TransitionGroup>
        </TabPanel>
      )}
    </Dialog>
  );
}
