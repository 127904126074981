import {
  chatBarHeight,
  chatWidth,
  lgChatWidth,
  xlChatWidth,
  xxlChatWidth,
  informationWidth,
  xxlInformationWidth,
} from "constant/chat";

export default {
  appBar: ({ breakpoints }, { isOpenInformation }) => ({
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    position: "absolute",
    left: chatWidth,
    width: `calc(100% - ${chatWidth}${isOpenInformation ? ` - ${informationWidth}` : ""})`,
    height: chatBarHeight,
    [breakpoints.down("xxl")]: {
      left: xxlChatWidth,
      width: `calc(100% - ${xxlChatWidth}${isOpenInformation ? ` - ${xxlInformationWidth}` : ""})`,
    },
    [breakpoints.down("xl")]: {
      left: xlChatWidth,
      width: `calc(100% - ${xlChatWidth})`,
    },
    [breakpoints.down("lg")]: {
      left: lgChatWidth,
      width: `calc(100% - ${lgChatWidth})`,
    },
    [breakpoints.down("md")]: {
      left: 0,
      width: "100%",
    },
  }),
  toolbar: {
    my: { xs: 1.5, sm: 1 },
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
  },
  informationContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: ({ breakpoints }) => ({
    width: "60px",
    height: "60px",
    [breakpoints.down("md")]: {
      width: "50px",
      height: "50px",
    },
    mr: 1.5,
  }),
  title: ({ breakpoints }) => ({
    fontSize: "20px",
    width: "200px",
    [breakpoints.down("lg")]: {
      width: "130px",
    },
    [breakpoints.down("md")]: {
      width: "200px",
    },
    [breakpoints.down("sm")]: {
      width: "130px",
    },
  }),
  description: ({ breakpoints }) => ({
    fontSize: "16px",
    width: "140px",
    [breakpoints.down("lg")]: {
      width: "100px",
    },
    [breakpoints.down("md")]: {
      width: "140px",
    },
    [breakpoints.down("sm")]: {
      width: "100px",
    },
  }),
  icon: {
    height: "30px",
    width: "30px",
    marginRight: "12px",
  },
  actionContainer: {
    display: "flex",
  },
};
