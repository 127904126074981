import { LANGUAGE_KEY, TOKEN_KEY, VID_MAP_KEY } from "config/keys";
import { decrypt, encrypt } from "./cipher";

const setLanguage = (language) => localStorage.setItem(LANGUAGE_KEY, language);
const getLanguage = () => localStorage.getItem(LANGUAGE_KEY);

const setUserToken = (token) => localStorage.setItem(TOKEN_KEY, encrypt(token));
const getUserToken = () => decrypt(localStorage.getItem(TOKEN_KEY));
const setVIDMapChat = (vID) => localStorage.setItem(VID_MAP_KEY, vID);
const getVIDMapChat = () => localStorage.getItem(VID_MAP_KEY);
const removeVIDMapChat = () => localStorage.removeItem(VID_MAP_KEY);

export {
  setLanguage,
  getLanguage,
  setUserToken,
  getUserToken,
  setVIDMapChat,
  removeVIDMapChat,
  getVIDMapChat,
};
