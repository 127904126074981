import { QueryClient } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      cacheTime: 15 * 60,
      refetchOnWindowFocus: false,
    },
  },
});

const jsSip = {
  host: "pbx.vcallid.com",
  port: 8443,
  // user: "prod_17882",
  // userId: 17882,
  // secondUser: "prod_62951",
  // secondUserId: 62951,
  // password: "qazmlP123",

  // host: "pbx.voiprivate.com",
  // port: 5066,
  // user: "media_439",
  // userId: 439,
  // secondUser: "media_440",
  // secondUserId: 440,
  // password: "qazmlP123",
};

export { queryClient, jsSip };
