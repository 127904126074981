import { Button, Dialog, Box, Typography } from "@mui/material";
import styles from "assets/styles/pages/Chat/component/NotificationPhoneBook";
import { useTranslation } from "react-i18next";

function NotificationRemoveMessage({ open, handleClose }) {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose} fullWidth sx={styles.dialog}>
      <Typography variant="h5" sx={styles.titleText}>
        {t("remove_group.notification")}
      </Typography>
      <Typography sx={{ color: "#000", marginTop: "10px" }} variant="h7">
        {t("notification_admin.notification_remove_message")}
      </Typography>
      <Box sx={{ marginTop: "20px", mb: 3 }}>
        <Button size="medium" sx={styles.buttonConfirm} onClick={handleClose}>
          {t("notification.close")}
        </Button>
      </Box>
    </Dialog>
  );
}

export default NotificationRemoveMessage;
