import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import styles from "assets/styles/pages/Chat/loading/Header";
import { Skeleton } from "@mui/material";

function LoadingHeader({ isOpenInformation }) {
  return (
    <AppBar sx={(e) => styles.appBar(e, { isOpenInformation })}>
      <Toolbar sx={styles.toolbar}>
        <Box sx={styles.informationContainer}>
          <Skeleton variant="circular" sx={styles.avatar} />
          <Box>
            <Skeleton variant="text" sx={styles.title} />
            <Skeleton variant="text" sx={styles.description} />
          </Box>
        </Box>
        <Box sx={styles.actionContainer}>
          <Skeleton variant="circular" sx={styles.icon} />
          <Skeleton variant="circular" sx={styles.icon} />
          <Skeleton variant="circular" sx={styles.icon} />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default LoadingHeader;
