import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as Reply2Icon } from "assets/svg/icon/reply2.svg";
import { ReactComponent as ForwardIcon } from "assets/svg/icon/forward.svg";
import { useState } from "react";
import { ReactComponent as DetailMessagseIcon } from "../../../assets/svg/icon/detail_messagse.svg";

function ReplyAndForwardAndDetail({
  value,
  handleOpenReplyMessage,
  handleOpenForwardDialog,
  handleOpenPopper,
}) {
  const [isHoveredReply2, setIsHoveredReply2] = useState(false);
  const [isHoveredForward, setIsHoveredForward] = useState(false);
  const [isHoveredMenu, setIsHoveredMenu] = useState(false);

  const handleMouseEnterReply2 = () => {
    setIsHoveredReply2(true);
  };
  const handleMouseLeaveReply2 = () => {
    setIsHoveredReply2(false);
  };
  const handleMouseEnterForward = () => {
    setIsHoveredForward(true);
  };
  const handleMouseLeaveForward = () => {
    setIsHoveredForward(false);
  };
  const handleMouseEnterMenu = () => {
    setIsHoveredMenu(true);
  };
  const handleMouseLeaveMenu = () => {
    setIsHoveredMenu(false);
  };

  return (
    <Box
      className="iconContainer"
      sx={{
        display: "none",
        marginLeft: "10px",
        marginRight: "10px",
        background: "#FFF",
        borderRadius: "5px",
      }}
    >
      <IconButton
        className="replyIcon"
        onClick={() => {
          handleOpenReplyMessage(value);
        }}
        onMouseEnter={handleMouseEnterReply2}
        onMouseLeave={handleMouseLeaveReply2}
        sx={{
          path: {
            fill: isHoveredReply2 ? "#406EE2" : "#B9C4E1",
          },
        }}
      >
        <Reply2Icon />
      </IconButton>
      <IconButton
        className="forwardIcon"
        onClick={() => {
          handleOpenForwardDialog(value);
        }}
        onMouseEnter={handleMouseEnterForward}
        onMouseLeave={handleMouseLeaveForward}
        sx={{
          path: {
            fill: isHoveredForward ? "#406EE2" : "#B9C4E1",
          },
        }}
      >
        <ForwardIcon />
      </IconButton>
      <IconButton
        className="detailIcon"
        onMouseEnter={handleMouseEnterMenu}
        onMouseLeave={handleMouseLeaveMenu}
        sx={{
          circle: {
            fill: isHoveredMenu ? "#406EE2" : "#B9C4E1",
          },
        }}
        onClick={(e) => handleOpenPopper(e)}
      >
        <DetailMessagseIcon />
      </IconButton>
    </Box>
  );
}

export default ReplyAndForwardAndDetail;
