import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "assets/styles/pages/Chat/component/DeletePinMessageDialog";
import { Box, Typography, Button, IconButton } from "@mui/material";
import VideoPoster from "assets/images/video_poster.png";
import { ReactComponent as AudioIcon } from "assets/svg/icon/audio_icon.svg";
import { ReactComponent as AttachFileActiveIcon } from "assets/svg/icon/attach_icon_active.svg";
import { useTranslation } from "react-i18next";
import { MESSAGE_TYPE } from "constant/chat";
import { Stickers } from "assets/stickers";

export default function DeletePinMessageDialog({
  openDialogDeletePinMessage,
  handleOpenDeletePinMessage,
  handleDeletePinMessage,
  pinContent,
}) {
  if (!pinContent) return;
  const { t } = useTranslation();

  return (
    <Box>
      <Dialog
        open={openDialogDeletePinMessage}
        onClose={handleOpenDeletePinMessage}
        sx={styles.dialog}
      >
        <Box sx={styles.container}>
          <Box textAlign="center">
            <DialogTitle>
              {pinContent.kind === MESSAGE_TYPE.TEXT && (
                <Box>
                  <Typography sx={styles.titleText}>
                    {t("chat.header.pin.unpin_messages")}
                  </Typography>
                  <Box sx={styles.boxMessage}>
                    &quot;
                    <Typography sx={styles.message}>{pinContent.text}</Typography>
                    &quot;
                  </Box>
                </Box>
              )}
              {pinContent.kind === MESSAGE_TYPE.FILE && (
                <Box>
                  <Typography sx={styles.titleText}>
                    {t("chat.header.pin.unpin_file_messages")}
                  </Typography>
                  <Box alignItems="center">
                    <IconButton>
                      <AttachFileActiveIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
              {pinContent.kind === MESSAGE_TYPE.IMAGE && (
                <Box>
                  <Typography sx={styles.titleText}>
                    {t("chat.header.pin.unpin_photo_messages")}
                  </Typography>
                  <Box sx={styles.boxImage}>
                    <Box component="img" src={pinContent?.image[0]?.url} sx={styles.image} />
                    {pinContent?.image?.length > 1 && (
                      <>
                        <Box sx={styles.imageBg} />
                        <Typography sx={styles.numberImagePin}>
                          +{pinContent.image.length - 1}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              )}
              {pinContent.kind === MESSAGE_TYPE.VIDEO && (
                <Box>
                  <Typography sx={styles.titleText}>
                    {t("chat.header.pin.unpin_video_messages")}
                  </Typography>
                  <Box sx={styles.boxImage}>
                    <Box component="img" src={VideoPoster} sx={styles.image} />
                  </Box>
                </Box>
              )}
              {pinContent.kind === MESSAGE_TYPE.AUDIO && (
                <Box>
                  <Typography sx={styles.titleText}>
                    {t("chat.header.pin.unpin_voice_messages")}
                  </Typography>
                  <Box alignItems="center">
                    <IconButton>
                      <AudioIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
              {pinContent.kind === MESSAGE_TYPE.EMOJI && (
                <Box>
                  <Typography sx={styles.titleText}>
                    {t("chat.header.pin.unpin_sticker_messages")}
                  </Typography>
                  <Box alignItems="center">
                    <Box
                      sx={styles.boxStickerImage}
                      component="img"
                      src={
                        Stickers[pinContent?.emoji?.stickerIndex]?.images[pinContent?.emoji?.index]
                      }
                    />
                  </Box>
                </Box>
              )}
              {pinContent.kind === MESSAGE_TYPE.VOTE && (
                <Box>
                  <Typography sx={styles.titleText}>
                    {t("chat.header.pin.unpin_vote_messages")}
                  </Typography>
                  <Box sx={styles.boxMessage}>
                    &quot;
                    <Typography sx={styles.message}>{pinContent.vote.content}</Typography>
                    &quot;
                  </Box>
                </Box>
              )}
            </DialogTitle>
          </Box>
          <Box sx={styles.action}>
            <Button
              onClick={handleOpenDeletePinMessage}
              sx={styles.buttonCancel}
              variant="outlined"
            >
              <Typography sx={styles.cancel}>{t("chat.header.pin.cancel")}</Typography>
            </Button>
            <Button
              sx={styles.buttonCancelPin}
              onClick={handleDeletePinMessage}
              variant="contained"
            >
              <Typography sx={styles.cancelPin}>{t("chat.header.pin.unpin")}</Typography>
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}
