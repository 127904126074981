import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Avatar, Button, InputAdornment, TextField } from "@mui/material";
import { ReactComponent as SearchIcon } from "assets/svg/icon/search.svg";
import { ReactComponent as CloseAddGroupIcon } from "assets/svg/icon/close_add_group.svg";
import { ReactComponent as CloseText } from "assets/svg/icon/close_text.svg";
import { useEffect, useRef, useState } from "react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ReactComponent as Remove } from "assets/svg/icon/remove.svg";
import { isEmpty } from "jssip/lib/Utils";
import { useTranslation } from "react-i18next";
import { addGroupChat } from "api/chat";
import styles from "../../../assets/styles/pages/Chat/component/SendMessageDialog";
import defaultAvatar from "../../../assets/svg/icon/default_avatar_group.svg";
import Checkbox from "./CheckBox";
import ListSearchGolfer from "./ListSearchGolfer";

export default function SendMessageDialog({
  userInformation,
  onClose,
  isOpen,
  selectForwardIndex,
  setSelectForwardIndex,
  handleDoneSendMessageTextDialog,
  listGroupChat,
}) {
  const [searchResults, setSearchResults] = useState(null);
  const [searchUser, setSearchUser] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [isClickedMessage, setIsClickedMessage] = useState(false);
  const [messageText, setMessageText] = useState("");

  const [listAdd, setListAdd] = useState([]);
  const [listGroupId, setListGroupId] = useState([]);

  useEffect(() => {
    if (listGroupChat?.length > 0) {
      const ids = listGroupChat.map((item) => item.id);
      setListGroupId(ids);
    }
  }, [listGroupChat]);

  const { t } = useTranslation();
  const textInputRef = useRef(null);
  const handleCheckBox = (id) => {
    const cpList = [...selectForwardIndex];
    if (cpList.includes(id)) {
      const newList = cpList.filter((item) => item !== id);
      setSelectForwardIndex(newList);
    } else {
      cpList.push(id);
      setSelectForwardIndex(cpList);
    }
  };
  const handleClose = (id) => {
    const cpList = [...selectForwardIndex];
    if (cpList.includes(id)) {
      const newList = cpList.filter((item) => item !== id);
      setSelectForwardIndex(newList);
    } else {
      cpList.push(id);
      setSelectForwardIndex(cpList);
    }
  };

  const changeListGolf = (item) => {
    const tmp = [...listAdd].map((golfer) => golfer.id);
    if (!tmp.includes(item.id)) setListAdd([...listAdd, { ...item }]);
    else {
      const newData = [...listAdd].filter((selected) => item.id !== selected.id);
      setListAdd([...newData]);
    }
  };

  const clearSearch = (item) => {
    handleClose(item.group);
    changeListGolf(item);
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value?.toLowerCase()?.trim();

    setSearchUser(searchTerm);

    if (searchTerm === "") {
      setSearchResults(listGroupChat);
    } else {
      const results = listGroupChat.filter((item) => {
        const itemName = item.name?.toLowerCase();
        const itemId = item.id.toString(); // Chuyển đổi id thành chuỗi để so sánh
        return itemName.includes(searchTerm) || itemId.includes(searchTerm);
      });
      setSearchResults(results);
    }
  };
  const handleTextFieldClick = () => {
    setIsClicked(true);
  };

  const handleTextFieldBlur = () => {
    setIsClicked(false);
  };
  const handleTextFieldClickMessage = () => {
    setIsClickedMessage(true);
  };

  const handleTextFieldBlurMessage = () => {
    setIsClickedMessage(false);
  };
  const handleReset = () => {
    setSearchResults("");
    setMessageText("");
    setSelectForwardIndex([]);
  };
  const handleClearTextUserList = () => {
    setSearchResults("");
    setSearchUser("");
    textInputRef.current.focus();
    setIsClickedMessage(false);
  };
  return (
    <Dialog
      PaperProps={{
        sx: {
          minWidth: "700px",
          width: "90%",
        },
      }}
      onClose={() => {
        onClose();
      }}
      open={isOpen}
      sx={styles.dialog}
    >
      <DialogTitle sx={styles.dFlexColumn}>
        <Box sx={styles.dialogTitle}>
          <Typography variant="body1" color="initial" sx={styles.title}>
            {t("chat.send_messages_simultaneously")}
          </Typography>
          <IconButton
            sx={styles.paddingReset}
            onClick={() => {
              onClose();
              handleReset();
            }}
          >
            <CloseAddGroupIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={styles.divider} />
      <TextField
        placeholder={t("chat.user_list.search")}
        value={searchUser}
        onChange={handleSearch}
        onClick={handleTextFieldClick}
        onBlur={handleTextFieldBlur}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: searchUser ? (
            <InputAdornment
              position="end"
              style={{ cursor: "pointer" }}
              onClick={handleClearTextUserList}
            >
              <CloseText />
            </InputAdornment>
          ) : null,
          sx: styles.search({ isClicked }),
        }}
        inputRef={textInputRef}
      />
      <Box sx={styles.contentContainer}>
        <Box sx={styles.countSelectedBox}>
          <Typography variant="caption" color="initial" style={{ fontWeight: 700 }}>
            {t("chat.user_list.suggest")}
          </Typography>
        </Box>
        <Box sx={styles.contentContainerLeftAndRight}>
          <Box sx={styles.userGroupContainerLeft}>
            {(searchResults || listGroupChat).map((item) => (
              <Box key={item.id} sx={styles.userGroupItem} onChange={() => handleCheckBox(item.id)}>
                <Checkbox
                  sx={styles.checkContainer}
                  label={
                    <Box sx={styles.labelContainer}>
                      <Avatar srcSet={item?.image} src={defaultAvatar} sx={styles.groupAvatar} />
                      <Box sx={styles.groupUsernameContainer}>
                        <Typography variant="body2" color="initial" sx={styles.listUserName}>
                          {item?.name}
                        </Typography>
                        {item?.id && (
                          <Typography ml={0.5} variant="body2" color="initial" sx={styles.vidUser}>
                            {item?.id}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  }
                  checked={Boolean(selectForwardIndex.includes(item.id))}
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  }
                />
              </Box>
            ))}

            {searchUser && (
              <ListSearchGolfer
                searchId={searchUser}
                setActive={() => {}}
                listSelect={listAdd.map((item) => item.id)}
                openSend
                handleAddSingleChat={async (item) => {
                  const storeData = {
                    type: "single",
                    member_users: [userInformation?.id, item.id],
                    is_private: 0,
                  };

                  const res = await addGroupChat(storeData);
                  if (res.error) return;

                  handleCheckBox(res.group.id);

                  changeListGolf({ ...item, group: res.group.id });
                }}
              />
            )}
          </Box>
          {selectForwardIndex.length !== 0 && (
            <Box sx={styles.userGroupContainerRight}>
              <Box sx={styles.memberContainer} className="container">
                <Box style={{ display: "flex" }}>
                  <Typography sx={styles.text}> {t("create_group.selected")} </Typography>
                  <Typography sx={styles.textCount}>{selectForwardIndex.length}</Typography>
                </Box>
                {listGroupChat.map((item) => (
                  <Box key={item.id}>
                    {selectForwardIndex.includes(item.id) && (
                      <Box key={item.id} sx={styles.memberAvatarContainer}>
                        <Avatar
                          sx={styles.memberAvatar}
                          src={item.image || item.avatar || defaultAvatar}
                        />
                        <Box style={{ display: "inline-block" }}>
                          <Typography sx={styles.listUserName}>
                            {item.name || item.username}
                          </Typography>
                          <Typography sx={styles.vidUser}>{item.id}</Typography>
                        </Box>
                        <IconButton
                          onClick={() => handleClose(item.id)}
                          sx={styles.removeMemberIconContainer}
                        >
                          <Remove />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                ))}

                {listAdd.map((item) => (
                  <Box key={item.id}>
                    {!listGroupId.includes(item.group) && (
                      <Box key={item.id} sx={styles.memberAvatarContainer}>
                        <Avatar
                          sx={styles.memberAvatar}
                          src={item.image || item.avatar || defaultAvatar}
                        />
                        <Box style={{ display: "inline-block" }}>
                          <Typography sx={styles.listUserName}>
                            {item.name || item.username}
                          </Typography>
                          <Typography sx={styles.vidUser}>{item.id}</Typography>
                        </Box>
                        <IconButton
                          onClick={() => clearSearch(item)}
                          sx={styles.removeMemberIconContainer}
                        >
                          <Remove />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
        <Typography variant="caption" color="initial" style={{ fontWeight: 700 }}>
          {t("chat.content_sent")}
        </Typography>
        <TextField
          placeholder={t("chat.import_content")}
          multiline
          onClick={handleTextFieldClickMessage}
          onBlur={handleTextFieldBlurMessage}
          rows={6}
          maxRows={6}
          value={messageText} // Corrected variable name
          onChange={(e) => setMessageText(e.target.value)}
          sx={styles.mesageText({ isClickedMessage })}
        />
        <Button
          variant="contained"
          sx={styles.buttonCreateGroup}
          disabled={isEmpty(messageText) || selectForwardIndex.length < 2}
          onClick={() => {
            handleDoneSendMessageTextDialog(messageText);
            onClose();
            handleReset();
          }}
        >
          {t("chat.send")}
        </Button>
      </Box>
    </Dialog>
  );
}
