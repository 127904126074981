export default {
  dialog: ({ breakpoints, borders }) => ({
    // m: 2,
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.lg,
        width: "100%",
        height: "90%",
        position: "relative",
        maxWidth: "450px",
        backgroundColor: "#F0F1F5",
        [breakpoints.down("sm")]: {
          maxWidth: "90vw",
        },
      },
    },
  }),
  dFlexColumn: { display: "flex", flexDirection: "column" },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    mx: 2,
    mt: 3,
    mb: 0,
  },
  title: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    color: "#000",
    fontSize: "26px",
    fontStyle: "normal",
    lineHeight: "normal",
    marginLeft: "auto",
    marginRight: "auto",
  }),
  divider: {
    marginBottom: "20px",
    backgroundColor: "#000",
    // margin: "15px 0",
  },
  divider1: {
    backgroundColor: "#DFE1E8",
    // margin: "15px 0",
  },
  search: ({ isClicked }) => ({
    borderRadius: "100px",
    mx: 2,
    mb: 2,
    background: "var(--White, #FFF)",
    fieldset: {
      borderRadius: "100px",
      border: "none",
      height: "50px",
    },
    input: {
      py: 1.5,
      "&::placeholder": {
        fontWeight: 550,
      },
    },
    border: isClicked ? "1px solid var(--button_new, #406EE2)" : "none",
    backgroundColor: isClicked ? "none" : "#F6F6F6",
  }),
  iconW18: {
    width: "14px",
    fill: "#fff",
  },
  paddingReset: {
    p: 1,
  },
  groupContainer: {
    display: "flex",
    alignItems: "center",
    p: 2,
  },
  groupCropImage: () => ({
    width: "60px",
    height: "63px",
    // border: `1px dashed ${palette.primary.main}`,
    backgroundColor: "#FFF",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  }),
  cameraContainer: {
    // width: "30px",
    mt: 0.5,
  },
  cropAvatarContainer: ({ borders, palette }) => ({
    cursor: "pointer",
    position: "relative",
    borderRadius: borders.borderRadius.round,
    border: `1px solid ${palette.grey[100]}`,
    "&:hover": {
      "& .avatar-overlay": {
        display: "flex",
      },
    },
  }),
  overlay: ({ palette, functions, borders }) => ({
    position: "absolute",
    display: "none",
    width: "100%",
    height: "100%",
    left: 0,
    bottom: 0,
    backgroundColor: functions.rgba(palette.black.main, 0.3),
    zIndex: 1,
    flexGrow: 1,
    borderRadius: borders.borderRadius.round,
    justifyContent: "center",
    alignItems: "center",
  }),
  updateAvatarIcon: {
    fill: "white",
  },
  cropAvatar: {
    width: "60px",
    height: "60px",
  },
  groupNameContainer: {
    width: "100%",
    ml: 2,
  },
  groupName: ({ typography }) => ({
    width: "100%",
    background: "#F0F1F5",
    input: {
      fontFamily: `${typography.fontFamily2} !important`,
      fontSize: "16px",
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#406EE2", // Màu của border bottom khi active
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "none", // Màu của border bottom khi không active
    },
    "& .MuiInput-root": {
      "&:before, :after, :hover:not(.Mui-disabled):before": {
        borderBottom: "1px solid #00000",
      },
    },
  }),
  buttonCreateGroup: {
    borderRadius: "10px",
    background: "#406EE2",
    minWidth: "100px",
    maxWidth: "170px",
    height: "50px",
    margin: "30px auto",
    fontWeight: 700,
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: "0px 8px 16px 0px rgba(51, 102, 255, 0.25)",
    "&:disabled": {
      backgroundColor: "#c8d3f3",
      color: "#FFFF",
    },
    "&:hover": {
      background: "#406EE2",
      boxShadow: "0px 8px 16px 0px rgba(51, 102, 255, 0.25)",
    },
  },
  boxCompetitionFormat: {
    borderRadius: "10px",
    background: "#FFF",
    width: "100%",
    height: "200px",
    boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.20)",
  },
  boxDateTime: {
    marginTop: "15px",
    borderRadius: "10px",
    background: "#FFF",
    width: "100%",
    height: "auto",
    boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.20)",
  },
  boxReporter: {
    marginTop: "15px",
    borderRadius: "10px",
    background: "#FFF",
    width: "100%",
    height: "100px",
    boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.20)",
  },
  boxStadium: {
    borderRadius: "10px",
    background: "#FFF",
    width: "100%",
    height: "310px",
    boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.20)",
  },
  boxPlayer: {
    marginTop: "15px",
    borderRadius: "10px",
    background: "#FFF",
    width: "100%",
    height: "200px",
    boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.20)",
  },
  boxTitle: {
    padding: 1.3,
    display: "flex",
    borderBottom: "1px solid #DFE1E8",
  },
  boxTitlePlayer: {
    padding: 1.3,
    display: "flex",
  },
  createReporter: {
    padding: 1.3,
    display: "flex",
  },
  boxTextCompetitionFormat: {
    marginLeft: "10px",
    lineHeight: "20px",
    color: "#999",
    fontSize: "14px",
  },
  boxTextReporter: {
    marginLeft: "10px",
    lineHeight: "30px",
    color: "#406EE2",
    fontSize: "12px",
  },
  boxText: {
    marginLeft: "10px",
    lineHeight: "10px",
    color: "#999",
    fontSize: "14px",
  },
  selectPlayer: {
    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
      borderRadius: 5,
    },
    "&:focus": {
      backgroundColor: "red",
    },
  },
  choose: {
    marginLeft: "10px",
    marginTop: "10px",
    lineHeight: "10px",
    color: "#090710 ",
    fontSize: "14px",
    fontWeight: 400,
  },
  selectOptionsContainer: {
    display: "flex",
    marginLeft: "10px",
    marginBottom: "20px",
    marginTop: "15px",
  },
  selectOptionBox: {
    width: "50px",
    height: "30px",
    borderRadius: "50px",
    marginRight: "15px",
    cursor: "pointer",
  },
  textSelectOptions: {
    fontSize: "14px",
    lineHeight: "30px",
    textAlign: "center",
  },
  hole: {
    marginTop: "15px",
    marginLeft: "10px",
    "& .MuiInputBase-root": {
      borderColor: "#E4EFFF",
      background: "#E4EFFF",
      color: "#406EE2",
      border: "none",
      borderRadius: "100px",
      height: "35px",
      minWidth: "80px",
    },
  },
  radio: {
    "& .MuiSvgIcon-root": {
      zoom: 1.3,
      border: "1px solid #000",
    },
    "&.Mui-checked + .MuiFormControlLabel-label": {
      color: "#406EE2", // Màu sẽ thay đổi khi Radio được chọn
    },
  },
  imageBorderRed: {
    width: "100%", // Chắc chắn rằng hình ảnh co dãn theo chiều rộng
    height: "auto", // Cho phép hình ảnh tự động co dãn theo chiều cao
    minWidth: "150px", // Đặt giá trị tối thiểu cho chiều rộng
    minHeight: "80px", // Đặt giá trị tối thiểu cho chiều cao
    maxWidth: "100%",
    maxHeight: "180px",
  },
  boxAddPlayerBlue: {
    position: "absolute",
    // top: "23%", // Điều chỉnh vị trí top tùy thuộc vào nhu cầu
    left: "47%", // Điều chỉnh vị trí left tùy thuộc vào nhu cầu
    transform: "translate(-50%, -50%)",
    display: "flex",
    width: "125px",
    height: "35px",
    marginTop: "-23px",
  },
  boxAddPlayerRed: {
    position: "absolute",
    // top: "23%", // Điều chỉnh vị trí top tùy thuộc vào nhu cầu
    left: "53%", // Điều chỉnh vị trí left tùy thuộc vào nhu cầu
    transform: "translate(-50%, -50%)",
    display: "flex",
    width: "125px",
    height: "35px",
    marginTop: "-23px",
  },
  textAddPlayerBlue: {
    width: "120px",
    color: "#406EE2",
    fontSize: "12px",
    lineHeight: "30px",
    marginLeft: "5px",
  },
  textAddPlayerRed: {
    width: "120px",
    color: "#406EE2",
    fontSize: "12px",
    lineHeight: "30px",
    marginLeft: "5px",
  },
  // icon: {
  //   marginLeft: "10px",
  //   lineHeight: "50px",
  // },
};
