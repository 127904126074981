import { updateInformationGroup } from "api/chat";
import { useMutation, useQueryClient } from "react-query";

export const useRenameGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["rename_group"],
    async (group) => {
      const data = await updateInformationGroup(group);
      return data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["group"]);
        queryClient.invalidateQueries(["group-idb"]);
      },
    }
  );
};
