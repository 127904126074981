export default {
  dialog: ({ borders }) => ({
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.xl,
      },
    },
  }),
  container: {
    width: "300px",
    mt: 2,
  },
  titleText: ({ typography }) => ({
    margin: "10px 15px 10px 15px",
    fontFamily: typography.fontFamily2,
  }),

  boxMessage: {
    display: "flex",
    justifyContent: "center",
    px: 3,
  },
  message: ({ typography }) => ({
    fontSize: typography.size.sm,
    textAlign: "center",
    fontFamily: typography.fontFamily3,
    fontWeight: 500,
    mb: 1,
  }),
  DialogTittle: {
    alignItems: "center",
    p: 2,
  },
  action: {
    borderTop: "1px solid #d9d9d9",

    py: 1,
    mt: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: "#d9d9d9",
    },
  },
  buttonCancel: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    background: "#fff",
    border: "2px solid #406EE2",
    color: "#406EE2",
    fontWeight: 700,
    borderRadius: "7px",
    marginRight: "15px",
    height: "40px",
    width: "120px",
    "&:hover": {
      background: "#fff",
    },
  }),
  buttonConfirm: ({ typography }) => ({
    fontFamily: typography.fontFamily2,
    background: "#406EE2",
    color: "#ffffff",
    borderRadius: "7px",
    fontWeight: 700,
    marginRight: "15px",
    height: "40px",
    width: "120px",
    "&:hover": {
      background: "#406EE2",
    },
  }),
};
