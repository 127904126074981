import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Avatar, Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { ReactComponent as CameraIcon } from "assets/svg/icon/camera.svg";
import { ReactComponent as SearchIcon } from "assets/svg/icon/search.svg";
import { ReactComponent as Remove } from "assets/svg/icon/remove.svg";
import { ReactComponent as CloseAddGroupIcon } from "assets/svg/icon/close_add_group.svg";
import { ReactComponent as CloseText } from "assets/svg/icon/close_text.svg";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "jssip/lib/Utils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Fade from "@mui/material/Fade";
import InputAdornment from "@mui/material/InputAdornment";
import styles from "../../../assets/styles/pages/Chat/component/NewGroupDialog";
import defaultAvatar from "../../../assets/svg/icon/default_avatar_group.svg";
import CropGroupAvatar from "./CropGroupAvatar";

export default function NewGroupDialog({
  onClose,
  isOpen,
  addGroupChat,
  listGroupAdd,
  handleModifyMember,
  members,
  setMember,
}) {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState("");
  const [isOpenCrop, setOpenCrop] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const textInputRef = useRef(null);
  const [isPrivate, setIsPrivate] = useState(0); // Khởi tạo trạng thái mặc định
  const [groupPermission, setGroupPermission] = useState(0); // Khởi tạo trạng thái mặc định
  const handleResetGroup = () => {
    setAvatar(null);
    setGroupName("");
    setMember([]);
    setIsPrivate(0);
    setGroupPermission(0);
    addGroupChat.setSearchGolferKey("");
  };
  useEffect(() => {
    const slider = document.querySelector(".container");
    let isDown = false;
    let startX;
    let scrollLeft;

    if (slider) {
      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 2;
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }, [members]);

  const handleOpenCropImage = () => setOpenCrop(true);
  const handleCloseCropImage = () => setOpenCrop(false);
  const countMembers = members?.length;

  const handleTextFieldClick = () => {
    setIsClicked(true);
  };

  const handleTextFieldBlur = () => {
    setIsClicked(false);
  };
  const handleClearTextUserList = () => {
    addGroupChat.setSearchGolferKey("");
    textInputRef.current.focus();
    setIsClicked(false);
  };

  const handleIsPrivateChange = (option) => {
    setIsPrivate(option);
  };

  const handleGroupPermissionChange = (option) => {
    setGroupPermission(option);
  };
  return (
    <Dialog
      PaperProps={{
        sx: {
          minWidth: "700px",
          width: "90%",
          maxHeight: "90%",
        },
      }}
      onClose={() => {
        handleResetGroup();
        onClose();
      }}
      open={isOpen}
      sx={styles.dialog}
    >
      <CropGroupAvatar
        setting={{
          isOpen: isOpenCrop,
          onClose: handleCloseCropImage,
          setAvatar,
          avatar,
          setOpenCrop,
        }}
      />
      <DialogTitle sx={styles.dFlexColumn}>
        <Box sx={styles.dialogTitle}>
          <Typography variant="body1" color="initial" sx={styles.title}>
            {t("create_group.create_a_group")}
          </Typography>
          <IconButton
            sx={styles.paddingReset}
            onClick={() => {
              handleResetGroup();
              onClose();
            }}
          >
            <CloseAddGroupIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={styles.divider} />
      <Box sx={styles.groupContainer}>
        {!avatar ? (
          <Button sx={styles.groupCropImage} onClick={handleOpenCropImage}>
            <Box sx={styles.cameraContainer}>
              <CameraIcon />
            </Box>
          </Button>
        ) : (
          <Box onClick={handleOpenCropImage} sx={styles.cropAvatarContainer}>
            <Box className="avatar-overlay" sx={styles.overlay}>
              <CameraIcon style={styles.updateAvatarIcon} />
            </Box>
            <Avatar sx={styles.cropAvatar} src={avatar} />
          </Box>
        )}
        <Box sx={styles.groupNameContainer}>
          <TextField
            sx={styles.groupName}
            variant="standard"
            placeholder={t("chat.user_list.group_name")}
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            InputProps={{ autoComplete: "off" }}
          />
        </Box>
      </Box>
      <TextField
        value={addGroupChat.searchGolferKey}
        placeholder={t("chat.user_list.search")}
        onChange={addGroupChat.handleSearchUser}
        onClick={handleTextFieldClick}
        onBlur={handleTextFieldBlur}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={styles.searchIcon}>
              <SearchIcon style={styles.searchIcon} />
            </InputAdornment>
          ),
          endAdornment: addGroupChat.searchGolferKey ? (
            <InputAdornment
              position="end"
              style={{ cursor: "pointer" }}
              onClick={handleClearTextUserList}
            >
              <CloseText />
            </InputAdornment>
          ) : null,
          sx: styles.search({ isClicked }),
        }}
        inputRef={textInputRef}
      />
      <Typography sx={styles.searchType} color="initial.main" variant="body1">
        {addGroupChat.searchGolferKey ? t("chat.user_list.search") : t("chat.user_list.suggest")}
      </Typography>

      <Box style={{ height: "300px" }}>
        <Box sx={styles.userGroupContainerLeft}>
          {members
            .concat(listGroupAdd?.filter((v) => !members.map((m) => m?.id).includes(v?.id)))
            .map((item, index) => (
              <Box key={index} sx={styles.userGroupItem}>
                <FormControlLabel
                  onChange={(e) => handleModifyMember(item, e.target.checked)}
                  sx={styles.checkContainer}
                  label={
                    <Box sx={styles.labelContainer}>
                      <Avatar srcSet={item?.avatar} src={defaultAvatar} sx={styles.groupAvatar} />
                      <Box sx={styles.groupUsernameContainer}>
                        <Typography variant="body2" color="initial" sx={styles.listUserName}>
                          {item?.username}
                        </Typography>
                        {item?.id && (
                          <Typography ml={0.5} variant="body2" color="initial" sx={styles.vidUser}>
                            {item?.id}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  }
                  checked={members.map((v) => v?.id).includes(item?.id)}
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  }
                />
              </Box>
            ))}
        </Box>
        {countMembers <= 0 ? null : (
          <Box sx={styles.userGroupContainerRight}>
            <Fade in={!isEmpty(members)} unmountOnExit>
              <Box sx={styles.memberContainer} className="container">
                <Box style={{ display: "flex" }}>
                  <Typography sx={styles.text}> {t("create_group.selected")} </Typography>
                  <Typography sx={styles.textCount}>{countMembers}</Typography>
                </Box>
                {members?.map((item, index) => (
                  <Box key={index} sx={styles.memberAvatarContainer}>
                    <Avatar sx={styles.memberAvatar} src={defaultAvatar} srcSet={item.avatar} />
                    <Box style={{ display: "inline-block" }}>
                      <Typography sx={styles.listUserName}>{item.username}</Typography>
                      <Typography sx={styles.vidUser}>{item.id}</Typography>
                    </Box>
                    <IconButton
                      onClick={() => handleModifyMember(item, false)}
                      sx={styles.removeMemberIconContainer}
                    >
                      <Remove />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Fade>
          </Box>
        )}
      </Box>
      <Divider style={{ marginLeft: "15px", marginRight: "15px" }} />
      <Box sx={styles.selectOptionsContainer}>
        <Typography sx={styles.titleSelectOptions}>
          {t("new_group.permission_to_add_members")}:
        </Typography>
        <Box
          sx={{
            ...styles.selectOptionBox,
            backgroundColor: groupPermission === 0 ? "#E4EFFF" : "#F4F4F4",
          }}
          onClick={() => handleGroupPermissionChange(0)}
        >
          <Typography
            sx={{
              ...styles.textSelectOptions,
              color: groupPermission === 0 ? "#406EE2" : "#808080",
            }}
          >
            {t("new_group.administrator")}
          </Typography>
        </Box>
        <Box
          sx={{
            ...styles.selectOptionBox,
            backgroundColor: groupPermission === 1 ? "#E4EFFF" : "#F4F4F4",
          }}
          onClick={() => handleGroupPermissionChange(1)}
        >
          <Typography
            sx={{
              ...styles.textSelectOptions,
              color: groupPermission === 1 ? "#406EE2" : "#808080",
            }}
          >
            {t("new_group.member")}
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.selectOptionsContainer}>
        <Typography sx={styles.titleSelectOptions}>{t("new_group.group_mode")}:</Typography>
        <Box
          sx={{
            ...styles.selectOptionBox,
            backgroundColor: isPrivate === 0 ? "#E4EFFF" : "#F4F4F4",
          }}
          onClick={() => handleIsPrivateChange(0)}
        >
          <Typography
            sx={{
              ...styles.textSelectOptions,
              color: isPrivate === 0 ? "#406EE2" : "#808080",
            }}
          >
            {t("new_group.public")}
          </Typography>
        </Box>
        <Box
          sx={{
            ...styles.selectOptionBox,
            backgroundColor: isPrivate === 1 ? "#E4EFFF" : "#F4F4F4",
          }}
          onClick={() => handleIsPrivateChange(1)}
        >
          <Typography
            sx={{
              ...styles.textSelectOptions,
              color: isPrivate === 1 ? "#406EE2" : "#808080",
            }}
          >
            {t("new_group.private")}
          </Typography>
        </Box>
      </Box>

      <Button
        variant="contained"
        sx={styles.buttonCreateGroup}
        disabled={isEmpty(groupName) || members.length < 2}
        onClick={() => {
          // Determine the review value based on isPrivate
          const reviewValue = isPrivate === 1 ? 1 : 0;

          // Call handleAddGroupChat with the correct review value
          addGroupChat.handleAddGroupChat(
            {
              name: groupName,
              members: members.map((v) => v.id),
              image: avatar,
              is_private: isPrivate,
              group_permission: groupPermission,
              review: reviewValue,
            },
            handleResetGroup
          );

          // Close the dialog or perform other actions as needed
          onClose();
        }}
      >
        {t("create_group.create_a_group")}
      </Button>
    </Dialog>
  );
}
