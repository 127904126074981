import {
  Box,
  Checkbox,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import styles from "assets/styles/pages/Chat/component/ListMemberPending";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { t } from "i18next";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import defaultAvatarUser from "../../../assets/svg/icon/default_avatar_user.svg";

function ListMemberPending({
  isMediumSize,
  handleCloseListMemberPending,
  isOpenListMemberPendingDialog,
  listPendingJoinGroup,
  handleRefuseMemberGroup,
  handleApprovalMemberGroup,
}) {
  const [shouldShowCheckbox, setShouldShowCheckbox] = useState(false);
  const [member, setMembers] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const handleOpenCheckBox = () => {
    setShouldShowCheckbox(true);
  };
  const handleCloseCheckBox = () => {
    setShouldShowCheckbox(false);
  };
  const handleCheckboxChange = (itemId, isChecked) => {
    setMembers((prevMembers) => {
      if (isChecked) {
        // Thêm itemId vào object với giá trị là true
        return {
          ...prevMembers,
          [itemId]: true,
        };
      }
      // Xoá itemId khỏi object
      const updatedMembers = { ...prevMembers };
      delete updatedMembers[itemId];
      return updatedMembers;
    });
  };
  const numbersArray = Object.keys(member).map(Number);
  const handleSelectAll = () => {
    const updatedMembers = {};
    const allItemsChecked = !selectAllChecked;
    listPendingJoinGroup.forEach((item) => {
      updatedMembers[item.id] = allItemsChecked;
    });
    setMembers(updatedMembers);
    if (allItemsChecked === false) {
      setMembers([]);
      setSelectAllChecked(false);
    }
    setSelectAllChecked(allItemsChecked);
  };
  const handleClearSelectAll = () => {
    setMembers([]);
    setSelectAllChecked(false);
  };
  return (
    <Drawer
      sx={(e) => styles.drawer(e, { isOpenListMemberPendingDialog })}
      onClose={handleCloseListMemberPending}
      anchor="right"
      open={isOpenListMemberPendingDialog}
      variant={!isMediumSize ? "permanent" : "temporary"}
    >
      <Box sx={styles.fLexCol}>
        <Box sx={styles.dialogTitle}>
          <IconButton
            onClick={() => {
              handleCloseListMemberPending();
              handleClearSelectAll();
              handleCloseCheckBox();
            }}
          >
            <ArrowBackIosIcon sx={styles.arrowIcon} />
          </IconButton>
          <Typography variant="body1" color="initial" sx={styles.title}>
            {t("chat.chat_information.approval")}
          </Typography>
        </Box>
      </Box>
      {listPendingJoinGroup?.length > 1 ? (
        <Grid container spacing={1} style={{ backgroundColor: "#F0F1F5" }}>
          <Grid xs={6}>
            {shouldShowCheckbox ? (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    color: "#406EE2",
                  },
                  ...styles.chipChooseAll, // Nếu bạn muốn kế thừa các kiểu khác từ styles.chipChooseAll
                }}
                label={t("chat.chat_information.select_all")}
                control={
                  <Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon />} />
                }
                onChange={handleSelectAll}
              />
            ) : null}
          </Grid>
          <Grid xs={6}>
            {shouldShowCheckbox ? (
              <Typography
                variant="body1"
                color="initial"
                onClick={() => {
                  handleClearSelectAll();
                  handleCloseCheckBox();
                }}
                sx={styles.chipChoose}
              >
                {t("chat.chat_information.cancel")}
              </Typography>
            ) : (
              <Typography
                variant="body1"
                color="initial"
                onClick={handleOpenCheckBox}
                sx={styles.chipChoose}
              >
                {t("chat.chat_information.choose")}
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : null}
      <Box sx={styles.listUserContainer}>
        {listPendingJoinGroup?.map((item, index) => (
          <Box key={index} sx={styles.singleContainer}>
            <FormControlLabel
              onChange={(e) => handleCheckboxChange(item?.id, e.target.checked)}
              sx={styles.checkContainer}
              label={
                <Box sx={styles.labelContainer}>
                  <Avatar sx={styles.singleAvatar} src={defaultAvatarUser} srcSet={item?.avatar} />
                  <Box style={{ display: "inline-block" }}>
                    <Box sx={styles.singleNameContainer}>
                      <Box>
                        <Box style={{ display: "flex", marginBottom: "5px" }}>
                          <Typography variant="body2" color="initial" sx={styles.username}>
                            {(() => {
                              if (item?.username?.includes(" ")) {
                                return item?.username;
                              }
                              if (item?.username?.length > 13) {
                                return `${item?.username.substring(0, 13)}...`;
                              }
                              return item?.username;
                            })()}{" "}
                            - {item?._id}
                          </Typography>
                        </Box>
                        {shouldShowCheckbox ? (
                          <Box />
                        ) : (
                          <Box style={{ display: "flex" }}>
                            <Box
                              variant="outlined"
                              size="small"
                              sx={styles.buttonRefuse}
                              onClick={() => {
                                handleRefuseMemberGroup([item?.user_id]);
                              }}
                            >
                              <Typography
                                variant="body2"
                                color="initial"
                                sx={styles.textButtonRefuse}
                              >
                                {t("chat.chat_information.refuse")}
                              </Typography>
                            </Box>
                            <Box
                              variant="outlined"
                              size="small"
                              sx={styles.buttonApproval}
                              onClick={() => {
                                handleApprovalMemberGroup([item?.user_id]);
                              }}
                            >
                              <Typography
                                variant="body2"
                                color="initial"
                                sx={styles.textButtonApproval}
                              >
                                {t("chat.chat_information.approval")}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              }
              checked={member[item.id] || false}
              control={
                shouldShowCheckbox ? (
                  <Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon />} />
                ) : (
                  <Box />
                ) // hoặc null, hoặc một thành phần khác tùy thuộc vào cấu trúc của bạn
              }
            />
          </Box>
        ))}
      </Box>
      {
        shouldShowCheckbox ? (
          <Box
            style={{
              display: "flex",
              marginBottom: "10px",
              marginTop: "10px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Box
              variant="outlined"
              size="small"
              sx={styles.buttonRefuse2}
              onClick={() => {
                handleRefuseMemberGroup(numbersArray);
              }}
            >
              <Typography variant="body2" color="initial" sx={styles.textButtonRefuse2}>
                {t("chat.chat_information.refuse")}
              </Typography>
            </Box>
            <Box
              variant="outlined"
              size="small"
              sx={styles.buttonApproval2}
              onClick={() => {
                handleApprovalMemberGroup(numbersArray);
              }}
            >
              <Typography variant="body2" color="initial" sx={styles.textButtonApproval2}>
                {t("chat.chat_information.approval")}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box />
        ) // hoặc null, hoặc một thành phần khác tùy thuộc vào cấu trúc của bạn
      }
    </Drawer>
  );
}
export default ListMemberPending;
