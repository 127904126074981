import { Grid, Tab, Tabs } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import GroupIDB from "IndexedDB/GroupIDB";
import { groupChatDetail } from "api/chat";
import styles from "assets/styles/pages/Chat/section/UserList";
import { ReactComponent as ENIcon } from "assets/svg/icon/england_icon.svg";
import { ReactComponent as GolferIcon } from "assets/svg/icon/golfer.svg";
import { ReactComponent as LockIcon } from "assets/svg/icon/lock.svg";
import { ReactComponent as LogoutIcon } from "assets/svg/icon/log_out.svg";
import { ReactComponent as MessageIcon2 } from "assets/svg/icon/message.svg";
import { ReactComponent as MessageIcon1 } from "assets/svg/icon/messagse.svg";
import { ReactComponent as NotificationIcon } from "assets/svg/icon/notification.svg";
import { ReactComponent as PhoneBookIcon1 } from "assets/svg/icon/phone_book1.svg";
import { ReactComponent as PhoneBookIcon2 } from "assets/svg/icon/phone_book2.svg";
import { ReactComponent as PopupIcon } from "assets/svg/icon/popup.svg";
import { ReactComponent as SearchIcon } from "assets/svg/icon/search.svg";
import { ReactComponent as SettingUserIcon1 } from "assets/svg/icon/setting_user1.svg";
import { ReactComponent as SettingUserIcon2 } from "assets/svg/icon/setting_user2.svg";
import { ReactComponent as SoundIcon } from "assets/svg/icon/sound.svg";
import { ReactComponent as VNIcon } from "assets/svg/icon/vietnam.svg";
import { ReactComponent as CloseText } from "assets/svg/icon/close_text.svg";
import TabPanel from "components/TabPanel";
import { isEmpty } from "jssip/lib/Utils";
import moment from "moment";
import LoadingUserList from "pages/Chat/loading/UserList";
import PropTypes from "prop-types";
import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { encrypt } from "utils/cipher";
import { decodeID } from "utils/hex";
import { emptyFunction } from "utils/utils";
import defaultAvatar from "../../../assets/svg/icon/default_avatar_group.svg";
import defaultAvatarUser from "../../../assets/svg/icon/default_avatar_user.svg";
import { getLanguage, setLanguage } from "../../../utils/storage";
import ChatActionMenuGroup from "../component/ChatActionMenuGroup";
import ChossePrivateDialog from "../component/ChossePrivateDialog";
import ConfimLogoutDialog from "../component/ConfimLogoutDialog";
import NewGroupDialog from "../component/NewGroupDialog";
import NewMessagseDialog from "../component/NewMessagseDialog";
import NotificationPhoneBook from "../component/NotificationPhoneBook";
import PersonalPopper from "../component/PersonalPopper";
import PopupMessageNew from "../component/PopupMessageNew";
import SendMessageDialog from "../component/SendMessageDialog";
import UserItem from "../component/UserItem";
import InformationDialog from "./InformationDialog";
import SettingDialog from "./SettingDialog";
import ListSearchGolfer from "../component/ListSearchGolfer";
import NewChannelTvDialog from "../component/NewChannelTvDialog";

function UserList({
  setSearchUser,
  searchOptions,
  selectOptions,
  personalOptions,
  dialogOptions,
  listGroupChat,
  isLoading,
  userInformation,
  conversationOptions,
  listGroupAdd,
  addGroupChat,
  handleLeaveAndRemoveGroupChatMenu,
  handleRemoveGroupChatMenu,
  handleMuteGroupChat,
  listDataPhoneBook,
  searchPhoneBook,
  setSearchPhoneBook,
  filteredData,
  // handleDoneForwardDialog,
  selectForwardIndex,
  setSelectForwardIndex,
  handleDoneSendMessageTextDialog,
  selectedOption,
  setSelectedOption,
  handleOpenNotificationAdminDialog,
  handleCloseNotificationAdminDialog,
  openNotificationAdminDialog,
  handleChangePermissionGroup,
  handleRemoveUser,
}) {
  const { t, i18n } = useTranslation();
  const { vID } = useParams();
  const [members, setMember] = useState([]);
  const userListRef = useRef();
  const [contextMenu, setContextMenu] = useState(null);
  const [showChatActionSheet, setShowChatActionSheet] = useState(false);
  const [information, setInformation] = useState({});
  const [openPopupMessageNew, setOpenPopupMessageNew] = useState(false);
  const [openNewMessagseDialog, setOpenNewMessagseDialog] = useState(false);
  const [openNewGroup, setOpenNewGroup] = useState(false);
  const [openNewChannelTv, setOpenNewChannelTv] = useState(false);
  const [openNotificationPhoneBook, setOpenNotificationPhoneBook] = useState(false);
  const [openSettingDialog, setOpenSettingDialog] = useState(false);
  const [openInformationDialog, setOpenInformationDialog] = useState(false);
  const [openConfimLogout, setOpenConfimLogout] = useState(false);
  const [openChossePrivate, setOpenChossePrivate] = useState(false);
  const [openSendMessage, setOpenSendMessage] = useState(false);
  const [selectedBoxTabSetting, setSelectedBoxTabSetting] = useState(null);
  const [selectedBoxPhoneBook, setSelectedBoxPhoneBook] = useState(null);
  const [lang, setLang] = useState(getLanguage() || "vi");
  const containerRef = useRef();
  const [value, setValue] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [searchGolfId, setSearchGolf] = useState("");
  const textInputRef = useRef(null);
  const [active, setActive] = useState("");

  useEffect(() => {
    const slider = document.querySelector(".container");
    let isDown = false;
    let startX;
    let scrollLeft;

    if (slider) {
      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 2;
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }, [members]);

  const isUserInMembers = members.some((member) => member.id === userInformation.id);

  let updatedMembers;

  if (isUserInMembers) {
    // Nếu userInformation tồn tại trong members, loại bỏ nó
    updatedMembers = members.filter((member) => member.id !== userInformation.id);
  } else {
    // Nếu userInformation không tồn tại trong members, giữ nguyên danh sách
    updatedMembers = members;
  }
  const groupDetailMutate = useMutation("detail", (id) => groupChatDetail(id), {
    onSuccess: async (res) => {
      if (res.error !== 0) return;
      const idbGroup = await GroupIDB.get(res?.group?.id, "_id");
      await GroupIDB.put({
        ...(idbGroup || {}),
        _id: res?.group?.id,
        user_id: userInformation?.id,
        detail: encrypt(res?.group),
      });
    },
  });

  const handleModifyMember = async (user, state) => {
    let member = {};
    if (state) {
      if (isEmpty(user.id)) {
        const group = (await groupDetailMutate.mutateAsync(user.group_id))?.group?.users?.filter(
          (v) => v.id !== userInformation.id
        )[0];
        addGroupChat.refetchRecent();
        if (updatedMembers.map((v) => v.id).includes(group.id)) member = null;
        else
          member = {
            id: group.id,
            avatar: group.avatar,
            username: group.username,
            group_id: user.group_id,
          };
      } else {
        listGroupChat.forEach(async (v) => {
          if (v.group.name === user.username && isEmpty(v.detail)) {
            await groupDetailMutate.mutateAsync(v._id);
            addGroupChat.refetchRecent();
          }
        });
        member = {
          id: user.id,
          avatar: user.avatar,
          username: user.username,
        };
      }
      if (!isEmpty(member)) setMember((prev) => [...prev, member]);
    } else {
      const removeMember = updatedMembers.filter((v) => v.id !== user.id);
      setMember(removeMember);
    }
  };
  const handleContextMenu = (event, element) => {
    event.preventDefault();
    setContextMenu({
      element,
      x: event.clientX,
      y: event.clientY > 600 ? event.clientY - 180 : event.clientY,
    });
    setShowChatActionSheet(true);
    setInformation(element);
  };
  const closeContextMenu = () => {
    setContextMenu(null);
    setShowChatActionSheet(false);
  };

  const handleOpenPopup = () => {
    setOpenPopupMessageNew(true);
  };
  const handleClosePopup = () => {
    setOpenPopupMessageNew(false);
  };
  const handleOpenNewGroupDialog = () => {
    setOpenNewGroup(true);
  };
  const handleOpenNewChannelTvDialog = () => {
    setOpenNewChannelTv(true);
  };
  const handleCloseNewChannelTvDialog = () => {
    setOpenNewChannelTv(false);
  };
  const handleCloseNewGroupDialog = () => {
    setOpenNewGroup(false);
  };
  const handleOpenNewMessagseDialog = () => {
    setOpenNewMessagseDialog(true);
  };
  const handleCloseNewMessagseDialog = () => {
    setOpenNewMessagseDialog(false);
  };
  const handleOpenNotificationPhoneBook = () => {
    setOpenNotificationPhoneBook(true);
  };
  const handleCloseNotificationPhoneBook = () => {
    setOpenNotificationPhoneBook(false);
  };
  const handleOpenSendMessage = () => {
    setSearchUser("");
    setOpenSendMessage(true);
  };
  const handleCloseSendMessage = () => {
    setSearchUser("");
    setOpenSendMessage(false);
  };
  const handleCloseSettingDialog = () => {
    setOpenSettingDialog(false);
  };

  const handleCloseInformationDialog = () => {
    setOpenInformationDialog(false);
  };
  const handleSearchUserPhoneBook = (event) => {
    const userPhoneBook = event.target.value;
    setSearchPhoneBook(userPhoneBook);
  };
  const handleOpenConfirmLogout = () => {
    setOpenConfimLogout(true);
  };
  const handleCloseConfirmLogout = () => {
    setOpenConfimLogout(false);
  };

  const handleOpenChossePrivateDialog = () => {
    setOpenChossePrivate(true);
  };
  const handleCloseChossePrivateDialog = () => {
    setOpenChossePrivate(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const clearSearch = () => {
    setSearchUser("");
    setSearchGolf("");
  };

  const tabList = [
    { id: 1, label: t("chat.user_list.all") },
    { id: 2, label: "Golfer" },
  ];
  const dataToRender = searchPhoneBook ? filteredData : listDataPhoneBook;
  const filteredDataWithVgaIds =
    dataToRender && dataToRender.filter((item) => item?.vga_ids && item.vga_ids.length > 0);
  const usersWithVgaIds = filteredDataWithVgaIds.slice(0, 25);

  const handleClickPhoneBook = (item) => {
    if (item.vga_ids && item.vga_ids.length > 0) {
      // If vga_ids is not empty, handle addSingleChatUserPhoneBook
      addGroupChat.handleAddSingleChatUserPhoneBook({
        ...item,
        isClick: true,
      });
    } else {
      // If vga_ids is empty, handle open notification
      handleOpenNotificationPhoneBook();
    }
  };
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    switch (option) {
      case 0:
        conversationOptions.handleOpenMessage();
        clearSearch();
        break;
      case 1:
        conversationOptions.handleOpenPhoneBook();
        setSearchPhoneBook("");
        break;
      case 2:
        conversationOptions.handleOpenSetting();
        break;
      default:
        conversationOptions.handleOpenMessage();
        break;
    }
  };
  useEffect(() => {
    handleOptionClick(0);
  }, []);

  const handleBoxClickTabSetting = (boxIndex) => {
    setSelectedBoxTabSetting((prevSelectedBox) => (prevSelectedBox === boxIndex ? null : boxIndex));
  };
  const handleBoxClickBoxPhoneBook = (boxIndex) => {
    setSelectedBoxPhoneBook((prevSelectedBox) => (prevSelectedBox === boxIndex ? null : boxIndex));
  };
  const handleChangeLang = (event) => {
    setLang(event.target.value);
    i18n.changeLanguage(event.target.value);
    setLanguage(event.target.value);
  };
  const handleTextFieldClick = () => {
    setIsClicked(true);
  };

  const handleTextFieldBlur = () => {
    setIsClicked(false);
  };
  const handleClearTextUserList = () => {
    setSearchUser("");
    setSearchGolf("");
    setSearchPhoneBook("");
    textInputRef.current.focus();
    setIsClicked(false);
  };

  moment.locale("vi");
  if (isLoading) return <LoadingUserList />;
  return (
    <Box ref={userListRef}>
      <PersonalPopper
        userInformation={userInformation}
        anchor={personalOptions.personalAnchor}
        handleClose={personalOptions.handleClosePersonalPopper}
        isOpenPopper={personalOptions.isPersonalPopperOpen}
        handleLogout={personalOptions.handleLogout}
        handleOpenSettingDialog={dialogOptions.handleOpenSettingDialog}
        handleOpenInformationDialog={dialogOptions.handleOpenInformationDialog}
      />
      <PopupMessageNew
        personalAnchor={containerRef.current}
        handleClose={handleClosePopup}
        isOpenPopper={openPopupMessageNew}
        handleOpenNewGroupDialog={handleOpenNewGroupDialog}
        handleOpenNewChannelTvDialog={handleOpenNewChannelTvDialog}
        handleOpenNewMessagseDialog={handleOpenNewMessagseDialog}
        handleOpenSendMessage={handleOpenSendMessage}
      />
      <NewGroupDialog
        onClose={handleCloseNewGroupDialog}
        isOpen={openNewGroup}
        addGroupChat={addGroupChat}
        listGroupAdd={listGroupAdd}
        handleModifyMember={handleModifyMember}
        members={members}
        setMember={setMember}
      />
      <NewChannelTvDialog onClose={handleCloseNewChannelTvDialog} isOpen={openNewChannelTv} />
      <NewMessagseDialog
        onClose={handleCloseNewMessagseDialog}
        isOpen={openNewMessagseDialog}
        addGroupChat={addGroupChat}
        listGroupAdd={listGroupAdd}
        members={members}
        handleOpenMessage={() => handleOptionClick(0)}
      />
      <SendMessageDialog
        userInformation={userInformation}
        onClose={handleCloseSendMessage}
        isOpen={openSendMessage}
        selectForwardIndex={selectForwardIndex}
        setSelectForwardIndex={setSelectForwardIndex}
        handleDoneSendMessageTextDialog={handleDoneSendMessageTextDialog}
        listGroupChat={listGroupChat}
      />
      <SettingDialog isOpen={openSettingDialog} onClose={handleCloseSettingDialog} />
      <InformationDialog
        isOpen={openInformationDialog}
        onClose={handleCloseInformationDialog}
        userInformation={userInformation}
      />
      <Drawer sx={styles.drawer} variant="permanent">
        <Grid container spacing={0} style={{ height: "100vh" }}>
          <Grid item xs={2} style={{ height: "100%" }}>
            <Box sx={styles.menu}>
              <Box sx={styles.boxHeader}>
                <Box sx={styles.personalContainer1}>
                  <Badge
                    onClick={personalOptions.handleOpenPersonalPopper}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent=""
                    color="success"
                    sx={styles.avatarContainer}
                  >
                    <Avatar src={userInformation.avatar} sx={styles.avatar} />
                  </Badge>
                </Box>
                <Box
                  sx={{
                    ...styles.listMessagse,
                    backgroundColor: selectedOption === 0 ? "#406EE2" : "transparent",
                  }}
                  onClick={() => handleOptionClick(0)}
                >
                  {selectedOption === 0 ? <MessageIcon1 /> : <MessageIcon2 />}
                </Box>
                <Box
                  sx={{
                    ...styles.phonebook,
                    backgroundColor: selectedOption === 1 ? "#406EE2" : "transparent",
                  }}
                  onClick={() => handleOptionClick(1)}
                >
                  {selectedOption === 1 ? <PhoneBookIcon2 /> : <PhoneBookIcon1 />}
                </Box>
                <Box
                  sx={{
                    ...styles.setting,
                    backgroundColor: selectedOption === 2 ? "#406EE2" : "transparent",
                  }}
                  onClick={() => handleOptionClick(2)}
                >
                  {selectedOption === 2 ? <SettingUserIcon2 /> : <SettingUserIcon1 />}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={10} style={{ height: "100%" }}>
            {conversationOptions.isAddConversation === 0 && (
              <>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "20px",
                    marginRight: "20px",
                    marginTop: "20px",
                  }}
                >
                  <Typography sx={styles.messagseTile}>{t("chat.user_list.message")}</Typography>
                  <PopupIcon
                    style={styles.settingIcon}
                    onClick={() => {
                      handleOpenPopup();
                    }}
                    ref={containerRef}
                  />
                </Box>
                <Box sx={styles.backIconContainer}>
                  <TextField
                    placeholder={t("chat.user_list.search")}
                    value={searchGolfId}
                    onChange={(e) => {
                      setSearchGolf(e.target.value);
                      searchOptions.handleSearchUserList(e);
                    }}
                    onClick={handleTextFieldClick}
                    onBlur={handleTextFieldBlur}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment sx={styles.searchIcon} position="start">
                          <SearchIcon style={styles.searchIcon} />
                        </InputAdornment>
                      ),
                      endAdornment: searchGolfId ? (
                        <InputAdornment
                          position="end"
                          style={{ cursor: "pointer" }}
                          onClick={handleClearTextUserList}
                        >
                          <CloseText />
                        </InputAdornment>
                      ) : null,
                      autoComplete: "off",
                    }}
                    sx={styles.search({ isClicked })}
                    inputRef={textInputRef}
                  />
                </Box>
                <Box sx={styles.userList}>
                  {listGroupChat?.map((element) => (
                    <Box
                      sx={styles.userContainer}
                      key={element.id}
                      onContextMenu={(event) => handleContextMenu(event, element)}
                    >
                      <UserItem
                        onSelect={() => {
                          setActive("");
                          selectOptions.handleSelect(element?.group);
                        }}
                        isSelected={+decodeID(vID) === element?.group?.id}
                        mute={element?.group?.is_mute}
                        information={{
                          ...element,
                          time: t("dates.ago", {
                            date: moment(element?.last_message?.createdAt).format(
                              "YYYY-MM-DD HH:mm:ss"
                            ),
                          }),
                        }}
                        userInformation={userInformation}
                        // nameGroup={nameGroup}
                      />
                    </Box>
                  ))}
                  {showChatActionSheet && contextMenu && (
                    <ChatActionMenuGroup
                      setMember={setMember}
                      showChatActionSheet
                      openSetting
                      contextMenu={contextMenu}
                      anchor={userListRef.current}
                      handleClose={closeContextMenu}
                      handleOpenNewGroupDialog={handleOpenNewGroupDialog}
                      idGroup={information?.id}
                      information={information}
                      handleLeaveAndRemoveGroupChatMenu={handleLeaveAndRemoveGroupChatMenu}
                      handleRemoveGroupChatMenu={handleRemoveGroupChatMenu}
                      handleMuteGroupChat={() => handleMuteGroupChat(information?.id)}
                      isAdmin={information?.admin_ids?.includes(userInformation?.id)}
                      openNotificationAdminDialog={openNotificationAdminDialog}
                      handleOpenNotificationAdminDialog={handleOpenNotificationAdminDialog}
                      handleCloseNotificationAdminDialog={handleCloseNotificationAdminDialog}
                      handleChangePermissionGroup={handleChangePermissionGroup}
                      handleRemoveUser={handleRemoveUser}
                    />
                  )}

                  {searchGolfId && (
                    <ListSearchGolfer
                      searchId={searchGolfId}
                      setActive={setActive}
                      active={active}
                      handleAddSingleChat={addGroupChat.handleAddSingleChat}
                    />
                  )}
                </Box>
              </>
            )}
            {conversationOptions.isAddConversation === 1 && (
              <Fade
                in={conversationOptions.isAddConversation}
                unmountOnExit
                style={{ height: "100%" }}
              >
                <Box>
                  <Typography sx={styles.messagseTileBook}>
                    {t("chat.user_list.phone_book")}
                  </Typography>
                  <Box sx={styles.backIconContainer}>
                    <TextField
                      placeholder={t("chat.user_list.search")}
                      value={searchPhoneBook}
                      onChange={handleSearchUserPhoneBook}
                      onClick={handleTextFieldClick}
                      onBlur={handleTextFieldBlur}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment sx={styles.searchIcon} position="start">
                            <SearchIcon style={styles.searchIcon} />
                          </InputAdornment>
                        ),
                        endAdornment: searchPhoneBook ? (
                          <InputAdornment
                            position="end"
                            style={{ cursor: "pointer" }}
                            onClick={handleClearTextUserList}
                          >
                            <CloseText />
                          </InputAdornment>
                        ) : null,
                        autoComplete: "off",
                      }}
                      sx={styles.search({ isClicked })}
                      inputRef={textInputRef}
                    />
                  </Box>
                  <Box>
                    <Tabs
                      value={value}
                      variant="fullWidth"
                      onChange={handleChange}
                      TabIndicatorProps={{
                        style: { transition: "none", boxShadow: "none", borderRadius: "100px" },
                      }}
                      sx={styles.tabList}
                    >
                      {tabList.map((tab, i) => (
                        <Tab
                          key={tab.id}
                          label={
                            <Typography
                              variant="body2"
                              sx={value === i ? styles.textActive : styles.textDefault}
                            >
                              {tab.label}
                            </Typography>
                          }
                          sx={() => styles.tab(value, i)}
                        />
                      ))}
                    </Tabs>
                  </Box>
                  <Box sx={styles.userListAdd}>
                    <TabPanel value={value} sx={styles.tabPanel} index={0}>
                      <Box sx={styles.listUserContainer}>
                        {dataToRender?.map((item, index) => (
                          <Box
                            key={index}
                            sx={(theme) =>
                              styles.singleContainer({
                                selectedBox: selectedBoxPhoneBook,
                                boxIndex: index,
                                theme,
                              })
                            }
                            onClick={() => {
                              handleBoxClickBoxPhoneBook(index);
                              handleClickPhoneBook(item);
                            }}
                          >
                            {
                              listGroupChat.filter((item1) => item1.id === +decodeID(vID))
                                .users?.[0]
                            }
                            <Avatar
                              sx={styles.singleAvatar}
                              src={defaultAvatarUser}
                              srcSet={item?.avatar}
                            />
                            <Box sx={styles.singleNameContainer}>
                              <Typography
                                variant="body2"
                                color="initial"
                                noWrap
                                sx={styles.username}
                              >
                                {(() => {
                                  if (item?.name.includes(" ")) {
                                    return item?.name;
                                  }
                                  if (item?.name.length > 13) {
                                    return `${item?.name.substring(0, 13)}...`;
                                  }
                                  return item?.name;
                                })()}
                              </Typography>
                              <Typography variant="body2" color="initial" sx={styles.vid}>
                                {item?.phone_number}
                              </Typography>
                            </Box>

                            {item.vga_ids.length > 0 && (
                              <Box style={{ float: "right" }}>
                                <GolferIcon />
                              </Box>
                            )}
                          </Box>
                        ))}
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} sx={styles.tabPanel} index={1}>
                      <Box sx={styles.listUserContainer}>
                        {usersWithVgaIds?.map((item, index) => (
                          <Box
                            key={index}
                            sx={(theme) =>
                              styles.singleContainer({
                                selectedBox: selectedBoxPhoneBook,
                                boxIndex: index,
                                theme,
                              })
                            }
                            onClick={() => {
                              handleBoxClickBoxPhoneBook(index);
                              addGroupChat.handleAddSingleChatUserPhoneBook({
                                ...item,
                                isClick: true,
                              });
                            }}
                          >
                            <Avatar
                              sx={styles.singleAvatar}
                              src={defaultAvatarUser}
                              srcSet={item?.avatar}
                            />
                            <Box sx={styles.singleNameContainer}>
                              <Typography
                                variant="body2"
                                color="initial"
                                noWrap
                                sx={styles.username}
                              >
                                {(() => {
                                  if (item?.name.includes(" ")) {
                                    return item?.name;
                                  }
                                  if (item?.name.length > 13) {
                                    return `${item?.name.substring(0, 13)}...`;
                                  }
                                  return item?.name;
                                })()}
                              </Typography>
                              <Typography variant="body2" color="initial" sx={styles.vid}>
                                {item?.phone_number}
                              </Typography>
                            </Box>
                            <Box style={{ float: "right" }}>
                              <GolferIcon />
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </TabPanel>
                    <NotificationPhoneBook
                      open={openNotificationPhoneBook}
                      handleClose={handleCloseNotificationPhoneBook}
                      style={{ borderRadius: "20px !important" }}
                    />
                  </Box>
                </Box>
              </Fade>
            )}
            {conversationOptions.isAddConversation === 2 && (
              <Box>
                <Typography sx={styles.messagseTileBook}>{t("chat.user_list.setting")}</Typography>
                <Box sx={styles.avatarContainerSetting}>
                  <Badge
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent=""
                    color="success"
                    sx={styles.avatarContainer}
                  >
                    <Avatar
                      src={defaultAvatar}
                      srcSet={userInformation.avatar}
                      sx={styles.avatar}
                    />
                  </Badge>
                  <Box style={{ marginLeft: "10px" }}>
                    <Typography variant="body1" color="initial" sx={styles.nameSetting}>
                      {userInformation?.username}
                    </Typography>
                    <Typography variant="body2" sx={styles.vgaSetting}>
                      vID: {userInformation?.id}
                    </Typography>
                  </Box>
                </Box>
                <Box style={{ marginTop: "30px", marginLeft: "20px", marginRight: "20px" }}>
                  <Box
                    sx={styles.personalContainer({ selectedBoxTabSetting, boxIndex: 0 })}
                    onClick={() => {
                      handleBoxClickTabSetting(0);
                      handleOpenChossePrivateDialog();
                    }}
                  >
                    <Box style={{ display: "flex" }}>
                      <Box style={{ lineHeight: "50px", marginRight: "15px", marginTop: "6px" }}>
                        <LockIcon />
                      </Box>
                      <Typography variant="body1" color="initial" sx={styles.personalTitle}>
                        {t("chat.setting.privacy")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={styles.personalContainer({ selectedBoxTabSetting, boxIndex: 1 })}
                    onClick={() => handleBoxClickTabSetting(1)}
                  >
                    <Box style={{ display: "flex" }}>
                      <Box style={{ lineHeight: "50px", marginRight: "10px", marginTop: "7px" }}>
                        <SoundIcon />
                      </Box>
                      <Typography variant="body1" color="initial" sx={styles.personalTitle}>
                        {t("chat.setting.sound")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={styles.personalContainer({ selectedBoxTabSetting, boxIndex: 2 })}
                    onClick={() => handleBoxClickTabSetting(2)}
                  >
                    <Box style={{ display: "flex" }}>
                      <Box style={{ lineHeight: "50px", marginRight: "15px", marginTop: "5px" }}>
                        <NotificationIcon />
                      </Box>
                      <Typography variant="body1" color="initial" sx={styles.personalTitle}>
                        {t("chat.setting.notification")}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={styles.languageContainer}>
                    <FormControl sx={styles.formSelect}>
                      <Select
                        value={lang}
                        sx={styles.select}
                        MenuProps={styles.selectLang}
                        onChange={handleChangeLang}
                      >
                        <MenuItem sx={styles.menuItemLang} value="vi">
                          <Box sx={styles.boxLang}>
                            <Box sx={styles.lang}>
                              <IconButton sx={styles.iconButton}>
                                <VNIcon />
                              </IconButton>
                              <Typography sx={styles.nameLang}>Tiếng Việt</Typography>
                            </Box>
                          </Box>
                        </MenuItem>
                        <MenuItem sx={styles.menuItemLang} value="en">
                          <Box sx={styles.boxLang}>
                            <Box sx={styles.lang}>
                              <IconButton sx={styles.iconButton}>
                                <ENIcon />
                              </IconButton>
                              <Typography sx={styles.nameLang}>English</Typography>
                            </Box>
                          </Box>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Divider />
                  <Box
                    sx={styles.personalContainer({ selectedBoxTabSetting, boxIndex: 4 })}
                    onClick={() => {
                      handleBoxClickTabSetting(4);
                      handleOpenConfirmLogout();
                    }}
                  >
                    <Box style={{ display: "flex" }}>
                      <Box style={{ lineHeight: "50px", marginRight: "15px", marginTop: "5px" }}>
                        <LogoutIcon />
                      </Box>
                      <Typography variant="body1" color="initial" sx={styles.personalTitle}>
                        {t("chat.user_list.personal.sign_out")}
                      </Typography>
                    </Box>
                  </Box>
                  <ConfimLogoutDialog
                    open={openConfimLogout}
                    handleClose={handleCloseConfirmLogout}
                    handleLogout={personalOptions.handleLogout}
                  />
                  <ChossePrivateDialog
                    open={openChossePrivate}
                    handleClose={handleCloseChossePrivateDialog}
                  />
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Drawer>
    </Box>
  );
}

UserList.defaultProps = {
  selectOptions: {
    handleSelect: emptyFunction,
    selectId: null,
  },
  personalOptions: {
    isPersonalPopperOpen: false,
    handleOpenPersonalPopper: emptyFunction,
    handleClosePersonalPopper: emptyFunction,
  },
  searchOptions: {
    handleSearchUserList: emptyFunction,
  },
  dialogOptions: {
    handleOpenSettingDialog: emptyFunction,
    handleOpenInformationDialog: emptyFunction,
  },
  conversationOptions: {
    isAddConversation: false,
    handleOpenConversation: emptyFunction,
    handleCloseConversation: emptyFunction,
  },
  addGroupChat: {
    handleSearchUser: emptyFunction,
  },
};

UserList.propTypes = {
  selectOptions: PropTypes.shape({
    handleSelect: PropTypes.func,
    selectId: PropTypes.any,
  }),
  searchOptions: PropTypes.shape({
    handleSearchUserList: PropTypes.func,
  }),
  personalOptions: PropTypes.shape({
    isPersonalPopperOpen: PropTypes.bool,
    handleOpenPersonalPopper: PropTypes.func,
    personalAnchor: PropTypes.any,
    handleClosePersonalPopper: PropTypes.func,
    handleLogout: PropTypes.func,
  }),
  dialogOptions: PropTypes.shape({
    handleOpenSettingDialog: PropTypes.func,
    handleOpenInformationDialog: PropTypes.func,
  }),
  conversationOptions: PropTypes.shape({
    isAddConversation: PropTypes.bool,
    handleOpenConversation: PropTypes.func,
    handleCloseConversation: PropTypes.func,
  }),
  addGroupChat: PropTypes.shape({
    handleSearchUser: PropTypes.func,
  }),
};

export default memo(UserList);
