import { Avatar, Box, Grow, Typography, Badge } from "@mui/material";
import { memo } from "react";
import { ReactComponent as MuteIcon } from "assets/svg/icon/mute.svg";
import { ReactComponent as ChannelTvIcon } from "assets/svg/icon/chanel_icon.svg";
import { ReactComponent as ClubIcon } from "assets/svg/icon/club.svg";
import { ReactComponent as GroupIcon } from "assets/svg/icon/group.svg";
import { ReactComponent as GroupPrivateIcon } from "assets/svg/icon/private.svg";
import { ReactComponent as DraftIcon } from "assets/svg/icon/draft_icon.svg";
import defaultAvatar from "assets/svg/icon/default_avatar_group.svg";
import styles from "assets/styles/pages/Chat/component/UserItem";
import PropTypes from "prop-types";
import { emptyFunction } from "utils/utils";
import { isEmpty } from "utils/validate";
import { useTranslation } from "react-i18next";

function UserItem({ information, mute, onSelect, isSelected, userInformation }) {
  const { t } = useTranslation();
  const name = information?.detail?.name || information?.group?.name;
  const inputString = information?.time;
  const secondSpaceIndex = inputString.indexOf(" ", inputString.indexOf(" ") + 1);
  // Kiểm tra xem có dấu cách thứ hai không
  let resultString;
  if (secondSpaceIndex !== -1) {
    resultString = inputString.substring(0, secondSpaceIndex);
  }
  return (
    <Box
      onClick={onSelect}
      sx={(theme) => ({
        ...styles.container(theme, { isSelected }),
        position: "relative",
        "&:hover": {
          backgroundColor: "#E5F0FF",
          ".hidden-content": {
            display: "none",
          },
        },
      })}
    >
      <Box sx={styles.informationContainer}>
        <Box sx={styles.avatarContainer}>
          {information?.group?.type === "single" &&
          information?.detail?.users?.find((e) => +e.id !== +userInformation.id)?.last_state ===
            "online" ? (
            <Badge
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent=""
              color="success"
              sx={styles.avatarUser}
            >
              <Avatar
                srcSet={information?.detail?.image || information?.group?.image}
                sx={styles.avatar}
                src={defaultAvatar}
              />
            </Badge>
          ) : (
            <Avatar
              srcSet={information?.detail?.image || information?.group?.image}
              sx={styles.avatar}
              src={defaultAvatar}
            />
          )}
        </Box>
        <Box sx={styles.information}>
          <Box sx={styles.infoContainer}>
            <Box sx={styles.nameContainer}>
              {information.channel && (
                <Box sx={{ mr: 1 }}>
                  <ChannelTvIcon />
                </Box>
              )}
              {information?.is_club === 1 && (
                <Box sx={{ mr: 1 }}>
                  <ClubIcon />
                </Box>
              )}
              {information?.type === "group" &&
                information?.is_club === 0 &&
                information?.channel !== "livechat" &&
                information?.is_private === 0 && (
                  <Box sx={{ mr: 1 }}>
                    <GroupIcon />
                  </Box>
                )}
              {information?.is_private === 1 && (
                <Box sx={{ mr: 1 }}>
                  <GroupPrivateIcon />
                </Box>
              )}
              <Typography variant="body1" color="initial" sx={styles.name}>
                {name}
              </Typography>
              <Grow
                in={!isEmpty(mute) && Number(mute) === 1}
                unmountOnExit
                className="hidden-content"
              >
                <Box style={styles.muteIcon}>
                  <MuteIcon />
                </Box>
              </Grow>
            </Box>
            <Typography
              variant="body2"
              sx={(e) => styles.time(e, { isUnread: information?.last_message?.isUnread })}
              className="hidden-content"
            >
              {resultString}
            </Typography>
          </Box>
          <Box sx={styles.lastMessageContainer}>
            <Box sx={styles.lastMessage}>
              {information.draft_last_message && (
                <Box sx={{ display: "flex", mt: 0.5, mr: 0.5 }}>
                  <Box sx={{ mr: 1 }}>
                    <DraftIcon />
                  </Box>
                  <Typography variant="body2" sx={{ color: "red", fontSize: "12px" }}>
                    {t("chat.user_list.draft")}:
                  </Typography>
                </Box>
              )}
              {information?.type === "group" && !information.draft_last_message && (
                <Typography
                  variant="body2"
                  sx={(e) =>
                    styles.userSendMessage(e, { isUnread: information?.last_message?.isUnread })
                  }
                >
                  {information?.last_message?.user?.username} :
                </Typography>
              )}
              <Typography
                variant="body2"
                sx={(e) =>
                  styles.message(
                    e,
                    { isUnread: information?.last_message?.isUnread },
                    { isSelected }
                  )
                }
              >
                {information.draft_last_message
                  ? information.draft_last_message
                  : information.last_message.text || "..."}
              </Typography>
            </Box>
            <Badge sx={styles.badgeUnread} badgeContent={information.unreadCount} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

UserItem.defaultProps = {
  information: {
    avatar: "",
    name: "",
    message: "",
    time: "",
  },
  mute: null,
  onSelect: emptyFunction,
  isSelected: false,
};

UserItem.propTypes = {
  information: PropTypes.shape({
    avatar: PropTypes.any,
    name: PropTypes.string,
    message: PropTypes.string,
    time: PropTypes.string,
  }),
  mute: PropTypes.string,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default memo(UserItem);
