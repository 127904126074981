import {
  informationWidth,
  lgInformationWidth,
  xlInformationWidth,
  xxlInformationWidth,
} from "constant/chat";

export default {
  drawer: ({ transitions, breakpoints }, { isOpenSetting }) => ({
    ...(!isOpenSetting && {
      width: 0,
      "& .MuiDrawer-paper": {
        width: 0,
      },
      transition: transitions.create("width", {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
    }),

    ...(isOpenSetting && {
      width: informationWidth,
      "& .MuiDrawer-paper": {
        width: informationWidth,
      },
      transition: transitions.create(["margin", "width"], {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
      [breakpoints.down("xxl")]: {
        width: xxlInformationWidth,
        "& .MuiDrawer-paper": {
          width: xxlInformationWidth,
        },
      },
      [breakpoints.down("xl")]: {
        width: xlInformationWidth,
        "& .MuiDrawer-paper": {
          width: xlInformationWidth,
        },
      },
      [breakpoints.down("lg")]: {
        width: lgInformationWidth,
        "& .MuiDrawer-paper": {
          width: lgInformationWidth,
        },
      },
      [breakpoints.down("md")]: {
        width: "100%",
        "& .MuiDrawer-paper": {
          width: "100%",
        },
      },
    }),
    height: "100%",
  }),
  dialog: ({ breakpoints, borders }) => ({
    backdropFilter: "blur(4px)", // Add this line to create a blurred backdrop
    backgroundColor: "rgba(0, 0, 0, 0.10)",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.lg,
        width: "100%",
        height: "100%",
        // maxHeight: "600px",
        maxWidth: "450px",
        [breakpoints.down("lg")]: {
          maxWidth: "90vw",
        },
      },
    },
  }),
  selectOptionBox: {
    width: "100px",
    height: "30px",
    borderRadius: "50px",
    marginRight: "15px",
    cursor: "pointer",
    lineHeight: "40px",
  },
  textSelectOptions: {
    fontSize: "14px",
    lineHeight: "30px",
    textAlign: "center",
  },
  dFlexNotJust: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
  },
  dFlexNotJust1: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    height: "45px",
    // marginTop: "5px",
  },
  boxUser: {
    // justifyContent: "space-between",
    "&:hover": {
      background: "#E5F0FF",
      borderRadius: "10px",
    },
  },
  boxUserSelect: {
    // justifyContent: "space-between",
    background: "#E5F0FF",
    borderRadius: "10px",
  },
  fLexCol: { display: "flex", flexDirection: "column", height: "73px" },
  arrowIcon: { fill: "#090710", height: "14px", marginRight: "-20px" },
  dialogTitle: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    m: 2,
  },
  title: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    // textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  }),
  title1: {
    fontWeight: 600,
    lineHeight: "40px",
    fontSize: "14px",
  },
  title2: {
    fontWeight: 600,
    // lineHeight: "40px",
    marginBottom: "10px",
    fontSize: "14px",
  },
  overflowY: ({ breakpoints }) => ({
    pl: 0.5,
    ml: 2,
    marginRight: "15px",
    [breakpoints.between("md", "lg")]: {
      ml: 0,
    },
    [breakpoints.down("sm")]: {
      ml: 0,
    },
  }),
  avatarImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
    "&>img": {
      height: "44px",
    },
    border: "0.1px solid lightgray",
  },
  divider: {
    my: 0,
    backgroundColor: "#707070",
  },
  contentContainer: {
    height: "500px",
    maxHeight: "80%",
    minHeight: "20%",
    flexGrow: 1,
    display: "flex",
    borderRadius: 0,
    overflowY: "auto",
    // mb: 2,
    // ml: 2,
    mt: 1,
    flexDirection: "column",
  },
  tag: ({ borders }) => ({
    flexGrow: "0",
    flexShrink: "0",
    borderRadius: borders.borderRadius.round,
    padding: "4px 0px",
    display: "none",
  }),
  fullWidth: {
    width: "100%",
    height: "100%",
    overflow: "scroll",
  },
  listUserContainer: {
    height: "100%", // Sử dụng chiều cao 100% của parent container
    overflow: "auto ",
    paddingLeft: 2,
    paddingRight: 2,
    mt: 1,
  },
  singleContainer: {
    py: 1,
    px: 1,
    display: "flex",
    borderRadius: "5px",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    marginTop: "5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E5F0FF",
    },
  },
  checkContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    mr: 0,
    label: {
      width: "100%",
    },
    svg: {
      width: "18px !important",
      height: "18px !important",
    },
  },
  labelContainer: {
    // py: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
  },
  singleAvatar: {
    width: "40px",
    height: "40px",
    border: "0.1px solid lightgray",
    marginLeft: "10px",
  },
  singleNameContainer: {
    ml: 1.5,
    display: "flex",
  },
  username: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    lineHeight: "18px",
    height: "18px",
    whiteSpace: "normal", // Change to "normal" instead of "pre-wrap"
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  vid: ({ typography }) => ({
    fontFamily: typography.fontFamily,
    fontSize: "12px",
    fontWeight: 500,
  }),
  buttonRefuse: {
    height: "30px",
    marginRight: "10px",
    border: "1px solid #406EE2",
    borderRadius: "5px",
    width: "80px",
    cursor: "pointer",
  },
  buttonApproval: {
    height: "30px",
    marginRight: "10px",
    border: "1px solid #406EE2",
    borderRadius: "5px",
    width: "80px",
    backgroundColor: "#406EE2",
    cursor: "pointer",
  },
  textButtonRefuse: {
    color: "#406EE2",
    lineHeight: "28px",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 600,
  },
  textButtonApproval: {
    color: "#FFF",
    lineHeight: "28px",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 600,
  },
  buttonRefuse2: {
    height: "40px",
    marginRight: "10px",
    border: "1px solid #406EE2",
    borderRadius: "5px",
    width: "150px",
    cursor: "pointer",
  },
  buttonApproval2: {
    height: "40px",
    marginRight: "10px",
    border: "1px solid #406EE2",
    borderRadius: "5px",
    width: "150px",
    backgroundColor: "#406EE2",
    cursor: "pointer",
  },
  textButtonRefuse2: {
    color: "#406EE2",
    lineHeight: "40px",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 600,
  },
  textButtonApproval2: {
    color: "#FFF",
    lineHeight: "40px",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 600,
  },
  chipChoose: {
    color: "#406EE2",
    width: "auto",
    float: "right",
    lineHeight: "40px",
    marginRight: "20px",
  },
  chipChooseAll: {
    color: "#406EE2",
    width: "auto",
    float: "left",
    marginLeft: "21px",
    marginTop: "4px",
  },
};
