import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
// import Switch from "@mui/material/Switch";
import styles from "assets/styles/pages/Chat/component/MessageVote";
import defaultAvatar from "assets/svg/icon/default_avatar_group.svg";
import { ReactComponent as HeartVoteIcon } from "assets/svg/icon/heart-vote-icon.svg";
import { ReactComponent as PendingIcon } from "assets/svg/icon/pending.svg";
import { ReactComponent as SendIcon } from "assets/svg/icon/send.svg";
import { ReactComponent as SendedIcon } from "assets/svg/icon/sended.svg";
import { ReactComponent as UnVoteBlue } from "assets/svg/icon/unvote_blue.svg";
import { ReactComponent as ViewVoted } from "assets/svg/icon/view_voted.svg";
import { ReactComponent as ViewVotedWhite } from "assets/svg/icon/view_voted_white.svg";
import { ReactComponent as VoteBlue } from "assets/svg/icon/vote_blue.svg";
import { ReactComponent as LockIcon } from "assets/svg/icon/vote_lock.svg";
import { ReactComponent as OwnerLockIcon } from "assets/svg/icon/vote_owner_lock.svg";
import { ReactComponent as OwnerOutlinedHeart } from "assets/svg/icon/vote_owner_outlined_heart.svg";
import { CHAT_ACTIVE_TYPE, MESSAGE_STATE } from "constant/chat";
import { numberRegex } from "constant/regex";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatTimeSecond } from "utils/date";
import ReplyAndForwardAndDetail from "./ReplyAndForwardAndDetail";

function MessageVote({
  isOwner,
  value,
  handleChangeVote,
  handleOpenPopper,
  handleOpenVoteInfo,
  userInformation,
  totalMember,
  addGroupChat,
  handleOpenReplyMessage,
  handleOpenForwardDialog,
  statusMessage,
}) {
  const { t } = useTranslation();
  const [selectIndex, setSelectIndex] = useState(null);
  const getRateNumber = (list) => {
    let total = 0;
    if (Array.isArray(list)) {
      for (let index = 0; index < list.length; index += 1) {
        total += list[index].length;
      }
      return total;
    }
    return total;
  };
  const [ownerOneTimeSelect] = useState(false);
  const [isOneTimeSelect] = useState("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const getVoteRate = (vote) => {
    if (vote.length < 1) {
      return 0;
    }
    return (100 / totalMember) * vote.length;
  };

  const content = value?.vote?.content;
  const lines = content ? content.split("\n") : [];
  const formattedText = lines.map((line, index) => (
    <Box key={index}>
      {line}
      {index < lines.length - 1 && <br />}
    </Box>
  ));

  return (
    <Box sx={() => styles.container({ statusMessage })}>
      <Box
        sx={() => ({
          ...styles.section({ isOwner }),
          alignItems: "center",
          "&:hover": {
            "& .iconContainer": {
              display: "flex",
            },
          },
        })}
      >
        {!isOwner && !statusMessage && (
          <Avatar
            src={defaultAvatar}
            srcSet={value?.user?.avatar}
            sx={styles.avatar}
            onClick={() => addGroupChat.handleAddSingleChat({ ...value?.user, isClick: true })}
          />
        )}
        <Box sx={styles.messageSection}>
          <Box
            sx={(e) => styles.messageContainer(e, { isOwner, statusMessage })}
            className={`id-message-${value._id}`}
          >
            {value?.group?.type === "group" && !statusMessage && !isOwner && (
              <Box
                sx={styles.nameContainer}
                onClick={() => addGroupChat.handleAddSingleChat({ ...value?.user, isClick: true })}
              >
                <Typography
                  className="username"
                  color="initial"
                  variant="body1"
                  sx={styles.username}
                >
                  {value?.user?.username || value?.user?.user_name} - {value?.user?.id}
                </Typography>
              </Box>
            )}
            <Box
              sx={() =>
                styles.voteContainer({ username: value?.user?.username || value?.user?.user_name })
              }
            >
              <Typography variant="subtitle2" sx={(e) => styles.question(e, { isOwner })}>
                {formattedText}
              </Typography>
              {value?.vote?.options?.map((vote, index) => (
                <Box sx={styles.selectionContainer} key={index}>
                  <Box sx={(e) => styles.progressContainer(e, { isOwner })}>
                    <LinearProgress
                      variant="determinate"
                      value={getVoteRate(vote.voteUser)}
                      sx={(e) =>
                        vote.voteUser.length > 0
                          ? styles.progressUserVote(e, {
                              isOwner,
                            })
                          : styles.progress(e, {
                              isOwner,
                            })
                      }
                    />
                    <Typography variant="body1" sx={(e) => styles.selectionLabel(e, { isOwner })}>
                      {vote.content}
                    </Typography>

                    <Box sx={styles.iconContainer}>
                      {((!isOwner && isOneTimeSelect) || (isOwner && ownerOneTimeSelect)) &&
                      numberRegex.test(selectIndex) ? (
                        <IconButton disabled sx={styles.iconLock}>
                          {isOwner ? <OwnerLockIcon /> : <LockIcon />}
                        </IconButton>
                      ) : (
                        <>
                          {vote.voteUser.find((v) => v.id === userInformation?.id) && (
                            <IconButton
                              onClick={() => {
                                handleChangeVote(value, index, CHAT_ACTIVE_TYPE.unVote);
                                setSelectIndex(null);
                                setIsButtonClicked(!isButtonClicked);
                              }}
                              sx={styles.iconButton}
                            >
                              {isOwner ? <HeartVoteIcon /> : <VoteBlue />}
                            </IconButton>
                          )}
                          {!vote.voteUser.find((v) => v.id === userInformation?.id) && (
                            <IconButton
                              onClick={() => {
                                handleChangeVote(value, index, CHAT_ACTIVE_TYPE.vote);
                                setSelectIndex(index);
                                setIsButtonClicked(!isButtonClicked);
                              }}
                              sx={styles.iconButton}
                            >
                              {isOwner ? <OwnerOutlinedHeart /> : <UnVoteBlue />}
                            </IconButton>
                          )}
                        </>
                      )}
                      {/* <Typography variant="body2" sx={(e) => styles.selectNumber(e, { isOwner })}>
                          {voteUserLength}
                        </Typography> */}
                    </Box>
                    <Typography variant="body2" sx={styles.rateNumber({ isOwner })}>
                      {vote.voteUser.length}
                    </Typography>
                    <Typography
                      onClick={() => handleOpenVoteInfo(vote)}
                      variant="body2"
                      sx={styles.Voted({ isOwner })}
                    >
                      {isOwner ? <ViewVotedWhite /> : <ViewVoted />}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box sx={styles.messageTimeContainer}>
              {getRateNumber(value?.vote?.options?.map((item) => item.voteUser)) > 0 && (
                <Typography sx={(e) => styles.voteNumber(e, { isOwner })} variant="body2">
                  {getRateNumber(value?.vote?.options?.map((item) => item.voteUser))}{" "}
                  {t("chat.content_chat.message.vote")}
                </Typography>
              )}

              <Typography sx={(e) => styles.messageTime(e, { isOwner })} variant="body2">
                {formatTimeSecond(value?.createdAt)}
              </Typography>
              {isOwner && (
                <>
                  {value.state === MESSAGE_STATE.PENDING && (
                    <PendingIcon style={styles.messagePendingIcon} />
                  )}
                  {value.state === MESSAGE_STATE.SENDING && (
                    <SendIcon style={styles.messageSendedIcon} />
                  )}
                  {value.state === MESSAGE_STATE.SENDED && (
                    <SendedIcon style={styles.messageSendedIcon} />
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
        <ReplyAndForwardAndDetail
          value={value}
          handleOpenReplyMessage={handleOpenReplyMessage}
          handleOpenForwardDialog={handleOpenForwardDialog}
          handleOpenPopper={(e) => handleOpenPopper(e, isOwner, value)}
        />
      </Box>
    </Box>
  );
}

export default memo(MessageVote);
