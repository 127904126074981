export default {
  // ****************************************************
  // *                PRIMARY COLOR                     *
  // ****************************************************
  background: {
    default: "#FFFFFF",
    secondary: "#F1F1F1",
    message: "#DDF7F9",
    search: "#F3F3F3",
  },
  primary: {
    main: "#00ABA7",
    focus: "#00ABA7",
  },
  secondary: {
    main: "#007AFF",
    focus: "#007AFF",
  },
  light: {
    main: "#f0f2f5",
    focus: "#f0f2f5",
  },
  dark: {
    main: "#333",
    focus: "#333",
  },
  initial: {
    main: "#000000",
    focus: "#000000",
  },
  inherit: {
    main: "#3D3D3D",
    focus: "#3D3D3D",
  },
  text: {
    main: "#090710",
    focus: "#090710",
  },
  transparent: {
    main: "transparent",
    focus: "transparent",
  },
  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },
  black: {
    main: "#000000",
    focus: "#000000",
  },
  info: {
    main: "#007AFF",
    focus: "#007AFF",
    light: "#02A5C9",
  },
  success: {
    main: "#0AA749",
    focus: "#0AA749",
    light: "#00D060",
    dark: "#00908D",
  },
  warning: {
    main: "#fb8c00",
    focus: "#fc9d26",
    light: "#e9e6c4",
    dark: "#fb8c00",
  },
  error: {
    main: "#FF0000",
    focus: "#FF0000",
    light: "#EB5545",
    dark: "#DB0000",
  },
  grey: {
    100: "#F1F1F1",
    150: "#DDDDDD",
    200: "#D9D9D9",
    250: "#B7B7B7",
    300: "#ABABAB",
    350: "#9A9A9A",
    400: "#999999",
    450: "#959595",
    500: "#949494",
    550: "#8B8B8B",
    600: "#8C8C8C",
    650: "#8A8A8D",
    700: "#757575",
    750: "#727272",
    800: "#5E5E5E",
    850: "#545454",
    900: "#3D3D3D",
    950: "#273B4A",

    // SF Pro Text
  },
  // ****************************************************
  // *               GRADIENT COLOR                     *
  // ****************************************************
  gradients: {
    primary: {
      main: "#444",
      state: "#D81B60",
    },
    secondary: {
      main: "#FFC107",
      state: "#FFC107",
    },
    initial: {
      main: "#333",
      focus: "#333",
    },
    info: {
      main: "#49a3f1",
      state: "#1A73E8",
    },
    success: {
      main: "#66BB6A",
      state: "#43A047",
    },
    warning: {
      main: "#FFA726",
      state: "#FB8C00",
    },
    error: {
      main: "#EF5350",
      state: "#E53935",
    },
    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },
    dark: {
      main: "#42424a",
      state: "#191919",
    },
    white: {
      main: "#FFFFFF",
      state: "#FFFFFF",
    },
    grey: {
      main: "#919699",
      state: "#919699",
    },
  },
  // ****************************************************
  // *                 SOCIAL COLOR                     *
  // ****************************************************
  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },
    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },
    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },
    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },
    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },
    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },
    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },
    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },
    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },
    github: {
      main: "#24292e",
      dark: "#171a1d",
    },
    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },
    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },
  // ****************************************************
  // *                  BADGE COLOR                     *
  // ****************************************************
  badgeColors: {
    primary: {
      background: "#444",
      text: "#cc084b",
    },
    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },
    info: {
      background: "#aecef7",
      text: "#095bc6",
    },
    success: {
      background: "#bce2be",
      text: "#339537",
    },
    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },
    error: {
      background: "#fcd3d0",
      text: "#f61200",
    },
    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },
    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },
  // ****************************************************
  // *                BOX SHADOW COLOR                  *
  // ****************************************************
  coloredShadows: {
    primary: "#e91e62",
    secondary: "#110e0e",
    info: "#00bbd4",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
  },
  // ****************************************************
  // *                  UTILITY COLOR                   *
  // ****************************************************
  borderColor: {
    main: "#CDCDCD",
  },
  button: {
    text: "#ffffff", // Text color on click
    active: "#404040", // Active color on click
  },
  inputBorderColor: {
    main: "#E0E0E0",
    focus: "#C1C1C1",
  },
  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};
