export default {
  containerFooter: {
    backgroundColor: "#EDF1F8",
  },
  container1: ({ breakpoints }, { openEditMessage, openReplyMessage }) => ({
    backgroundColor: "#FFF",
    boxShadow:
      !openEditMessage && !openReplyMessage ? "0px 4px 4px 0px rgba(0, 0, 0, 0.05)" : "none",
    marginTop: "10px",
    marginBottom: "20px",
    borderRadius: "10px",
    marginLeft: "24px",
    marginRight: "24px",
    [breakpoints.down("lg")]: {
      padding: "0 8px",
    },
  }),
  list: ({ borders: { borderRadius } }) => ({
    width: "100%",
    borderRadius: `${borderRadius.lg} !important`,
    minWidth: "100px",
  }),
  listItem: {
    display: "inline",
  },
  paper: {
    boxShadow: "none",
  },
  container: {
    backgroundColor: "#FFF",
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    borderRadius: "15px",
  },
  flexGrow: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  flexGrowResponsive: (images) => ({
    flexGrow: 1,
    display: "flex",
    flexDirection: images.length > 0 ? "column-reverse" : "column-reverse",
    alignItems: images.length > 0 ? "start" : "start",
    width: "100%",
    // marginBottom: "5px",
  }),
  flexBoxRight1: ({ breakpoints }, isVoice) => ({
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [breakpoints.down("lg")]: {
      flexDirection: "column-reverse",
      mr: 1.5,
    },
    [breakpoints.down("xl")]: {
      display: isVoice ? "none" : "flex",
    },
  }),
  flexBoxRight2: ({ breakpoints }, isVoice) => ({
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    [breakpoints.down("lg")]: {
      flexDirection: "column-reverse",
      mr: 1.5,
    },
    [breakpoints.down("xl")]: {
      display: isVoice ? "none" : "flex",
    },
  }),
  listIcon: ({ breakpoints }) => ({
    // paddingTop: "20px",
    marginBottom: "-15px",
    marginTop: "5px",
    [breakpoints.down("lg")]: {
      width: "100%",
      // marginBottom: "5px",
    },
  }),
  positionCenter: ({ breakpoints }) => ({
    width: "190px",
    [breakpoints.down("lg")]: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    [breakpoints.between("lg", "xxl")]: {
      width: "180px",
    },
  }),
  fillColorIconLeft: {
    fill: "#00ABA7",
    width: "24px",
    height: "24px",
  },
  fillColorIconRight: ({ isChangeText }) => ({
    background: "#406EE2  !important",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    float: "right",
    opacity: isChangeText ? 0.5 : 1,
    "&:hover": {
      background: "#406EE2 !important",
      width: "30px",
      height: "30px",
    },
  }),
  fillColorWhite: () => ({
    fill: "#FFFF",
    width: "20px",
    height: "20px",
    "&:hover": {
      background: "#406EE2 !important",
      width: "20px",
      height: "20px",
    },
  }),
  closeVoiceIcon: {
    px: 1.25,
    py: 0,
  },
  inputMessage: ({ breakpoints }) => ({
    width: "100%",
    position: "relative",
    // top: "12px",
    borderRadius: "100px",
    // mb: 3,

    fieldset: {
      borderRadius: "100px",
      border: "none",
      height: "50px",
    },
    input: {
      fontSize: "18px",
      py: 0,
      "&::placeholder": {
        fontWeight: 550,
      },
    },
    [breakpoints.down("lg")]: {
      position: "relative",
      top: "8px",
      mb: 1.5,
    },
  }),
  boxRight: ({ breakpoints }) => ({
    width: "190px",
    padding: "0px 10px",
    marginBottom: "15px",
    [breakpoints.down("lg")]: {
      width: "64px",
    },
    [breakpoints.between("lg", "xl")]: {
      width: "100px",
    },
    [breakpoints.down("md")]: {
      // flexGrow: 1,
    },
  }),
  flexEnd: ({ breakpoints }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    [breakpoints.down("md")]: {
      flexGrow: 1,
    },
  }),
  time: ({ typography, palette }) => ({
    margin: "0 10px 0 3px",
    fontSize: "16px",
    color: palette.grey[650],
    fontFamily: typography.fontFamily2,
  }),
  pasteInputBox: {
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "200px",
    overflowY: "auto",
    px: 1.5,
  },
  pasteInputImage: {
    height: "50px",
    width: "80px",
    objectFit: "cover",
    marginTop: "5px",
    position: "relative",
    border: "1px solid #F3F3F3",
    borderRadius: "10px",
  },
  pasteInputClose: {
    fill: "#8A8A8D",
    position: "relative",
    right: "25px",
    bottom: "35px",
    background: "white",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    opacity: 0.8,
    "&:hover": {
      background: "white",
      opacity: 1,
    },
  },
  iconW18: { width: "18px" },
  blockBox: {
    background: "#DB4040",
    py: 1,
  },
  blockTitle: ({ palette }) => ({
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: palette.white.main,
  }),
};
