import { Box } from "@mui/material";
import React from "react";
import ReactPlayer from "react-player";

export default function PlayVideo({ url, endLive }) {
  const genUrl = (video_url) => {
    const startIndex = video_url.indexOf("stream_");
    const result = video_url.substring(startIndex);
    return `https://upload.vcallid.com/${result}/index.m3u8`;
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <ReactPlayer
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        url={genUrl(url)}
        controls
        width="100%"
      />

      {!endLive && (
        <Box
          sx={{
            position: "absolute",
            top: "8px",
            left: "10px",
            color: "#fff",
            fontSize: "8px",
            padding: "1px 6px",
            lineHeight: "16px",
            background: "red",
            borderRadius: "10px",
            fontWeight: "700",
          }}
        >
          TRỰC TIẾP
        </Box>
      )}

      {/* <Box
        sx={{
          position: "absolute",
          top: "9px",
          left: "65px",
          color: "#fff",
          fontSize: "8px",
          padding: "0px 6px",
          lineHeight: "16px",
          background: "#555",
          borderRadius: "10px",
          fontWeight: "700",
        }}
      >
        1
      </Box> */}
    </Box>
  );
}
