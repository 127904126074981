import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Avatar, TextField } from "@mui/material";
import { ReactComponent as SearchIcon } from "assets/svg/icon/search.svg";
import { ReactComponent as CloseAddGroupIcon } from "assets/svg/icon/close_add_group.svg";
import { ReactComponent as CloseText } from "assets/svg/icon/close_text.svg";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InputAdornment from "@mui/material/InputAdornment";
import styles from "../../../assets/styles/pages/Chat/component/NewMessagseDialog";
import defaultAvatar from "../../../assets/svg/icon/default_avatar_user.svg";

export default function NewMessagseDialog({
  onClose,
  isOpen,
  addGroupChat,
  listGroupAdd,
  members,
  handleOpenMessage,
}) {
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState(false);
  const textInputRef = useRef(null);

  useEffect(() => {
    const slider = document.querySelector(".container");
    let isDown = false;
    let startX;
    let scrollLeft;

    if (slider) {
      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 2;
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }, [members]);

  const handleTextFieldClick = () => {
    setIsClicked(true);
  };

  const handleTextFieldBlur = () => {
    setIsClicked(false);
  };
  const handleRest = () => {
    addGroupChat.setSearchGolferKey("");
  };
  const handleClearTextUserList = () => {
    addGroupChat.setSearchGolferKey("");
    textInputRef.current.focus();
    setIsClicked(false);
  };
  return (
    <Dialog
      PaperProps={{
        sx: {
          minWidth: "700px",
          width: "90%",
        },
      }}
      onClose={() => {
        handleRest();
        onClose();
      }}
      open={isOpen}
      sx={styles.dialog}
    >
      <Box sx={styles.dialogTitle}>
        <Typography variant="body1" color="initial" sx={styles.title}>
          {t("chat.message_new")}
        </Typography>
        <IconButton
          sx={styles.paddingReset}
          onClick={() => {
            handleRest();
            onClose();
          }}
        >
          <CloseAddGroupIcon />
        </IconButton>
      </Box>
      <Divider sx={styles.divider} />
      <TextField
        value={addGroupChat.searchGolferKey}
        placeholder={t("chat.user_list.search")}
        onChange={addGroupChat.handleSearchUser}
        onClick={handleTextFieldClick}
        onBlur={handleTextFieldBlur}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={styles.searchIcon}>
              <SearchIcon style={styles.searchIcon} />
            </InputAdornment>
          ),
          endAdornment: addGroupChat.searchGolferKey ? (
            <InputAdornment
              position="end"
              style={{ cursor: "pointer" }}
              onClick={handleClearTextUserList}
            >
              <CloseText />
            </InputAdornment>
          ) : null,
          sx: styles.search({ isClicked }),
        }}
        inputRef={textInputRef}
      />
      <Typography sx={styles.searchType} color="initial.main" variant="body1">
        {addGroupChat.searchGolferKey ? t("chat.user_list.search") : t("chat.user_list.suggest")}
      </Typography>
      <Box sx={styles.userGroupContainerLeft}>
        {members
          .concat(listGroupAdd?.filter((v) => !members.map((m) => m?.id).includes(v?.id)))
          .map((item, index) => (
            <Box
              key={index}
              sx={styles.userGroupItem}
              onClick={() => {
                addGroupChat.handleAddSingleChat({
                  ...item,
                  isClick: true,
                });
                handleOpenMessage();
                handleRest();
                onClose();
              }}
            >
              <Box sx={styles.labelContainer}>
                <Avatar src={item?.avatar || defaultAvatar} sx={styles.groupAvatar} />
                <Box sx={styles.groupUsernameContainer}>
                  <Typography variant="body2" color="initial" sx={styles.listUserName}>
                    {item?.username}
                  </Typography>
                  {item?.id && (
                    <Typography ml={0.5} variant="body2" color="initial" sx={styles.vidUser}>
                      {item?.id}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    </Dialog>
  );
}
