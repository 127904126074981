import colors from "assets/theme/base/colors";

const heightDefault = "375px";
const heightMedia = "290px";
const { initial } = colors;
export default {
  posterChannelTV: () => ({
    with: "100%",
    height: "100%",
    background: "linear-gradient(90deg,  #005DB4 0%, #D90000 100%)",
    borderBottomColor: "#406EE2",
    borderTopColor: "#DDDDDD",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    zIndex: 100,
    top: "-55px",
    position: "relative",
    flexDirection: "row",
    minHeight: "355px",
  }),
  infoUserContainer: () => ({
    pt: 3,

    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  }),

  timeRedContainer: ({ check }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: check ? "space-between" : "center",
    height: "100%",
    minHeight: "250px",
    maxHeight: "250px",
  }),
  timeBlueContainer: ({ check }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: check ? "space-between" : "center",
    height: "100%",
    minHeight: "250px",
    maxHeight: "250px",
  }),
  imageLogoContainer: {},
  golferContainer: ({ flex }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: flex,
  }),
  infoGolferTeamRed: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  infoGolferTeamBlue: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  avatarContainer: {
    mx: 2,
    display: "flex",
  },
  avatarGolfer: ({ breakpoints }) => ({
    width: "60px",
    height: "60px",
    objectFit: "cover",

    [breakpoints.down("lg")]: {
      width: "55px",
      height: "55px",
    },
    [breakpoints.down("md")]: {
      fontWeight: "600",
      fontSize: "18px",
    },
    [breakpoints.down("sm")]: {
      fontWeight: "600",
      fontSize: "18px",
    },
    [breakpoints.down("xs")]: {
      fontWeight: "600",
      fontSize: "18px",
    },
  }),
  userName: ({ typography, breakpoints }, { text }) => ({
    color: "#ffff",
    fontWeight: "600",
    fontSize: "18px",
    textAlign: text,
    fontFamily: typography.fontFamily3,
    [breakpoints.down("xl")]: {
      fontWeight: "600",
      fontSize: "16px",
    },
    [breakpoints.down("lg")]: {
      fontWeight: "600",
      fontSize: "14px",
    },
    [breakpoints.down("md")]: {
      fontWeight: "600",
      fontSize: "14px",
    },
    [breakpoints.down("sm")]: {
      fontWeight: "600",
      fontSize: "14px",
    },
    [breakpoints.down("xs")]: {
      fontWeight: "600",
      fontSize: "14px",
    },
  }),
  vID: ({ typography, breakpoints }) => ({
    color: "#ffff",
    fontWeight: "400",
    fontSize: "16px",
    pt: 0.5,
    opacity: 0.8,
    fontFamily: typography.fontFamily3,
    [breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  }),
  infoPlaceContainer: {
    position: "relative",
    mt: 4,
    height: "55px",
  },
  timeAndPlace: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    py: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-around",
    zIndex: 100000,
  },
  placeBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex_start",
  },
  facility_name: ({ typography, breakpoints }) => ({
    ml: 1,
    color: "#ffff",
    fontSize: "13px",
    fontFamily: typography.fontFamily3,
    [breakpoints.down("lg")]: {
      fontSize: "14px",
    },
    [breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  }),
  hole_index_text: ({ typography }) => ({
    ml: 1,
    color: "#ffff",
    fontFamily: typography.fontFamily3,
    fontSize: "13px",
    opacity: "0.5",
  }),
  hole_index: ({ typography }) => ({
    ml: 1,
    color: "#ffff",
    fontFamily: typography.fontFamily3,
    fontSize: "13px",
  }),
  time: ({ typography, breakpoints }) => ({
    ml: 1,
    color: "#ffff",
    fontFamily: typography.fontFamily3,
    [breakpoints.down("lg")]: {
      fontSize: "14px",
    },
    [breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  }),
  channelTV: () => ({
    with: "100%",
    height: "100%",
    background: "#ffff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomColor: "#406EE2",
    borderTopColor: "#DDDDDD",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    zIndex: 100,
    top: "-55px",
    position: "relative",
    flexDirection: "row",
  }),
  rightColumItem: ({ breakpoints }) => ({
    width: "100%",
    maxHeight: "41.5vh",
    minHeight: heightDefault,
    overflowY: "auto",
    height: heightDefault,
    [breakpoints.down("xl")]: {
      height: "125px",
      minHeight: "125px",
    },
  }),
  leftColumItem: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "relative",
  },
  videoSection: ({ breakpoints }) => ({
    minWidth: "120px",
    height: heightDefault,
    background: "#000",
    minHeight: heightDefault,
    lineHeight: 0,

    [breakpoints.down("lg")]: {
      height: heightMedia,
      minHeight: heightMedia,
    },
    [breakpoints.down("md")]: {
      height: heightMedia,
      minHeight: heightMedia,
    },
    [breakpoints.down("sm")]: {
      height: heightMedia,
      minHeight: heightMedia,
    },
    [breakpoints.down("xs")]: {
      height: heightMedia,
      minHeight: heightMedia,
    },
  }),
  video: ({ breakpoints }) => ({
    background: "#000",
    width: "100%",
    height: "100%",
    objectFit: "contain",
    minHeight: heightDefault,
    [breakpoints.down("lg")]: {
      height: heightMedia,
      minHeight: heightMedia,
    },
    [breakpoints.down("md")]: {
      height: heightMedia,
      minHeight: heightMedia,
    },
    [breakpoints.down("sm")]: {
      height: heightMedia,
      minHeight: heightMedia,
    },
    [breakpoints.down("xs")]: {
      height: heightMedia,
      minHeight: heightMedia,
    },
  }),
  audioSection: ({ breakpoints }) => ({
    minWidth: "120px",
    lineHeight: 0,
    maxHeight: "41vh",
    height: heightDefault,
    minHeight: heightDefault,

    [breakpoints.down("lg")]: {
      height: heightMedia,
      minHeight: heightMedia,
    },
    [breakpoints.down("md")]: {
      height: heightMedia,
      minHeight: heightMedia,
    },
    [breakpoints.down("sm")]: {
      height: heightMedia,
      minHeight: heightMedia,
    },
    [breakpoints.down("xs")]: {
      height: heightMedia,
      minHeight: heightMedia,
    },
  }),
  lottieContainer: ({ breakpoints }) => ({
    background: "black",
    minHeight: "330px",
    [breakpoints.down("lg")]: {
      height: "250px",
      minHeight: "250px",
    },
  }),
  lottie: { height: "300px", minHeight: "300px" },
  audio: () => ({
    width: "100%",
    maxHeight: "20px",
    minHeight: "10px",
    height: "30px",
  }),
  audioContainer: {
    width: "100%",
    maxHeight: "6vh",
    height: "40px",
    minHeight: "10px",
  },
  itemMediaContainer: {
    height: "100%",
    minHeight: "125px",
  },
  videoItemSection: {
    minWidth: "120px",
    minHeight: "125px",
    maxHeight: "125px",
    height: "100%",
    lineHeight: 0,
    position: "relative",
    border: "1px solid #969696",
  },
  videoItem: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    maxHeight: "20vh",
  },
  imgMediaVideo: {
    cursor: "pointer",
    width: "100%",
    objectFit: "cover",
    height: "14vh",
    minHeight: "125px",
    maxHeight: "125px",
    background: "black",
  },
  imgMediaAudio: {
    cursor: "pointer",
    width: "100%",
    objectFit: "cover",
    height: "14vh",
    minHeight: "125px",
    maxHeight: "125px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#000",
  },
  imgAudio: () => ({
    cursor: "pointer",
    width: "100%",

    objectFit: "cover",
    height: "14vh",
    minHeight: "125px",
    maxHeight: "125px",
    background: "#000",
  }),
  playAudio: ({ breakpoints }) => ({
    width: "100%",

    [breakpoints.down("lg")]: {
      width: "50%",
    },
  }),
  imgPlayVideo: {
    opacity: 1,
    position: "absolute",
    cursor: "pointer",
    objectFit: "cover",
  },
  indexChanelContainer: ({ selectIndex }) => ({
    position: "absolute",
    bottom: 4,
    left: 4,
    zIndex: 100,
    width: "100%",
    height: "100%",
    maxWidth: "30px",
    maxHeight: "30px",
    background: selectIndex ? "red" : "black",
    opacity: selectIndex ? 1 : 0.5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  indexChanel: () => ({
    color: "#ffff",
  }),
  textColorBlack: { color: initial.main },
  scoreBox: {
    display: "flex",
  },
  ScoreContainer: {
    position: "absolute",
    top: 20,
    right: 12,
    border: "2px solid white",
    background: "#ffff",
    borderRadius: "6px",
    width: "70px",
    zIndex: 200,
  },
  endChannel: {
    position: "absolute",
    top: 20,
    right: 12,
    background: "#C3C3C3",
    borderRadius: "6px",
    zIndex: 200,
    fontWeight: 700,
    fontSize: "14px",
    px: 2,
    color: "#ffff",
    "&:hover": {
      background: "#C3C3C3",
    },
  },
  hole: ({ palette, typography }) => ({
    color: palette.initial.main,
    fontSize: typography.size.xs,
    textAlign: "center",
    fontFamily: typography.fontFamily3,
    pt: 0.5,
    fontWeight: 500,
  }),
  score: ({ palette, typography }) => ({
    color: palette.white.main,
    fontSize: typography.size.xs,
    textAlign: "center",
    fontFamily: typography.fontFamily3,
    fontWeight: 700,
    pt: 0.5,
  }),
  rightTopBox: {
    borderTopLeftRadius: "6px",
    width: "35px",
    height: "30px",
    background: "blue",
    zIndex: 200,
    mr: 0.1,
  },
  rightBottomBox: {
    borderTopRightRadius: "6px",
    width: "35px",
    height: "30px",
    background: "red",
    zIndex: 200,
  },
};
