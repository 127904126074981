import { Box, Fade } from "@mui/material";
import styles from "assets/styles/pages/Chat";
import breakpoints from "assets/theme/base/breakpoints";
import { useEffect, useRef, useState } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateRoute } from "redux/actions/routeAction";
import routes from "routes/routes";
import { useSearchGolfer } from "service";
import { decodeID, encodeID } from "utils/hex";
import { GetLastMessage } from "utils/message";
import { getUserToken, setUserToken } from "utils/storage";
import { isEmpty } from "utils/validate";
import { v5 as uuid, v4 } from "uuid";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FFmpeg } from "@ffmpeg/ffmpeg";
// eslint-disable-next-line import/no-extraneous-dependencies
import { fetchFile, toBlobURL } from "@ffmpeg/util";

import {
  AddMember,
  ChangePermission,
  RemoveMember,
  addGroupChat,
  addMemberPendingJoinGroup,
  addReporter,
  detailChanel,
  getDetailMess,
  getListAttachment,
  getListGroupChat,
  getListPhoneBookUser,
  groupChatDetail,
  listMemberPendingJoinGroup,
  messageOffline,
  removeReporter,
  sendEventScore,
  updateInformationGroup,
  updateScore,
  uploadResource,
} from "api/chat";
import { downloadMedia, websitePreview } from "api/global";
import { addPin, getListPin, removePin } from "api/pinChat";
import {
  ACTION_TYPE,
  AdminNames,
  CHAT_ACTIVE_TYPE,
  MESSAGE_STATE,
  MESSAGE_TYPE,
  MESSAGE_TYPE_MEDIA,
  SPECIAL_FILE_TYPE,
  TYPE_MUTE,
} from "constant/chat";
import { decrypt, encrypt } from "utils/cipher";
import useDelaySearch from "utils/useDelaySearch";
// import GroupIDB from "IndexedDB/GroupIDB";
import MessageIDB from "IndexedDB/MessageIDB";
import ringBell from "assets/audio/ring_bell.mp3";
import LogoWGHN from "assets/images/logo/logoWGHN.png";
import { BASE_URL_API_CHAT, UPLOAD_URL } from "config/keys";
import { tagManyNameRegex, urlRegex } from "constant/regex";
import { Howl } from "howler";
import { useTranslation } from "react-i18next";
import { useGetProfile } from "service/UserService";
import { io } from "socket.io-client";
import { genUniqueName } from "utils/convert";
import {
  SaveAudioMessage,
  SaveEmojiMessage,
  SaveFileMessage,
  SaveImageMessage,
  SaveTextMessage,
  SaveVideoMessage,
  SaveVotingMessage,
  SendAudioMessage,
  SendEmojiMessage,
  SendFileMessage,
  SendImageMessage,
  SendTextMessage,
  SendVideoMessage,
  SendVotingMessage,
} from "utils/format";
import { overrideDefault } from "utils/utils";
import NotificationRemoveMessage from "./component/NotificationRemoveMessage";
import ChatInformation from "./section/ChatInformation";
import ContentChat from "./section/ContentChat";
import Footer from "./section/Footer";
import Header from "./section/Header";
import InformationDialog from "./section/InformationDialog";
import SettingDialog from "./section/SettingDialog";
import UserList from "./section/UserList";

export default function Chat() {
  // ##########################################################################
  // #                              STATE                                     #
  // ##########################################################################
  // global

  let userInformation = getUserToken();
  const { data: userDb } = useGetProfile(userInformation?.id);
  userInformation = { ...userInformation, ...userDb };

  const { t } = useTranslation();

  const [isMobileSize, setMobileSize] = useState(false);
  const [isMediumSize, setMediumSize] = useState(false);
  const [sip, setSip] = useState();
  const { vID } = useParams();
  const [mediaLimit, setMediaLimit] = useState(10);
  const [isFetchingMessage, setFetchingMessage] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [filterGroup, setFilterGroup] = useState([]);
  const [pointUser, setPointUser] = useState(null);

  // user list
  const [isAddGroup, setAddGroup] = useState(false);
  const [isAddConversation, setAddConversation] = useState(0);
  const [isPersonalPopperOpen, setPersonalPopperOpen] = useState(false);
  const [personalAnchor, setPersonalAnchor] = useState(null);
  const [isSelectGroup, setSelectGroup] = useState(false);
  const [searchGolferKey, setSearchGolferKey] = useState("");
  const [listDataPhoneBook, setListDataPhoneBook] = useState([]);
  const [searchPhoneBook, setSearchPhoneBook] = useState("");
  const [filteredData, setFilteredDataUserPhoneBook] = useState(listDataPhoneBook);
  // setting dialog
  const [isOpenSettingDialog, setOpenSettingDialog] = useState(false);
  const [openInformationDialog, setOpenInformationDialog] = useState(false);
  const [isOpenSetting, setIsOpenSetting] = useState(false);
  // header
  const [openSetting, setOpenSetting] = useState(false);
  // const [anchor, setAnchorEl] = useState(null);
  const [isAddFriend, setAddFriend] = useState(false);
  const [isBlockFriend, setBlockFriend] = useState(false);
  const [openPinMessage, setOpenPinMessage] = useState(false);
  const [messagePinContent, setMessagePinContent] = useState({});
  const [openDialogDeletePinMessage, setOpenDialogDeletePinMessage] = useState(false);
  const [openDialogPinMessage, setOpenDialogPinMessage] = useState(false);
  const [openDialogNotifyStatusPin, setOpenDialogNotifyStatusPin] = useState(false);
  const [openChanelTv, setOpenChanelTv] = useState(false);
  const [openDialogNotifyLoadingStatusPin, setOpenDialogNotifyLoadingStatusPin] = useState(false);

  // chat
  const chatRef = useRef();
  const [isDragOverlay, setDragOverlay] = useState(false);
  const [openMessageSetting, setOpenMessageSetting] = useState(false);
  const [isSelectOwner, setSelectOwner] = useState(false);
  const [openViewerDialog, setOpenViewerDialog] = useState(false);
  const [openReplyMessage, setOpenReplyMessage] = useState(false);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [messageReplyContent, setMessageReplyContent] = useState({});
  const [unreadMessage, setUnreadMessage] = useState(0);
  const [isScrollEnd, setScrollEnd] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [listMess, setListMessage] = useState([]);
  const [countMessage, setCountMessage] = useState(0);
  const [listGroupDB, setListGroup] = useState([]);
  const [groupDetailDB, setGroupDetail] = useState({});
  const [typeMedia, setTypeMedia] = useState(null);
  const [detailMessage, setDetailMessage] = useState(null);
  const [openTipMessage, setOpenTipMessage] = useState(false);
  const [listChanel, setListChanel] = useState([]);
  const [messageChat, setMessage] = useState("");
  const [infoChanel, setDetailChanel] = useState(null);
  const [openVoteInfoDialog, setOpenVoteInfoDialog] = useState(false);
  const [voteInfo, setVoteInfo] = useState(null);
  const [nameGroup, setNameGroup] = useState("");
  const [openNotificationAdminDialog, setOpenNotificationAdminDialog] = useState(false);
  // const [isPrivateInforGroup, setIsPrivateInforGroup] = useState(null);
  // footer
  const textRef = useRef();
  const sendRef = useRef();
  const [isVoice, setIsVoice] = useState(false);
  const [timeAudio, setTime] = useState(0);
  const [images, setImages] = useState([]);
  const [prevText, setPrevText] = useState("");
  const [isBlock, setIsBlock] = useState(false);
  const [openEditMessage, setOpenEditMessage] = useState(false);
  const [listPoint, setListPoint] = useState([]);
  // information
  const [isOpenInformation, setOpenInformation] = useState(false);
  // chat-info-add-member-chat
  const [selectIndex, setSelectIndex] = useState([]);
  const [isOpenAddMemberChat, setOpenAddMemberChat] = useState(false);
  // forward-dialog
  const [selectForwardIndex, setSelectForwardIndex] = useState([]);
  const [isOpenForwardDialog, setOpenForwardDialog] = useState(false);
  // add-member
  const [searchValue, setSearchValue] = useState("");
  // list-member
  const [isOpenListMemberDialog, setOpenListMemberDialog] = useState(false);
  const [isOpenMultimediaDialog, setOpenMultimediaDialog] = useState(false);
  const [isOpenListMemberPendingDialog, setOpenListMemberPendingDialog] = useState(false);
  const [sharedLink, setSharedLink] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpenError] = useState(false);

  const queryClient = useQueryClient();
  // ##########################################################################
  // #                              HOOK                                      #
  // ##########################################################################
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // voice
  const { recordingBlob, startRecording, stopRecording, recordingTime } = useAudioRecorder();
  // const { startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder();
  const listPin = useQuery("pins", () => getListPin({ id: +decodeID(vID) }));

  const groupDetail = useQuery("group-idb", () => groupChatDetail(+decodeID(vID)), {
    select: (res) => {
      if (+decodeID(vID) !== 0) {
        const group = listGroupDB.find((item) => res.group && +item._id === +res.group.id);
        const state = {
          ...res,
          group: decrypt(res.group),
          detail: decrypt(res.group),
          _id: res?.group?.id,
          user_id: userInformation?.id,
          last_message: {
            ...(group?.last_message || {}),
            text: GetLastMessage(group?.last_message?.text),
          },
        };
        return state;
      }
    },
    onSuccess: (res) => {
      if (+decodeID(vID) !== 0) {
        const group = listGroupDB.find((item) => res.group && +item._id === +res.group.id);
        const state = {
          ...res,
          group: decrypt(res.group),
          detail: decrypt(res.group),
          _id: res?.group?.id,
          user_id: userInformation?.id,
          last_message: {
            ...(group?.last_message || {}),
            text: GetLastMessage(group?.last_message?.text),
          },
        };
        setGroupDetail(state);
        if (res?.group?.channel) {
          setOpenChanelTv(true);
          const params = {
            token: "135bfec52ae4524fc60bc6cfdbe7378d",
            uid: 17882,
            page: 1,
            limit: 1000,
            channel_id: res.group?.id,
          };
          getListAttachment(params).then((json) => {
            if (json.error === 0) {
              setListChanel(json?.data?.items?.reverse());
            }
          });
          detailChanel(params, res.group?.id).then((json) => {
            if (json.error === 0) {
              setDetailChanel(json.info);
            }
          });
        } else if (!res?.group?.channel) {
          if (openChanelTv) {
            setOpenChanelTv(false);
          }
        }
      }
    },
  });

  const listMessage = useQuery(
    ["message-idb", vID],
    () => messageOffline({ group_id: +decodeID(vID), page: currentPage, limit: 20 }),
    {
      onSuccess: (res) => {
        if (res.error === 0) {
          setFetchingMessage(false);
          const data = res.data.items.map((value) => ({
            ...value,
            _id: value.client_id,
            state: MESSAGE_STATE.SENDED,
            message: { ...value.content, state: MESSAGE_STATE.SENDED },
          }));
          setCountMessage(res.data.count);

          const listMessId = listMess.map((item) => item.id);
          const messWillAdd = data.filter((item) => !listMessId.includes(item.id));
          const messWillSeen = data
            .filter((item) => item?.content?.last_action < 2 && item.user_id !== userInformation.id)
            .map((i) => i.id);

          const body = {
            msg_id: messWillSeen,
            type: "action",
            user_id: userInformation?.id,
            value: 2,
          };

          sip.emit("actions", JSON.stringify(body));

          setListMessage([...messWillAdd.reverse(), ...listMess]);
        }
      },
    }
  );
  const listMessageMedia = useQuery(
    "messageMedia",
    () => messageOffline({ group_id: +decodeID(vID), media_type: typeMedia, limit: mediaLimit }),
    {
      select: (res) => res?.data?.items.map((value) => ({ ...value, message: value.content })),
      enabled: false,
    }
  );

  const groupQuery = useQuery("group", () => getListGroupChat(), {
    onSuccess: (res) => {
      if (res.error !== 0 || !res.groups) return;
      const data = res?.groups?.map((value, index) => {
        let last_message = "";
        switch (value.last_messenge_of_group?.kind) {
          case MESSAGE_TYPE.TEXT:
            last_message = {
              ...value.last_messenge_of_group,
              text: tagManyNameRegex.exec(value.last_messenge_of_group.text)
                ? value.last_messenge_of_group?.text.replace(tagManyNameRegex, `$1`)
                : value.last_messenge_of_group.text,
            };
            break;
          case MESSAGE_TYPE.IMAGE:
            last_message = {
              ...value.last_messenge_of_group,
              text: GetLastMessage("system_message.image_message_text"),
            };
            break;
          case MESSAGE_TYPE.VIDEO:
            last_message = {
              ...value.last_messenge_of_group,
              text: GetLastMessage("system_message.video_message_text"),
            };
            break;
          case MESSAGE_TYPE.FILE:
            last_message = {
              ...value.last_messenge_of_group,
              text: GetLastMessage("system_message.file_message_text"),
            };
            break;
          case MESSAGE_TYPE.AUDIO:
            last_message = {
              ...value.last_messenge_of_group,
              text: GetLastMessage("system_message.audio_message_text"),
            };
            break;
          case MESSAGE_TYPE.VOTE:
            last_message = {
              ...value.last_messenge_of_group,
              text: GetLastMessage("system_message.vote_message_text"),
            };
            break;
          case MESSAGE_TYPE.EMOJI:
            last_message = {
              ...value.last_messenge_of_group,
              text: GetLastMessage("system_message.emoji_message_text"),
            };
            break;
          default:
        }
        return {
          ...value,
          group: value,
          detail: value,
          _id: value.id,
          user_id: userInformation?.id,
          last_message,
          index,
        };
      });
      setListGroup(data);
    },
  });

  // mutation
  const uploadImageMutate = useMutation((data) => uploadResource(data));
  const addGroupMutate = useMutation((value) => addGroupChat(value));
  // JSSIP

  const mutationData = (unzipValue) => {
    queryClient.setQueriesData("ListMessageMedia", (oldData) => {
      let media_type = 6;
      if (unzipValue?.kind === "image") media_type = 1;
      if (unzipValue?.kind === "video") media_type = 2;
      if (unzipValue?.kind === "file") media_type = 3;
      if (unzipValue?.kind === "audio") media_type = 4;

      if (!oldData)
        return {
          count: 1,
          data: [{ message: unzipValue, media_type }],
        };

      const newData = [{ message: unzipValue, media_type }, ...oldData.data];

      return {
        count: oldData.count + 1,
        data: newData,
      };
    });
  };

  // useEffect(() => {
  //   if (groupDetailDB?.detail) {
  //     const userIds = groupDetailDB?.detail?.users.map((item) => item.id);
  //     if (!userIds.includes(userInformation?.id)) navigate(`/t/${encodeID(listGroupDB?.[0]?.id)}`);
  //   }
  // }, [groupDetailDB]);

  useEffect(() => {
    if (isEmpty(getUserToken())) return;
    const audioBell = new Howl({
      src: [ringBell],
      html5: true,
      muted: false,
    });
    let checkFocus = true;
    let notifyOpen = true;
    const requestPermission = async () => {
      if (Notification.permission === "default") Notification.requestPermission();
    };
    const notificationOnBlur = async () => {
      checkFocus = false;
    };
    const handleFocus = () => {
      checkFocus = true;
    };
    const token = getUserToken() ? getUserToken().token : "";
    const ioClient = io(`${BASE_URL_API_CHAT}?token=${token}`);
    ioClient.emit("ping", JSON.stringify({ value: "ok" }));
    ioClient.emit("money", JSON.stringify({ user_ids: [userInformation?.id] }));
    ioClient.on("money", async (res) => {
      const Jdata = JSON.parse(res);
      if (Jdata.value === "ok") {
        const user = Jdata?.users?.find((item) => item.user_id === userInformation.id);
        if (user) setPointUser(user);
      }
    });
    const typeArr = ["image", "file", "video", "audio"];
    ioClient.on("messenge", async (res) => {
      const unzipValue = JSON.parse(res);
      if (unzipValue && unzipValue?.group?.id === +decodeID(vID)) {
        if (unzipValue?.kind === "text" && unzipValue?.preview?.url) mutationData(unzipValue);
        if (typeArr.includes(unzipValue?.kind)) mutationData(unzipValue);
      }
      const group_id = +decodeID(window.location.pathname.replace("/t/", ""));
      if (unzipValue.type === "signal") return;
      if (unzipValue.type === "update_point") return;
      if (unzipValue.type === "message_update") {
        if (unzipValue.update.last_action === 2) {
          const idbMessage = await MessageIDB.get(unzipValue._id, "_id");
          if (!idbMessage) return;
          await MessageIDB.put({
            ...idbMessage,
            message: encrypt({
              ...decrypt(idbMessage?.message || {}),
              state: MESSAGE_STATE.SENDED,
              id: unzipValue?.update?.id,
            }),
            state: MESSAGE_STATE.SENDED,
          });
          await listMessage.refetch();
          return;
        }
        return;
      }

      if (unzipValue.message_type === "message") {
        if (+decodeID(vID) === unzipValue.group_id) {
          const body = {
            msg_id: [unzipValue.id],
            type: "action",
            user_id: userInformation?.id,
            value: 2,
          };

          ioClient.emit("actions", JSON.stringify(body));
        }
        // const newTime = Date.now();
        let notification_message = "";
        let last_message = "";
        const checkUnread =
          group_id !== unzipValue.group_id && +unzipValue.user_id !== +userInformation.id;
        switch (unzipValue.kind) {
          case MESSAGE_TYPE.TEXT:
            {
              const message_url_preview = urlRegex.test(unzipValue.text)
                ? await websitePreview({ uri: urlRegex.exec(unzipValue.text)[0] })
                : null;
              if (message_url_preview) unzipValue.url_preview = message_url_preview;
              last_message = {
                ...unzipValue,
                text: tagManyNameRegex.exec(unzipValue.text)
                  ? unzipValue?.text.replace(tagManyNameRegex, `$1`)
                  : unzipValue.text,
                isUnread: checkUnread,
              };
              notification_message = unzipValue.text;
            }
            break;
          case MESSAGE_TYPE.IMAGE:
            last_message = {
              ...unzipValue,
              text: GetLastMessage("system_message.image_message_text"),
              isUnread: checkUnread,
            };
            notification_message = GetLastMessage("system_message.image_message_text");
            break;
          case MESSAGE_TYPE.VIDEO:
            last_message = {
              ...unzipValue,
              text: GetLastMessage("system_message.video_message_text"),
              isUnread: checkUnread,
            };
            notification_message = GetLastMessage("system_message.video_message_text");
            break;
          case MESSAGE_TYPE.FILE:
            last_message = {
              ...unzipValue,
              text: GetLastMessage("system_message.file_message_text"),
              isUnread: checkUnread,
            };
            notification_message = GetLastMessage("system_message.file_message_text");
            break;
          case MESSAGE_TYPE.AUDIO:
            last_message = {
              ...unzipValue,
              text: GetLastMessage("system_message.audio_message_text"),
              isUnread: checkUnread,
            };
            notification_message = GetLastMessage("system_message.audio_message_text");
            break;
          case MESSAGE_TYPE.EMOJI:
            last_message = {
              ...unzipValue,
              text: GetLastMessage("system_message.emoji_message_text"),
              isUnread: checkUnread,
            };
            notification_message = GetLastMessage("system_message.emoji_message_text");
            break;
          case MESSAGE_TYPE.VOTE:
            last_message = {
              ...unzipValue,
              text: GetLastMessage("system_message.vote_message_text"),
              isUnread: checkUnread,
            };
            notification_message = GetLastMessage("system_message.vote_message_text");

            break;
          default:
            return;
        }
        setListGroup((prev) => {
          if (!prev?.map((v) => +v._id).includes(+unzipValue.group_id)) {
            groupQuery.refetch();
            return prev;
          }
          const groupCurrent = prev.find((item) => +item.id === +unzipValue.group_id);
          const groupOther = prev.filter((item) => +item.id !== +unzipValue.group_id);
          if (
            +unzipValue.user_id !== +userInformation.id &&
            +groupCurrent.detail.is_mute === TYPE_MUTE.TRUE
          ) {
            audioBell.pause();
            setTimeout(() => {
              audioBell.seek(0);
              audioBell.play();
            }, 300);
          }

          if (
            +unzipValue.user_id !== +userInformation.id &&
            +groupCurrent.detail.is_mute === TYPE_MUTE.TRUE &&
            !checkFocus &&
            notifyOpen
          ) {
            notifyOpen = false;
            let title = unzipValue?.user?.username;
            if (unzipValue.group.type === "group")
              title = `${unzipValue?.user?.username} ${t("notify.to")} ${unzipValue.group.name}`;
            const notify = new Notification(title, {
              body: notification_message,
              icon: LogoWGHN,
            });
            notify.onclick = (event) => {
              event.preventDefault();
              checkFocus = true;
              window.open(`https://chat.wghn.net/t/${encodeID(unzipValue.group_id)}`);
            };
            setTimeout(() => {
              notifyOpen = true;
            }, 5000);
          }
          return [
            {
              ...groupCurrent,
              last_message,
              unreadCount: checkUnread ? (groupCurrent.unreadCount || 0) + 1 : 0,
            },
            ...groupOther,
          ];
        });
        // save message
        if (+unzipValue.group_id === +group_id) {
          setListMessage((prev) => {
            const index = prev.findIndex((mess) => mess._id === unzipValue._id);
            if (+unzipValue.user_id === +userInformation.id && index !== -1) {
              const messageUpdate = [...prev];
              messageUpdate[index] = {
                _id: unzipValue._id,
                id: unzipValue.id,
                user_id: unzipValue.user_id,
                client_id: unzipValue._id,
                group_id: +unzipValue.group_id,
                message: { ...unzipValue, state: MESSAGE_STATE.SENDING },
                date: unzipValue.createdAt,
                state: MESSAGE_STATE.SENDING,
                kind: unzipValue.kind,
              };
              return messageUpdate;
              // return prev;
            }

            // check bold unread and render
            if (chatRef.current?.scrollTop < -100) {
              setUnreadMessage((prevUnread) => prevUnread + 1);
            }
            return [
              ...prev,
              {
                _id: unzipValue._id,
                id: unzipValue.id,
                user_id: unzipValue.user_id,
                client_id: unzipValue._id,
                group_id: +unzipValue.group_id,
                message: { ...unzipValue, state: MESSAGE_STATE.SENDING },
                date: unzipValue.createdAt,
                state: MESSAGE_STATE.SENDING,
                kind: unzipValue.kind,
              },
            ];
          });
          if (
            [MESSAGE_TYPE.AUDIO, MESSAGE_TYPE.VIDEO, MESSAGE_TYPE.IMAGE].includes(unzipValue.kind)
          ) {
            const params = {
              token: "135bfec52ae4524fc60bc6cfdbe7378d",
              uid: 17882,
              page: 1,
              limit: 1000,
              channel_id: unzipValue.group_id,
            };
            getListAttachment(params).then((json) => {
              if (json.error === 0) {
                setListChanel(json?.data?.items?.reverse());
              }
            });
          }
        }
      }
    });

    ioClient.on("actions", async (res) => {
      const unzipValue = JSON.parse(res);

      if (unzipValue.type === "pin_change") {
        const { pathname } = window.location;
        const id = unzipValue?.data?.chatId;

        if (pathname.includes(encodeID(id))) listPin.refetch();
      }

      if (unzipValue.type === "update_messenger_other") {
        if (unzipValue?.update?.last_action === 1) {
          setListMessage((prev) => {
            const index = prev.findIndex((mess) => mess._id === unzipValue.client_id);
            if (index !== -1) {
              const messageUpdate = [...prev];
              messageUpdate[index].message.last_action = 0;

              return messageUpdate;
            }
            return prev;
          });
        }
      }

      if (unzipValue.type === "message_update") {
        if (unzipValue?.update?.last_action === 2) {
          setListMessage((prev) => {
            const index = prev.findIndex((mess) => mess._id === unzipValue.client_id);
            if (index !== -1) {
              const messageUpdate = [...prev];
              messageUpdate[index].message.last_action = 2;

              return messageUpdate;
            }
            return prev;
          });
        } else {
          setListMessage((prev) => {
            const index = prev.findIndex((mess) => mess._id === unzipValue.client_id);
            if (index !== -1) {
              const messageUpdate = [...prev];
              if (unzipValue?.update?.likeUsers) {
                messageUpdate[index].message.likeUsers = unzipValue.update.likeUsers;
              } else if (unzipValue?.update?.tipUsers?.length > 0) {
                messageUpdate[index].message.tipUsers = unzipValue.update.tipUsers;
              } else if (unzipValue?.update?.edited) {
                messageUpdate[index].message.edited = true;
                messageUpdate[index].message.text = unzipValue?.update?.text;
              } else if (unzipValue?.update?.is_delete) {
                messageUpdate.splice(index, 1);
              }
              return messageUpdate;
            }
            return prev;
          });
        }
      } else if (unzipValue.kind === MESSAGE_TYPE.VOTE) {
        console.log("SOCKET_MESS", unzipValue);

        setListMessage((prev) => {
          const index = prev.findIndex((mess) => mess._id === unzipValue.client_id);
          if (index !== -1) {
            const messageUpdate = [...prev];
            messageUpdate[index].message = unzipValue;
            return messageUpdate;
          }
          return prev;
        });
      }
    });

    setSip(ioClient);

    requestPermission();
    window.addEventListener("blur", notificationOnBlur);
    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("blur", notificationOnBlur);
      window.removeEventListener("focus", handleFocus);
      if (audioBell) {
        audioBell.stop();
      }
    };
  }, []);

  useEffect(() => {
    if ((currentPage - 1) * 20 < +countMessage) {
      setFetchingMessage(true);
      listMessage.refetch();
    }
  }, [currentPage]);

  useEffect(() => {
    listPin.refetch();
    textRef?.current?.focus();
    setTimeout(() => chatRef?.current?.scroll({ top: 0, behavior: "smooth" }), 700);
    groupDetail.refetch();
    if (groupDetail?.isFetching) groupDetail.remove();
    setListMessage([]);
    if (currentPage !== 1) setCurrentPage(1);
    listMessage.refetch({});
    if (openReplyMessage) {
      setOpenReplyMessage(false);
      setMessageReplyContent({});
    }

    async function checkReadMessage() {
      const arrGroupNew = [];
      for (let i = 0; i < listGroupDB.length; i += 1) {
        const group = listGroupDB[i];
        if (+group._id === +decodeID(vID)) {
          group.last_message = {
            ...decrypt(group?.last_message || {}),
            isUnread: false,
          };
          delete group.unreadCount;
        }
        arrGroupNew.push(group);
      }
      setListGroup(arrGroupNew);
    }
    checkReadMessage();
  }, [vID]);
  // other
  const [search, setSearch] = useState("");

  const delaySearchValue = useDelaySearch(searchValue, 200);
  const delaySearchGolfer = useDelaySearch(searchGolferKey, 200);

  useEffect(() => {
    setSearch(delaySearchValue);
  }, [delaySearchValue]);

  useEffect(() => {
    setSearch(delaySearchGolfer);
  }, [delaySearchGolfer]);

  const { data: listGolfer } = useSearchGolfer(search);

  const delaySearchUser = useDelaySearch(searchUser, 200);

  useEffect(() => {
    const listChat = listGroupDB?.filter((value) => {
      if (value?.group?.name?.toLowerCase().includes(delaySearchUser?.toLowerCase())) return true;
      return false;
    });

    setFilterGroup(listChat || []);
  }, [delaySearchUser]);

  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.md) {
        setMobileSize(true);
        setMediumSize(false);
      } else if (
        window.innerWidth >= breakpoints.values.md &&
        window.innerWidth < breakpoints.values.xxl
      ) {
        setMediumSize(true);
        setMobileSize(false);
      } else {
        setMobileSize(false);
        setMediumSize(false);
      }
    }
    async function getFirstGroup() {
      const groupChat = await getListGroupChat();
      if (groupChat.groups && groupChat.groups.length > 0) {
        const [first] = groupChat.groups;
        const idGroup = encodeID(first.id.toString());
        navigate(`/t/${idGroup}`);
      }
    }

    // Add Event Listener
    window.addEventListener("resize", displayMobileNavbar);

    // First call
    displayMobileNavbar();
    if (isEmpty(vID) && getUserToken()) {
      getFirstGroup();
    }
    return () => {
      window.removeEventListener("resize", displayMobileNavbar);
    };
  }, []);

  useEffect(() => {
    async function onChatScroll() {
      setTimeout(() => {
        if (chatRef.current?.scrollTop < -100) {
          if (
            chatRef.current.scrollHeight <=
            Math.ceil(chatRef.current.offsetHeight - chatRef.current.scrollTop) + 1
          ) {
            setCurrentPage(currentPage + 1);
          }
          setScrollEnd(true);
        } else {
          setUnreadMessage(0);
          setScrollEnd(false);
        }
      }, [1000]);
    }
    function onChatScrollMobile() {
      if (chatRef.current?.scrollTop < -100) {
        if (
          chatRef.current.scrollHeight ===
          chatRef.current.offsetHeight - chatRef.current.scrollTop
        ) {
          setCurrentPage(currentPage + 1);
        }
        setScrollEnd(true);
      } else {
        setUnreadMessage(0);
        setScrollEnd(false);
      }
    }
    chatRef?.current?.addEventListener("scroll", onChatScroll);
    window.addEventListener("touchmove", onChatScrollMobile);
    return () => {
      chatRef?.current?.removeEventListener("scroll", onChatScroll);
      window.removeEventListener("touchmove", onChatScrollMobile);
    };
  }, [currentPage]);

  useEffect(() => {
    if (recordingBlob) {
      if (timeAudio === 0) return;
      const uploadAudioMessage = async () => {
        const ffmpeg = new FFmpeg({ log: false });
        const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd";
        await ffmpeg.load({
          coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
          wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm"),
        });
        const inputName = "input.webm";
        const outputName = "output.wav";
        await ffmpeg.writeFile(inputName, await fetchFile(recordingBlob));
        await ffmpeg.exec(["-i", "input.webm", "output.wav"]);
        const outputData = await ffmpeg.readFile(outputName);
        const outputBlob = new Blob([outputData], { type: "audio/wav" });
        const myNewFile = new File(
          [outputBlob],
          `${uuid(`${Math.floor(Math.random() * 1000)}`, v4())}.wav`,
          {
            type: "audio/wav",
          }
        );
        const formData = new FormData();

        formData.append("image", myNewFile);
        const message_file = await uploadImageMutate.mutateAsync(formData);
        const audio = {
          ...message_file,
          time: timeAudio,
          url: `${UPLOAD_URL}/${message_file.path}`,
        };

        const message_time = new Date().getTime();
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
        const message_state = MESSAGE_STATE.PENDING;
        const message_reply_id = openReplyMessage ? messageReplyContent?._id : null;
        const message_user = {
          id: userInformation.id,
          avatar: userInformation.avatar,
          user_name: userInformation.username || userInformation.user_name,
        };
        const reply = openReplyMessage ? detailMessage : null;
        const sendAudio = SendAudioMessage({
          message_audio: audio,
          message_group_id,
          message_time,
          message_user_id,
          message_uuid,
          message_reply_id,
        });
        const saveAudio = SaveAudioMessage({
          message_group_id,
          message_state,
          message_audio: audio,
          message_time,
          message_user,
          message_user_id,
          message_uuid,
          message_reply_id,
        });
        // send message
        const newAudio = {
          _id: message_uuid,
          client_id: message_uuid,
          group_id: message_group_id,
          message: saveAudio,
          date: message_time * 1000,
          state: message_state,
          kind: saveAudio.kind,
          user_id: saveAudio.user_id,
          reply,
        };

        setListMessage((prev) => [...prev, { ...newAudio }]);

        mutationData(newAudio);

        sip.emit("messenge", JSON.stringify(sendAudio));

        // save last message
        setListGroup((prev) => {
          const groupCurrent = prev.find((item) => +item.id === +message_group_id);
          const groupOther = prev.filter((item) => +item.id !== +message_group_id);
          return [
            {
              ...groupCurrent,
              last_message: {
                ...saveAudio,
                text: GetLastMessage("system_message.video_message_text"),
                isUnread: false,
              },
            },
            ...groupOther,
          ];
        });
        if (chatRef.current?.scrollTop < -100) {
          setTimeout(() => chatRef?.current?.scroll({ top: 0, behavior: "instant" }), 100);
        }
        if (openReplyMessage) {
          setOpenReplyMessage(false);
        }
      };
      uploadAudioMessage();
    }
  }, [recordingBlob]);
  useEffect(() => {
    if (listPin?.data?.list?.length > 0) setOpenPinMessage(true);
    else {
      setOpenPinMessage(false);
      setOpenDialogPinMessage(false);
    }
  }, [listPin]);

  // ##########################################################################
  // #                            FUNCTION                                    #
  // ##########################################################################
  // setting dialog
  const handleLogout = () => {
    setUserToken({});
    dispatch(updateRoute(routes.filter((element) => !element.require_token)));
    navigate("/qr");
  };
  const handleOpenSettingDialog = () => setOpenSettingDialog(true);
  const handleCloseSettingDialog = () => setOpenSettingDialog(false);
  const handleOpenInformationDialog = () => setOpenInformationDialog(true);
  const handleCloseInformationDialog = () => setOpenInformationDialog(false);
  // header
  // const handleOpenHeaderPopper = (event) => {
  //   setAnchorEl(event.currentTarget);
  //   setOpenSetting((prevOpen) => !prevOpen);
  // };
  const handleAddFriend = () => {
    setAddFriend(false);
    setBlockFriend(false);
  };
  const handleCloseHeaderPopper = () => setOpenSetting(false);
  const handleDialogPinMessage = () => {
    setOpenDialogPinMessage((prevOpen) => !prevOpen);
  };
  const handleOpenDeletePinMessage = async (element) => {
    setOpenDialogDeletePinMessage((prevOpen) => !prevOpen);
    if (element.id) setMessagePinContent(element);
  };
  const handleDeletePinMessage = async () => {
    const res = await removePin({ id: +decodeID(vID), message_id: messagePinContent.id });
    if (res.error === 0) {
      await listPin.refetch();
      setOpenDialogDeletePinMessage((prevOpen) => !prevOpen);
    }
  };

  const handleOpenChanelTv = () => {
    if (!openChanelTv) {
      const params = {
        token: "135bfec52ae4524fc60bc6cfdbe7378d",
        uid: 17882,
        page: 1,
        limit: 1000,
        channel_id: groupDetail?.data?.group?.id,
      };
      getListAttachment(params).then((json) => {
        if (json.error === 0) {
          setListChanel(json?.data?.items?.reverse());
        }
      });
    }
    setOpenChanelTv((prev) => !prev);
  };
  // user list
  const handleOpenAddGroup = () => {
    setAddGroup(true);
    setSearchGolferKey("");
  };
  const handleCloseAddGroup = (callback) => {
    setAddGroup(false);
    setSearchGolferKey("");
    if (callback && typeof callback === "function") callback();
  };
  const handleOpenMessage = () => {
    // eslint-disable-next-line no-use-before-define
    featchDataPhoneBookUser()
      .then(() => {
        setAddConversation(0);
      })
      .catch((error) => {
        console.error("Error opening conversation:", error);
      });
  };
  const handleOpenPhoneBook = () => {
    setSearchGolferKey("");
    setAddConversation(1);
  };

  const handleOpenSetting = () => {
    setAddConversation(2);
  };
  const addNewGroupChat = (storeData, callback) => {
    addGroupMutate
      .mutateAsync(storeData)
      .then((res) => {
        if (+res.error !== 0) return;
        groupQuery.refetch().then(() => {
          setTimeout(() => {
            setSelectGroup(true);
            navigate(`/t/${encodeID(res?.group?.id)}`);
          }, 500);
        });
      })
      .finally(() => {
        handleOpenMessage();
        if (callback && typeof callback === "function") callback();
      });
  };
  const handleAddSingleChat = async (element) => {
    if (isEmpty(searchGolferKey) && !element?.isClick) {
      navigate(`/t/${encodeID(element.group_id)}`);
      setSelectGroup(true);
      handleOpenMessage();
    } else {
      let storeData = {};
      storeData = {
        type: "single",
        member_users: [userInformation?.id, element.id],
        is_private: 0,
      };
      addNewGroupChat(storeData);
    }
    // eslint-disable-next-line no-use-before-define
    handleCloseListMember();
  };
  const handleAddSingleChatUserPhoneBook = async (element) => {
    if (!element?.isClick) {
      navigate(`/t/${encodeID(element.group_id)}`);
      setSelectGroup(true);
      setSelectedOption(0);
    } else {
      let storeData = {};
      storeData = {
        type: "single",
        member_users: [userInformation?.id, element?.id_vga],
        is_private: 0,
      };
      setSelectedOption(0);
      addNewGroupChat(storeData);
    }
  };
  const handleAddGroupChat = async (element, callback) => {
    const res = await fetch(element.image);
    const img = await res.blob();
    const formData = new FormData();
    formData.append("image", img, `${v4()}.png`);
    const imageURL = await uploadImageMutate.mutateAsync(formData);
    let imageGroup;
    if (element.image === null) {
      imageGroup = "";
    }
    if (element.image !== null) {
      imageGroup = `${UPLOAD_URL}/${imageURL.path}`;
    }
    let storeData = {};
    storeData = {
      type: "group",
      name: element.name,
      admin_ids: [userInformation?.id],
      member_users: element.members,
      image: imageGroup,
      is_private: element.is_private,
      group_permission: element.group_permission,
      review: element.review,
    };
    addNewGroupChat(storeData, callback);
  };
  const handleSearchUserList = (e) => setSearchUser(e?.target?.value);
  const handleSearchUser = (e) => setSearchGolferKey(e?.target?.value?.trim());
  const closeAllDialogInformation = () => {
    setOpenAddMemberChat(false);
    setOpenListMemberDialog(false);
    setOpenMultimediaDialog(false);
    setOpenListMemberPendingDialog(false);
    setIsOpenSetting(false);
  };

  const handleSelect = (element) => {
    if (element?.id !== +decodeID(vID) && currentPage !== 1) setCurrentPage(1);

    if (element?.id) {
      const indexSelect = listGroupDB.findIndex((item) => +item.id === +element.id);
      if (!isEmpty(textRef?.current?.value)) {
        const last_message = textRef.current.value;
        const group = listGroupDB.find((item) => +item.id === +decodeID(vID));
        if (group.draft_last_message !== last_message) {
          setListGroup((prev) => {
            const messageUpdate = [...prev];
            const indexCurrent = messageUpdate.findIndex((item) => +item.id === +decodeID(vID));
            messageUpdate[indexCurrent].draft_last_message = last_message;
            delete messageUpdate[indexSelect].draft_last_message;
            return messageUpdate;
          });
        }
      } else {
        const group = listGroupDB.find((item) => +item.id === +element.id);
        if (group.draft_last_message && indexSelect !== -1) {
          setListGroup((prev) => {
            const messageUpdate = [...prev];
            delete messageUpdate[indexSelect].draft_last_message;
            return messageUpdate;
          });
        }
      }
      setSelectGroup(true);
      const vId = encodeID(element.id);
      navigate(`/t/${vId}`);
    } else {
      setSelectGroup(false);
    }
    if (element?.is_block) setIsBlock(true);
    else setIsBlock(false);
    closeAllDialogInformation();
  };
  const handleOpenPersonalPopper = (event) => {
    setPersonalAnchor(event.currentTarget);
    setPersonalPopperOpen((prevOpen) => !prevOpen);
  };
  const handleClosePersonalPopper = () => setPersonalPopperOpen(false);
  // handleRemoveGroupChat
  const handleRemoveGroupChat = async (idGroup) => {
    const deleteGroup = {
      group_id: idGroup,
      value: ACTION_TYPE.DELETE_GROUP,
      msg_id: detailMessage?.id,
      type: "action",
      user_id: userInformation.id,
      uuid: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
    };
    sip.emit("actions", JSON.stringify(deleteGroup));
    await groupDetail.refetch();
    await groupQuery.refetch();
    const location = await getListGroupChat();
    navigate(`/t/${encodeID(location?.groups[0]?.id)}`);
    handleCloseHeaderPopper();
  };
  // handleLeaveAndRemoveGroupChat
  const handleLeaveAndRemoveGroupChat = async (idGroup) => {
    const deleteGroup = {
      group_id: idGroup,
      value: ACTION_TYPE.LEAVE_GROUP,
      msg_id: detailMessage?.id,
      type: "action",
      user_id: userInformation.id,
      uuid: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
    };
    // eslint-disable-next-line no-use-before-define
    postMessageToOtherTabs(idGroup);
    sip.emit("actions", JSON.stringify(deleteGroup));
    await groupDetail.refetch();
    await groupQuery.refetch();
    const location = await getListGroupChat();
    navigate(`/t/${encodeID(location?.groups[0]?.id)}`);
    handleCloseHeaderPopper();
  };

  const postMessageToOtherTabs = (idGroup) => {
    // Gửi thông điệp đến tất cả các tab với dữ liệu cần chia sẻ
    window.postMessage({ action: "removeGroup", idGroup }, "*");
  };

  // Lắng nghe sự kiện message từ các tab khác
  window.addEventListener("message", (event) => {
    const { action, idGroup } = event.data;

    if (action === "removeGroup") {
      // Thực hiện các bước khác cần thiết để xóa nhóm
      // Có thể bao gồm cập nhật giao diện người dùng hoặc fetching danh sách nhóm đã cập nhật
      console.log(`Received message to remove group ${idGroup} from another tab`);
    }
  });
  // chat
  const handleMuteGroupChat = async (idGroup) => {
    // const detailGroup = groupDetail.data.detail || {};
    setGroupDetail((prev) => {
      const state = prev;
      state.detail.is_mute = prev?.detail.is_mute === TYPE_MUTE.TRUE ? 1 : 0;
      return prev;
    });
    setListGroup((prev) => {
      const index = prev.findIndex((item) => +item.id === +idGroup);
      if (index !== -1) {
        const group = [...prev];
        group[index].group.is_mute = +group[index].group.is_mute === TYPE_MUTE.TRUE ? 1 : 0;
        const body = {
          msg_id: idGroup,
          type: "action",
          user_id: userInformation?.id,
          value:
            +group[index].group.is_mute === TYPE_MUTE.FALSE
              ? CHAT_ACTIVE_TYPE.mute
              : CHAT_ACTIVE_TYPE.unMute,
          uuid: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
        };
        sip.emit("actions", JSON.stringify(body));
        return group;
      }
      return prev;
    });
  };
  const handleDropFiles = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOverlay(false);
    if (!e.dataTransfer.files) return;
    const { files } = e.dataTransfer;
    const message_files = await Promise.all(
      Object.keys(files).map(async (key, index) => {
        const formData = new FormData();
        let saveFile = {};
        if (files[key].type.includes(SPECIAL_FILE_TYPE.IMAGE)) {
          const image = new Image();
          image.src = window.URL.createObjectURL(files[key]);
          await image.decode();
          saveFile = {
            index,
            width: image.width,
            height: image.height,
            createdAt: Math.floor(Date.now() / 1000),
            fileType: "image",
          };
        } else if (files[key].type.includes(SPECIAL_FILE_TYPE.VIDEO)) {
          saveFile = {
            index,
            _id: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
            fileType: "video",
          };
        } else {
          saveFile = {
            index,
            _id: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
            typeFile: e.target.files[key].type,
            name: e.target.files[key].name,
            size: e.target.files[key].size,
            fileType: "file",
          };
        }

        formData.append("image", files[key], genUniqueName(files[key].name));
        return uploadImageMutate.mutateAsync(formData).then((value) => ({
          ...value,
          ...saveFile,
          url: `${UPLOAD_URL}/${value.path}`,
        }));
      })
    );
    // MESSAGE IMAGE
    const message_image = message_files
      .filter((v) => v.fileType === "image")
      .sort((a, b) => a.index - b.index)
      .map((v, i) => {
        const img = v;
        delete img.fileType;
        return { ...img, index: i };
      });
    const message_video = message_files
      .filter((v) => v.fileType === "video")
      .sort((a, b) => a.index - b.index)
      .map((v, i) => {
        const img = v;
        delete img.fileType;
        return { ...img, index: i };
      });
    const message_other = message_files
      .filter((v) => v.fileType === "file")
      .sort((a, b) => a.index - b.index)
      .map((v, i) => {
        const img = v;
        delete img.fileType;
        return { ...img, index: i };
      });
    if (!isEmpty(message_image)) {
      const message_time = new Date().getTime();
      const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
      const message_user_id = userInformation.id;
      const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
      const message_state = MESSAGE_STATE.PENDING;
      const message_user = {
        id: userInformation.id,
        avatar: userInformation.avatar,
        user_name: userInformation.user_name,
      };
      const sendImage = SendImageMessage({
        message_image,
        message_group_id,
        message_time,
        message_user_id,
        message_uuid,
      });
      const saveImage = SaveImageMessage({
        message_group_id,
        message_state,
        message_image,
        message_time,
        message_user,
        message_user_id,
        message_uuid,
      });

      // send message
      const newImage = {
        _id: message_uuid,
        client_id: message_uuid,
        group_id: message_group_id,
        message: saveImage,
        date: message_time * 1000,
        state: message_state,
        kind: saveImage.kind,
      };

      setListMessage((prev) => [...prev, { ...newImage }]);
      mutationData(newImage);

      sip.emit("messenge", JSON.stringify(sendImage));
      // save last message
      setListGroup((prev) => {
        const groupCurrent = prev.find((item) => +item.id === +message_group_id);
        const groupOther = prev.filter((item) => +item.id !== +message_group_id);
        return [
          {
            ...groupCurrent,
            last_message: {
              ...saveImage,
              text: GetLastMessage("system_message.image_message_text"),
              isUnread: false,
            },
          },
          ...groupOther,
        ];
      });

      if (chatRef.current?.scrollTop < -100) {
        setTimeout(() => chatRef?.current?.scroll({ top: 0, behavior: "instant" }), 100);
      }
    }
    // MESSAGE VIDEO
    if (!isEmpty(message_video)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const value of message_video) {
        const message_time = new Date().getTime();
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
        const message_state = MESSAGE_STATE.PENDING;
        const message_user = {
          id: userInformation.id,
          avatar: userInformation.avatar,
          user_name: userInformation.user_name,
        };
        const sendVideo = SendVideoMessage({
          message_video: value,
          message_group_id,
          message_time,
          message_user_id,
          message_uuid,
        });
        const saveVideo = SaveVideoMessage({
          message_group_id,
          message_state,
          message_video: value,
          message_time,
          message_user,
          message_user_id,
          message_uuid,
        });

        // send message

        const newVideo = {
          _id: message_uuid,
          client_id: message_uuid,
          group_id: message_group_id,
          message: saveVideo,
          date: message_time * 1000,
          state: message_state,
          kind: saveVideo.kind,
        };
        setListMessage((prev) => [...prev, { ...newVideo }]);

        mutationData(newVideo);

        sip.emit("messenge", JSON.stringify(sendVideo));
        // save last message
        setListGroup((prev) => {
          const groupCurrent = prev.find((item) => +item.id === +message_group_id);
          const groupOther = prev.filter((item) => +item.id !== +message_group_id);
          return [
            {
              ...groupCurrent,
              last_message: {
                ...saveVideo,
                text: GetLastMessage("system_message.video_message_text"),
                isUnread: false,
              },
            },
            ...groupOther,
          ];
        });

        if (chatRef.current?.scrollTop < -100)
          chatRef?.current?.scroll({ top: 0, behavior: "instant" });
      }
    }
    // MESSAGE FILE
    if (!isEmpty(message_other)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const value of message_other) {
        const message_time = new Date().getTime();
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
        const message_state = MESSAGE_STATE.PENDING;
        const message_user = {
          id: userInformation.id,
          avatar: userInformation.avatar,
          user_name: userInformation.user_name,
        };
        const sendFile = SendFileMessage({
          message_other: value,
          message_group_id,
          message_time,
          message_user_id,
          message_uuid,
        });
        const saveFile = SaveFileMessage({
          message_group_id,
          message_state,
          message_other: value,
          message_time,
          message_user,
          message_user_id,
          message_uuid,
        });

        // send message

        const newFile = {
          _id: message_uuid,
          client_id: message_uuid,
          group_id: message_group_id,
          message: saveFile,
          date: message_time * 1000,
          state: message_state,
          kind: saveFile.kind,
        };
        setListMessage((prev) => [...prev, { ...newFile }]);

        sip.emit("messenge", JSON.stringify(sendFile));
        // save last message
        setListGroup((prev) => {
          const groupCurrent = prev.find((item) => +item.id === +message_group_id);
          const groupOther = prev.filter((item) => +item.id !== +message_group_id);
          return [
            {
              ...groupCurrent,
              last_message: {
                ...saveFile,
                text: GetLastMessage("system_message.file_message_text"),
                isUnread: false,
              },
            },
            ...groupOther,
          ];
        });

        if (chatRef.current?.scrollTop < -100)
          setTimeout(() => chatRef?.current?.scroll({ top: 0, behavior: "instant" }), 100);
      }
    }
    // eslint-disable-next-line no-param-reassign
    e.target.value = "";
  };
  const handleDragEnterFiles = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOverlay(true);
  };
  const handleDragLeaveFiles = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOverlay(false);
  };
  const handleMessageClose = () => {
    setMouseX(0);
    setMouseY(0);
    setOpenMessageSetting(false);
  };

  const handleOpenMessagePopper = (event, isOwner, infoMessage = null) => {
    event.stopPropagation();
    setMouseX(event.clientX);
    setMouseY(event.clientY);
    setSelectOwner(isOwner);
    event.preventDefault();
    setDetailMessage(infoMessage);
    setOpenMessageSetting(true);
  };
  const handleViewerClose = () => setOpenViewerDialog(false);
  const handleVotingClose = () => setOpenVoteInfoDialog(false);
  const handleOpenViewerDialog = (infoMessage = null) => {
    setDetailMessage(infoMessage);
    setOpenViewerDialog(true);
    setOpenMessageSetting(false);
  };

  const handleDownMediaMessage = async () => {
    let url = "";
    if (detailMessage?.kind === MESSAGE_TYPE.VIDEO) {
      url = detailMessage?.video.url;
    }
    if (detailMessage?.kind === MESSAGE_TYPE.AUDIO) {
      url = detailMessage?.audio.url;
    }
    if (detailMessage?.kind === MESSAGE_TYPE.IMAGE) {
      url = detailMessage?.image.map((i) => i.url);
    }

    await downloadMedia(url);

    handleMessageClose();
  };
  const handleSend = async () => {
    // send voice
    if (isVoice) {
      setTime(recordingTime);
      stopRecording();
      setIsVoice(false);
      return;
    }
    // send message edit
    if (openEditMessage) {
      const message_edit = {
        msg_id: detailMessage?.id,
        value: CHAT_ACTIVE_TYPE.edit,
        text: textRef?.current?.value?.trim(),
        user_id: userInformation?.id,
        type: "action",
        uuid: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
      };
      sip.emit("actions", JSON.stringify(message_edit));
      // reset
      setOpenEditMessage(false);
      if (textRef?.current) textRef.current.value = "";
      textRef.current.focus();
      return;
    }
    // send message
    if (textRef?.current?.value?.trim() !== "") {
      const list_user_group = groupDetail?.data?.detail?.users.map((item) => ({
        name: `${item.username} - ${item.id}`,
        id: item.id,
      }));
      // Tạo một biến mới để lưu trữ kết quả
      let result = textRef?.current?.value;
      // Lặp qua từng phần tử trong mảng list_user_group
      list_user_group.forEach((item) => {
        // Tạo một biểu thức chính quy để tìm kiếm cụm từ bắt đầu bằng @ và tên trong danh sách list_user_group
        const regex = new RegExp(`@${item.name}(?=\\s|$)`, "g");
        // Thay thế các cụm từ bắt đầu bằng @ bằng chuỗi mới
        result = result.replace(regex, `[@${item.name.split(" - ")[0]}:${item.id}]`);
      });
      // const message_text = result?.trim();

      let message_text = result?.trim();

      // Kiểm tra nếu tin nhắn không kết thúc bằng xuống dòng, thêm xuống dòng vào cuối tin nhắn
      if (!/\n$/.test(message_text)) {
        message_text += "\n";
      }

      const message_time = new Date().getTime();
      const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
      const message_user_id = userInformation.id;
      const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
      const message_state = MESSAGE_STATE.SENDING;
      const message_reply_id = openReplyMessage ? messageReplyContent?._id : null;
      const message_url_preview = urlRegex.test(message_text)
        ? await websitePreview({ uri: urlRegex.exec(message_text)[0] })
        : null;
      const message_user = {
        id: userInformation.id,
        avatar: userInformation.avatar,
        user_name: userInformation.username || userInformation.user_name,
      };

      const reply = openReplyMessage ? detailMessage : null;
      const sendMessage = SendTextMessage({
        message_text,
        message_group_id,
        message_time,
        message_user_id,
        message_uuid,
        message_reply_id,
      });
      const saveMessage = SaveTextMessage({
        message_group_id,
        message_state,
        message_text,
        message_time,
        message_user,
        message_user_id,
        message_uuid,
        message_url_preview,
        message_reply_id,
        edited: !!openEditMessage,
      });
      const newLink = {
        _id: message_uuid,
        client_id: message_uuid,
        group_id: message_group_id,
        message: { ...saveMessage, reply },
        date: message_time * 1000,
        state: message_state,
        kind: saveMessage.kind,
        user_id: saveMessage.user_id,
        reply,
      };
      setListMessage((prev) => [...prev, { ...newLink }]);

      sip.emit("messenge", JSON.stringify(sendMessage));
    }
    // send image
    if (images.length > 0) {
      if (isEmpty(images)) return;
      const message_image = await Promise.all(
        Object.keys(images).map(async (key, index) => {
          const formData = new FormData();
          const image = new Image();
          image.src = window.URL.createObjectURL(images[key]);
          await image.decode();
          formData.append("image", images[key], `${v4()}.png`);
          return uploadImageMutate.mutateAsync(formData).then((value) => ({
            index,
            url_local: null,
            url_original: null,
            width: image.width,
            height: image.height,
            createdAt: new Date().getTime(),
            url: `${UPLOAD_URL}/${value.path}`,
          }));
        })
      );
      const message_time = new Date().getTime();
      const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
      const message_user_id = userInformation.id;
      const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
      const message_state = MESSAGE_STATE.SENDING;
      const message_reply_id = openReplyMessage ? messageReplyContent?._id : null;
      const message_user = {
        id: userInformation.id,
        avatar: userInformation.avatar,
        user_name: userInformation.user_name,
      };
      const reply = openReplyMessage ? detailMessage : null;
      const sendImage = SendImageMessage({
        message_image,
        message_group_id,
        message_time,
        message_user_id,
        message_uuid,
        message_reply_id,
      });
      const saveImage = SaveImageMessage({
        message_group_id,
        message_state,
        message_image,
        message_time,
        message_user,
        message_user_id,
        message_uuid,
        message_reply_id,
      });
      sip.emit("messenge", JSON.stringify(sendImage));
      setListMessage((prev) => [
        ...prev,
        {
          _id: message_uuid,
          client_id: message_uuid,
          group_id: message_group_id,
          message: saveImage,
          date: message_time * 1000,
          state: message_state,
          kind: saveImage.kind,
          user_id: saveImage.user_id,
          reply,
        },
      ]);
      setListGroup((prev) => {
        const groupCurrent = prev.find((item) => +item.id === +message_group_id);
        const groupOther = prev.filter((item) => +item.id !== +message_group_id);
        return [
          {
            ...groupCurrent,
            last_message: {
              ...saveImage,
              text: GetLastMessage("system_message.image_message_text"),
              isUnread: false,
            },
          },
          ...groupOther,
        ];
      });

      setImages([]);
      if (chatRef.current?.scrollTop < -100) {
        setTimeout(() => chatRef?.current?.scroll({ top: 0, behavior: "instant" }), 100);
      }
    }
    // reset
    if (textRef?.current) textRef.current.value = "";
    textRef.current.focus();
    if (openReplyMessage) {
      setOpenReplyMessage(false);
    }
  };
  const handleFeeMessage = async (fee) => {
    const body = {
      msg_id: detailMessage.id,
      value_action: fee,
      type: "action",
      user_id: userInformation?.id,
      value: CHAT_ACTIVE_TYPE.love,
      uuid: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
    };
    const uFee = detailMessage.likeUsers?.find((item) => +item.id === +userInformation.id);
    if (uFee && +uFee.value === +fee) {
      body.value = CHAT_ACTIVE_TYPE.unlike;
    }
    sip.emit("actions", JSON.stringify(body));
    handleMessageClose();
  };

  const handleDeleteMessage = async (value) => {
    const body = {
      msg_id: detailMessage.id,
      type: "action",
      user_id: userInformation?.id,
      value,
      uuid: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
    };
    sip.emit("actions", JSON.stringify(body));
    handleMessageClose();
  };

  const handleOpenTipMessage = async () => {
    handleMessageClose();
    setOpenTipMessage((prev) => !prev);
  };
  const handleOpenVoteInfo = async (value) => {
    setOpenVoteInfoDialog(true);
    setOpenMessageSetting(false);
    setVoteInfo(value);
  };
  // forward dialog
  const handleCloseForwardDialog = () => {
    setSelectForwardIndex([]);
    setOpenForwardDialog(false);
  };
  const handleOpenForwardDialog = (infoMessage) => {
    setOpenForwardDialog(true);
    setDetailMessage(infoMessage);
    handleMessageClose();
  };
  const featchDataPhoneBookUser = async () => {
    try {
      const data = await getListPhoneBookUser({
        uid: userInformation?.id,
        page: 1,
        number: 10000,
        max_id_sync_book: 0,
      });
      setListDataPhoneBook(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Filter the data based on name and user_id when there is a search term
    if (searchPhoneBook) {
      const filteredResultsUserPhoneBook = listDataPhoneBook.filter((item) => {
        const namePhoneBook = item.name?.toLowerCase().includes(searchPhoneBook?.toLowerCase());
        const userIdPhoneBook = item.user_id.toString().includes(searchPhoneBook);
        return namePhoneBook || userIdPhoneBook;
      });
      setFilteredDataUserPhoneBook(filteredResultsUserPhoneBook);
    } else {
      // Display all users when there is no search term
      setFilteredDataUserPhoneBook(listDataPhoneBook);
    }
  }, [searchPhoneBook]); // Run this effect whenever searchPhoneBook changes
  const handleDoneForwardDialog = async () => {
    if (selectForwardIndex.length > 0) {
      const message_text_link = sharedLink || detailMessage?.text?.trim() || "";
      if (message_text_link) {
        const message_text = message_text_link;
        const message_time = new Date().getTime();
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        selectForwardIndex.forEach((item) => {
          const message_group_id = +decodeID(encodeID(item));
          const sendMessage = SendTextMessage({
            message_text,
            message_group_id,
            message_time,
            message_user_id,
            message_uuid,
          });
          sip.emit("messenge", JSON.stringify(sendMessage));
        });
      }
      if (detailMessage?.image?.length > 0) {
        // Kiểm tra nếu detailMessage.image không null và không rỗng
        const message_image = detailMessage?.image;
        const message_time = new Date().getTime();
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        selectForwardIndex.forEach((item) => {
          const message_group_id = +decodeID(encodeID(item));
          const sendImage = SendImageMessage({
            message_image,
            message_group_id,
            message_time,
            message_user_id,
            message_uuid,
          });
          sip.emit("messenge", JSON.stringify(sendImage));
        });
      }
      if (detailMessage?.video) {
        const message_video = detailMessage?.video;
        const message_time = new Date().getTime();
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        selectForwardIndex.forEach((item) => {
          const message_group_id = +decodeID(encodeID(item));
          const sendVideo = SendVideoMessage({
            message_video,
            message_group_id,
            message_time,
            message_user_id,
            message_uuid,
          });
          sip.emit("messenge", JSON.stringify(sendVideo));
        });
      }

      if (detailMessage?.file) {
        const message_time = new Date().getTime();
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        const message_other = detailMessage?.file;
        selectForwardIndex.forEach((item) => {
          const message_group_id = +decodeID(encodeID(item));

          const sendFile = SendFileMessage({
            message_other,
            message_group_id,
            message_time,
            message_user_id,
            message_uuid,
          });
          sip.emit("messenge", JSON.stringify(sendFile));
        });
      }

      if (detailMessage?.audio) {
        const message_time = new Date().getTime();
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        const message_audio = detailMessage?.audio;
        selectForwardIndex.forEach((item) => {
          const message_group_id = +decodeID(encodeID(item));
          const sendAudio = SendAudioMessage({
            message_audio,
            message_group_id,
            message_time,
            message_user_id,
            message_uuid,
          });
          sip.emit("messenge", JSON.stringify(sendAudio));
        });
      }
    }
    setOpenForwardDialog(false);
    setSelectForwardIndex([]);
    setSharedLink("");
  };

  const handleDoneSendMessageTextDialog = async (messageText) => {
    if (selectForwardIndex.length > 0) {
      if (messageText) {
        const message_text = messageText;
        const message_time = new Date().getTime();
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        selectForwardIndex.forEach((item) => {
          const message_group_id = +decodeID(encodeID(item));
          const sendMessage = SendTextMessage({
            message_text,
            message_group_id,
            message_time,
            message_user_id,
            message_uuid,
          });
          sip.emit("messenge", JSON.stringify(sendMessage));
        });
      }
    }
    // setSe(false);
    setSelectForwardIndex([]);
  };
  const handleOpenPinMessage = async () => {
    const res = await addPin({
      uid: getUserToken().id,
      id: +decodeID(vID),
      message_id: detailMessage.id,
    });
    if (res.error === 0) {
      listPin.refetch();
    }
    handleMessageClose();
  };

  const handleScrollPin = async (pin) => {
    const [scrollPin] = document.getElementsByClassName(`id-message-${pin._id}`);
    if (openDialogPinMessage) {
      setOpenDialogPinMessage(false);
    }

    if (!scrollPin) {
      const res = await getDetailMess(pin._id, userInformation.id);
      if (!res.item) {
        setOpenError(true);
        setTimeout(() => {
          setOpenError(false);
        }, 5000);
        return;
      }
    }

    if (scrollPin) {
      scrollPin.scrollIntoView({ behavior: "instant", block: "center", inline: "nearest" });
      scrollPin.style.backgroundColor = "#9bb5f6";
      setTimeout(() => {
        scrollPin.style.backgroundColor = "";
      }, 1500);
    } else {
      const scrollInterval = setInterval(() => {
        chatRef.current.scrollTo({
          top: chatRef.current.clientHeight - chatRef.current.scrollHeight,
          behavior: "instant",
        });
        const [scroll] = document.getElementsByClassName(`id-message-${pin._id}`);
        if (scroll) {
          clearInterval(scrollInterval);
          scroll.style.backgroundColor = "#9bb5f6";
          scroll.scrollIntoView({ behavior: "instant", block: "center", inline: "nearest" });
          setTimeout(() => {
            scroll.style.backgroundColor = ""; // Reset background color after 2 seconds
          }, 1500);
        }
      }, 100);
    }
  };

  // list-member-dialog
  const handleOpenListMember = () => {
    setOpenInformation(true);
    setOpenListMemberDialog(true);
    setOpenAddMemberChat(false);
  };
  const handleOpenMultimedia = () => {
    setOpenMultimediaDialog(true);
    setOpenInformation(true);
    setOpenListMemberDialog(false);
    setOpenAddMemberChat(false);
  };
  const handleOpenListMemberPendingDialog = () => {
    setOpenListMemberPendingDialog(true);
    setOpenMultimediaDialog(false);
    setOpenInformation(true);
    setOpenListMemberDialog(false);
    setOpenAddMemberChat(false);
  };
  const handleOpenSettingGroup = () => {
    setOpenListMemberPendingDialog(false);
    setOpenMultimediaDialog(false);
    setOpenInformation(true);
    setOpenListMemberDialog(false);
    setOpenAddMemberChat(false);
    setIsOpenSetting(true);
  };
  const handleCloseSettingGroup = () => {
    setIsOpenSetting(false);
  };
  const handleCloseListMember = () => {
    setOpenListMemberDialog(false);
  };
  const handleCloseMultimedia = () => {
    setOpenMultimediaDialog(false);
  };
  const handleCloseListMemberPending = () => {
    setOpenListMemberPendingDialog(false);
  };
  // footer
  const handleUploadImage = async (e) => {
    if (isEmpty(e?.target?.files)) return;

    const message_files = await Promise.all(
      Object.keys(e.target.files).map(async (key, index) => {
        const formData = new FormData();
        let saveFile = {};

        if (e.target.files[key].type.includes(SPECIAL_FILE_TYPE.IMAGE)) {
          const image = new Image();
          image.src = window.URL.createObjectURL(e.target.files[key]);
          await image.decode();
          saveFile = {
            index,
            width: image.width,
            height: image.height,
            createdAt: Math.floor(Date.now() / 1000),
            //
            fileType: "image",
          };
        } else if (e.target.files[key].type.includes(SPECIAL_FILE_TYPE.VIDEO)) {
          saveFile = {
            index,
            _id: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
            fileType: "video",
          };
        } else {
          saveFile = {
            index,
            _id: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
            typeFile: e.target.files[key].type,
            name: e.target.files[key].name,
            size: e.target.files[key].size,
            fileType: "file",
          };
        }

        formData.append("image", e.target.files[key], genUniqueName(e.target.files[key].name));

        return uploadImageMutate.mutateAsync(formData).then((value) => ({
          ...saveFile,
          url: `${UPLOAD_URL}/${value.path}`,
          url_thumb: value.url_thumb,
        }));
      })
    );

    // MESSAGE IMAGE
    const message_image = message_files
      .filter((v) => v.fileType === "image")
      .sort((a, b) => a.index - b.index)
      .map((v, i) => {
        const img = v;
        delete img.fileType;
        return { ...img, index: i };
      });
    const message_video = message_files
      .filter((v) => v.fileType === "video")
      .sort((a, b) => a.index - b.index)
      .map((v, i) => {
        const img = v;
        delete img.fileType;
        return { ...img, index: i };
      });
    const message_other = message_files
      .filter((v) => v.fileType === "file")
      .sort((a, b) => a.index - b.index)
      .map((v, i) => {
        const img = v;
        delete img.fileType;
        return { ...img, index: i };
      });
    if (!isEmpty(message_image)) {
      const message_time = new Date().getTime();
      const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
      const message_user_id = userInformation.id;
      const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
      const message_state = MESSAGE_STATE.PENDING;
      const message_reply_id = openReplyMessage ? messageReplyContent?._id : null;
      const message_user = {
        id: userInformation.id,
        avatar: userInformation.avatar,
        user_name: userInformation.username || userInformation.user_name,
      };
      const reply = openReplyMessage ? detailMessage : null;
      const sendImage = SendImageMessage({
        message_image,
        message_group_id,
        message_time,
        message_user_id,
        message_uuid,
        message_reply_id,
      });
      const saveImage = SaveImageMessage({
        message_group_id,
        message_state,
        message_image,
        message_time,
        message_user,
        message_user_id,
        message_uuid,
        message_reply_id,
      });

      // send message
      sip.emit("messenge", JSON.stringify(sendImage));
      setListMessage((prev) => [
        ...prev,
        {
          _id: message_uuid,
          client_id: message_uuid,
          group_id: message_group_id,
          message: saveImage,
          date: message_time * 1000,
          state: message_state,
          kind: saveImage.kind,
          user_id: saveImage.user_id,
          reply,
        },
      ]); // save last message
      setListGroup((prev) => {
        const groupCurrent = prev.find((item) => +item.id === +message_group_id);
        const groupOther = prev.filter((item) => +item.id !== +message_group_id);
        return [
          {
            ...groupCurrent,
            last_message: {
              ...saveImage,
              text: GetLastMessage("system_message.image_message_text"),
              isUnread: false,
            },
          },
          ...groupOther,
        ];
      });

      if (chatRef.current?.scrollTop < -100) {
        setTimeout(() => chatRef?.current?.scroll({ top: 0, behavior: "instant" }), 100);
      }
      if (openReplyMessage) {
        setOpenReplyMessage(false);
      }
    }
    // MESSAGE VIDEO
    if (!isEmpty(message_video)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const value of message_video) {
        const message_time = new Date().getTime();
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
        const message_state = MESSAGE_STATE.PENDING;
        const message_reply_id = openReplyMessage ? messageReplyContent?._id : null;
        const message_user = {
          id: userInformation.id,
          avatar: userInformation.avatar,
          user_name: userInformation.username || userInformation.user_name,
        };
        const reply = openReplyMessage ? detailMessage : null;
        const sendVideo = SendVideoMessage({
          message_video: value,
          message_group_id,
          message_time,
          message_user_id,
          message_uuid,
          message_reply_id,
        });

        const saveVideo = SaveVideoMessage({
          message_group_id,
          message_state,
          message_video: value,
          message_time,
          message_user,
          message_user_id,
          message_uuid,
          message_reply_id,
        });

        // send message
        sip.emit("messenge", JSON.stringify(sendVideo));
        setListMessage((prev) => [
          ...prev,
          {
            _id: message_uuid,
            client_id: message_uuid,
            group_id: message_group_id,
            message: saveVideo,
            date: message_time * 1000,
            state: message_state,
            kind: saveVideo.kind,
            user_id: saveVideo.user_id,
            reply,
          },
        ]);
        // save last message
        setListGroup((prev) => {
          const groupCurrent = prev.find((item) => +item.id === +message_group_id);
          const groupOther = prev.filter((item) => +item.id !== +message_group_id);
          return [
            {
              ...groupCurrent,
              last_message: {
                ...saveVideo,
                text: GetLastMessage("system_message.video_message_text"),
                isUnread: false,
              },
            },
            ...groupOther,
          ];
        });

        if (chatRef.current?.scrollTop < -100) {
          setTimeout(() => chatRef?.current?.scroll({ top: 0, behavior: "instant" }), 100);
        }
        if (openReplyMessage) {
          setOpenReplyMessage(false);
        }
      }
    }
    // MESSAGE FILE
    if (!isEmpty(message_other)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const value of message_other) {
        const message_time = new Date().getTime();
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
        const message_state = MESSAGE_STATE.PENDING;
        const message_reply_id = openReplyMessage ? messageReplyContent?._id : null;
        const message_user = {
          id: userInformation.id,
          avatar: userInformation.avatar,
          user_name: userInformation.username || userInformation.user_name,
        };
        const reply = openReplyMessage ? detailMessage : null;
        const sendFile = SendFileMessage({
          message_other: value,
          message_group_id,
          message_time,
          message_user_id,
          message_uuid,
          message_reply_id,
        });
        const saveFile = SaveFileMessage({
          message_group_id,
          message_state,
          message_other: value,
          message_time,
          message_user,
          message_user_id,
          message_uuid,
          message_reply_id,
        });

        // send message
        sip.emit("messenge", JSON.stringify(sendFile));
        setListMessage((prev) => [
          ...prev,
          {
            _id: message_uuid,
            client_id: message_uuid,
            group_id: message_group_id,
            message: saveFile,
            date: message_time * 1000,
            state: message_state,
            kind: saveFile.kind,
            user_id: saveFile.user_id,
            reply,
          },
        ]);
        // save last message
        setListGroup((prev) => {
          const groupCurrent = prev.find((item) => +item.id === +message_group_id);
          const groupOther = prev.filter((item) => +item.id !== +message_group_id);
          return [
            {
              ...groupCurrent,
              last_message: {
                ...saveFile,
                text: GetLastMessage("system_message.file_message_text"),
                isUnread: false,
              },
            },
            ...groupOther,
          ];
        });

        if (chatRef.current?.scrollTop < -100) {
          setTimeout(() => chatRef?.current?.scroll({ top: 0, behavior: "instant" }), 100);
        }
        if (openReplyMessage) {
          setOpenReplyMessage(false);
        }
      }
    }

    // eslint-disable-next-line no-param-reassign
    e.target.value = "";
  };
  const handleCloseEditMessage = () => {
    setOpenEditMessage(false);
    if (textRef?.current) textRef.current.value = "";
    setPrevText("");
  };
  const handleCloseReplyMessage = () => {
    setOpenReplyMessage(false);
    setMessageReplyContent({});
  };
  const handleOpenReplyMessage = (messSelect) => {
    const message = messSelect.text;
    const imageUrl = messSelect.image?.[0]?.url;
    const fileName = messSelect?.file?.name;
    const userName = messSelect.user.username;
    const userID = messSelect?.user.id;
    handleCloseEditMessage();
    setMessageReplyContent({
      ...messSelect,
      replyName: userName,
      userID,
      message,
      imageUrl,
      fileName,
    });
    setOpenReplyMessage(true);
    handleMessageClose();
  };

  const handleOpenEditMessage = () => {
    const message_text_link = sharedLink || detailMessage?.text?.trim() || "";
    const message = !isEmpty(message_text_link) ? message_text_link : "";
    handleCloseReplyMessage();
    if (textRef?.current) textRef.current.value = message_text_link;
    setPrevText(message);
    setOpenEditMessage(true);
    handleMessageClose();
  };
  const onPaste = (e) => {
    const clipboardItems = e.clipboardData.items;
    const items = [].slice
      .call(clipboardItems)
      ?.filter((item) => item.type.indexOf("image") !== -1);
    if (items.length === 0) return;
    const file = items[0].getAsFile();
    file.preview = URL.createObjectURL(file);
    setImages([...images, file]);
  };
  const handleDeleteImage = (e, index) => {
    const cpList = [...images];
    cpList.splice(index, 1);
    setImages(cpList);
  };

  const handleKeypress = (event) => {
    if (event && event.key === "Enter" && !event.nativeEvent.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };
  const handleReadAllMessage = () => chatRef.current.scroll({ top: 0, behavior: "instant" });
  const handleTipMessage = (value) => {
    const body = {
      msg_id: detailMessage.id,
      value_action: value,
      type: "action",
      user_id: userInformation?.id,
      value: CHAT_ACTIVE_TYPE.tip,
      uuid: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
    };
    sip.emit("actions", JSON.stringify(body));
    sip.emit("money", JSON.stringify({ user_ids: [userInformation?.id] }));
  };
  const handleSendTicker = (stickerIndex, index) => {
    const message_emoji = { stickerIndex, index };
    const message_time = new Date().getTime();
    const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
    const message_user_id = userInformation.id;
    const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
    const message_state = MESSAGE_STATE.SENDED;
    const message_reply_id = openReplyMessage ? messageReplyContent?._id : null;
    const message_user = {
      id: userInformation.id,
      avatar: userInformation.avatar,
      user_name: userInformation.username || userInformation.user_name,
    };
    const reply = openReplyMessage ? detailMessage : null;
    const sendMessage = SendEmojiMessage({
      message_emoji,
      message_group_id,
      message_time,
      message_user_id,
      message_uuid,
      message_reply_id,
    });
    const saveMessage = SaveEmojiMessage({
      message_group_id,
      message_state,
      message_emoji,
      message_time,
      message_user,
      message_user_id,
      message_uuid,
      message_reply_id,
    });
    setListMessage((prev) => [
      ...prev,
      {
        _id: message_uuid,
        client_id: message_uuid,
        group_id: message_group_id,
        message: saveMessage,
        date: message_time * 1000,
        state: message_state,
        kind: saveMessage.kind,
        user_id: saveMessage.user_id,
        reply,
      },
    ]);
    console.log("sendMessageEmoji", sendMessage);
    sip.emit("messenge", JSON.stringify(sendMessage));
    if (chatRef.current?.scrollTop < -100) {
      setTimeout(() => chatRef?.current?.scroll({ top: 0, behavior: "instant" }), 100);
    }
    if (openReplyMessage) {
      setOpenReplyMessage(false);
    }
  };
  const handleVotingMessage = (selectMulti, question, listVoteFinal) => {
    const message_voting = {
      c: question,
      t: new Date().getTime() + 36000,
      o: listVoteFinal,
    };
    const sendMessageVote = SendVotingMessage({
      message_voting,
      message_time: new Date().getTime(),
      message_uuid: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
      message_user_id: userInformation.id,
      message_group_id: +decodeID(window.location.pathname.replace("/t/", "")),
    });
    const saveMessageVote = SaveVotingMessage({
      message_uuid: sendMessageVote.i,
      message_time: sendMessageVote.time,
      message_group_id: sendMessageVote.g,
      message_user: {
        id: userInformation.id,
        avatar: userInformation.avatar,
        user_name: userInformation.username || userInformation.user_name,
      },
      message_user_id: sendMessageVote.u,
      message_state: MESSAGE_STATE.SENDED,
      message_voting,
    });

    setListMessage((prev) => [
      ...prev,
      {
        _id: sendMessageVote.i,
        client_id: sendMessageVote.i,
        group_id: sendMessageVote.g,
        message: saveMessageVote,
        date: sendMessageVote.time * 1000,
        state: MESSAGE_STATE.SENDED,
        kind: saveMessageVote.kind,
        user_id: sendMessageVote.u,
      },
    ]);

    sip.emit("messenge", JSON.stringify(sendMessageVote));
  };
  const handleChangeVote = (message, index, value) => {
    const body = {
      msg_id: message.id,
      index,
      type: "action",
      user_id: userInformation?.id,
      value,
      uuid: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
    };
    sip.emit("actions", JSON.stringify(body));
    console.log("CHANGE VOTE");
  };
  const handleToggleInformation = () => {
    setOpenInformation((prev) => !prev);
    setTypeMedia(MESSAGE_TYPE_MEDIA.IMAGE);
    setMediaLimit(10);
    if (isOpenInformation) closeAllDialogInformation();
  };

  // information-add-member-chat
  const handleSetSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const handleToggleAddMemberChat = (group = true) => {
    if (group) {
      setOpenAddMemberChat((prev) => !prev);
      setSelectIndex([]);
    } else {
      setOpenAddMemberChat(false);
    }
  };
  const handleOpenAddMemberChat = () => {
    setOpenAddMemberChat(true);
    setOpenListMemberDialog(false);
    setSelectIndex([]);
  };
  const handleAddMemberChat = (id) => {
    if (selectIndex.length > 0) {
      AddMember({
        user_ids: selectIndex.map((item) => item.id),
        permission: 0,
        group_id: id,
      }).then((res) => {
        if (res.error === 0) {
          groupDetail.refetch();
          setOpenAddMemberChat(false);
          setOpenInformation(false);
          setSelectIndex([]);
        }
      });
    }
    groupQuery.refetch();
  };

  const handleChangePermissionGroup = async (userId, groupId) => {
    const res = await ChangePermission({
      user_id: userId,
      permission: groupDetail.data.group.admin_ids.includes(userId) ? 0 : 1,
      group_id: groupId,
    });
    if (res.error === 0) {
      setOpenInformation(true);
      await groupDetail.refetch();
      await groupQuery.refetch();
    }
  };

  const handleRemoveUser = async (userId, groupId) => {
    const res = await RemoveMember({
      user_ids: typeof userId === "object" ? userId : [userId],
      group_id: groupId,
    });
    if (res.error === 0) {
      setOpenInformation(true);
      await groupDetail.refetch();
      await groupQuery.refetch();
      if (userId === userInformation.id) {
        const location = await getListGroupChat();
        navigate(`/t/${encodeID(location?.groups[0]?.id)}`);
      }
    }
  };

  useEffect(() => {
    if (infoChanel?.flight?.score) {
      setListPoint(infoChanel?.flight?.score);
    }
  }, [infoChanel?.flight?.score]);

  const updateScoreInputPoint = async (list) => {
    const body = {
      scores: list,
      channel_id: infoChanel?.flight?.id,
    };
    await updateScore(body);
    await groupDetail.refetch();
  };

  const sendEventScoreInputPoint = async (list) => {
    const body = {
      channel_id: infoChanel?.flight?.id,
      score: {
        score: list,
        channel_id: infoChanel?.flight?.id,
      },
    };
    const res = await sendEventScore(body);
    if (res !== undefined && res.error === 0) {
      await updateScoreInputPoint(list);
    }
    await groupDetail.refetch();
  };

  const filteredRedScores = infoChanel?.flight.score?.filter((item) => item?.status === "red");
  const filteredBlueScores = infoChanel?.flight.score?.filter((item) => item?.status === "blue");

  const finishListPoint = () => {
    const newListPoint = listPoint.map((item) => {
      if (!item?.status) {
        return {
          ...item,
          active: false,
          status: "none",
        };
      }
      return {
        ...item,
        active: false,
      };
    });
    sendEventScoreInputPoint(newListPoint);
  };

  const confirmUpdatePoint = () => {
    if (
      listPoint[listPoint.length - 1]?.status ||
      filteredRedScores.length > 9 ||
      filteredBlueScores.length > 9
    ) {
      finishListPoint();
    } else {
      sendEventScoreInputPoint(listPoint);
    }
  };

  const handleSetSharedLink = (link) => {
    setSharedLink(link);
  };

  const handleOpenNotificationAdminDialog = () => {
    setOpenNotificationAdminDialog(true);
  };

  const handleCloseNotificationAdminDialog = () => {
    setOpenNotificationAdminDialog(false);
  };

  const [idMediaCurrent, setIdMediaCurrent] = useState("");
  const handleCloseMedia = (val) => {
    setIdMediaCurrent(val);
  };

  const listPendingJoinGroup = useQuery(
    ["listMemberPendingJoinGroup", userInformation?.id, groupDetail?.data?.detail?.id],
    () => listMemberPendingJoinGroup(userInformation?.id, groupDetail?.data?.detail?.id)
  );
  const handleRefuseMemberGroup = async (listUser) => {
    const body = {
      user_ids: listUser,
      group_id: groupDetail?.data?.detail?.id,
      state: 0,
    };
    await addMemberPendingJoinGroup(userInformation?.id, groupDetail?.data?.detail?.id, body);
    setTimeout(() => {
      listPendingJoinGroup.refetch();
    }, 100);
  };
  const handleApprovalMemberGroup = async (listUser) => {
    const body = {
      user_ids: listUser,
      group_id: groupDetail?.data?.detail?.id,
      state: 1,
    };
    await addMemberPendingJoinGroup(userInformation?.id, groupDetail?.data?.detail?.id, body);
    setTimeout(() => {
      listPendingJoinGroup.refetch();
    }, 100);
    setTimeout(() => {
      groupDetail.refetch();
      groupQuery.refetch();
    }, 1000); // 1000 milliseconds (1 second) delay
  };

  const addReporterChanelTv = async (idReporter) => {
    await addReporter(groupDetail?.data?.detail?.id, idReporter, userInformation?.id);
    setTimeout(() => {
      groupQuery.refetch();
    }, 100);
    // eslint-disable-next-line no-unreachable
    setTimeout(() => {
      groupDetail.refetch();
    }, 100);
  };
  const removeReporterChanelTv = async (idReporter) => {
    await removeReporter(groupDetail?.data?.detail?.id, idReporter, userInformation?.id);
    setTimeout(() => {
      groupQuery.refetch();
    }, 100);
    // eslint-disable-next-line no-unreachable
    setTimeout(() => {
      groupDetail.refetch();
    }, 100);
  };

  const updateSettingGroup = (data) => {
    const body = {
      id: data.id,
      group_permission: data.groupPermission,
      is_private: data.isPrivate,
      review: data.review,
    };
    updateInformationGroup(body);
    // setTimeout(() => groupDetail.refetch(), 1000);
    setTimeout(() => groupQuery.refetch(), 1000);
  };
  return (
    <Box
      sx={styles.container}
      onDragStart={overrideDefault}
      onDrop={overrideDefault}
      onDragLeave={overrideDefault}
      onDragOver={overrideDefault}
    >
      <NotificationRemoveMessage open={open} handleClose={() => setOpenError(false)} />

      <SettingDialog isOpen={isOpenSettingDialog} onClose={handleCloseSettingDialog} />
      {userInformation && (
        <InformationDialog
          isOpen={openInformationDialog}
          onClose={handleCloseInformationDialog}
          userInformation={userInformation}
        />
      )}
      <Fade in={!isSelectGroup || !isMobileSize} unmountOnExit timeout={500}>
        <Box>
          <UserList
            setSearchUser={setSearchUser}
            listGroupChat={searchUser ? filterGroup : listGroupDB}
            isLoading={groupQuery?.isLoading}
            searchOptions={{ handleSearchUserList }}
            addGroupChat={{
              searchGolferKey,
              setSearchGolferKey,
              handleSearchUser,
              handleAddSingleChat,
              handleAddSingleChatUserPhoneBook,
              isAddGroup,
              handleOpenAddGroup,
              handleCloseAddGroup,
              refetchRecent: groupQuery.refetch,
              handleAddGroupChat,
            }}
            selectOptions={{ handleSelect }}
            personalOptions={{
              isPersonalPopperOpen,
              handleOpenPersonalPopper,
              personalAnchor,
              handleClosePersonalPopper,
              handleLogout,
            }}
            listGroupAdd={listGolfer}
            conversationOptions={{
              isAddConversation,
              handleOpenMessage,
              handleOpenPhoneBook,
              handleOpenSetting,
            }}
            handleDoneForwardDialog={handleDoneForwardDialog}
            selectForwardIndex={selectForwardIndex}
            setSelectForwardIndex={setSelectForwardIndex}
            dialogOptions={{ handleOpenSettingDialog, handleOpenInformationDialog }}
            closeDialogOptions={{ handleCloseSettingDialog }}
            userInformation={userInformation}
            handleRemoveGroupChatMenu={handleRemoveGroupChat}
            handleLeaveAndRemoveGroupChatMenu={handleLeaveAndRemoveGroupChat}
            handleMuteGroupChat={handleMuteGroupChat}
            groupDetail={groupDetail}
            listDataPhoneBook={listDataPhoneBook}
            searchPhoneBook={searchPhoneBook}
            setSearchPhoneBook={setSearchPhoneBook}
            filteredData={filteredData}
            isOpenSettingDialog={isOpenSettingDialog}
            openInformationDialog={openInformationDialog}
            handleDoneSendMessageTextDialog={handleDoneSendMessageTextDialog}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            nameGroup={nameGroup}
            setNameGroup={setNameGroup}
            handleOpenNotificationAdminDialog={handleOpenNotificationAdminDialog}
            handleCloseNotificationAdminDialog={handleCloseNotificationAdminDialog}
            openNotificationAdminDialog={openNotificationAdminDialog}
            detailGroup={groupDetailDB?.detail || groupDetail?.data?.detail}
            handleChangePermissionGroup={handleChangePermissionGroup}
            handleRemoveUser={handleRemoveUser}
          />
        </Box>
      </Fade>
      <Fade in={(!isOpenInformation && isSelectGroup) || !isMobileSize} unmountOnExit timeout={500}>
        <Box sx={(e) => styles.header(e, { isHide: !isSelectGroup })}>
          <Header
            isMobileSize={isMobileSize}
            isOpenInformation={isOpenInformation}
            openSetting={openSetting}
            // anchor={anchor}
            isAddFriend={isAddFriend}
            isBlockFriend={isBlockFriend}
            openPinMessage={openPinMessage}
            onBack={() => handleSelect()}
            handleAddFriend={handleAddFriend}
            // handleOpenHeaderPopper={handleOpenHeaderPopper}
            handleCloseHeaderPopper={handleCloseHeaderPopper}
            handleToggleInformation={handleToggleInformation}
            messagePinContent={messagePinContent}
            openDialogDeletePinMessage={openDialogDeletePinMessage}
            handleOpenDeletePinMessage={handleOpenDeletePinMessage}
            handleDeletePinMessage={handleDeletePinMessage}
            openDialogPinMessage={openDialogPinMessage}
            handleDialogPinMessage={handleDialogPinMessage}
            handleOpenListMember={handleOpenListMember}
            detailGroup={groupDetail.data}
            listPin={listPin?.data?.list || []}
            handleScrollPin={handleScrollPin}
            userInformation={userInformation}
            isFetchingNewGroup={groupQuery?.isLoading}
            handleRemoveGroupChat={handleRemoveGroupChat}
            handleMuteGroupChat={handleMuteGroupChat}
            openDialogNotifyStatusPin={openDialogNotifyStatusPin}
            openDialogNotifyLoadingStatusPin={openDialogNotifyLoadingStatusPin}
            handleOpenNotifyPin={setOpenDialogNotifyStatusPin}
            handleOpenLoadingNotifyPin={setOpenDialogNotifyLoadingStatusPin}
            openChanelTv={openChanelTv}
            handleOpenChanelTv={handleOpenChanelTv}
            listChanel={listChanel}
            detailChanel={infoChanel}
            nameGroup={nameGroup}
          />
          <ContentChat
            mouseX={mouseX}
            mouseY={mouseY}
            isSelectOwner={isSelectOwner}
            openMessageSetting={openMessageSetting}
            openViewerDialog={openViewerDialog}
            openVoteInfoDialog={openVoteInfoDialog}
            isPaddingTop={isAddFriend || isBlockFriend || openPinMessage}
            openChanelTv={openChanelTv}
            unreadMessage={unreadMessage}
            chatRef={chatRef}
            handleDragLeaveFiles={handleDragLeaveFiles}
            handleDropFiles={handleDropFiles}
            handleDragEnterFiles={handleDragEnterFiles}
            isDragOverlay={isDragOverlay}
            isScrollEnd={isScrollEnd}
            selectForwardIndex={selectForwardIndex}
            isOpenForwardDialog={isOpenForwardDialog}
            handleDownMediaMessage={handleDownMediaMessage}
            handleMessageClose={handleMessageClose}
            handleOpenMessagePopper={handleOpenMessagePopper}
            handleOpenViewerDialog={handleOpenViewerDialog}
            handleViewerClose={handleViewerClose}
            handleVotingClose={handleVotingClose}
            handleReadAllMessage={handleReadAllMessage}
            handleOpenEditMessage={handleOpenEditMessage}
            handleOpenReplyMessage={handleOpenReplyMessage}
            setSelectForwardIndex={setSelectForwardIndex}
            listGroupChat={listGroupDB}
            handleCloseForwardDialog={handleCloseForwardDialog}
            handleOpenForwardDialog={handleOpenForwardDialog}
            handleDoneForwardDialog={handleDoneForwardDialog}
            handleOpenPinMessage={handleOpenPinMessage}
            handleFeeMessage={handleFeeMessage}
            listMessage={listMess || []}
            userInformation={userInformation}
            isFetchingMessage={isFetchingMessage}
            isFetchingNewGroup={groupQuery?.isLoading}
            detailMessage={detailMessage || {}}
            detailGroup={groupDetail?.data?.detail}
            handleOpenTipMessage={handleOpenTipMessage}
            handleDeleteMessage={handleDeleteMessage}
            handleChangeVote={handleChangeVote}
            handleOpenVoteInfo={handleOpenVoteInfo}
            open={openVoteInfoDialog}
            onClose={() => setOpenVoteInfoDialog(false)}
            voteInfo={voteInfo}
            handleScrollPin={handleScrollPin}
            addGroupChat={{
              handleAddSingleChat,
              handleAddGroupChat,
            }}
            idMediaCurrent={idMediaCurrent}
            setIdMediaCurrent={handleCloseMedia}
            // hanldeSetMessageAnchorReply={handleOpenReplyMessage}
          />
          <Footer
            isFetchingNewGroup={groupQuery?.isLoading}
            textRef={textRef}
            sendRef={sendRef}
            isVoice={isVoice}
            time={recordingTime}
            images={images}
            recordingBlob={recordingBlob}
            openEditMessage={openEditMessage}
            openReplyMessage={openReplyMessage}
            replyObject={messageReplyContent}
            isBlock={isBlock}
            setIsVoice={setIsVoice}
            startRecording={startRecording}
            togglePauseResume={stopRecording}
            messageChat={messageChat}
            setMessage={setMessage}
            handleSend={handleSend}
            handleKeypress={handleKeypress}
            handleCloseEditMessage={handleCloseEditMessage}
            handleCloseReplyMessage={handleCloseReplyMessage}
            onPaste={onPaste}
            handleDeleteImage={handleDeleteImage}
            handleUploadImage={handleUploadImage}
            isChangeText={
              textRef?.current?.value === "" ||
              prevText?.localeCompare(textRef?.current?.value) === 0
            }
            prevText={prevText}
            disabled={AdminNames.includes(groupDetail?.data?.group?.name)}
            isOpenTipMessage={openTipMessage}
            handleCloseTip={handleOpenTipMessage}
            handleTipMessage={handleTipMessage}
            pointUser={pointUser}
            handleSendTicker={handleSendTicker}
            handleVotingMessage={handleVotingMessage}
            detailGroup={groupDetail?.data?.detail}
            detailChanel={infoChanel}
            confirmEnterPoint={confirmUpdatePoint}
            listPoint={listPoint}
            setListPoint={setListPoint}
            handleOpenForwardDialog={handleOpenForwardDialog}
            setSharedLink={handleSetSharedLink}
            groupDetailKenhTv={groupDetail}
          />
        </Box>
      </Fade>
      <Fade in={isSelectGroup || !isMobileSize} unmountOnExit timeout={500}>
        <Box>
          <ChatInformation
            isMediumSize={isMediumSize}
            isOpenInformation={isOpenInformation}
            isOpenAddMemberChat={isOpenAddMemberChat}
            selectIndex={selectIndex}
            handleToggleInformation={handleToggleInformation}
            handleToggleAddMemberChat={handleToggleAddMemberChat}
            handleRemoveGroupChat={handleRemoveGroupChat}
            handleLeaveAndRemoveGroupChat={handleLeaveAndRemoveGroupChat}
            handleOpenAddMemberChat={handleOpenAddMemberChat}
            handleAddMemberChat={handleAddMemberChat}
            setSelectIndex={setSelectIndex}
            isOpenListMemberDialog={isOpenListMemberDialog}
            handleCloseListMember={handleCloseListMember}
            isOpenMultimediaDialog={isOpenMultimediaDialog}
            handleCloseMultimedia={handleCloseMultimedia}
            handleOpenMultimedia={handleOpenMultimedia}
            handleOpenListMemberPendingDialog={handleOpenListMemberPendingDialog}
            isOpenListMemberPendingDialog={isOpenListMemberPendingDialog}
            handleCloseListMemberPending={handleCloseListMemberPending}
            users={
              groupDetail?.data?.detail?.users?.filter((item) => item.id !== userInformation?.id) ||
              []
            }
            detailGroupFetching={groupDetail.isFetching}
            detailGroup={groupDetailDB?.detail || groupDetail?.data?.detail}
            idDetailGroup={groupDetail?.data?.detail?.id}
            handleSetSearch={handleSetSearch}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            listSearchVID={listGolfer || []}
            isAdmin={groupDetail?.data?.detail?.admin_ids?.includes(userInformation?.id)}
            handleChangePermissionGroup={handleChangePermissionGroup}
            handleRemoveUser={handleRemoveUser}
            handleOpenListMember={handleOpenListMember}
            listMessageMedia={listMessageMedia.data || []}
            handleTypeMedia={setTypeMedia}
            handleMuteGroupChat={handleMuteGroupChat}
            addGroupChat={{
              searchGolferKey,
              setSearchGolferKey,
              handleOpenAddGroup,
              handleAddSingleChat,
              handleAddGroupChat,
              handleSearchUser,
            }}
            conversationOptions={{
              handleOpenMessage,
            }}
            listGroupChat={searchUser ? filterGroup : listGroupDB}
            userInformation={userInformation}
            listGroupAdd={listGolfer}
            nameGroup={nameGroup}
            handleOpenNotificationAdminDialog={handleOpenNotificationAdminDialog}
            handleCloseNotificationAdminDialog={handleCloseNotificationAdminDialog}
            openNotificationAdminDialog={openNotificationAdminDialog}
            setMediaLimit={setMediaLimit}
            listMessageMediaReaload={listMessageMedia}
            listPendingJoinGroup={listPendingJoinGroup}
            handleRefuseMemberGroup={handleRefuseMemberGroup}
            handleApprovalMemberGroup={handleApprovalMemberGroup}
            updateSettingGroup={updateSettingGroup}
            handleOpenSettingGroup={handleOpenSettingGroup}
            handleCloseSettingGroup={handleCloseSettingGroup}
            isOpenSetting={isOpenSetting}
            infoChanel={infoChanel}
            addReporterChanelTv={addReporterChanelTv}
            removeReporterChanelTv={removeReporterChanelTv}
          />
        </Box>
      </Fade>
    </Box>
  );
}
