import styles from "assets/styles/pages/Chat/component/Unread";
import { Badge, Box, Button, Fade, IconButton, Typography } from "@mui/material";
import { ReactComponent as ReadIcon } from "assets/svg/icon/read_message.svg";
import { ReactComponent as UnreadUpIcon } from "assets/svg/icon/unread_up.svg";
import { useTranslation } from "react-i18next";

function Unread({ unreadMessage, handleReadAllMessage, isScrollEnd }) {
  const { t } = useTranslation();
  return (
    <Fade in={Boolean(unreadMessage) || isScrollEnd} timeout={200}>
      <Box sx={styles.scrollBottomContainer}>
        <Fade in={Boolean(unreadMessage)} timeout={200} unmountOnExit>
          <Button sx={styles.unreadButton} onClick={handleReadAllMessage}>
            <Typography sx={styles.unread} variant="body2">
              {t("chat.content_chat.unread")}
            </Typography>
            <UnreadUpIcon />
          </Button>
        </Fade>
        <IconButton onClick={handleReadAllMessage} sx={styles.scrollEndButton}>
          <Badge
            sx={styles.badge}
            badgeContent={unreadMessage}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <ReadIcon style={styles.scrollEndIcon} />
          </Badge>
        </IconButton>
      </Box>
    </Fade>
  );
}

export default Unread;
