import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import styles from "assets/styles/pages/Chat/component/InputPoint";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";
import { ReactComponent as Place } from "assets/svg/icon/place.svg";
import { ReactComponent as DoubleDot } from "assets/svg/icon/doubleDot.svg";
import { ReactComponent as RedIcon } from "assets/svg/icon/red.svg";
import { ReactComponent as BlueIcon } from "assets/svg/icon/blue.svg";
import { ReactComponent as DrawIcon } from "assets/svg/icon/draw.svg";
import { ReactComponent as WhiteIcon } from "assets/svg/icon/white.svg";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils/convert";
import BGRed from "../../../assets/images/bgRed.png";
import TeamRed from "../../../assets/images/borderRed.png";
import defaultAvatar from "../../../assets/svg/icon/default_avatar_group.svg";
import Score from "../../../assets/images/score_circle.png";
import BlueTeam from "../../../assets/images/borderBlue.png";
import BGBlue from "../../../assets/images/bgBlue.png";
import { STATUS_SCORE } from "../../../constant/chat";
// import { sendEventScore, updateScore } from "../../../api/chat";
// import {sendEventScore, updateScore} from "../../../api/chat";

export default function InputPoint({
  onClose,
  isOpenInputPoint,
  detailChanel,
  confirmEnterPoint,
  listPoint,
  setListPoint,
}) {
  // state
  // const [openConfirmInputPoint, setOpenConfirmInputPoint] = useState(false);
  if (!detailChanel) return null;
  const index_hole = detailChanel.hole_index;
  const holeName = detailChanel?.flight.score.find((score) => score.hole === +index_hole);
  const checkStart = listPoint.find((item) => item?.status);
  const { t } = useTranslation();
  const updateStatus = (hole, newStatus, indexChose) => {
    if (indexChose + 1 < +index_hole && !checkStart) {
      // setOpenConfirmInputPoint(true);
      window.confirm(`Vui lòng nhập điểm từ hố xuất phát ${holeName?.holeName}`);
      return;
    }
    const listWait = listPoint.slice(0, +index_hole - 1);
    const checkWait = listWait.find((item) => item?.status);

    const newListPoint = listPoint.map((item, index) => {
      if (item.hole === hole) {
        return {
          ...item,
          status: newStatus,
        };
      }
      if (index < indexChose && !item?.status) {
        if (!checkWait && index + 1 < +index_hole && indexChose + 1 >= +index_hole) {
          return item;
        }
        return {
          ...item,
          status: "none",
        };
      }
      if (index > indexChose && !item?.status) {
        if (indexChose + 1 < +index_hole && index + 1 > +index_hole) {
          return {
            ...item,
            status: "none",
          };
        }
        return item;
      }
      return item;
    });
    if (newListPoint.some((item) => item.status === "none" && item.hole !== hole.hole)) {
      const listHoleNone1 = newListPoint
        .filter((item) => item.status === "none" && item.hole !== hole.hole)
        .map((item) => item);
      const listHoleNone2 = listPoint
        .filter((item) => item.status === "none" && item.hole !== hole.hole)
        .map((item) => item);

      const commonNoneObjects = listHoleNone1.filter((item1) =>
        listHoleNone2.some((item2) => item1.hole === item2.hole)
      );

      // Lọc bỏ những đối tượng giống nhau có status là "none" khỏi listHoleNone1
      const filteredListHoleNone1 = listHoleNone1.filter(
        (item1) => !commonNoneObjects.some((item2) => item1.hole === item2.hole)
      );

      const holeNames = filteredListHoleNone1.map((item) => item?.holeName);
      const textListShow = holeNames.join(", ");

      // Replace with your ReactJS dialog/modal logic
      if (filteredListHoleNone1.length > 0) {
        if (window.confirm(`Bạn có chắc chắn muốn bỏ hố ${textListShow} không ?`)) {
          // Check if the current hole has a status other than "none"
          const holeExist = newListPoint.find((item) => item.hole === hole.hole);
          if (holeExist?.status !== "none") {
            setListPoint(newListPoint);
          }
        }
      } else {
        setListPoint(newListPoint);
      }
    } else {
      setListPoint(newListPoint);
    }
  };
  if (!detailChanel) return null;
  const getScore = () => {
    if (detailChanel) {
      const countBlue = detailChanel?.flight.score.filter(
        (item) => item.status && item.status === STATUS_SCORE.BLUE
      ).length;
      const countRed = detailChanel?.flight.score.filter(
        (item) => item.status && item.status === STATUS_SCORE.RED
      ).length;
      return { countBlue, countRed };
    }
    return null;
  };
  const getUserByTeam = (team) => {
    let idsTeam = [];
    if (detailChanel) {
      idsTeam = team
        .filter((item) => item != null)
        .map((u) => {
          const data = detailChanel?.list_users
            ?.filter((item) => item !== null)
            .find((user) => user.id === u.id);
          return { ...u, avatar: data.avatar, avatar_large: data.avatar_large };
        });
    }
    return idsTeam;
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          minWidth: "700px",
          width: "90%",
          maxHeight: "95%",
        },
      }}
      onClose={onClose}
      open={isOpenInputPoint}
      sx={styles.ScoreBox}
    >
      <DialogTitle sx={styles.dFlexColumn}>
        <Box />
        <Box sx={styles.dialogTitle}>
          <Typography variant="h3" sx={styles.title1}>
            {t("update_point.input_point")}
          </Typography>
        </Box>
      </DialogTitle>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box />
        <Typography sx={styles.title} variant="h6">
          {detailChanel?.flight?.play_type.name}
        </Typography>
        {detailChanel.is_endding_channel === 1 && <Button sx={styles.endChannel}>Kết thúc</Button>}
      </Box>
      <Grid container alignItems="center" sx={styles.placeAndTime}>
        <Grid sx={styles.facilityContainer} item>
          <Place />
          <Box>
            <Typography sx={styles.facility} variant="caption">
              {detailChanel.flight.facility_name}
            </Typography>
            <Box sx={{ display: "flex", ml: 0.5 }}>
              <Typography variant="body1" sx={styles.hole_index_text}>
                {t("update_point.starting_hole")}
              </Typography>
              <Typography variant="body1" sx={styles.hole_index}>
                {
                  detailChanel.flight.score.find(
                    (item) => +item.hole === +detailChanel.flight.hole_index
                  )?.holeName
                }
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={styles.TeeContainer}>
            <Box sx={() => styles.boxTeeTime({ colorTee: detailChanel.tee_id })} />
            <Typography>{formatDate(detailChanel.tee_time, "HH:mm DD/MM/YYYY")}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={styles.TeamPlay}>
        <Grid item>
          <Box style={{ position: "relative" }}>
            {getScore()?.countBlue <= getScore()?.countRed ? (
              <Box component="img" sx={styles.imageBorderRed} src={BlueTeam} />
            ) : (
              <Box component="img" sx={styles.imageBorderRed} src={BGBlue} />
            )}

            {getUserByTeam(detailChanel.flight.user_plays.team1).map((user, keyUser) => (
              <Box key={keyUser}>
                <Box
                  sx={() =>
                    styles.playerTeamRed({
                      indexUser: detailChanel.flight.user_plays.team1.filter((u) => u !== null)
                        .length,
                      keyUser,
                    })
                  }
                >
                  <Box sx={() => styles.avatarContainer({ direction: "left" })}>
                    <Avatar sx={styles.avatar} src={defaultAvatar} srcSet={user?.avatar} />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      sx={() =>
                        styles.fullname({
                          index: getScore()?.countBlue <= getScore()?.countRed,
                        })
                      }
                      variant="caption"
                    >
                      {user.username || user.name}
                    </Typography>
                    <Typography
                      sx={() =>
                        styles.vID({
                          index: getScore()?.countBlue <= getScore()?.countRed,
                        })
                      }
                      variant="caption"
                    >
                      {user.id}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={() =>
                    styles.ScoreRed({
                      indexUser: detailChanel.flight.user_plays.team1.filter((u) => u !== null)
                        .length,
                      keyUser,
                    })
                  }
                >
                  <Box sx={styles.scoreContainer}>
                    <Box sx={styles.imageCircle} component="img" src={Score} />
                    <Typography sx={styles.cap} variant="caption">
                      {user.cap}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
        {detailChanel.is_endding_channel === 1 && (
          <Grid item sx={styles.Score}>
            {getScore()?.countBlue === getScore()?.countRed && (
              <>
                <Typography sx={styles.TextABlue} variant="caption">
                  A
                </Typography>
                <Typography sx={styles.TextSRed} variant="caption">
                  S
                </Typography>
              </>
            )}
            {getScore()?.countBlue > getScore()?.countRed && (
              <>
                <Typography sx={styles.TextScoreBlue} variant="caption">
                  {getScore().countBlue - getScore().countRed}
                </Typography>
                <Typography sx={styles.TextBlue} variant="caption">
                  &
                </Typography>
                <Typography sx={styles.TextBlue} variant="caption">
                  {
                    detailChanel?.flight.score.filter((item) => item.status === STATUS_SCORE.NONE)
                      .length
                  }
                </Typography>
              </>
            )}
            {getScore()?.countBlue < getScore()?.countRed && (
              <>
                <Typography sx={styles.TextScoreRed} variant="caption">
                  {getScore().countRed - getScore().countBlue}
                </Typography>
                <Typography sx={styles.TextSRed} variant="caption">
                  &
                </Typography>
                <Typography sx={styles.TextSRed} variant="caption">
                  {
                    detailChanel?.flight.score.filter((item) => item.status === STATUS_SCORE.NONE)
                      .length
                  }
                </Typography>
              </>
            )}
          </Grid>
        )}
        {detailChanel.is_endding_channel !== 1 && (
          <Grid item sx={styles.Score}>
            {getScore() && (
              <Typography sx={styles.ScoreTeamBlue} variant="caption">
                {getScore()?.countBlue}
              </Typography>
            )}

            <Typography style={{ width: "20px" }} variant="caption">
              <DoubleDot />
            </Typography>
            {getScore() && (
              <Typography sx={styles.ScoreTeamRed} variant="caption">
                {getScore()?.countRed}
              </Typography>
            )}
          </Grid>
        )}

        <Box style={{ position: "relative" }}>
          <Grid item>
            {getScore()?.countBlue >= getScore()?.countRed ? (
              <Box component="img" sx={styles.imageBorderRed} src={TeamRed} />
            ) : (
              <Box component="img" sx={styles.imageBorderRed} src={BGRed} />
            )}

            {getUserByTeam(detailChanel.flight.user_plays.team2)
              .filter((u) => u !== null)
              .map((user, keyUser) => (
                <Box key={keyUser}>
                  <Box
                    sx={() =>
                      styles.playerTeamBlue({
                        indexUser: detailChanel.flight.user_plays.team2.filter((u) => u !== null)
                          .length,
                        keyUser,
                      })
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        sx={() =>
                          styles.fullname({
                            index: getScore()?.countBlue >= getScore()?.countRed,
                          })
                        }
                        variant="caption"
                      >
                        {user.username || user.name}{" "}
                      </Typography>
                      <Typography
                        sx={() =>
                          styles.vID({
                            index: getScore()?.countBlue >= getScore()?.countRed,
                          })
                        }
                        variant="caption"
                      >
                        {user.id}
                      </Typography>
                    </Box>
                    <Box sx={() => styles.avatarContainer({ direction: "right" })}>
                      <Avatar sx={styles.avatar} src={defaultAvatar} srcSet={user?.avatar} />
                    </Box>
                  </Box>
                  <Box
                    sx={() =>
                      styles.scoreBlue({
                        indexUser: detailChanel.flight.user_plays.team1.filter((u) => u !== null)
                          .length,
                        keyUser,
                      })
                    }
                  >
                    <Box sx={styles.scoreContainer}>
                      <Box sx={styles.imageCircle} component="img" src={Score} />
                      <Typography sx={styles.cap} variant="caption">
                        {user.cap}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Grid>
        </Box>
      </Grid>
      <TableContainer
        component={Paper}
        style={{ marginTop: "30px", border: "1px solid #D6D6D6", borderRadius: "0px" }}
      >
        <Table>
          <TableBody>
            {listPoint &&
              listPoint.map((hole, indexHole) => (
                <TableRow
                  key={indexHole}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onContextMenu={(event) => {
                    event.preventDefault();
                    updateStatus(hole.hole, "none", indexHole);
                  }}
                >
                  <TableCell align="center" style={{ border: "1px solid #D6D6D6", width: "33%" }}>
                    <Box
                      style={{ with: "100%", height: "100%" }}
                      onClick={() => updateStatus(hole.hole, "red", indexHole)}
                    >
                      {hole.status === "red" && <RedIcon />}
                      {hole.status === "draw" && <DrawIcon />}
                      <WhiteIcon />
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: "1px solid #D6D6D6", position: "relative", width: "33%" }}
                  >
                    <Box onClick={() => updateStatus(hole.hole, "draw", indexHole)}>
                      {hole?.holeName}
                    </Box>
                    {hole.status === "none" && (
                      <div
                        style={{
                          position: "absolute",
                          top: -3,
                          left: 70,
                          width: "20%",
                          height: "80%",
                          background:
                            "linear-gradient(-50deg, transparent, transparent 12px, red 5px, black 5px, transparent 15px)",
                        }}
                      />
                    )}
                  </TableCell>

                  <TableCell align="center" style={{ border: "1px solid #D6D6D6", width: "33%" }}>
                    <Box
                      style={{ with: "100%", height: "100%" }}
                      onClick={() => updateStatus(hole.hole, "blue", indexHole)}
                    >
                      {hole.status === "blue" && <BlueIcon />}
                      {hole.status === "draw" && <DrawIcon />}
                      <WhiteIcon />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        style={{ backgroundColor: "#005DB4", color: "white", height: "100px" }}
        onClick={() => {
          confirmEnterPoint();
          onClose();
        }}
      >
        {t("update_point.update_point")}
      </Button>
    </Dialog>
  );
}
