// import colors from "assets/theme/base/colors";
import { chatWidth, lgChatWidth, xlChatWidth, xxlChatWidth } from "constant/chat";

// const { initial } = colors;
export default {
  paper: {
    boxShadow: "none",
    borderRadius: "10px",
  },
  dFlex: {
    display: "flex",
  },
  list: {
    width: "100%",
    maxHeight: "200px",
    bgcolor: "background.paper",
    overflow: "auto",
    borderRadius: "10px",
  },
  listItem: {
    height: "50px",
  },
  popper: ({ breakpoints }) => ({
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    zIndex: "9999",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "10px",
    [breakpoints.up("md")]: {
      maxWidth: `calc(80% - ${lgChatWidth})`,
    },
    [breakpoints.up("sm")]: {
      width: "80%",
    },
    [breakpoints.up("xxl")]: {
      maxWidth: `calc(80% - ${chatWidth})`,
    },
    [breakpoints.up("xl")]: {
      maxWidth: `calc(80% - ${xxlChatWidth})`,
    },
    [breakpoints.up("lg")]: {
      maxWidth: `calc(80% - ${xlChatWidth})`,
    },
  }),

  hover: ({ index, selectedIndex }) => ({
    background: index === selectedIndex ? "lightgray" : "white",
  }),
  avatar: {
    pr: 2,
  },
  image: {
    width: "30px",
    height: "30px",
  },
  name: ({ typography }) => ({
    fontFamily: `${typography.fontFamily2} !important`,
    fontWeight: 600,
    fontSize: "14px",
  }),
  vid: ({ typography }) => ({
    fontSize: "12px",
    fontFamily: `${typography.fontFamily2} !important`,
    color: "#8A8A8D",
  }),
};
