/* eslint-disable no-promise-executor-return */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const stringToHTML = (embed) => {
  const parentEmbed = document.createElement("span");
  parentEmbed.innerHTML = embed;

  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);

  oldIframe.forEach((i) => {
    let url = oldIframe[i].getAttribute("url");
    url = url.replace("watch?v=", "embed/");

    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("width", "auto");
    newIframe.setAttribute("height", "auto");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    if (url) {
      newIframe.setAttribute("src", url);
    }
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  });

  return parentEmbed.outerHTML;
};

const emptyFunction = () => {};

const cmsColumnCalculator = (length) => {
  if (length > 4) return 12 / (4 + 1);
  return 12 / (length + 1);
};

function delay(time) {
  return new Promise((resolve) => setTimeout(() => resolve, time));
}

function isJSON(text) {
  if (typeof text !== "string") {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
}

const overrideDefault = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

export {
  emptyFunction,
  ScrollToTop,
  stringToHTML,
  cmsColumnCalculator,
  delay,
  isJSON,
  overrideDefault,
};
